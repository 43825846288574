import React, {
    FunctionComponent,
    MutableRefObject,
    useCallback,
    useEffect,
    useRef,
    useState
} from 'react';

import { observer } from 'mobx-react';

import { Stack } from '@mui/material';

import PropContainer from 'dataObj/PropContainer';
import CustomDataset from 'dataObj/customDataset';

import { ToolButtonType } from 'forms/interfaces';

import ToolbarButton from './ToolbarButton';

interface PropsType {
    buttons: ToolButtonType[];
    propContainer: PropContainer;
    containerWidth?: number;
    dataset?: CustomDataset;
}

const Buttons: FunctionComponent<PropsType> = observer(
    ({ containerWidth, buttons, propContainer, dataset }) => {
        const toolbarRef = useRef(null) as MutableRefObject<any>;

        const [breakpoint, setBreakpoint] = useState(0);

        useEffect(() => {
            toolbarRef?.current && setBreakpoint(toolbarRef?.current?.offsetWidth);
        }, [toolbarRef.current]);

        const handleBreakpoint = useCallback((node: HTMLDivElement) => {
            setBreakpoint(node?.offsetWidth);
        }, []);

        return containerWidth !== undefined ? (
            <Stack ref={handleBreakpoint} spacing={1} direction="row">
                {buttons.map(button => (
                    <ToolbarButton
                        key={button.guid}
                        descr={button}
                        propContainer={propContainer}
                        fullWidth={containerWidth >= breakpoint}
                        dataset={dataset}
                    />
                ))}
            </Stack>
        ) : null;
    }
);

export default Buttons;
