import React from 'react';
import { IMaskInput } from 'react-imask';

import { isRegExpString, convertStrToRegExpObj } from 'forms/form-utils';

interface TextMaskProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const TextMaskCustom = (mask: string) =>
    React.forwardRef<HTMLElement, TextMaskProps>((props, inputRef) => {
        const { onChange, ...other } = props;
        const isRegExp = isRegExpString(mask);
        const preparedMask = isRegExp ? convertStrToRegExpObj(mask) : mask;
        return (
            <IMaskInput
                {...other}
                mask={preparedMask}
                inputRef={inputRef as any}
                onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
                overwrite
            />
        );
    });

export default TextMaskCustom;
