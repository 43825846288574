import React, { FunctionComponent, useCallback } from 'react';
import { observer } from 'mobx-react';

import MUIButton from '@mui/material/Button';
import { IconButton, Tooltip } from '@mui/material';
import { Add, Delete, Edit, Code, Visibility } from '@mui/icons-material';

import { ControlPropsType, ToolButtonType } from 'forms/interfaces';
import SVGIcon from 'forms/controls/SVGIcon';

import useScriptHandler from 'hooks/scriptHandler';
import useCtrlProps from 'hooks/ctrlProps';

import ConfigurationStore from 'store/configurationStore';
import ActionStore from 'store/actionStore';
import CustomDataset from 'dataObj/customDataset';

import { add, del, view, edit } from 'store/actionStore/dataset/actions';

import ScriptButton from 'forms/controls/ScriptButton/ScriptButton';

interface PropsType extends ControlPropsType {
    descr: ToolButtonType;
    fullWidth?: boolean;
    dataset?: CustomDataset;
}

const getIcon = (action?: string) => {
    switch (action) {
        case add:
            return <Add />;

        case view:
            return <Visibility />;

        case edit:
            return <Edit />;

        case del:
            return <Delete />;

        default:
            return <Code />;
    }
};

const ToolbarButton: FunctionComponent<PropsType> = observer(
    ({ descr, fullWidth, dataset, propContainer }) => {
        const { locale, content } = ConfigurationStore;

        const { guid, caption, onClickScript, params, variant, iconSet, icon, items } = descr;

        const datasetActions = ActionStore.getDatasetActions(dataset?.guid);

        const { ctrlEnabled, ctrlVisible, ctrlHidden } = useCtrlProps(propContainer, guid);

        if (ctrlHidden || !ctrlVisible) return null;

        const handleScript = useScriptHandler(onClickScript, propContainer, params);

        const handleOnClick = async () => {
            onClickScript && (await handleScript());

            if ('action' in descr && descr.action && datasetActions) {
                const dsAction = datasetActions.getAction(descr.action);

                dsAction && (await dsAction(propContainer));
            }
        };

        const getCaption = useCallback(
            (action?: string) => {
                switch (action) {
                    case add:
                        return content.controls.buttons.add;

                    case view:
                        return content.controls.buttons.view;

                    case edit:
                        return content.controls.buttons.change;

                    case del:
                        return content.controls.buttons.delete;

                    default:
                        return '';
                }
            },
            [locale]
        );

        if (items?.length) {
            return (
                <ScriptButton
                    descr={descr}
                    propContainer={propContainer}
                    icon={!fullWidth}
                    style={{ width: 'inherit' }}
                />
            );
        }

        return fullWidth ? (
            <MUIButton
                variant={variant || 'contained'}
                onClick={() => handleOnClick()}
                disabled={!ctrlEnabled}
                sx={{
                    whiteSpace: 'nowrap',
                    visibility: ctrlVisible ? 'inherit' : 'hidden'
                }}
                color={descr.action === del ? 'warning' : undefined}
                startIcon={
                    descr.action ? (
                        getIcon(descr.action)
                    ) : (
                        <SVGIcon descr={descr} propContainer={propContainer} />
                    )
                }
            >
                {caption || getCaption(descr.action)}
            </MUIButton>
        ) : (
            <Tooltip title={caption}>
                <span>
                    <IconButton
                        onClick={() => handleOnClick()}
                        disabled={!ctrlEnabled}
                        color={descr.action === del ? 'warning' : undefined}
                    >
                        {iconSet && icon ? (
                            <SVGIcon descr={descr} propContainer={propContainer} />
                        ) : (
                            getIcon(descr.action)
                        )}
                    </IconButton>
                </span>
            </Tooltip>
        );
    }
);

export default ToolbarButton;
