/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import isEmpty from 'lodash/isEmpty';

import Box from '@mui/material/Box';
import { TreeItem }  from '@mui/x-tree-view'
import FolderOpenIcon from '@mui/icons-material/FolderOpen';

import DataStock from 'dataObj/DataStock';

// eslint-disable-next-line import/no-cycle
import DatasetTreeItem from './DatasetTreeItem';

interface PropsType {
    node: any;
    parentKey?: number | string;
    nodeKey?: number | string;
    dataStock: DataStock;
    setActiveFilter: (value: string) => unknown;
    handleNodeSelect: (value: string) => unknown;
}

const DatasetTreeNode: FunctionComponent<PropsType> = observer(
    ({ node, parentKey, nodeKey, dataStock, setActiveFilter, handleNodeSelect }) => {
        const { datasetName } = node;
        const dataset = dataStock.getDatasetObj(datasetName);
        const { masterDS } = dataset;

        const isDisabled = !!parentKey && isEmpty(masterDS);

        const onNodeSelect = (nodeId: any) => {
            if (isDisabled) return;
            handleNodeSelect(nodeId);
            setActiveFilter(dataset?.name);
        };

        return node ? (
            <Box>
                {node.caption.length > 0 ? (
                    <TreeItem
                        key={nodeKey || node.guid}
                        nodeId={nodeKey || node.guid}
                        label={node.caption}
                        icon={<FolderOpenIcon />}
                        disabled={isDisabled}
                        onClick={() => onNodeSelect(nodeKey || node.guid)}
                    >
                        <DatasetTreeItem
                            dataStock={dataStock}
                            parentKey={parentKey}
                            nodeDescr={node}
                            setActiveFilter={setActiveFilter}
                            handleNodeSelect={handleNodeSelect}
                        />
                    </TreeItem>
                ) : (
                    <DatasetTreeItem
                        dataStock={dataStock}
                        parentKey={parentKey}
                        nodeDescr={node}
                        setActiveFilter={setActiveFilter}
                        handleNodeSelect={handleNodeSelect}
                    />
                )}
            </Box>
        ) : null;
    }
);

export default DatasetTreeNode;
