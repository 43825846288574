import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import styles from './ExternalForm.module.scss';

import ExtRefsPage from '../../components/resources/ResEditor/ExtRefsPage';
import CustomForm from '../../forms/CustomForm';

import ConfigurationStore from '../../store/configurationStore';

import Empty from '../../components/utils/Empty/Empty';

interface GetComponentOptionsType {
    component: string;
    guid?: string;
    table?: string;
    tableId?: string;
    extParamVals: any;
}

const getComponent = ({
    component,
    guid,
    table,
    tableId,
    extParamVals
}: GetComponentOptionsType) => {
    const { content } = ConfigurationStore;

    switch (component) {
        case 'ext-ref-page':
            return <ExtRefsPage />;

        case 'form':
            return guid ? (
                <CustomForm key={guid} formGuid={guid} extParamVals={extParamVals} />
            ) : (
                <Empty message={content.application.external.error.formId} />
            );

        case 'edit':
            return table ? (
                <CustomForm
                    key={table}
                    formGuid={table}
                    tableId={tableId}
                    extParamVals={extParamVals}
                    initEditMode={tableId ? 'edit' : null}
                />
            ) : (
                <Empty message={content.application.external.error.gridName} />
            );

        default:
            return <Empty message={content.application.external.error.pageExists} />;
    }
};

const b64toObj = (b64string: string | null) => {
    if (!b64string) return null;

    try {
        const str = atob(b64string); //
        return JSON.parse(str) as string;
    } catch (err: any) {
        console.log(err.message);
    }

    return null;
};

const ExternalForm = observer(() => {
    interface ParamsType {
        [key: string]: any;
    }

    const params: any = useParams();

    const [guid, setGuid] = useState('');
    const [table, setTable] = useState('');
    const [tableId, setTableId] = useState('');
    const [extParamVals, setExtParamVals] = useState({});

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);

        if (ConfigurationStore.isAuthenticated) {
            const tmpParams: ParamsType = {};

            setGuid(queryParams.get('formGuid') as string);
            setTable(queryParams.get('table') as string);
            setTableId(queryParams.get('id') as string);

            for (const key of queryParams.keys())
                if (['token', 'formGuid', 'table', 'id'].indexOf(key) === -1)
                    tmpParams[key] = queryParams.get(key);

            setExtParamVals(tmpParams);
        }
    }, []);

    const getCurrentComponent = () => {
        // В случае когда авторизация прошла успешно выводим форму
        if (ConfigurationStore.isAuthenticated) {
            return getComponent({ component: params.form, guid, table, tableId, extParamVals });
        }

        // В противном случае делаем вывод о недействительности токена -
        // если при запросе вывалится 401 ошибка, то произойдёт логаут и мы попадаем сюда
        return <Empty message={ConfigurationStore.content.application.auth.tokenInvalid} />;
    };

    return <div className={styles.wrapper}>{getCurrentComponent()}</div>;
});

export default ExternalForm;
