import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';

import { Box } from '@mui/material';

import { useTheme } from '@mui/material/styles';

import useCtrlData from 'hooks/ctrlData';
import useCtrlProps from 'hooks/ctrlProps';

import { ControlPropsType, DBIframeType } from 'forms/interfaces';

import Loading from 'components/utils/Loading/Loading';

interface PropsType extends ControlPropsType {
    descr: DBIframeType;
}

const DBIframe: FunctionComponent<PropsType> = ({ descr, propContainer }) => {
    const { guid, datasetName, fieldName } = descr;
    const { dataset, dataVal } = useCtrlData(propContainer, datasetName, fieldName);
    const { ctrlVisible } = useCtrlProps(propContainer, guid);
    const theme = useTheme();

    const valueToText = useCallback(
        val => (val === undefined || val === null ? '' : val),
        [dataVal]
    );

    const [src, setSrc] = useState(() => valueToText(dataVal));

    const styles = {
        root: {
            position: 'relative',
            height: '100%',
            padding: theme.spacing(2),
            maxHeight: `calc(100% - ${theme.spacing(4)}`,
            maxWidth: `calc(100% - ${theme.spacing(4)}`,
            overflow: 'hidden'
        }
    } as const;

    useEffect(() => {
        if (dataset) setSrc(valueToText(dataVal));
    }, [dataset, dataVal, valueToText]);

    const renderLoading = () => (dataset ? <Loading delay /> : <Loading type="error" />);

    return ctrlVisible ? (
        <Box style={styles.root}>
            {dataset?.hasData() ? (
                <iframe title={descr.name} src={src} frameBorder="0" width="100%" height="100%" />
            ) : (
                renderLoading()
            )}
        </Box>
    ) : null;
};

export default DBIframe;
