import { MarkerType } from 'reactflow';

import { TTNodeType } from '../TaskTree/interface';
import { EdgeElement } from './interfaces';

const checkGroupNode = (data: Array<TTNodeType>, node: TTNodeType) =>
    !!data.filter(n => n.parent_tt_node_id === node.tt_node_id).length;

const checkEmptyNode = (node: TTNodeType) => !node.label && !node.task_number && !node.task_name;

const getPrevNodeId = (data: Array<TTNodeType>, node: TTNodeType): string | undefined => {
    const parentNode = data.find(n => n.tt_node_id === node.parent_tt_node_id);
    const prevNode = data.find(
        n => n.parent_tt_node_id === node.parent_tt_node_id && n.exec_order === node.exec_order - 1
    );

    if (parentNode?.exec_mode === 'P') {
        return getPrevNodeId(data, parentNode);
    }

    if (node.exec_order >= 0 && parentNode?.exec_mode === 'C')
        return prevNode?.tt_node_id.toString();

    return;
};

export const makeNodes = (parentTask: TTNodeType, data: Array<TTNodeType>) => {
    const nodeList = data
        .filter(node => !checkEmptyNode(node))
        .sort((n1, n2) => n1.child_level - n2.child_level)
        .map(node => ({
            id: node.tt_node_id.toString(),
            type: 'selectorNode',
            draggable: false,
            data: {
                number: node.task_number,
                deadline: node.task_number,
                title: node.task_name || node.label,
                status: node.state
            }
        }));

    if (parentTask)
        nodeList.push({
            id: parentTask.tt_node_id.toString(),
            type: 'selectorNode',
            data: {
                number: parentTask.task_number,
                deadline: parentTask.task_number,
                title: parentTask.task_name || parentTask.label,
                status: parentTask.state,
                draggable: false,
                user: `${parentTask?.surname || ''} ${
                    parentTask?.name
                        ?.split(' ')
                        .map(s => `${s[0]}.`)
                        .join('') ?? ''
                }`
            }
        } as any);

    return nodeList;
};

export const makeEdges = (data: Array<TTNodeType>) => {
    const edgesList: EdgeElement[] = [];

    data.sort((n1, n2) => n1.child_level - n2.child_level).forEach(node => {
        const prevNodeId = getPrevNodeId(data, node);

        if (prevNodeId) {
            const prevNode = data.find(n => n.tt_node_id.toString() === prevNodeId);

            if (prevNode && checkGroupNode(data, prevNode)) {
                if (prevNode.exec_mode === 'C') {
                    const childNodes = data
                        .filter(n => n.parent_tt_node_id === prevNode.tt_node_id)
                        .sort((n1, n2) => n2.exec_order - n1.exec_order);

                    if (childNodes?.length) {
                        const lastChildNode = childNodes[0];

                        edgesList.push({
                            id: `${lastChildNode.tt_node_id}-${node.tt_node_id}`,
                            source: lastChildNode.parent_tt_node_id.toString(),
                            target: node.tt_node_id.toString(),
                            markerEnd: {
                                type: MarkerType.ArrowClosed
                            }
                        });
                    }
                } else {
                    const childNodes = data.filter(
                        n => n.parent_tt_node_id === prevNode.tt_node_id
                    );

                    if (childNodes?.length) {
                        childNodes.forEach(childNode => {
                            edgesList.push({
                                id: `${childNode.tt_node_id}-${node.tt_node_id}`,
                                source: childNode.tt_node_id.toString(),
                                target: node.tt_node_id.toString(),
                                markerEnd: {
                                    type: MarkerType.ArrowClosed
                                }
                            });
                        });
                    }
                }
            }
        }

        edgesList.push({
            id: `${node.parent_tt_node_id}-${node.tt_node_id}`,
            source: prevNodeId || node.parent_tt_node_id.toString(),
            target: node.tt_node_id.toString(),
            markerEnd: {
                type: MarkerType.ArrowClosed
            }
        });
    });

    return edgesList;
};
