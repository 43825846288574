import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import Prism from 'prismjs';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-json';
import 'prismjs/themes/prism.css';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import NotificationStore from '../../store/notificationStore';

import { dateToString } from '../../utils';

const dateFormat = 'DD.MM.YYYY HH:mm:ss';

const ErrorList: FunctionComponent = observer(() => {
    const { apiErrors, showErrors, hideApiErrors } = NotificationStore;
    const [expanded, setExpanded] = React.useState<number | null>(null);

    const handleChange = (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : null);
    };

    const handleClose = () => {
        hideApiErrors();
        setExpanded(null);
    };

    const getHighlightedError = (message: string) => {
        const formattedMessage = JSON.stringify(message, null, 2);
        const highlighted = Prism.highlight(formattedMessage, Prism.languages.json, 'json');

        return (
            <pre className="language-json">
                {/* eslint-disable-next-line react/no-danger */}
                <code
                    dangerouslySetInnerHTML={{ __html: highlighted }}
                    style={{
                        whiteSpace: 'pre-wrap',
                        fontSize: 12,
                        fontFamily: '"Fira code", "Fira Mono", monospace'
                    }}
                />
            </pre>
        );
    };

    const getAccordionSummary = (error: any) => {
        const registeredTime = dateToString(error.timestamp, dateFormat);
        return (
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-content">
                <Typography sx={{ width: '85%', flexShrink: 0 }} variant="subtitle2">
                    {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
                    {`${error.method} ${error.url} (${error.statusText})`}
                </Typography>
                <Typography variant="subtitle2">{`${registeredTime}`}</Typography>
            </AccordionSummary>
        );
    };

    const getErrorList = () => (
        <>
            {apiErrors.map((error, index) => (
                <Accordion key={index} expanded={expanded === index} onChange={handleChange(index)}>
                    {getAccordionSummary(error)}
                    <AccordionDetails>
                        <Typography variant="subtitle1">Payload:</Typography>
                        {error.payload && getHighlightedError(error.payload)}
                        <Typography variant="subtitle1">Response:</Typography>
                        {error.responseMessage && getHighlightedError(error.responseMessage)}
                    </AccordionDetails>
                </Accordion>
            ))}
        </>
    );

    return (
        <Dialog fullWidth maxWidth="lg" sx={{ pt: 12 }} open={showErrors}>
            <DialogTitle>Список ошибок</DialogTitle>
            <DialogContent>{getErrorList()}</DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" autoFocus>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default ErrorList;
