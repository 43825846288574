import {
    useState,
    useRef,
    forwardRef,
    useImperativeHandle,
    FocusEvent,
    KeyboardEvent,
    MouseEvent,
    ReactNode,
    HTMLAttributes,
    RefAttributes
} from 'react';

import Menu, { MenuProps } from '@mui/material/Menu';
import { MenuItemProps } from '@mui/material/MenuItem';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, ListItemIcon, ListItemText } from '@mui/material';

import MenuItem from '../MenuItem';
import SVGIcon from '../../SVGIcon';

import { ControlPropsType, MenuItemType } from '../../../interfaces';

interface NestedMenuItemPropsType extends ControlPropsType, Omit<MenuItemProps, 'button'> {
    descr: MenuItemType;
    parentMenuOpen?: boolean;
    children?: ReactNode;
    tabIndex?: number;
    disabled?: boolean;
    ContainerProps?: HTMLAttributes<HTMLElement> & RefAttributes<HTMLElement | null>;
    menuProps?: Partial<Omit<MenuProps, 'children'>>;
    button?: true | undefined;
    closeMenu?: any;
}

const NestedMenuItem = forwardRef<HTMLLIElement | null, NestedMenuItemPropsType>((props, ref) => {
    const {
        descr,
        propContainer,
        parentMenuOpen = false,
        children,
        tabIndex: tabIndexProp,
        ContainerProps: ContainerPropsProp = {},
        menuProps,
        closeMenu,
        ...menuItemProps
    } = props;

    const { ref: containerRefProp, ...ContainerProps } = ContainerPropsProp;

    const menuItemRef = useRef<any>(null);
    useImperativeHandle(ref, () => menuItemRef?.current);

    const containerRef = useRef<HTMLLIElement>(null);
    useImperativeHandle(containerRefProp, () => containerRef.current);

    const menuContainerRef = useRef<HTMLLIElement>(null);

    const [subMenuOpen, setSubMenuOpen] = useState(false);

    const handleMouseEnter = (e: MouseEvent<HTMLElement>) => {
        setSubMenuOpen(true);

        if (ContainerProps.onMouseEnter) {
            ContainerProps.onMouseEnter(e);
        }
    };
    const handleMouseLeave = (e: MouseEvent<HTMLElement>) => {
        setSubMenuOpen(false);

        if (ContainerProps.onMouseLeave) {
            ContainerProps.onMouseLeave(e);
        }
    };

    const isSubmenuFocused = () => {
        const active = containerRef?.current?.ownerDocument?.activeElement;
        if (menuContainerRef?.current?.children) {
            for (const child of menuContainerRef.current.children) {
                if (child === active) {
                    return true;
                }
            }
        }
        return false;
    };

    const handleFocus = (e: FocusEvent<HTMLElement>) => {
        if (e.target === containerRef.current) {
            setSubMenuOpen(true);
        }

        if (ContainerProps.onFocus) {
            ContainerProps.onFocus(e);
        }
    };

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            return;
        }

        if (isSubmenuFocused()) {
            e.stopPropagation();
        }

        const active = containerRef?.current?.ownerDocument.activeElement;

        if (e.key === 'ArrowLeft' && isSubmenuFocused()) {
            containerRef?.current?.focus();
        }

        if (e.key === 'ArrowRight' && e.target === containerRef.current && e.target === active) {
            const firstChild = menuContainerRef?.current?.children[0] as any;
            firstChild?.focus();
        }
    };

    const open = subMenuOpen && parentMenuOpen;

    let tabIndex;
    if (!props.disabled) {
        tabIndex = tabIndexProp !== undefined ? tabIndexProp : -1;
    }

    return (
        <Box
            {...ContainerProps}
            ref={containerRef as any}
            onFocus={handleFocus}
            tabIndex={tabIndex}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onKeyDown={handleKeyDown}
        >
            <MenuItem
                descr={descr}
                propContainer={propContainer}
                ref={menuItemRef}
                closeMenu={closeMenu}
                {...menuItemProps}
            >
                {descr.icon ? (
                    <ListItemIcon style={{ color: 'inherit' }}>
                        <SVGIcon descr={descr} propContainer={propContainer} fontSize="small" />
                    </ListItemIcon>
                ) : null}
                <ListItemText>{descr.caption}</ListItemText>
                <ChevronRightIcon />
            </MenuItem>
            <Menu
                style={{ pointerEvents: 'none' }}
                anchorEl={menuItemRef.current}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                open={open}
                autoFocus={false}
                disableAutoFocus
                disableEnforceFocus
                onClose={() => {
                    setSubMenuOpen(false);
                }}
                {...menuProps}
            >
                <div ref={menuContainerRef as any} style={{ pointerEvents: 'auto' }}>
                    {children}
                </div>
            </Menu>
        </Box>
    );
});

export default NestedMenuItem;
