import { useEffect, useState } from 'react';

import CustomDataset, { dsStates } from '../dataObj/customDataset';
import { ContentType } from '../localization/interfaces';

export default function useDatasetLoading(dataset: CustomDataset, content: ContentType) {
    const [loading, setLoading] = useState(true);

    const getMessage = () => {
        if (!dataset) return content.controls.data.noDataset;

        switch (dataset.state) {
            case dsStates.dsLoading:
            case dsStates.dsWaiting:
                return content.controls.data.loading;
            case dsStates.dsBrowse:
                return !dataset.recCount
                    ? content.controls.data.noData
                    : content.controls.data.loading;
            case dsStates.dsError:
                return content.controls.data.error;
            default:
                return content.controls.data.noData;
        }
    };

    const getType = (): 'loading' | 'warning' | 'error' | 'broken' | 'empty' => {
        if (!dataset) return 'error';

        switch (dataset?.state) {
            case dsStates.dsLoading:
            case dsStates.dsWaiting:
                return 'loading';
            case dsStates.dsBrowse:
                return !dataset.recCount ? 'empty' : 'loading';
            case dsStates.dsError:
                return 'error';
            default:
                return 'empty';
        }
    };

    useEffect(() => {
        if (dataset?.hasData() && dataset?.data?.length) {
            setLoading(false);
        } else setLoading(true);
    }, [dataset, dataset?.state]);

    return { loading, loadingType: getType(), loadingMessage: getMessage() };
}
