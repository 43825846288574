import { FunctionComponent, useState } from 'react';

import TextField, { TextFieldProps } from '@mui/material/TextField';

import { EventType } from '../../ResInterfaces';

interface PropsType extends Omit<TextFieldProps, 'input'> {
    changeValue: (value: string) => unknown;
}

const InputField: FunctionComponent<PropsType> = ({ changeValue, ...otherProps }) => {
    const [localValue, setLocalValue] = useState<string>('');

    const handleChange = (event: EventType) => {
        setLocalValue(event.target.value ?? '');
    };

    const handleBlur = () => {
        changeValue(localValue);
    };

    return (
        <TextField
            size="small"
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            {...otherProps}
        />
    );
};

export default InputField;
