import { FunctionComponent, useEffect, useState, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';

import { Box } from '@mui/material';

import useDBEdit from 'hooks/dbEdit';
import { getLocalizedString } from 'utils';

import DatePicker from 'components/Inputs/DateTimePicker';

import { ControlPropsType, DBDateTimeType } from 'forms/interfaces';
import ActionStore from 'store/actionStore';

interface PropsType extends ControlPropsType {
    descr: DBDateTimeType;
    dataRow: any;
    index?: number;
}

const DBEdit: FunctionComponent<PropsType> = observer(
    ({ descr, propContainer, dataRow, index }) => {
        const {
            dataset,
            fieldName,
            dataVal,
            fieldRequired,
            ctrlEnabled,
            ctrlVisible,
            onScreen,
            editRef,
            fieldDescr
        } = useDBEdit(descr, propContainer, dataRow);

        const formActions = ActionStore.getFormActions(propContainer.getCurrentForm()?.descr.guid);

        const [value, setValue] = useState(() => new Date(dataVal));
        const [error, setError] = useState<string | null>(null);

        const label = useMemo(() => getLocalizedString(descr.label), [descr.label]);

        const handleError = (errMsg: string | null) => {
            setError(errMsg);
            formActions.setValidation(!errMsg);
        };

        const handleChange = useCallback(
            (newValue: Date) => {
                if (ctrlEnabled) {
                    setValue(newValue);
                    dataset.setFieldValue(fieldName, newValue, index);
                }
            },
            [descr]
        );

        useEffect(() => {
            setValue(dataVal);
        }, [dataVal]);

        useEffect(() => {
            propContainer.setPageRequirement(descr.name, fieldRequired && ctrlEnabled && !value);
        }, [value, fieldRequired, ctrlEnabled]);

        const props = {
            type: fieldDescr?.dataType as 'KRN_DATE' | 'KRN_DATETIME' | 'KRN_TIME',
            withTime: !!descr.withTime,
            label,
            value,
            error,
            required: fieldRequired,
            disabled: !ctrlEnabled,
            handleChange,
            handleError
        };

        return (
            <Box
                ref={editRef}
                sx={{
                    width: '100%',
                    p: 2,
                    boxSizing: 'border-box',
                    visibility: ctrlVisible ? 'inherit' : ('hidden' as any)
                }}
                className={fieldRequired && onScreen && ctrlEnabled ? 'required-field' : ''}
            >
                <DatePicker {...props} />
            </Box>
        );
    }
);

export default DBEdit;
