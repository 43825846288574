import { v4 as uuidv4 } from 'uuid';

import Dataset, { DatasetType } from './customDataset';
import ClientDataset from './ClientDataset';
import CDO from './CDO';
import { CDOType } from 'forms/interfaces';
import DataStore from '../store/dataStore';

import { jsonFetch } from '../utils';
import { collectDatasetDescriptors, collectDataset } from '../forms/form-utils';
import PropContainer, { controlElement } from './PropContainer';
import { FormType, ParamType, SimpleObject } from 'forms/interfaces';
import { CDODataStruct, ErrAccum, ResAccum, UpdateCDOResult, UpdateResult } from './dataInterfaces';

export default class DataStock {
    readonly guid: string;
    readonly formGuid: string;
    readonly ownerPropContainer: PropContainer;
    readonly CDOs: CDO[];
    readonly data: Record<string, Dataset>;
    readonly datasets: Dataset[];
    readonly scriptClient: null; // TODO: выяснить зачем?
    readonly formDescr: FormType;
    extParamVals: { [key: string]: any };
    phantomId: number;

    constructor(formDescr: FormType, ownerPropContainer: PropContainer) {
        this.guid = uuidv4();
        this.formGuid = formDescr?.guid;
        this.ownerPropContainer = ownerPropContainer;
        // массив CDO
        this.CDOs = [];
        // объект датасетов, как свободных, так и принадлежащих CDO
        this.data = {};
        // массив датасетов, как свободных, так и принадлежащих CDO
        this.datasets = [];
        this.scriptClient = null;
        this.extParamVals = {};
        this.formDescr = formDescr;
        this.phantomId = -1;

        // регистрируем CDO
        this.registerCDOs(this.formDescr.dataObjects);
        // регистрируем свободные датасеты, не входящие в CDO
        this.registerDatasets(this.formDescr.datasets);
    }

    async loadData(
        extParamVals: { [key: string]: any },
        newRecord: boolean,
        cdoData: CDODataStruct
    ) {
        // функция инициализации клиентских датасетов
        // вызывается после чтения данных из БД
        const initClientDatasets = async () => {
            for (let i = 0; i < this.datasets.length; i++) {
                const ds = this.datasets[i];
                if (ds.descr.type === 'clientDataset')
                    await (ds as ClientDataset).appendUniqueRecord();
            }
        };

        this.extParamVals = extParamVals;

        // читаем CDO
        for (let i = 0; i < this.CDOs.length; i++) {
            cdoData
                ? this.CDOs[i].parseDatasetsCollection(undefined, cdoData)
                : await this.CDOs[i].loadData(newRecord);
        }

        // читаем свободные датасеты
        return new Promise<void>(async resolve => {
            let datasets = this.getFreeDatasets().filter(
                ds => ds.descr.autoOpen && ds.descr.type !== 'clientDataset'
            ) as Dataset[];

            if (newRecord) {
                datasets = datasets.filter(ds => !ds.descr.editable);
            }

            if (datasets.length) {
                DataStore.taskQueue
                    .addLoadTask(datasets, async () => {
                        await initClientDatasets();
                        resolve();
                    })
                    .catch(err => console.error(err.message));
            } else {
                await initClientDatasets();
                resolve();
            }
        });
    }

    calcIdList(paramVals: any, sourceField: string, paramName: string) {
        if (paramVals) {
            if (typeof paramVals === 'object') {
                if (paramVals[sourceField || paramName]) {
                    return paramVals[sourceField || paramName];
                }
                return null;
            }
            return paramVals;
        }
        return null;
    }

    getParams(params: ParamType[]) {
        const parObj: SimpleObject = {};
        params.forEach(par => {
            let val = null;

            switch (par.paramType) {
                case 'af':
                    val = DataStore.AF.getAF(par.sourceField || par.sourceAF);
                    break;
                case 'field':
                    const ds = this.getDatasetObj(par.sourceDataset);
                    if (ds) {
                        val = ds.getFieldValue(par.sourceField);
                    } else {
                        console.error(`Dataset ${par.sourceDataset} does not exist!`);
                    }
                    break;
                case 'afServer':
                    val = null;
                    break;
                case 'const':
                    val = par.value;
                    break;
                case 'extern':
                case 'idList':
                    val = this.calcIdList(this.extParamVals, par.sourceField, par.paramName);
                    break;

                default:
                    console.warn('Unknown paramType', par);
            }
            parObj[par.paramName] = val !== undefined ? val : null;
        });
        return parObj;
    }

    analizeDataset(descr: DatasetType, ds: Dataset, masterDS?: Dataset) {
        if (masterDS) {
            ds.masterDS = masterDS;

            descr.fields?.forEach(fldDescr => {
                if (fldDescr.masterField) {
                    masterDS.addDependentDataset(fldDescr.masterField, ds);
                }
            });
        }

        descr.params?.forEach(p => {
            if (p.paramType === 'af' && p.sourceAF) {
                ds.masterAFs.push(p.sourceAF);
            }
            if (p.paramType === 'field' && p.sourceDataset) {
                const parentDS = this.getDatasetObj(p.sourceDataset);
                if (parentDS) {
                    parentDS.addDependentDataset(p.sourceField, ds);
                }
            }
        });
    }

    registerCDOs(cdosDescr: CDOType[]) {
        cdosDescr.forEach(cdoDescr => {
            const cdo = new CDO({ dataStock: this, descr: cdoDescr });
            this.CDOs.push(cdo);
            this.registerDatasets(cdoDescr.datasets, cdo);
        });
    }

    registerDatasets(datasetsDescr: DatasetType[], cdo?: CDO) {
        datasetsDescr.forEach(dsDescr => this.registerDataset(dsDescr, undefined, cdo));
    }

    registerDataset(descr: DatasetType, masterDS?: Dataset, cdo?: CDO) {
        let ds: Dataset | ClientDataset = this.data[descr.name];
        if (!ds) {
            ds =
                descr.type === 'clientDataset'
                    ? new ClientDataset({ dataStock: this, descr, cdo })
                    : new Dataset({ dataStock: this, descr, cdo });
            this.data[descr.name] = ds as Dataset;
            ds.index = this.datasets.push(ds as Dataset) - 1;

            // запоминаем от каких master-датасетов
            // и активных фильтров зависит запрос

            if (descr.type !== 'clientDataset') {
                this.analizeDataset(descr, ds as Dataset, masterDS);
                descr.datasets?.forEach(cldDescr =>
                    this.registerDataset(cldDescr, ds as Dataset, cdo)
                );
            }

            ds.readOptions();
        }

        return ds;
    }

    dropDataset(name: string) {
        const ds = this.data[name];
        if (ds) {
            const ind = this.datasets.indexOf(ds);
            if (ind >= 0) {
                this.datasets.splice(ind, 1);
            }
            delete this.data[name];
        }
    }

    getFreeDatasets(formDescr?: FormType): Dataset[] {
        if (!formDescr) return this.datasets.filter(ds => !ds.cdo);

        const arrDSDescr: DatasetType[] = [];
        collectDatasetDescriptors(formDescr.datasets, arrDSDescr);
        return arrDSDescr.map(dsDescr => this.data[dsDescr.name]);
    }

    checkDSRequiredFields(ds: Dataset, err = '') {
        const emptyReqFld = ds.descr.fields
            .filter(fld => this.ownerPropContainer.getFieldRequirement(fld.name, ds.name))
            .filter(fld => {
                const val = ds.getFieldValue(fld.name);

                if (fld.dataType === 'KRN_ARRAY' || Array.isArray(val)) return !val?.length;

                return val === null || val === undefined || val === '';
            });

        if (emptyReqFld?.length) {
            return [
                ...err,
                ...emptyReqFld
                    .map(fld => (fld.caption ? fld.caption : fld.name))
                    .filter(fld => !err.includes(fld))
            ];
        }

        return err;
    }

    checkRequiredFields(propContainer: PropContainer) {
        let reqFlds: any = [];
        const formDescr = propContainer.ctrlArr[0].descr;
        const arrDSDescr: DatasetType[] = [];
        collectDataset(formDescr, arrDSDescr);

        arrDSDescr.forEach(dsDescr => {
            const ds = this.getDatasetObj(dsDescr.name);
            if (ds?.recCount && [2, 3].includes(ds?.state)) {
                reqFlds = this.checkDSRequiredFields(ds, reqFlds);
            }
        });

        return reqFlds;
    }

    async checkData(propContainer: PropContainer, errAccum: ErrAccum) {
        await propContainer.formulaCalculator?.validateRecord(errAccum);
        const requiredFields = this.checkRequiredFields(propContainer);

        if (requiredFields.length) {
            errAccum.err.push(`Не заполнены обязательные поля: \n${requiredFields.join(', ')}`);
        }
    }

    async saveData(errAccum?: ErrAccum, resAccum?: ResAccum) {
        const doUpdate = async (
            type: 'form' | 'cdo',
            guid: string,
            updDescr: any[],
            parObj?: SimpleObject
        ) => {
            if (updDescr.length > 0) {
                const url = type === 'cdo' ? 'dataObject/update' : 'update';
                return await jsonFetch(url, 'POST', {
                    guid,
                    parObj,
                    datasets: updDescr,
                    ownerGuid: this.formDescr?.ownerGuid // для сабформ
                });
            }
        };

        try {
            for (const cdoName in this.CDOs) {
                const cdo = this.CDOs[cdoName];
                const updDescr = this.getUpdateDescriptor(cdo.datasets);
                const result = (await doUpdate(
                    'cdo',
                    cdo.guid,
                    updDescr,
                    cdo.parObj
                )) as UpdateCDOResult;

                if (resAccum && result) resAccum.push(result);
            }

            const updDescr = this.getUpdateDescriptor(this.getFreeDatasets());
            const result = (await doUpdate('form', this.formGuid, updDescr)) as UpdateResult;

            if (resAccum && result) resAccum.push(result);
        } catch (err: any) {
            if (errAccum) {
                errAccum.err.push(err.message);
            } else {
                throw err;
            }
            for (const cdoName in this.CDOs) {
                const cdo = this.CDOs[cdoName];
                cdo.datasets.forEach(ds => ds.edit());
            }
            this.getFreeDatasets().forEach(ds => ds.edit());

            return { error: errAccum };
        }
    }

    getUpdateDescriptor(datasets: Dataset[]) {
        const updData: any[] = [];

        datasets.forEach(ds => {
            if (ds.descr.type !== 'dataset') return;

            ds.post();

            // проверка на допустимость сохранения данных текущего датасета
            if (ds.needToSave()) {
                // цикл по всем измененным записям
                const recs = [];
                for (let keyVal in ds.delta) {
                    // цикл по всем полям записи
                    const delta = (ds.delta as SimpleObject)[keyVal];
                    const diff: SimpleObject = {};
                    for (const fld in delta.clone) {
                        if (delta.clone[fld] !== delta.source[fld]) {
                            diff[fld] = delta.clone[fld];
                        }
                    }

                    if (Object.keys(diff).length > 0) {
                        let keyFields: SimpleObject = {};
                        ds.serverKeyFields.forEach(kf => {
                            keyFields[kf] = delta.clone[kf];
                        });

                        recs.push({
                            keyFields,
                            diff,
                            hash: ds.getProtectedFieldsHash(keyFields[ds.keyField]),
                            operation: 'update'
                        });
                    }
                }

                // Отметить для удаления
                if (ds.markForDelete?.length > 0) {
                    for (const rec of ds.markForDelete) {
                        let keyFields: SimpleObject = {};
                        ds.serverKeyFields.forEach(kf => {
                            keyFields[kf] = rec[kf];
                        });

                        recs.push({
                            keyFields,
                            operation: 'delete'
                        });
                    }
                }

                if (recs.length > 0) {
                    updData.push({
                        datasetName: ds.descr.name,
                        records: recs
                    });
                }
            }

            //TODO: локальное сохранение измененных данных - временная мера,
            //нужно получить данные с сервера
            ds.localSave();
        });
        return updData;
    }

    getDataset(name: string) {
        return this.data[name]?.data;
    }

    getDatasetObj(name: string): Dataset {
        return this.data[name];
    }

    /**
     * Получить ds(flMainSelect = true)
     * @returns {import('../forms/interfaces').DatasetType} DS
     */
    getMainDataset() {
        return Object.values(this.data || {}).find(ds => ds.descr?.flMainSelect);
    }

    getPhantomId() {
        return this.phantomId--;
    }

    /**
     * Регистрация subForm-редакторов для dataset-ов
     * @param {import('./PropContainer').controlElement[]} forms
     * @param {PropContainer} parentPropContainer
     */
    setEditForms(forms: controlElement[], parentPropContainer: PropContainer) {
        forms.forEach(form => {
            const { guid, name, type, editDatasetName } = form.descr as FormType;
            const ds = this.datasets.find(ds => ds.name === editDatasetName);
            if (ds) {
                ds.descr.editForm = { guid, name, type };
                (form.descr as FormType).ownerGuid = parentPropContainer.guid; // Для запросов данных из сабформ
                ds.actions?.setDefaultEditor(ds.descr.editForm, parentPropContainer);
            }
        });
    }

    setActiveFilter(afName: string, value: any) {
        if (DataStore?.AF && afName) {
            DataStore.AF.setAF(afName, value);
            DataStore.taskQueue
                .loadAFDependencies([afName])
                .catch(err => console.error(err.message));
        }
    }
}
