import tinycolor from 'tinycolor2';

import { Property } from 'csstype';

const primary = '#6157FF';
const secondary = '#73D7F5';
const error = '#F57069';
const warning = '#F57069';
const success = '#34DFA2';
const info = '#FF9690';
const hero = '#6157FF';
const greyBorder = '#D9DEEC';
const disabled = '#DFDEDE';

const lightenRate = 7.5;
const darkenRate = 15;

const defaults = {
    palette: {
        hero,
        bgLight: {
            one: '#F1F4FC',
            two: 'rgba(241, 244, 252, 0.6)',
            disabled: 'rgb(97, 87, 255, 0.3)'
        },

        avatarLinearGradient: {
            one: '#7385FF',
            two: '#584DFF'
        },
        baseButton: {
            bgColor: 'linear-gradient(139.78deg, #7385FF 3.01%, #584DFF 95.95%);',
            bgColorHover: 'red',
            bgColorActive:
                'linear-gradient(0deg, #000000, #000000), linear-gradient(139.78deg, #7385FF 3.01%, #584DFF 95.95%)',
            shadow: '0px 2px 14px rgba(111, 102, 255, 0.4)'
        },
        appBar: {
            color: '#7C7D80',
            colorHover: '#6157FF',
            colorActive: '#000',
            bg: '#fff'
        },
        border: {
            main: greyBorder,
            light: tinycolor(greyBorder).lighten(lightenRate).toHexString(),
            dark: tinycolor(greyBorder).darken(darkenRate).toHexString()
        },
        primary: {
            main: primary,
            light: tinycolor(primary).lighten(lightenRate).toHexString(),
            dark: tinycolor(primary).darken(darkenRate).toHexString()
        },
        secondary: {
            main: secondary,
            light: tinycolor(secondary).lighten(lightenRate).toHexString(),
            dark: tinycolor(secondary).darken(darkenRate).toHexString(),
            contrastText: '#ccc'
        },
        warning: {
            main: warning,
            light: tinycolor(warning).lighten(lightenRate).toHexString(),
            dark: tinycolor(warning).darken(darkenRate).toHexString()
        },
        error: {
            main: error,
            light: tinycolor(error).lighten(lightenRate).toHexString(),
            dark: tinycolor(error).darken(darkenRate).toHexString()
        },
        success: {
            main: success,
            light: tinycolor(success).lighten(lightenRate).toHexString(),
            dark: tinycolor(success).darken(darkenRate).toHexString()
        },
        disabled: {
            main: disabled,
            light: tinycolor(disabled).lighten(lightenRate).toHexString(),
            dark: tinycolor(disabled).darken(darkenRate).toHexString()
        },
        info: {
            main: info,
            light: tinycolor(info).lighten(lightenRate).toHexString(),
            dark: tinycolor(info).darken(darkenRate).toHexString()
        },
        inherit: {
            main: 'inherit',
            light: tinycolor('inherit').lighten(lightenRate).toHexString(),
            dark: tinycolor('inherit').darken(darkenRate).toHexString()
        },
        text: {
            primary: '#353638',
            secondary: '#B2B6C2',
            hint: '#B2B6C2'
        },
        background: {
            default: '#f7f7f7',
            light: '#e8ebf100'
        }
    },

    customShadows: {
        widget: '0px 2px 35px rgba(78, 72, 190, 0.08)',
        widgetDark: '0px 2px 35px rgba(78, 72, 190, 0.08)',
        widgetWide: '0px 2px 35px rgba(78, 72, 190, 0.08)'
    },

    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920
        }
    },

    spacing: 4,

    components: {
        MuiButton: {
            defaultProps: {
                disableRipple: true
            },
            styleOverrides: {
                root: {
                    textTransform: 'none' as Property.TextTransform,
                    borderRadius: 4,
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    boxShadow: 'none',
                    '&:hover': {
                        boxShadow: 'none',
                        '@media (hover: none)': {
                            boxShadow: 'none'
                        }
                    },
                    '&$focusVisible': {
                        boxShadow: 'none'
                    },
                    '&:active': {
                        boxShadow: 'none'
                    },
                    '&$disabled': {
                        boxShadow: 'none'
                    }
                },
                contained: {
                    color: '#fff',
                    background: 'linear-gradient(139.78deg, #7385FF 3.01%, #584DFF 95.95%)',
                    '&:hover': {
                        background:
                            'linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), linear-gradient(139.78deg, #7385FF 3.01%, #584DFF 95.95%)'
                    },
                    '&:active': {
                        background:
                            'linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0.1)), linear-gradient(139.78deg, #7385FF 3.01%, #584DFF 95.95%)'
                    },
                    '&:disabled': {
                        color: '#fff',
                        opacity: 0.25
                    }
                },
                outlined: {
                    // color: '#6157FF', отключить принудительный цвет шрифта для outlined-кнопки
                    border: `1px solid ${greyBorder}`,
                    '&:hover': {
                        background: 'rgba(0, 0, 0, 0.02)',
                        border: `1px solid ${greyBorder}`
                    },
                    '&:active': {
                        background: 'rgba(99, 102, 114, 0.1)'
                    },
                    '&:disabled': {
                        color: 'rgba(194, 196, 203, 1)',
                        background: '#FFFFFF',
                        border: '1px solid #EDEDED'
                    }
                }
            }
        },
        MuiButtonGroup: {
            defaultProps: {
                disableRipple: true
            },
            styleOverrides: {
                root: {
                    boxShadow: 'none'
                },
                contained: {
                    button: { color: '#fff' },
                    background: 'linear-gradient(139.78deg, #7385FF 3.01%, #584DFF 95.95%)',
                    '&:hover': {
                        background:
                            'linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), linear-gradient(139.78deg, #7385FF 3.01%, #584DFF 95.95%)'
                    },
                    '&:active': {
                        background:
                            'linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0.1)), linear-gradient(139.78deg, #7385FF 3.01%, #584DFF 95.95%)'
                    },
                    '&:disabled': {
                        color: '#fff',
                        opacity: 0.25
                    }
                }
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    '& input': {
                        color: '#353638'
                    },
                    '& .MuiFormLabel-root': {
                        '&.Mui-error': {
                            color: error,
                            /* На случай, если захочется не выделять красным label незаполненного поля
                            color: '#888888FF',

                            '&.MuiFormLabel-filled': {
                                color: error
                            },
                            '&.Mui-focused': {
                                color: error
                            }, */
                            '&.Mui-disabled': {
                                // color: disabled
                                color: '#B2B6C2'
                            }
                        },
                        '&.Mui-disabled': {
                            // color: disabled
                            color: '#B2B6C2'
                        }
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: greyBorder
                        },
                        '&:hover fieldset': {
                            borderColor: greyBorder
                        },
                        '&.Mui-focused fieldset': {
                            borderWidth: '1px',
                            borderColor: primary
                        },
                        '&.Mui-error': {
                            '& fieldset': {
                                borderColor: error
                            },
                            '&:hover fieldset': {
                                borderColor: error
                            }
                        },
                        '&.Mui-disabled': {
                            color: '#00000042',
                            borderColor: disabled,

                            '& fieldset': {
                                borderColor: disabled
                            },
                            '&:hover fieldset': {
                                borderColor: disabled
                            }
                        }
                    }
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                inputRoot: { paddingTop: 0 },
                input: { paddingTop: 0 }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    '&.MuiTypography-subtitle1': {
                        fontWeight: 500,
                        fontSize: '0.875rem',
                        lineHeight: '1.57',
                        letterSpacing: '0.00714em'
                    },
                    '&.MuiTypography-subtitle2': {
                        color: '#7C7D80',
                        fontWeight: 400,
                        fontSize: '0.75rem',
                        lineHeight: 1.66
                    }
                }
            }
        }
    },

    props: {
        MuiButtonBase: { disableRipple: true, disableFocusRipple: true, disableTouchRipple: true }
    }
};

export default defaults;
