import { FunctionComponent } from 'react';
import { observer } from 'mobx-react';

import CustomDataset from 'dataObj/customDataset';
import DataChunk from 'dataObj/DataChunk';

import RootNodeContainer from './TreeNode/Root/RootNodeContainer';

interface PropsType {
    dataset: CustomDataset;
    parentId?: number | string;
    disabled?: boolean;
}

const TreeNode: FunctionComponent<PropsType> = observer(
    ({ dataset, parentId, disabled }: PropsType) => {
        const chunks = dataset.chunkContainer.chunks as {
            [key: string | number]: DataChunk;
        };

        const currentChunksKey = Object.keys(chunks).filter(key =>
            parentId ? `${key}` === `${parentId}` : true
        );

        if (currentChunksKey.length)
            return (
                <>
                    {currentChunksKey.map((key, index) => {
                        const currentChunk = chunks[key];

                        return (
                            <RootNodeContainer
                                key={`${key}-${index}`}
                                index={index}
                                dataset={dataset}
                                parentId={key}
                                currentChunk={currentChunk}
                                disabled={disabled}
                            />
                        );
                    })}
                </>
            );

        if (parentId) {
            const currentChunk = dataset.chunkContainer.assignDataChunk(parentId, []);

            return (
                <RootNodeContainer
                    index={0}
                    dataset={dataset}
                    parentId={parentId}
                    currentChunk={currentChunk}
                    disabled={disabled}
                />
            );
        }

        return null;
    }
);

export default TreeNode;
