import React from 'react';

import FlowChart from 'components/Charts/FlowChart';

const initNodes = [
    {
        id: '110',
        type: 'selectorNode',
        data: {
            number: '110',
            deadline: '01.01.2024',
            title: 'Разработка ТТ3',
            status: 'done'
        },
        position: { x: 0, y: 0 }
    },
    {
        id: '111',
        type: 'selectorNode',
        data: {
            number: '111',
            deadline: '01.01.2024',
            title: 'Сделать ТТ3',
            status: 'done'
        },
        position: { x: 0, y: 0 }
    },
    {
        id: '112',
        type: 'selectorNode',
        data: {
            number: '112',
            deadline: '01.01.2024',
            title: 'Надо сделать бэк',
            status: 'done'
        },
        position: { x: 0, y: 0 }
    },
    {
        id: '113',
        type: 'selectorNode',
        data: {
            number: '113',
            deadline: '01.01.2024',
            title: 'Надо сделать фронт',
            status: 'done'
        },
        position: { x: 0, y: 0 }
    },
    {
        id: '114',
        type: 'selectorNode',
        data: {
            number: '113',
            deadline: '01.01.2024',
            title: 'Надо сделать бэк ещё',
            status: 'done'
        },
        position: { x: 0, y: 0 }
    },
    {
        id: '115',
        type: 'selectorNode',
        data: {
            number: '115',
            deadline: '01.06.2024',
            title: 'Реализовать отображение графов',
            status: 'progress'
        },
        position: { x: 0, y: 0 }
    },
    {
        id: '116',
        type: 'selectorNode',
        data: {
            number: '116',
            deadline: '01.06.2023',
            title: 'Реализовать визуальное редактирование элементов графа'
        },
        position: { x: 0, y: 0 }
    },
    {
        id: '199',
        type: 'selectorNode',
        data: {
            number: '199',
            deadline: '01.11.2023',
            title: 'Тестирование',
            status: 'progress'
        },
        position: { x: 0, y: 0 }
    },
    {
        id: '200',
        type: 'selectorNode',
        data: {
            number: '200',
            deadline: '01.01.2024',
            title: 'Конец'
        },
        position: { x: 0, y: 0 }
    }
];

const initEdges = [
    {
        id: '110-111',
        source: '110',
        target: '111'
    },
    {
        id: '111-112',
        source: '111',
        target: '112',
        sourceHandle: 'a'
    },
    {
        id: '111-113',
        source: '111',
        target: '113',
        sourceHandle: 'b'
    },
    {
        id: '112-114',
        source: '112',
        target: '114',
        sourceHandle: 'b'
    },
    {
        id: '114-199',
        source: '114',
        target: '199',
        sourceHandle: 'b'
    },
    {
        id: '113-115',
        source: '113',
        target: '115',
        sourceHandle: 'b'
    },
    {
        id: '115-116',
        source: '115',
        target: '116',
        sourceHandle: 'b'
    },
    {
        id: '116-199',
        source: '116',
        target: '199',
        sourceHandle: 'b'
    },
    {
        id: '199-200',
        source: '199',
        target: '200',
        sourceHandle: 'b'
    }
];

const FlowChartDemo = () => (
    <div style={{ height: '100vh' }}>
        <FlowChart
            initNodes={initNodes}
            initEdges={initEdges}
            showMap
            showControls
            directionSwitcher
        />
    </div>
);

export default FlowChartDemo;
