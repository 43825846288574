const fullHeight = { position: 'relative', height: '100%' };

const panel = { ...fullHeight, ...{ overflow: 'auto' } };

export default function (type: string): { [key: string]: any } {
    switch (type) {
        case 'panel':
            return panel;
        case 'container':
            return { ...panel, ...{ minHeight: 100 } };
        case 'editorContainer':
            return { ...panel, ...{ height: '100%' } };
        case 'grid':
            return { ...panel, ...{ flex: '1 1 !important' } };
        case 'pageControl':
            return {
                height: '100%',
                flex: 1,
                overflow: 'auto',
                minHeight: 150
            };
        case 'splitter':
            return fullHeight;
        case 'dbHtmlViewer':
        case 'dbRtfViewer':
            return { height: '100%', flex: '1 1 !important' };
        case 'dbPdfViewer':
            return { ...fullHeight, ...{ '&canvas': { m: 'auto' } } };
        case 'taskButton':
        case 'scriptButton':
            return { p: 2 };
        case 'dbTreeEdit':
        case 'dbImage':
            return { height: '100%' };
        default:
            return {};
    }
}
