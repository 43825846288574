import React, { FunctionComponent, useState } from 'react';

import debounce from 'lodash/debounce';

import { IconButton, Stack, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material//Close';

import { DatasetFieldType, GridType } from 'forms/interfaces';

import ConfigurationStore from 'store/configurationStore';
import CustomDataset from 'dataObj/customDataset';

import FastFilter from './FastFilter';

const debouncedLoadData = debounce((dataset: CustomDataset) => {
    dataset.loadData();
}, 500);

interface FastFiltersType {
    filters: Array<string>;
    gridDescr: GridType;
    dataset: any;
}

const FastFilters: FunctionComponent<FastFiltersType> = ({ filters, gridDescr, dataset }) => {
    const { content } = ConfigurationStore;

    const getOperator = (fieldName: string) => {
        const dataType = dataset.descr.fields.find(
            (field: DatasetFieldType) => field.name === fieldName
        )?.dataType;

        switch (dataType) {
            case 'KRN_INTEGER':
            case 'KRN_NUMERIC':
            case 'KRN_AUTOINC':
                return '=';

            default:
                return 'like *%';
        }
    };

    const [arrFF, setArrFF] = useState(() => {
        const initVals = Array(filters.length);
        if (dataset) {
            filters.forEach((fieldName, i) => {
                initVals[i] = { fieldName, operator: getOperator(fieldName), value: '' };
            });
        }
        return initVals;
    });

    const setFFObj = (index: number, ffObj: any) => {
        arrFF[index] = ffObj;
        setArrFF([...arrFF]);
        if (dataset) {
            dataset.fastFilters[ffObj.fieldName] = ffObj;
            debouncedLoadData(dataset);
        }
    };

    const clearVals = () => {
        setArrFF([
            ...arrFF.map(ffObj => {
                const clObj = { ...ffObj, value: '' };
                dataset.fastFilters[ffObj.fieldName] = clObj;

                return clObj;
            })
        ]);
        dataset.loadData();
    };

    return (
        <Stack spacing={1} direction="row" sx={{ width: '100%', zIndex: 1 }}>
            {filters.map((fieldName, index) => (
                <FastFilter
                    key={`ff-${index}`}
                    index={index}
                    caption={
                        gridDescr.columns.filter(column => column.fieldName === fieldName)[0]
                            ?.caption ?? fieldName
                    }
                    value={arrFF[index].value}
                    ffObj={arrFF[index]}
                    setFFObj={setFFObj}
                />
            ))}
            <Tooltip title={content.controls.buttons.clear}>
                <IconButton onClick={clearVals}>
                    <CloseIcon />
                </IconButton>
            </Tooltip>
        </Stack>
    );
};

export default FastFilters;
