import React, {
    FunctionComponent,
    MutableRefObject,
    useCallback,
    useEffect,
    useRef,
    useState
} from 'react';

import { Stack } from '@mui/material';

import PropContainer from 'dataObj/PropContainer';

import ToolbarButton from './CustomToolbarButton';
import { CustomToolbarButtonType } from '../interface';

interface PropsType {
    buttons: CustomToolbarButtonType[];
    propContainer: PropContainer;
    containerWidth?: number;
}

const Buttons: FunctionComponent<PropsType> = ({ containerWidth, buttons, propContainer }) => {
    const toolbarRef = useRef(null) as MutableRefObject<any>;

    const [breakpoint, setBreakpoint] = useState(0);

    useEffect(() => {
        toolbarRef?.current && setBreakpoint(toolbarRef?.current?.offsetWidth);
    }, [toolbarRef.current]);

    const handleBreakpoint = useCallback((node: HTMLDivElement) => {
        setBreakpoint(node?.offsetWidth);
    }, []);

    return containerWidth !== undefined ? (
        <Stack ref={handleBreakpoint} spacing={1} direction="row">
            {buttons.map((button, index) => (
                <ToolbarButton
                    key={index}
                    button={button}
                    fullWidth={containerWidth >= breakpoint}
                />
            ))}
        </Stack>
    ) : null;
};

export default Buttons;
