import { FunctionComponent, useRef } from 'react';
import { observer } from 'mobx-react';

import { Checkbox, FormGroup, FormControlLabel, FormLabel, FormControl } from '@mui/material';

import { EventType } from 'components/resources/ResEditor/ResInterfaces';
import { DataFormulaType } from 'forms/interfaces';

import { ResourceClass } from 'store/resourceStore';
import ConfigurationStore from 'store/configurationStore';

import resValidator from '../../clientResValidator';
import ResScriptEditor from './ResScriptEditor';

interface PropTypes {
    name: string;
    formula?: DataFormulaType;
    onConfirm: any;
    showText?: boolean;
    resourceStore?: ResourceClass;
}

const ResServerFormula: FunctionComponent<PropTypes> = observer(
    ({ name, formula, onConfirm, showText = false, resourceStore }) => {
        const { resource } = ConfigurationStore.content;
        const asDefaultRef = useRef<HTMLInputElement>(null);
        const asValueRef = useRef<HTMLInputElement>(null);

        const getInnerContent = () => (
            <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend">{resource.scriptEditor.options}</FormLabel>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                defaultChecked={
                                    formula?.asDefault === undefined
                                        ? true
                                        : Boolean(formula?.asDefault)
                                }
                                name="asDefault"
                                inputRef={asDefaultRef}
                            />
                        }
                        label={resource.scriptEditor.asDefault}
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                defaultChecked={Boolean(formula?.asValue)}
                                name="asValue"
                                inputRef={asValueRef}
                            />
                        }
                        label={resource.scriptEditor.asValue}
                    />
                </FormGroup>
            </FormControl>
        );

        const getFormula = (value?: string) =>
            value
                ? resValidator.validate({
                      ...formula,
                      type: 'dataFormula',

                      text: value,
                      asDefault: asDefaultRef?.current?.checked,
                      asValue: asValueRef?.current?.checked
                  })
                : undefined;

        const onFormulaConfirm = ({ target }: EventType) => {
            if (onConfirm) {
                onConfirm({ target: { name: target.name, value: getFormula(target.value) } });
            }
        };

        return (
            <ResScriptEditor
                name={name}
                text={formula?.text || ''}
                language="javascript"
                onConfirm={onFormulaConfirm}
                showText={showText}
                resourceStore={resourceStore}
                bottomContent={getInnerContent()}
            />
        );
    }
);

export default ResServerFormula;
