import { useState, useEffect, FunctionComponent, useMemo } from 'react';
import { observer } from 'mobx-react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid';

import SelectResource from './SelectResource';
import { EditorProps } from './ResInterfaces';
import { ResourceInfo } from '../../../forms/interfaces';
import GridList from './gridSettings/GridSettings/GridList';
import ConfigurationStore from '../../../store/configurationStore';

interface TableLink {
    name: string;
    resouce: ResourceInfo;
}

interface TableLinkRow extends TableLink {
    id: number;
}

function ResourceInputCell(props: GridRenderCellParams) {
    const { id, value, field } = props;

    const apiRef = useGridApiContext();
    // const classes = useStyles();

    const handleChange = (propName: string, resource: ResourceInfo) => {
        const info = { guid: resource.guid, name: resource.name, type: resource.type };
        const res = apiRef.current.setEditCellValue({ id, field, value: info });
        // Если promise
        if (res instanceof Object) {
            res.catch(err => console.error(err));
        }
    };

    const resTypes = ['wuiExtQuery'];
    return (
        <TextField
            size="small"
            fullWidth
            value={value?.name}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <SelectResource
                            types={resTypes}
                            property={value?.guid}
                            guid={value?.guid || ''}
                            onSelect={handleChange}
                        />
                    </InputAdornment>
                )
            }}
        />
    );
}

const tableLinksColumns = (columns: any) => [
    {
        field: 'name',
        headerName: columns.caption.headerName,
        description: columns.caption.description,
        width: 250,
        disableColumnMenu: true,
        editable: true,
        sortable: false,
        valueFormatter: (tableLink: any) => tableLink.name
    },
    {
        field: 'resource',
        disableColumnMenu: true,
        sortable: false,
        editable: true,
        headerName: columns.resource.headerName,
        description: columns.resource.description,
        renderCell: (cell: any) => {
            const resource = cell.value as ResourceInfo;
            return !resource?.guid ? '<empty>' : `(${resource.type}) ${resource.name ?? ''}`;
        },
        renderEditCell: ResourceInputCell,
        flex: 1
    }
];

const TableLinks: FunctionComponent<EditorProps> = observer((props: EditorProps) => {
    const content = ConfigurationStore.content.resource.gridProps;
    const [rows, setRows] = useState<TableLinkRow[]>([]);

    // Список действий которые может выполнять редактор
    const actions = useMemo(
        () => ({
            getName: () => 'Сопоставление имен таблиц',
            getValue: () => {
                // Убрать идентификатор
                const list = [...rows].map((item: any) => {
                    delete item.id;
                    return item;
                });
                return JSON.stringify(list);
            }
        }),
        [rows]
    );

    useEffect(() => {
        if (props.setActions && actions) {
            props.setActions(actions);
        }
    }, [actions, props]);

    useEffect(() => {
        let tabArr: TableLinkRow[] = [];
        try {
            tabArr = typeof props.value === 'string' && props.value ? JSON.parse(props.value) : [];
        } catch (err) {
            console.error(err);
        }

        // Добавить идентификатор
        setRows(
            tabArr.map((row, idx) => {
                row.id = idx + 1;
                return row;
            })
        );
    }, [props.value]);

    const createItem = () => ({
        id: rows.length + 1,
        name: `TableName${rows.length ? rows.length : ''}`,
        resource: { name: '', type: 'unknown', guid: '' }
    });

    return (
        <div style={{ height: 500 }}>
            <GridList
                actions={['create', 'remove', 'up', 'down']}
                columns={tableLinksColumns(content.columns)}
                rows={rows}
                setRows={setRows}
                defaultItem={createItem}
            />
        </div>
    );
});

export default TableLinks;
