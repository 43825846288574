import React, { FunctionComponent, useEffect } from 'react';
import { observer } from 'mobx-react';

import ConfigurationStore from '../../store/configurationStore';
import NotificationStore from '../../store/notificationStore';

import Empty from '../../components/utils/Empty/Empty';
import Loading from '../../components/utils/Loading/Loading';

interface TokenAuthProps {
    token: string;
    refreshToken: string;
}

const TokenAuthPage: FunctionComponent<TokenAuthProps> = observer(({ token, refreshToken }) => {
    const { content } = ConfigurationStore;

    useEffect(() => {
        if (token) {
            ConfigurationStore.authToken(token, refreshToken).catch((err: any) =>
                NotificationStore.showAlert(err.message)
            );
        }
    }, []);

    if (ConfigurationStore.error) {
        return <Empty message={content.application.auth.tokenInvalid} />;
    }

    return <Loading message={content.application.auth.tokenAuth} />;
});

export default TokenAuthPage;
