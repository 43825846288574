import { FC, MouseEvent, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Menu, MenuItem } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';

export type ComplexButtonConfig = {
    id: string;
    label: string;
    icon?: unknown;
    onClick?: () => unknown;
    actions?: ComplexButtonConfig[];
};

type PropType = { config: ComplexButtonConfig };

const ComplexButton: FC<PropType> = observer(({ config }: PropType) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);
    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (item: ComplexButtonConfig) => {
        setAnchorEl(null);
        if (item.onClick) {
            item.onClick();
        }
    };

    const getMenuItems = (actions: ComplexButtonConfig[]) =>
        actions.map(item => (
            <MenuItem key={item.id} onClick={() => handleClose(item)}>
                {item.icon}
                {item.label}
            </MenuItem>
        ));

    return (
        <>
            <Button
                key={config.id}
                onClick={config.actions ? e => handleClick(e) : config.onClick}
                endIcon={config.actions ? <KeyboardArrowDown /> : null}
                variant="contained"
                disableElevation
            >
                {config.label}
            </Button>
            {config.actions ? (
                <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
                    {getMenuItems(config.actions)}
                </Menu>
            ) : null}
        </>
    );
});

export default ComplexButton;
