import { observable, action, get, set, makeAutoObservable } from 'mobx';
import { decode } from 'js-base64';

import { IconSetType } from '../forms/interfaces';
import { jsonFetch } from '../utils';

class IconSet {
    @observable iconSet = {};

    constructor() {
        makeAutoObservable(this);
    }

    @action readResource = async (guid: string) => jsonFetch(`resources/${guid}`, 'GET');

    @action getLinkedIconSet = async (iconSetGuid: string) => {
        const loading = this.getLoadingIconSet(iconSetGuid);

        if (!loading) {
            this.setLoadingIconSet(iconSetGuid);
            const resLink = (await this.readResource(iconSetGuid)) as IconSetType;

            if (resLink) {
                this.setIcons(iconSetGuid, resLink);
            }
        }
    };

    @action setLoadingIconSet = (iconSetGuid: string) => {
        set(this.iconSet, { [iconSetGuid]: { loading: true } });
    };

    @action getLoadingIconSet = (iconSetGuid: string): boolean => {
        const iconSet = get(this.iconSet, iconSetGuid);

        return iconSet?.loading ?? false;
    };

    @action setIcons = (iconSetGuid: string, descr: IconSetType) => {
        if (descr.items?.length) {
            const icons = {} as { [key: string]: any };
            descr.items.map(icon => {
                icons[icon.name] = decode(icon.content);
                return;
            });

            const iconSet = {
                guid: descr.guid,
                name: descr.name,
                loading: false,
                icons
            };

            set(this.iconSet, { [iconSetGuid]: iconSet });
        }
    };

    getIcon = (iconSetGuid: string, iconName: string) => {
        const iconSet = get(this.iconSet, iconSetGuid);
        const icons = iconSet?.icons ?? [];

        return icons[iconName] ?? null;
    };

    checkIconSet = (iconSetGuid: string) => {
        const iconSet = get(this.iconSet, iconSetGuid);

        return !!iconSet;
    };

    getLoadingIcon = () =>
        `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 128 128" xml:space="preserve">
                <g>
                    <path d="M64 9.75A54.25 54.25 0 0 0 9.75 64H0a64 64 0 0 1 128 0h-9.75A54.25 54.25 0 0 0 64 9.75z" />
                    <animateTransform 
                        attributeName="transform" 
                        type="rotate" 
                        from="0 64 64" 
                        to="360 64 64" 
                        dur="1000ms" 
                        repeatCount="indefinite"/>
                </g>
        </svg>`;
}

const IconSetStore = new IconSet();
export default IconSetStore;
