import { action, makeObservable, observable } from 'mobx';
import { useDebugValue } from 'react';
import content from '../localization/content';

import { IConfigurationStore, UserInfo, UserPost, UserResource } from './index';
import { ContentType, Locale } from '../localization/interfaces';

import { initJsonFetch, jsonFetch, requests } from '../utils';
import resValidator from '../components/resources/clientResValidator';

const storages = {
    userInfo: 'userInfo',
    userPosts: 'userPosts',
    mainForm: 'mainForm',
    locale: 'locale',
    apiVersion: 'apiVersion',
    uiVersion: 'uiVersion'
};

const parseJwt = (token: string | null) => {
    if (token) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
            window
                .atob(base64)
                .split('')
                .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
                .join('')
        );

        return JSON.parse(jsonPayload);
    }
    return {};
};

export const parseStorage = (storage: string | null): UserInfo | UserResource | null => {
    if (storage) {
        try {
            // в сторе битый json
            return JSON.parse(storage);
        } catch {
            console.log('unknown localization in local storage:', storage);
        }
    }
    return null;
};

export class Configuration implements IConfigurationStore {
    @observable basename?: string;

    @observable isAuthenticated = false;

    @observable token: string | null = '';

    @observable loginToken: string | null = '';

    @observable refreshToken: string | null = '';

    @observable loading = false;

    @observable backdrop = false;

    @observable error: string | null = null;

    @observable locale: Locale = 'rus';

    @observable content: ContentType = content[this.locale];

    @observable isReady = false;

    @observable isDebug = true;

    @observable UserPosts: UserPost[] | null = null;

    @observable userInfo: UserInfo | null = null;

    @observable mainFormGuid: string | null = '';

    @observable demoFormGuid: string | null = 'f74836a8-65a4-4043-85f8-b17cb36042d4';

    @observable currentRouterOriginPath: string[] = [''];

    @observable currentRouterPath: string[] = [''];

    @observable applicationConfig: { [key: string]: any } = {};

    @observable colors: any[] = [];

    @observable taskId?: number;

    @observable apiVersion?: string;

    private accessTokenCheckTimer: any;

    constructor() {
        makeObservable(this);

        initJsonFetch(this);

        const storageContent = localStorage.getItem(storages.userInfo) as string;
        this.setUserInfo(parseStorage(storageContent) as UserInfo);

        this.changeLocale(localStorage.getItem(storages.locale) as string);

        try {
            const jsonTxt = localStorage.getItem(storages.userPosts) as string;
            if (jsonTxt) {
                const posts = JSON.parse(jsonTxt) as UserPost[];
                if (Array.isArray(posts)) {
                    this.setUserPosts(posts);
                }
            }
        } catch {
            this.setUserPosts(null);
        }

        this.afterUserInfo().catch((err: Error) => this.setError(err.message));
        // Запускаем прослушивание localStorage
        this.localStorageListener();
    }

    localStorageListener = () => {
        window.onstorage = event => {
            if (event.key !== 'userInfo' || !event.newValue) return;

            const userInfo = parseStorage(event.newValue) as UserInfo;

            if (userInfo?.accessToken === this.token) return;

            this.setUserInfo(parseStorage(event.newValue) as UserInfo);
        };
    };

    @action setBasename = (basename?: string) => {
        this.basename = basename;
    };

    @action setIsReady = (ready: boolean) => {
        this.isReady = ready;
    };

    @action setMainFormGuid = (guid: string | null) => {
        this.mainFormGuid = guid;
    };

    @action setLoginToken = (token: string) => {
        this.loginToken = token;
    };

    @action setLoading = (loading: boolean) => {
        this.loading = loading;
    };

    @action setBackdrop = (loading: boolean) => {
        this.backdrop = loading;
    };

    @action setError = (error: string | null) => {
        this.error = error;
    };

    @action setUserPosts = (UserPosts: UserPost[] | null) => {
        this.UserPosts = UserPosts;

        if (UserPosts) {
            localStorage.setItem(storages.userPosts, JSON.stringify(UserPosts));
        } else {
            localStorage.removeItem(storages.userPosts);
        }
    };

    @action setApiVersion = (version: string) => {
        this.apiVersion = version;
        localStorage.setItem(storages.apiVersion, version);
    };

    @action setUserInfo = (userInfo: UserInfo | null) => {
        if (userInfo) {
            this.token = userInfo?.accessToken;
            this.refreshToken = userInfo?.refreshToken;

            localStorage.setItem(storages.userInfo, JSON.stringify(userInfo));

            // Фиксируем версию api и ui в localStorage
            this.setApiVersion(userInfo.apiVersion);
            localStorage.setItem(storages.uiVersion, process.env.REACT_APP_VERSION as string);

            this.accessTokenUpdater();

            userInfo.appGuid &&
                this.getApplicationConfig(userInfo.appGuid).catch(err =>
                    console.error(err.message)
                );
        } else {
            this.token = null;
            this.refreshToken = null;

            localStorage.removeItem(storages.userInfo);
        }

        this.userInfo = userInfo;
        this.isAuthenticated = !!this.token;
    };

    @action setAccessTokenTimer = (timer: any) => {
        this.accessTokenCheckTimer = timer;
    };

    jwtExpDate = (token: string) => {
        // Парсим токен
        const tokenDecode = parseJwt(token);

        // Получаем информацию о сроке годности токена
        const exp = new Date(tokenDecode.exp * 1000);
        const cur = new Date();
        // Вычисляем время до истечения срока годности
        return exp.getTime() - cur.getTime();
    };

    accessTokenUpdater = () => {
        // Берём токен, сохранённый в localStorage
        const token = JSON.parse(localStorage.getItem(storages.userInfo) as string).accessToken;
        // Вычисляем время до истечения срока годности
        const notificationDeadline = this.jwtExpDate(token);

        // Очищаем предыдущие таймеры обновления
        this.accessTokenCheckTimer && clearTimeout(this.accessTokenCheckTimer);
        this.setAccessTokenTimer(null);

        // Запускаем таймер обновления по заданному времени
        const checkTimer = setTimeout(() => {
            const ls = JSON.parse(localStorage.getItem(storages.userInfo) as string);
            // Берём время до истечения срока годности токена, сохранённый в localStorage
            const lsTokenDeadLine = this.jwtExpDate(ls.accessToken);
            // Получаем текущий статус обновления токена (для случая с несколькими вкладками)
            const tokenStatus = localStorage.getItem('tokenStatus');

            // Проверяем, что токен, хранящийся в браузере, заканчивается и он не в процессе обновления
            if (lsTokenDeadLine <= 60000 && tokenStatus === 'updated') {
                this.tryRenewTokens().catch(err => {
                    console.error(err.message);
                    this.logout();
                });
            } else {
                const pause = 30000 + Math.floor(Math.random() * 30000);

                // В противном случае заново вызываем через интервал от полуминуты до минуты
                const newCheckTimer = setTimeout(() => this.accessTokenUpdater(), pause);

                this.setAccessTokenTimer(newCheckTimer);
            }
        }, notificationDeadline - 30000 - Math.floor(Math.random() * 20000));

        // Запоминаем таймер
        this.setAccessTokenTimer(checkTimer);
    };

    @action async afterUserInfo() {
        if (!this.isAuthenticated) {
            return;
        }
        try {
            await resValidator.getStructDescriptor();

            this.setMainFormGuid(localStorage.getItem(storages.mainForm));
            this.setIsReady(true);
        } catch (err: unknown) {
            this.setError((err as Error).message);
        }
    }

    @action changeLocale = (locale: string) => {
        this.locale = (['rus', 'eng', 'fra'].includes(locale) ? locale : 'rus') as Locale;
        this.content = content[this.locale];

        localStorage.setItem(storages.locale, locale);
    };

    @action authorize = async (url: string, method: string, body: unknown, headers = {}) => {
        method = method || 'GET';
        try {
            this.setLoading(true);
            this.setError(null);

            const data: UserInfo = await jsonFetch(url, method, body, headers, 'json');
            const userInfo = await this.login(data);

            await this.afterUserInfo();
            // Ставим отметку о факте обновления токенов
            localStorage.setItem('tokenStatus', 'updated');
            return userInfo;
        } catch (err: unknown) {
            this.setError((err as Error).message);
        } finally {
            this.setLoading(false);
        }
    };

    @action authToken = async (token: string, refreshToken: string) => {
        this.setLoginToken(token);
        this.logout();

        return this.authorize('auth/login-token', 'POST', { token, refreshToken });
    };

    @action login = async (userInfo: UserInfo | UserPost[]): Promise<boolean | UserPost[]> => {
        if (Array.isArray(userInfo)) {
            this.isAuthenticated = false;
            this.setUserInfo(null);
            this.setUserPosts(userInfo);
            await this.afterUserInfo();
            return userInfo;
        }

        this.setUserInfo(userInfo);
        this.changeLocale(this.loginToken && userInfo.lang ? userInfo.lang : this.locale);

        return false;
    };

    @action logout = () => {
        this.setUserInfo(null);
        this.setUserPosts(null);
        this.setMainForm(null);
        this.accessTokenCheckTimer && clearTimeout(this.accessTokenCheckTimer);
    };

    @action serverLogout = async () => {
        if (this.isAuthenticated) {
            await jsonFetch('auth/logout', 'POST', {}, {}, undefined, { action: 'logout' });
        }
        this.logout();
    };

    tryRenewTokens = async (payload?: object) => {
        // Ставим отметку о начале обновления токенов
        localStorage.setItem('tokenStatus', 'update');
        const token = this.refreshToken;
        if (!token) return false;

        const data = { ...payload, token };
        const userInfo = await jsonFetch('auth/refresh-token', 'POST', data, {}, 'json', {
            action: 'refreshToken'
        });
        await this.login(userInfo as UserInfo);
        // Ставим отметку об окончании обновления токенов
        localStorage.setItem('tokenStatus', 'updated');

        return true;
    };

    @action setMainForm(formGuid: string | null) {
        if (formGuid) {
            localStorage.setItem(storages.mainForm, formGuid || '');
        } else {
            localStorage.removeItem(storages.mainForm);
        }
        this.setMainFormGuid(formGuid);
        return formGuid;
    }

    @action readMainFormGuid = async (): Promise<string | null> => {
        const res = await jsonFetch('get-main-form-params', 'POST');
        const { formGuid } = res as { formGuid: string | null };
        return this.setMainForm(formGuid);
    };

    log = (
        message: string | object,
        type: 'error' | 'warn' | 'log' | 'debug' | 'alert' = 'error'
    ) => {
        type LogFn = (message: string) => void;

        if (type === 'debug') {
            // Вывод в devtool
            useDebugValue(message);
        }
        const fn: LogFn = type === 'alert' ? alert : console[type];

        fn(typeof message === 'object' ? JSON.stringify(message) : message);
    };

    getRequestList() {
        const tag = 'calypso';
        const byteCount = (str: string) => new Blob([str]).size;

        const summary = requests
            .map(r => {
                const method = r.method.toUpperCase();
                const url = !r.url || r.url[0] !== '/' ? `/${r.url}` : r.url;
                const lines = [
                    `${method} ${url} HTTP/1.1`,
                    // `Host: ${window.location.hostname}`,
                    `Authorization: bearer ${this.token || ''}`,
                    `Content-type: ${r.type}`
                ];

                let delta = 0;
                if (r.body && ['POST', 'PUT', 'DELETE'].includes(method)) {
                    delta = -5;
                    const text = typeof r.body === 'object' ? JSON.stringify(r.body) : r.body;
                    lines.push(`Content-Length: ${byteCount(text)}`);
                    lines.push('');
                    lines.push(text);
                }

                const result = lines.join('\r\n');

                return `${byteCount(result) + delta} ${tag}\n${result}`;
            })
            .join('\r\n\r\n');

        return `${summary}\r\n\r\n`;
    }

    @action setCurrentRouterPath = (params: { [key: string]: string }) => {
        if (params && params.hasOwnProperty('0')) {
            const path = params['0'];

            this.currentRouterPath = path.split('/').filter(item => !!item);
            this.setCurrentRouterOriginPath(this.currentRouterPath);
        }
    };

    @action setCurrentRouterOriginPath = (path: string[]) => {
        this.currentRouterOriginPath = window.location.pathname
            .split('/')
            .filter(item => !!item)
            .filter(
                item =>
                    !path.includes(item) &&
                    !(this.basename || '')
                        .split('/')
                        .filter(uri => !!uri)
                        .includes(item)
            );
    };

    @action getApplicationConfig = async (guid: string) => {
        const config: Record<string, any> = await jsonFetch(`resources/${guid}`, 'GET');
        this.applicationConfig = config;
    };

    @action setColor(extColor: string | number, bgColor: string, textColor: string) {
        const colorSet = {
            extColor,
            bgColor,
            textColor
        };
        this.colors.push(colorSet);
    }

    getColor(extColor: string | number) {
        return this.colors.find(f => f.extColor === extColor);
    }

    @action async selectAssembly(assemblyId: number) {
        return this.userInfo?.assemblyUse !== assemblyId
            ? this.tryRenewTokens({ assemblyId })
            : undefined;
    }

    @action selectTask(taskId?: number) {
        this.taskId = taskId;
    }
}

const ConfigurationStore = new Configuration();
export default ConfigurationStore;
