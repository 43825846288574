import moment from 'moment';
import forge from 'node-forge';
import { useRef } from 'react';
import { btoa } from 'js-base64';

import InternationalContent from 'localization/internationalContent';
import NotificationStore from 'store/notificationStore';

import HttpError from './HttpError';
import ConfigurationStore from './store/configurationStore';

const { REACT_APP_API_URL, NODE_ENV } = process.env;

// Связь с configurationStore
/** @type {import('./store/index').IConfigurationStore} */
let configurationStore = null;

let internationalContent = {};

/**
 * @typedef {object} request
 * @property {string} url
 * @property {string} method
 * @property {string} type
 * @property {string|undefined|null} body
 */

export const requests = /** @type {request[]} */ ([]);

/**
 * Выполнить запрос
 * @template [T = unknown]
 * @param {string} url
 * @param {string} [method = 'GET']
 * @param {unknown} [data=undefined]
 * @param {{[key: string]: string}} [headers={}]
 * @param {'json' | 'text' | 'blob'} [resType ='json']
 * @param {{ file?: boolean; action?: string, logout?: any, overwrite?: boolean }} [options={}]
 * @returns {Promise<T>}
 */
export const jsonFetch = async (
    url,
    method = 'GET',
    data = undefined,
    headers = {},
    resType = 'json',
    options = {}
) => {
    try {
        method = method || 'GET';

        const { token, content } = configurationStore;
        let body;
        if (options?.file && data) {
            const formData = new FormData();
            formData.append('file', data);
            if (options.overwrite) formData.append('options', 'overwrite');
            // headers['Content-Type'] = 'multipart/form-data';

            body = formData;
        } else if (method !== 'GET') {
            body = typeof data === 'object' ? JSON.stringify(data) : data;
            headers['Content-Type'] = 'application/json';
        }

        headers.Accept = 'application/json';
        if (token) {
            headers.authorization = `bearer ${token}`;
        }

        const urlObj = new URL(
            NODE_ENV === 'production'
                ? `${window.location.origin}${REACT_APP_API_URL || '/uiback/'}${url}`
                : `${REACT_APP_API_URL || 'http://localhost:3011/uiback/'}${url}`
        );

        if (data && method === 'GET' && Object.keys(data).length > 0) {
            // add get query params
            Object.keys(data)
                .filter(key => data[key] !== undefined)
                .forEach(key => urlObj.searchParams.append(key, String(data[key])));
            body = undefined;
        }

        if (configurationStore.isDebug) {
            requests.push({
                url: url + urlObj.search,
                method,
                body,
                type: headers['Content-Type']
            });
        }

        const response = await fetch(urlObj, {
            method,
            body,
            headers
        });

        if (!response.ok) {
            const contType = (response.headers.get('Content-Type') || '').split(';');

            const res =
                contType[0] === 'application/json'
                    ? await response.json()
                    : { message: await response.text() };

            const apiError = {
                status: response.status,
                statusText: response.statusText,
                url: response.url,
                method,
                payload: body && !(body instanceof FormData) ? JSON.parse(body) : '{}',
                responseMessage: res,
                timestamp: Date.now()
            };
            NotificationStore.setApiErrors(apiError);

            NotificationStore.showAlert(
                res.message,
                content.application.external.error.requestFailed
            );

            return;
        }

        switch (resType) {
            case 'json':
                return await response.json();
            case 'blob':
                return await response.blob();
            case 'text':
                return await response.text();
        }
    } catch (err) {
        const { action } = options;

        if (action === 'logout') {
            return;
        }
        if (err.code !== 401 || !configurationStore.refreshToken) {
            throw err;
        }
        if (action !== 'refreshToken' && (await configurationStore.tryRenewTokens())) {
            return jsonFetch(url, method, data, headers, resType, options);
        }

        return configurationStore.logout();
    }
};

export function downloadBlobFile(data, filename) {
    const url = window.URL.createObjectURL(data);
    const link = document.createElement('a');

    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
        link.remove();
    }, 1000);
}

export function isObjectsEqual(object1, object2) {
    const props1 = Object.getOwnPropertyNames(object1);
    const props2 = Object.getOwnPropertyNames(object2);

    if (props1.length !== props2.length) {
        return false;
    }

    for (let i = 0; i < props1.length; i += 1) {
        const prop = props1[i];
        const bothAreObjects =
            typeof object1[prop] === 'object' && typeof object2[prop] === 'object';

        if (
            (!bothAreObjects && object1[prop] !== object2[prop]) ||
            (bothAreObjects && !isObjectsEqual(object1[prop], object2[prop]))
        ) {
            return false;
        }
    }

    return true;
}

export const asyncMap = async (array, asyncFn) => {
    const res = [];
    for (const it of array) {
        // eslint-disable-next-line no-await-in-loop
        res.push(await asyncFn(it));
    }
    return res;
};

export const emptyGuid = '00000000-0000-0000-0000-000000000000';

/**
 * Взять идентифиатор ресурса
 * @param {resource|string} resource
 * @returns {string} Идентификатор
 */
export const getResourceGuid = resource => {
    const guid = typeof resource !== 'string' ? resource?.guid : resource;

    return guid !== emptyGuid ? guid : null;
};

export const saveFile = (fileData, fileName, type = 'text/plain') => {
    const blob = new Blob([fileData], { type });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = fileName;
    link.href = url;
    link.click();
};

// Хук для фокуса на поле ввода
export const useFocus = () => {
    const elRef = useRef(null);
    const setFocus = () => {
        elRef?.current && elRef.current?.focus();
    };

    return [elRef, setFocus];
};

export const asyncTimeout = ms => new Promise(resolve => setTimeout(resolve, ms));

// eslint-disable-next-line no-self-compare
export const isDateValid = date => date.getTime() === date.getTime();

export const dateToString = (dateVal, format) => {
    const getValue = val => {
        if (typeof val === 'object') {
            if (isDateValid(val)) {
                return val.toISOString();
            }

            const tmpVal = new Date(null);
            return tmpVal.toISOString();
        }

        return val;
    };

    const value = getValue(dateVal);

    const ISO_8601 =
        /[+-]?\d{4}(-[01]\d(-[0-3]\d(T[0-2]\d:[0-5]\d:?([0-5]\d(\.\d+)?)?[+-][0-2]\d:[0-5]\dZ?)?)?)?/i;

    if (!ISO_8601.test(value)) {
        return String(value);
    }

    const ISODate = /^([0-9]{4})(-?)(1[0-2]|0[1-9])\2(3[01]|0[1-9]|[12][0-9])$/i;

    // если в строке содержится только информация о дате, без времени,
    // то не учитываем таймзону, форматируем и отсекаем время
    if (ISODate.test(value)) {
        return moment(value).utc(false).format(format).split(' ')[0];
    }

    // utc(false) - не учитываем нулевую Тайм-зону,
    // т.е. 2022-02-22T00:00:00Z - это местное время, а не Гринвическое.
    // Не отображаем время, если оно нулевое 00:00:00
    return moment(value)
        .utc(!/00:00:00/i.test(value))
        .format(format)
        .split(' 00:00:00')[0];
};

function str2ab(str) {
    const buf = new ArrayBuffer(str.length);
    const bufView = new Uint8Array(buf);
    for (let i = 0, strLen = str.length; i < strLen; i++) {
        bufView[i] = str.charCodeAt(i);
    }
    return buf;
}

/**
 * Шифровать RSA ключом
 * @param {string} key
 * @param {string} data
 * @returns {Promise<string>}
 */
export const encryptRSA = (key, data) => {
    const publicKey = forge.pki.publicKeyFromPem(key);
    const cipher = publicKey.encrypt(data, 'RSA-OAEP', {
        mgf1: forge.md.sha1.create()
    });

    return btoa(cipher);
};

/**
 * Разбить полное имя поля на название датасета и поля
 * так как имя датасета может содержать точку, например PATIENTS.ini
 * разбиение надо производить по последней точке в fullFieldName
 * @param {string} fullFieldName
 * @returns {array}
 */
export const splitFullFieldName = fullFieldName => {
    let dataset = '';
    let field = fullFieldName;

    const ind = fullFieldName.lastIndexOf('.');
    if (ind !== -1) {
        dataset = fullFieldName.substring(0, ind);
        field = fullFieldName.substring(ind + 1);
    }

    return [dataset, field];
};

/**
 * Собрать строку для get запроса
 * @param {{[key: string]: string}} paramObj
 * @returns
 */
export const getParams = paramObj => {
    const query = Object.keys(paramObj).map(name => `${name}=${paramObj[name]}`);
    return query.join('&');
};

/**
 * Привязать configurationStore
 * @param {import('./store/index').IConfigurationStore} store
 */
export const initJsonFetch = store => {
    configurationStore = store;
    internationalContent = new InternationalContent(store);
};

/**
 * Функция валидации guid
 * @param {string} guid
 * @returns {boolean}
 */
export const checkGuid = guid => {
    const guidPattern =
        /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

    return guidPattern.test(guid);
};

/**
 * Проброс функции
 * @param opt1
 * @param [opt2]
 * @returns {string}
 */
export const getLocalizedString = (opt1, opt2) =>
    internationalContent.getLocalizedString(opt1, configurationStore.locale, opt2);

/**
 * Проброс функции
 * @param opt1
 * @param opt2
 * @returns {string}
 */
export const updateInternationalContent = (opt1, opt2) =>
    internationalContent.updateInternationalContent(opt1, opt2, configurationStore.locale);

/**
 * Проброс функции
 * @param opt
 * @returns {string}
 */
export const checkInternationalField = opt => internationalContent.checkInternationalField(opt);

/**
 * Перевод трудозатрат из минут в рабочие дни, часы и минуты
 * @param {number} minutes
 * @param {number} workHours - часов в рабочем дне
 * @returns {{hours: number, minutes: number, days: number}}
 */
export const calcWorkDays = (minutes, workHours = 8) => {
    const hours = (minutes / 60) | 0;
    const remainMinutes = minutes % 60;

    if (!hours) return { minutes: remainMinutes };

    if (hours >= workHours) {
        const days = (hours / workHours) | 0;
        const remainHours = hours % workHours;

        return {
            days,
            hours: remainHours,
            minutes: remainMinutes
        };
    }

    return {
        hours,
        minutes: remainMinutes
    };
};

/**
 * Получаем значение поля объекта по префиксу имени
 * @param {Object} obj
 * @param {string} fieldNamePrefix
 */
export const getFieldValueByNamePrefix = (obj, fieldNamePrefix) => {
    for (const key in obj) {
        if (key.startsWith(fieldNamePrefix)) {
            return obj[key];
        }
    }
    return null;
};

/**
 * Конвертируем стандартные имена веб-цветов в RGB
 * Так же можно использовать для конвертирования HEX в RGB
 * @param {string} colorName - red, green, aqua, etc...
 * @returns {string} - цвет в rgb
 */
export function convertColorNameToRGB(colorName) {
    const ctx = document.createElement('canvas').getContext('2d');
    ctx.fillStyle = colorName;
    const hex = ctx.fillStyle;
    const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
    return `rgb(${r}, ${g}, ${b})`;
}

/**
 * Конвертируем значение Delphi Tcolor в RGB
 * @param {number|string} color
 * @returns {string}
 */
export function convertTColorToRGB(color) {
    if (typeof color === 'string') return convertColorNameToRGB(color);
    const r = color & 0x0000ff;
    const g = (color & 0x00ff00) >> 8;
    const b = (color & 0xff0000) >> 16;
    return `rgb(${r}, ${g}, ${b})`;
}

/**
 * Проверяем цвет бекграунда, назначенный элементу
 * для изменения цвета текста этого элемента (контраст цветов)
 * See more:
 * https://www.w3.org/TR/AERT/#color-contrast
 * https://stackoverflow.com/a/11868159
 * @param {string|undefined} color цвет в rgb
 * @returns {string}
 */
export const getContrastColor = color => {
    if (!color) return false;
    const rgbColors = color
        .replace('rgb(', '')
        .replace(')', '')
        .split(',')
        .map(part => parseInt(part, 10));

    const [r, g, b] = rgbColors;
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return Math.round(brightness) < 125 ? 'white' : 'inherit';
};

/**
 *
 * @param {*[]} arr
 * @param {number} fromIndex
 * @param {number} toIndex
 * @returns {*[]}
 */
export const arrayMove = (arr, fromIndex, toIndex) => {
    const element = arr[fromIndex];

    arr.splice(fromIndex, 1);

    let newIndex = toIndex;
    if (toIndex < 0) {
        newIndex = arr.length;
    } else if (toIndex > arr.length) {
        newIndex = 0;
    }

    arr.splice(newIndex, 0, element);

    return arr;
};

/**
 * Трансформация файла в dataURL
 *
 * @param {File} file
 * @returns {Promise<unknown>}
 */
export const fileToDataURL = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

/**
 * Трансформация массива файлов в dataURL
 *
 * @param {File[]} fileList
 * @returns {Promise<Awaited<unknown>[]>}
 */
export const fileListToDataURL = fileList =>
    Promise.all(
        fileList.map(async file => {
            try {
                const data = await fileToDataURL(file);

                return {
                    fileName: file.name,
                    data
                };
            } catch (e) {
                console.error(e.message);
            }
        })
    );

/**
 * Трансформация dataURL в File
 *
 * @param {string} dataUrl
 * @param {string} [filename]
 * @returns {File}
 */
export const dataURLtoFile = (dataUrl, filename = 'file') => {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[arr.length - 1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
};

/**
 * Трансформация массива dataURL в массив File
 *
 * @param {string[]} dataUrlList
 * @returns {File[]}
 */
export const dataURLListToFile = dataUrlList =>
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    dataUrlList.map((dataUrl, i) => dataURLtoFile(dataUrl, `image-${i}`));

/**
 * Переход по ссылке в рамках приложения
 *
 * @param {string} path
 */
export const goToPath = path => {
    window.location = `${ConfigurationStore.basename || '/'}${path}/`;
};

/**
 * Вычисление идентификатора добавленной записи
 *
 * @param {UpdateResult[]} result
 * @param {string} keyField
 */
export const getCreatedRecordId = (result, keyField) => {
    const newRec = result.find(resArr =>
        resArr.find(res => res?.records.find(record => record.keyFields[keyField] === -1))
    );
    return newRec?.[0]?.records?.find(rec => rec.keyFields[keyField] === -1)?.diff[keyField];
};

/**
 * Вычисление идентификатора добавленной записи CDO
 *
 * @param {UpdateCDOResult[]} result
 * @param {datasetName} keyField
 * @param {string} keyField
 */
export const getCreatedCDORecordId = (result, datasetName, keyField) => {
    const newRec = result.find(res =>
        res?.datasets
            ?.find(dataset => dataset?.datasetName === datasetName)
            ?.records.find(record => record?.keyFields[keyField] < 0)
    );

    return newRec?.datasets.find(dataset => dataset.datasetName === datasetName).records[0].diff[
        keyField
    ];
};
