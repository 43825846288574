import PropContainer from '../dataObj/PropContainer';
import DataStore from '../store/dataStore';

import CustomDataset from '../dataObj/customDataset';

type UseCtrlDataResult = {
    dataset: CustomDataset;
    fieldName: string;
    dataVal: any;
    dataType: string | null;
    fieldEnabled: boolean;
    fieldRequired: boolean;
    internationalField: boolean;
    fieldSize?: number;
    fieldScale?: number;
};

export default function useCtrlData(
    propContainer: PropContainer,
    datasetName: string,
    fieldName: string,
    dataRow?: any
): UseCtrlDataResult {
    let dataset;
    let dataVal;
    let dataType;
    let fieldEnabled;
    let fieldRequired = false;
    let internationalField = false;
    let fieldSize;
    let fieldScale;

    const getDataVal = (ds: any) => {
        if (ds) {
            if (dataRow) {
                return dataRow[fieldName];
            }

            return ds.getFieldValue(fieldName);
        }

        return null;
    };

    const getDataType = (ds: any) => {
        if (ds) {
            const datasetField = ds.getFieldDescr(fieldName);

            return datasetField?.dataType ?? null;
        }

        return null;
    };

    if (datasetName.toUpperCase() === 'AF') {
        dataVal = DataStore.AF.getAF(fieldName);
        fieldEnabled = false;
    } else {
        dataset = propContainer.dataStock.getDatasetObj(datasetName);

        dataVal = getDataVal(dataset);
        dataType = getDataType(dataset);
        fieldEnabled = propContainer.getProperty(`${datasetName}.${fieldName}`, 'enabled') ?? false;
        fieldRequired = propContainer.getFieldRequirement(fieldName, datasetName);
        fieldSize = propContainer.getFieldSize(fieldName, datasetName);
        fieldScale = propContainer.getFieldScale(fieldName, datasetName);
        internationalField =
            typeof dataType === 'string' &&
            ['KRN_STRING_INTERNATIONAL', 'KRN_MEMO_INTERNATIONAL'].includes(dataType);
    }
    return {
        dataset,
        fieldName,
        dataVal,
        dataType,
        fieldEnabled,
        fieldRequired,
        internationalField,
        fieldSize,
        fieldScale
    };
}
