import React, { FunctionComponent, useRef, useState, useEffect } from 'react';
import { observer } from 'mobx-react';

import { Box } from '@mui/material';

import useCtrlData from '../../hooks/ctrlData';
import PropContainer from '../../dataObj/PropContainer';
import useOnScreen from '../../hooks/onScreen';
import CanvasDrawPane from '../../components/CanvasDraw/CanvasDrawPane';

interface PropsType {
    descr: any;
    propContainer: PropContainer;
}

const DBImageDraw: FunctionComponent<PropsType> = observer(({ descr, propContainer }) => {
    const { datasetName, fieldName } = descr;
    const { dataset, dataVal } = useCtrlData(propContainer, datasetName, fieldName);

    const [height, setHeight] = useState(0);
    const [val, setVal] = useState(dataVal);

    const ref = useRef<HTMLElement>(null);

    const onScreen = useOnScreen(ref);

    useEffect(() => {
        // Нужна точная высота, в текущей реализации её можно взять на 2 уровня выше по DOM
        setHeight(ref?.current?.parentElement?.parentElement?.offsetHeight ?? 0);
    }, [onScreen]);

    useEffect(() => {
        if (dataset) {
            setVal(dataVal);
        }
    }, [dataset, dataVal]);

    useEffect(() => {
        if (dataset?.inEdit) dataset.setFieldValue(fieldName, val);
    }, [val]);

    const onSaveHandler = (value: string) => {
        if (dataset?.inEdit) {
            setVal(value);
        }
    };

    return (
        <Box ref={ref}>
            <CanvasDrawPane content={val} wrapperHeight={height} onSaveHandler={onSaveHandler} />
        </Box>
    );
});

export default DBImageDraw;
