import applicationContent from './application/ruApplicationLocale';
import resourceContent from './resources/ruResourceLocale';
import controlsContent from './controls/ruControlsLocale';
import componentsContent from './components/ruComponentLocale';

export default {
    language: 'Русский',
    application: applicationContent,
    controls: controlsContent,
    resource: resourceContent,
    components: componentsContent
};
