import React, { FunctionComponent, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { Dialog, DialogContent, DialogActions, TextField, Button, Stack } from '@mui/material';

import ConfigurationStore from '../../../../../store/configurationStore';

import { EventType } from '../../ResInterfaces';
import { Locale, TranslationScheme } from '../interfaces';

interface PropsType {
    localisations: string[];
    lang: string;
    translationScheme: TranslationScheme;
    onConfirm: (string: { key: string; path: string; localisation: Locale }) => unknown;
}

const NewStringDialog: FunctionComponent<PropsType> = observer(props => {
    const { localisations, lang, translationScheme, onConfirm } = props;
    const { content } = ConfigurationStore;

    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [stringKey, setStringKey] = useState('');
    const [localisation, setLocalisation] = useState<{ [key: string]: string }>({});

    const handleOpen = () => {
        setOpen(true);
    };

    const handleChangeKey = (event: EventType) => {
        setStringKey(event.target.value ?? '');
        setError(
            !!translationScheme[
                `00000000-0000-0000-0000-000000000000_${event.target.value as string}`
            ]
        );
    };

    const handleChange = (locale: string, event: EventType) => {
        setLocalisation({ ...localisation, ...{ [locale]: event.target.value ?? '' } });
    };

    const handleConfirm = () => {
        onConfirm({
            key: `00000000-0000-0000-0000-000000000000_${stringKey}`,
            path: stringKey,
            localisation
        });
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
        setLocalisation({});
        setStringKey('');
    };

    useEffect(() => {
        if (open) {
            const emptyLocalisation: { [key: string]: string } = { init: '' };
            localisations.map(loc => {
                if (loc !== lang) emptyLocalisation[loc] = '';
                return;
            });

            setLocalisation(emptyLocalisation);
        }
    }, [open]);

    return (
        <>
            <Button onClick={handleOpen}>{content.resource.buttons.add}</Button>
            <Dialog open={open} maxWidth="sm" fullWidth>
                <DialogContent>
                    <Stack direction="column" spacing={4}>
                        <TextField
                            error={error}
                            helperText={error ? content.resource.translation.exists : ''}
                            fullWidth
                            size="small"
                            label={content.resource.translation.fieldName}
                            value={stringKey}
                            onChange={handleChangeKey}
                        />
                        <TextField
                            fullWidth
                            size="small"
                            label={content.resource.translation.baseValue}
                            value={localisation.init}
                            onChange={event => handleChange('init', event)}
                        />
                        {localisations
                            .filter(loc => loc !== lang)
                            .map((loc: string) => (
                                <TextField
                                    key={loc}
                                    fullWidth
                                    size="small"
                                    label={loc}
                                    value={localisation[loc] ?? ''}
                                    onChange={event => handleChange(loc, event)}
                                />
                            ))}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{content.resource.buttons.undo}</Button>
                    <Button disabled={error} onClick={handleConfirm}>
                        {content.resource.buttons.add}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
});

export default NewStringDialog;
