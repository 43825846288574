import { get, set, observable, action, makeAutoObservable } from 'mobx';

import { jsonFetch } from '../utils';

type GlossaryElement = string;
type GlossaryResponse = { elements: string };

type GlossaryKey = {
    guid: string;
    datasetName: string;
    field: string;
    isProfile?: number;
    isPersonal?: number;
};

class Glossary {
    // guid => datasetName => fieldName%isProfile%isPersonal
    @observable glossList: Record<string, Record<string, Record<string, GlossaryElement>>> = {};

    constructor() {
        makeAutoObservable(this);
    }

    // Добавление записи в кэш
    @action private setGlossary = (
        key: string,
        glossary: Record<string, Record<string, GlossaryElement>>
    ) => {
        set(this.glossList, { [key]: glossary });
    };

    // Чтение записи из кэша
    @action private getGlossary = (key: string) =>
        get(this.glossList, key) as Record<string, Record<string, GlossaryElement>>;

    private findGlossary = (key: GlossaryKey) => {
        const objGloss = this.getGlossary(key.guid);

        if (!objGloss) {
            return;
        }
        const dsGloss = objGloss[key.datasetName];
        if (!dsGloss) {
            return;
        }

        return dsGloss[Glossary.buildFieldName(key)];
    };

    private static buildFieldName(key: GlossaryKey) {
        return `${key.field}%${key.isProfile || 0}%${key.isPersonal || 0}`;
    }

    private registerGlossary = (key: GlossaryKey, element: GlossaryElement) => {
        const objGloss = this.getGlossary(key.guid) || {};

        objGloss[key.datasetName] = objGloss[key.datasetName] || {};
        const dsGloss = objGloss[key.datasetName];

        dsGloss[Glossary.buildFieldName(key)] = element;

        this.setGlossary(key.guid, objGloss);
        return element;
    };

    glossaryRequest = async (key: GlossaryKey): Promise<string> => {
        const data: GlossaryResponse = await jsonFetch('gloss', 'POST', key);
        // Пустое значение тоже записываем
        return this.registerGlossary(key, data?.elements || '');
    };

    getGlossaryText = (key: GlossaryKey) => {
        const glossitem = this.findGlossary(key);
        if (typeof glossitem !== 'undefined') {
            return glossitem;
        }

        return null;
    };

    setGlossaryText = async (key: GlossaryKey, elements: string) => {
        const data: GlossaryResponse = await jsonFetch('gloss/update', 'POST', {
            ...key,
            elements
        });

        return this.registerGlossary(key, data?.elements || '');
    };
}

const GlossaryStore = new Glossary();
export default GlossaryStore;
