import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';

import { Grid, IconButton, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import ConfigurationStore from 'store/configurationStore';

interface PropsType {
    handleUpload: () => unknown;
    disabled?: boolean;
}

const Add: FunctionComponent<PropsType> = observer(({ handleUpload, disabled }) => {
    const { content } = ConfigurationStore;

    return !disabled ? (
        <Grid
            item
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{
                width: 30,
                minHeight: 130,
                color: '#0000008a'
            }}
        >
            <Grid item>
                <Tooltip title={content.controls.fileUploader.add}>
                    <IconButton onClick={handleUpload}>
                        <AddIcon sx={{ fontSize: 60 }} />
                    </IconButton>
                </Tooltip>
            </Grid>
        </Grid>
    ) : null;
});

export default Add;
