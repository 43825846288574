import { useEffect } from 'react';

export default function useRequiredField(active: boolean) {
    useEffect(() => {
        if (active) {
            let input: HTMLInputElement;

            setTimeout(() => {
                const requiredFields = document.getElementsByClassName('required-field');
                if (requiredFields?.length) {
                    for (let i = 0; i < requiredFields.length; i++) {
                        const inputs = requiredFields[i]?.getElementsByTagName('input');
                        if (inputs[0]?.value === '') {
                            i = requiredFields.length;
                            input = inputs[0];
                        }
                    }
                }

                if (input) setTimeout(() => input.focus(), 100);
            }, 100);
        }
    }, [active]);
}
