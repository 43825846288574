import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';

import { Box } from '@mui/material';

import StyledTabs from 'forms/controls/PageControl/StyledTabs';
import StyledTab from 'forms/controls/PageControl/StyledTab';
import TabPanel from 'forms/controls/PageControl/TabPanel';

import styles from 'forms/form.module.scss';

import ConfigurationStore from 'store/configurationStore';

import { SimpleObject } from 'forms/interfaces';

import GridList from './GridList';
import { fieldsColumns, groupColumns, fieldListColumns } from '../CDOSettingsConst';

import {
    GroupFieldsListType,
    FieldInfoType,
    GroupInfoType,
    GroupRowProps,
    SettingActions,
    SettingsPropsType
} from '../index';

import SelectFields from './SelectFields';
import GroupFieldsList from './GroupFieldsList';

const CDOSettings: FunctionComponent<SettingsPropsType> = observer(props => {
    const content = ConfigurationStore.content.resource.cdoProps;

    const { resource, ResourceStore, setActions } = props;

    const cdoOptions = useMemo(() => JSON.parse(resource?.options || '{}'), [resource?.guid]);

    const [active, setActive] = useState(0);
    const [fieldList, setFieldList] = useState<FieldInfoType[]>([]);
    const [groupList, setGroupList] = useState<GroupInfoType[]>([]);

    const [openSelect, setOpenSelect] = useState(false);
    const [addFieldList, setAddFieldList] = useState<GroupFieldsListType[]>([]);

    const datasetList = useMemo(
        () => ResourceStore.getDatasetList().map(ds => ds.name),
        [resource?.guid]
    );

    const selectFieldsClose = (result: unknown) => {
        setOpenSelect(false);
    };

    // Список действий которые может выполнять редактор
    const actions: SettingActions = useMemo(
        () => ({
            getValue: () => {
                const options = { datasets: {} } as { [key: string]: SimpleObject };

                const datasets = ResourceStore.getDatasetList().filter(
                    ds =>
                        !!fieldList.find(field => field.dataset === ds.name) ||
                        !!groupList.find(group => group.dataset === ds.name)
                );

                datasets.forEach(ds => {
                    options.datasets[ds.name] = { fields: {}, groups: {}, groupOrder: [] };

                    fieldList
                        .filter(field => field.dataset === ds.name)
                        .forEach(field => {
                            options.datasets[ds.name].fields[field.name] = {
                                width: field.width
                            };
                        });

                    const groupOrder = groupList
                        .filter(group => group.dataset === ds.name)
                        .map(group => {
                            options.datasets[ds.name].groups[group.name] = {
                                caption: group.caption,
                                width: group.width,
                                fields: group.fields
                            };

                            return group.name;
                        });

                    if (groupOrder?.length) options.datasets[ds.name].groupOrder = groupOrder;
                });

                return JSON.stringify(options);
            }
        }),
        [fieldList, groupList]
    );

    useEffect(() => {
        if (setActions) {
            setActions(actions);
        }
    }, [setActions, actions]);

    useEffect(() => {
        const optionsFields: FieldInfoType[] = [];
        const optionsGroups: GroupInfoType[] = [];

        if (cdoOptions?.datasets) {
            const { datasets } = cdoOptions;

            // Формируем список полей и групп из настроек
            Object.keys(datasets).forEach(dsName => {
                const { fields, groups, groupOrder } = cdoOptions.datasets[dsName];

                Object.keys(fields).forEach(fieldName => {
                    optionsFields.push({
                        id: fieldName,
                        dataset: dsName,
                        name: fieldName,
                        width: fields[fieldName].width
                    });
                });

                if (groups) {
                    if (groupOrder?.length) {
                        groupOrder.map((groupName: string) =>
                            optionsGroups.push({
                                id: groupName,
                                dataset: dsName,
                                name: groupName,
                                caption: groups[groupName].caption,
                                width: groups[groupName].width,
                                fields: groups[groupName].fields.map((fld: string) => ({
                                    id: fld,
                                    caption: fld,
                                    width: fields[fld]?.width
                                }))
                            })
                        );
                    }

                    Object.keys(groups).forEach(groupName => {
                        if (!optionsGroups.find(group => group.name === groupName)) {
                            optionsGroups.push({
                                id: groupName,
                                dataset: dsName,
                                name: groupName,
                                caption: groups[groupName].caption,
                                width: groups[groupName].width,
                                fields: groups[groupName].fields.map((fld: string) => ({
                                    id: fld,
                                    caption: fld,
                                    width: fields[fld]?.width
                                }))
                            });
                        }
                    });
                }
            });
        }

        setFieldList(optionsFields);
        setGroupList(optionsGroups);
    }, [resource?.guid]);

    const updateGroupFields = (rowProps: GroupRowProps, grpFields: GroupFieldsListType[]) => {
        let arr = [...fieldList];

        grpFields.map(grpField => {
            if (grpField.width || grpField.width === '') {
                const exists = arr.find(field => field.id === grpField.caption);

                if (exists) {
                    exists.width = grpField.width;
                } else {
                    arr = [
                        ...fieldList,
                        ...[
                            {
                                id: grpField.caption,
                                dataset: rowProps.row.dataset,
                                name: grpField.caption,
                                width: grpField.width
                            }
                        ]
                    ];
                }
            }
            return;
        });

        setFieldList(arr);
    };

    const updateGroupHandler = (rowProps: GroupRowProps) => (fields: GroupFieldsListType[]) => {
        setGroupList(
            groupList.map(grp => {
                if (grp.id === rowProps.id) {
                    grp.fields = fields;
                }

                return grp;
            })
        );
        updateGroupFields(rowProps, fields);
    };

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <StyledTabs
                    onChange={(event: any, newValue: number) => setActive(newValue)}
                    value={active}
                >
                    <StyledTab key="1" label={content.tabs.fields} />
                    <StyledTab key="2" label={content.tabs.groups} />
                </StyledTabs>
            </Box>
            <div className={styles.tabPanel} style={{ height: 500 }}>
                <TabPanel key="1" index={0} value={active}>
                    <GridList
                        columns={fieldsColumns(content)}
                        actions={['add', 'remove']}
                        rows={fieldList}
                        setRows={setFieldList}
                        ResourceStore={ResourceStore}
                    />
                </TabPanel>
                <TabPanel key="2" index={1} value={active}>
                    <GridList
                        columns={groupColumns(content, datasetList, (rowProps: GroupRowProps) => (
                            <GroupFieldsList
                                group={rowProps}
                                ResourceStore={ResourceStore}
                                updateGroup={updateGroupHandler(rowProps)}
                            />
                        ))}
                        defaultItem={{
                            id: groupList.length,
                            dataset: '',
                            name: 'New group',
                            caption: '',
                            width: '',
                            fields: []
                        }}
                        actions={['create', 'remove', 'up', 'down']}
                        rows={groupList}
                        setRows={setGroupList}
                        ResourceStore={ResourceStore}
                    />
                    <SelectFields
                        setRows={() => {}}
                        open={openSelect}
                        columns={fieldListColumns(content)}
                        rows={addFieldList || []}
                        handleClose={selectFieldsClose}
                    />
                </TabPanel>
            </div>
        </>
    );
});

export default CDOSettings;
