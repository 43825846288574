import CustomDataset from '../../../dataObj/customDataset';

import { DatasetFieldType } from '../../interfaces';
import { generateEditControl, generateLookupControl } from '../../form-utils';
import NotificationStore from '../../../store/notificationStore';

export const getDetailDS = (ds: CustomDataset) => {
    let detailDatasets: Array<CustomDataset> = [];

    for (const fld in ds.detailDatasets) {
        detailDatasets = Array.from(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/ban-ts-comment
            // @ts-ignore
            new Set([...detailDatasets, ...ds.detailDatasets[fld]])
        );
    }

    return detailDatasets?.filter((detailDS: CustomDataset) => detailDS) || [];
};

export const lookupGenerator = (ds: CustomDataset, field: DatasetFieldType) => {
    try {
        const lookupSource: { tableName: string; splitField: string; splitValue: string } =
            JSON.parse(field.lookupSource);

        return generateLookupControl(
            field.caption,
            ds.name,
            field.name,
            ds.options?.fields[field.name],
            lookupSource.tableName,
            lookupSource.splitField,
            lookupSource.splitValue
        );
    } catch (err: any) {
        NotificationStore.showAlert(err.message);
        return generateEditControl(
            field.caption,
            ds.name,
            field.name,
            ds.options?.fields([field.name])
        );
    }
};
