import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';

import { ClickAwayListener, Grow, MenuList, Paper, Popper } from '@mui/material';

import { NestedMenuType } from '../interfaces';

import MenuItem from './MenuItem';

const NestedMenu: FunctionComponent<NestedMenuType> = observer(
    ({ open, items, propContainer, handleClose, menuPlacement, anchorRef, parentClose }) => (
        <Popper
            sx={{
                zIndex: 1
            }}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            placement={menuPlacement}
        >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                    }}
                >
                    <Paper
                        sx={{
                            minWidth:
                                menuPlacement === 'bottom-start'
                                    ? anchorRef.current?.offsetWidth
                                    : 'initial'
                        }}
                    >
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList id="split-button-menu" autoFocusItem>
                                {items?.map((item, index) => (
                                    <MenuItem
                                        key={index}
                                        descr={item}
                                        propContainer={propContainer}
                                        parentClose={parentClose}
                                    >
                                        {item.caption}
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    )
);

export default NestedMenu;
