import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormLabel,
    Step,
    StepContent,
    StepLabel,
    Stepper
} from '@mui/material';

import { DatasetFieldType } from 'forms/interfaces';

import ConfigurationStore from 'store/configurationStore';
import { ResourceClass } from 'store/resourceStore';

import DatasetSelection from './DatasetSelection';
import FieldsSelection from './FieldsSelection';

interface PropsType {
    open: boolean;
    setOpen: (open: boolean) => unknown;
    checkFields: (dsName: string, field: DatasetFieldType) => unknown;
    ResourceStore: ResourceClass;
    onConfirm: (
        selectedDataset: string[],
        selectedFields: string[],
        fieldsList: { [dsName: string]: DatasetFieldType[] }
    ) => unknown;
    dataset?: string[];
}

const ImportFields: FunctionComponent<PropsType> = observer(
    ({ open, setOpen, ResourceStore, dataset, onConfirm, checkFields }) => {
        const { getDatasetList, getDatasetsFields } = ResourceStore;
        const { content } = ConfigurationStore;

        const [activeStep, setActiveStep] = useState(dataset ? 1 : 0);
        const [selectedDataset, setSelectedDataset] = useState<string[]>(dataset || []);
        const [fieldsList, setFieldsList] = useState<{ [dsName: string]: DatasetFieldType[] }>({});
        const [selectedFields, setSelectedFields] = useState<string[]>([]);

        const datasetList = useMemo(() => getDatasetList(), [ResourceStore]);

        useEffect(() => {
            setFieldsList(
                getDatasetsFields(datasetList.filter(ds => selectedDataset.includes(ds.name)))
            );
        }, [selectedDataset, selectedDataset.length]);

        const handleSelectDataset = (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.checked) {
                setSelectedDataset([...selectedDataset, event.target.name]);
            } else {
                setSelectedDataset(selectedDataset.filter(ds => ds !== event.target.name));
            }
        };

        const selectAllDatasets = (datasets: string[] | null) => {
            if (datasets) {
                setSelectedDataset([...selectedDataset, ...datasets]);
            } else setSelectedDataset([]);
        };

        const handleSelectField = (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.checked) {
                setSelectedFields([...selectedFields, event.target.name]);
            } else {
                setSelectedFields(selectedFields.filter(ds => ds !== event.target.name));
            }
        };

        const selectAllFields = (select: boolean, fields: string[]) => {
            if (select) {
                setSelectedFields([...selectedFields, ...fields]);
            } else
                setSelectedFields([
                    ...selectedFields.filter(selected => !fields.includes(selected))
                ]);
        };

        const handleStepForward = () => {
            setActiveStep(prevActiveStep => prevActiveStep + 1);
        };

        const handleStepBackward = () => {
            setActiveStep(prevActiveStep => prevActiveStep - 1);
        };

        const handleCLose = () => {
            setOpen(false);
            setSelectedDataset([]);
            setSelectedFields([]);
            setActiveStep(0);
        };

        const handleConfirm = () => {
            onConfirm(selectedDataset, selectedFields, fieldsList);
            handleCLose();
        };

        const steps = [
            {
                label: content.resource.dialog.chooseDS,
                content: (
                    <DatasetSelection
                        datasetList={datasetList}
                        selectedDataset={selectedDataset}
                        checkFields={checkFields}
                        handleSelectDataset={handleSelectDataset}
                        handleSelectAll={selectAllDatasets}
                    />
                ),
                rule: selectedDataset.length
            },
            {
                label: content.resource.dialog.chooseFields,
                content: Object.keys(fieldsList)
                    .filter(
                        // Проверяем наличие полей в датасете
                        dsName =>
                            fieldsList[dsName].length &&
                            // Проверяем, что доступные поля ещё не добавлены на форму
                            fieldsList[dsName].filter(field => checkFields(dsName, field)).length
                    )
                    .map(dsName => (
                        <>
                            <FormLabel component="legend" sx={{ mt: 2 }}>
                                {dsName}
                            </FormLabel>
                            <FieldsSelection
                                dsName={dsName}
                                fieldsList={fieldsList}
                                selectedFields={selectedFields}
                                checkFields={checkFields}
                                handleSelectField={handleSelectField}
                                handleSelectAll={selectAllFields}
                            />
                        </>
                    )),
                rule: selectedFields.length
            }
        ];

        return (
            <Dialog open={open} maxWidth="sm" fullWidth onClose={handleCLose}>
                <DialogTitle>{content.resource.dialog.title}</DialogTitle>
                <DialogContent>
                    <Stepper activeStep={activeStep} orientation="vertical">
                        {steps.map((step, index) => (
                            <Step key={index}>
                                <StepLabel>{step.label}</StepLabel>
                                <StepContent>{step.content}</StepContent>
                            </Step>
                        ))}
                    </Stepper>
                </DialogContent>
                <DialogActions>
                    <Button disabled={activeStep !== steps.length - 1} onClick={handleStepBackward}>
                        {content.resource.buttons.back}
                    </Button>
                    <Button
                        disabled={!steps[activeStep].rule}
                        onClick={
                            activeStep === steps.length - 1 ? handleConfirm : handleStepForward
                        }
                    >
                        {activeStep === steps.length - 1
                            ? content.resource.buttons.add
                            : content.resource.buttons.next}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
);

export default ImportFields;
