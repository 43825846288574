import React, { useEffect } from 'react';
import { Rect, Circle, Line, Transformer, Group } from 'react-konva';

import DRAW_MODE from './constants';

interface PropsType {
    shapeProps?: any;
    isSelected?: boolean;
    onSelect: () => void;
    onChange: (value: unknown) => void;
}

const CanvasDrawShape = (props: PropsType) => {
    const { shapeProps, isSelected, onSelect, onChange } = props;
    const shapeRef = React.useRef(null) as any;
    const transformerRef = React.useRef(null) as any;

    const isLine = shapeProps.element === DRAW_MODE.PENCIL;

    useEffect(() => {
        if (isSelected && !isLine) {
            transformerRef.current.nodes([shapeRef.current]);
            transformerRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    const handleMouseEnter = (event: any) => {
        event.target.getStage().container().style.cursor = 'grab';
    };

    const handleMouseLeave = (event: any) => {
        event.target.getStage().container().style.cursor = 'crosshair';
    };

    const handleClick = (event: any) => {
        onSelect();
    };

    const handleDragStart = (event: any) => {
        onSelect();
    };

    const handleMouseDown = (event: any) => {
        event.target.getStage().container().style.cursor = 'grab';
        event.cancelBubble = true;
    };

    const renderShapes = () => {
        const { element } = shapeProps;
        switch (element) {
            case DRAW_MODE.RECTANGLE:
                return (
                    <Rect
                        ref={shapeRef}
                        onClick={handleClick}
                        {...shapeProps}
                        draggable
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        onDragStart={handleDragStart}
                        onDragEnd={event => {
                            onChange({
                                ...shapeProps,
                                x: event.target.x(),
                                y: event.target.y()
                            });
                        }}
                        onTransformEnd={event => {
                            const node = shapeRef.current;
                            const scaleX = node.scaleX();
                            const scaleY = node.scaleY();

                            node.scaleX(1);
                            node.scaleY(1);
                            onChange({
                                ...shapeProps,
                                x: node.x(),
                                y: node.y(),
                                width: Math.max(5, node.width() * scaleX),
                                height: Math.max(node.height() * scaleY)
                            });
                        }}
                    />
                );
            case DRAW_MODE.CIRCLE:
                return (
                    <Circle
                        ref={shapeRef}
                        onClick={handleClick}
                        {...shapeProps}
                        draggable
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        onDragStart={handleDragStart}
                        onDragEnd={event => {
                            onChange({
                                ...shapeProps,
                                x: event.target.x(),
                                y: event.target.y()
                            });
                        }}
                        onTransformEnd={event => {
                            const node = shapeRef.current;
                            // const height = node.height();
                            // const scaleX = node.scaleX();
                            const scaleY = node.scaleY();

                            node.scaleX(1);
                            node.scaleY(1);
                            onChange({
                                ...shapeProps,
                                x: node.x(),
                                y: node.y(),
                                radius: node.radius() * scaleY
                            });
                        }}
                    />
                );
            case DRAW_MODE.PENCIL:
                return (
                    <Line
                        points={shapeProps.points}
                        tension={0.5}
                        lineCap="round"
                        lineJoin="round"
                        draggable
                        onDragStart={handleDragStart}
                        onMouseEnter={handleMouseEnter}
                        onMouseDown={handleMouseDown}
                        onMouseLeave={handleMouseLeave}
                        onClick={handleClick}
                        opacity={isSelected ? 1 : 0.6}
                        onDragEnd={event => {
                            onChange({
                                ...shapeProps,
                                x: event.target.x(),
                                y: event.target.y()
                            });
                        }}
                        {...shapeProps}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <>
            {renderShapes()}
            {isSelected && !isLine && (
                <Transformer
                    ref={transformerRef}
                    rotateEnabled={shapeProps.element !== DRAW_MODE.CIRCLE}
                    centeredScaling={shapeProps.element === DRAW_MODE.CIRCLE}
                    boundBoxFunc={(oldBox, newBox) => {
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </>
    );
};

export default CanvasDrawShape;
