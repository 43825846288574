import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { StyledEngineProvider } from '@mui/material/styles';
import { Container } from '@mui/material';

import Loading from 'components/utils/Loading/Loading';
import AppVersion from 'components/Feedback/AppVersion';
import ConfigurationStore from 'store/configurationStore';

import links from './links';

const TestPage = () => {
    const { content, isAuthenticated } = ConfigurationStore;

    return (
        <StyledEngineProvider injectFirst>
            <main
                style={{
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    position: 'fixed',
                    top: 0,
                    left: 0
                }}
            >
                <Container maxWidth="xl" style={{ margin: 'auto' }}>
                    <Suspense fallback={<Loading message={content.controls.data.lazyLoading} />}>
                        <Switch>
                            {links
                                .filter(link => link.component)
                                .map((link, idx) => (
                                    <Route
                                        key={`${idx}-${link.link}`}
                                        path={`/test/${link.link}`}
                                        exact={link.exact}
                                    >
                                        {link.component}
                                    </Route>
                                ))}
                            <Route render={() => <Redirect to="/" />} />
                        </Switch>
                    </Suspense>
                </Container>
            </main>
            {!isAuthenticated && <AppVersion position="right" />}
        </StyledEngineProvider>
    );
};

export default TestPage;
