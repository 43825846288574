import { useMemo } from 'react';

export default function useEditType(dataType?: string, controlType?: string, password?: boolean) {
    return useMemo(() => {
        if (password) return 'password';

        if (dataType) return dataType;

        switch (controlType) {
            case 'dbNumEdit':
                return 'number';
            case 'dbDate':
                return 'date';
            case 'dbDateTime':
                return 'datetime-local';
            default:
                return 'text';
        }
    }, [dataType, controlType, password]);
}
