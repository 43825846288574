const langFra = {
    inputs: {
        autocomplete: {
            add: 'Ajouter',
            noOptions: 'Aucune option'
        },
        filterControl: {
            conditions: [
                `Dans l'intervalle`,
                `En dehors de l'intervalle`,
                'Fantes avec',
                'Contient',
                'Ne contient pas',
                'Se termine',
                'Équivaut à',
                'Inégal',
                'Rempli',
                'Vide',
                'Y compris',
                `À l'exclusion`,
                `N'a pas d'importance`,
                'Oui',
                'Pas'
            ]
        },
        date: {
            today: "Aujourd'",
            cancel: 'Annuler'
        }
    }
};

export default langFra;
