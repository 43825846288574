import React, { FunctionComponent, useState, useEffect, ReactNode } from 'react';
import { observer } from 'mobx-react';

import { Button, Dialog, DialogActions, DialogContent, Grid, Box, Typography } from '@mui/material';

import Prism from 'prismjs';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-xml-doc';
import 'prismjs/themes/prism.css';

import Editor from '../../../Inputs/HighlightEditor';

import ConfigurationStore from '../../../../store/configurationStore';

interface PropTypes {
    open: boolean;
    iconCode: string;
    IconPreview: (
        iconCode: string,
        fontSize?: number | 'inherit' | 'large' | 'medium' | 'small',
        props?: any
    ) => ReactNode;
    handleClose: () => unknown;
    handleConfirm: (code: string) => unknown;
}

const IconEditorDialog: FunctionComponent<PropTypes> = observer(
    ({ open, iconCode, IconPreview, handleClose, handleConfirm }) => {
        const { resource }: any = ConfigurationStore.content;

        const [code, setCode] = useState('');
        const [maxIconSize, setMaxIconSize] = useState<number | null>(null);

        const getIconMaxSize = () => {
            const iconWrapper = document.getElementById('icon-preview');

            if (iconWrapper) {
                const cs = window.getComputedStyle(iconWrapper, null);
                const wrapperWidth = iconWrapper.clientWidth;

                return (
                    wrapperWidth - (parseFloat(cs.paddingLeft) + parseFloat(cs.paddingRight)) - 4
                );
            }

            return null;
        };

        useEffect(() => setCode(iconCode), []);

        useEffect(() => {
            setMaxIconSize(getIconMaxSize());
        }, [code]);

        return (
            <Dialog open={open} maxWidth="md" fullWidth>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <Typography>{resource.iconEditor.title}</Typography>
                            <Box
                                sx={{
                                    height: maxIconSize ? maxIconSize + 4 : 100,
                                    maxHeight: 500,
                                    overflow: 'auto',
                                    borderColor: 'grey.400',
                                    borderRadius: 1,
                                    borderStyle: 'solid',
                                    borderWidth: 1
                                }}
                            >
                                <Editor
                                    value={code}
                                    onValueChange={(codeText: string) => setCode(codeText)}
                                    highlight={codeText =>
                                        Prism.highlight(codeText, Prism.languages.svg, 'svg')
                                    }
                                    padding={16}
                                    style={{
                                        fontFamily: '"Fira code", "Fira Mono", monospace',
                                        fontSize: 14,
                                        overflow: 'hidden'
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid
                            id="icon-preview"
                            sx={{ display: 'flex', flexDirection: 'column' }}
                            item
                            xs
                        >
                            <Typography>{resource.iconEditor.preview}</Typography>
                            <Box sx={{ m: 'auto' }}>
                                {!!code && (
                                    <>
                                        <Box
                                            sx={{
                                                borderColor: 'warning.main',
                                                borderRadius: 1,
                                                borderStyle: 'solid',
                                                borderWidth: 1
                                            }}
                                        >
                                            {IconPreview(code, maxIconSize ?? 'large')}
                                        </Box>
                                        <Typography sx={{ float: 'right' }}>
                                            {`${resource.iconEditor.fontSize as string}: ${
                                                `${maxIconSize ?? 'unknown'}px` ?? 'large'
                                            }`}
                                        </Typography>
                                    </>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{resource.scriptEditor.cancel}</Button>
                    <Button onClick={() => handleConfirm(code)}>
                        {resource.scriptEditor.apply}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
);

export default IconEditorDialog;
