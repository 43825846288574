import { useMemo } from 'react';

import { getControlClass } from 'forms/form-utils';

import FormActions from 'store/actionStore/form/FormActions';

export default function useMaxWidth(
    formActions: FormActions,
    defaultWidth: false | 'xl' | 'md' | 'sm' | 'xs' | 'lg' = 'md'
) {
    return useMemo(() => {
        if (formActions) {
            return (
                getControlClass(formActions.getFormDescr().classes, 'maxWidth')?.split('-')?.[1] ??
                defaultWidth
            );
        }

        return defaultWidth;
    }, [formActions]);
}
