import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';

import ResModal from './ResModal';
import CustomForm from '../../../forms/CustomForm';

import { ResourceClass } from '../../../store/resourceStore';

interface PreviewControlType {
    ResourceStore: ResourceClass;
}

const PreviewControl: FunctionComponent<PreviewControlType> = observer(({ ResourceStore }) => {
    const { previewResource, setPreviewResource } = ResourceStore;

    return previewResource ? (
        <ResModal
            content={<CustomForm key={previewResource} formGuid={previewResource} />}
            open={!!previewResource}
            onClose={() => setPreviewResource()}
        />
    ) : null;
});

export default PreviewControl;
