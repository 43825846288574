import { FunctionComponent, useMemo, MouseEvent } from 'react';
import { observer } from 'mobx-react';

import TreeLeafItem from '../../TreeItem/LeafItem';

import { TreeItemEditableProps } from '../../../interfaces';

import ControlTree from '../../../../../ControlTree';
import MultipleEditWrapper from './MultipleEditWrapper';

const EditableNode: FunctionComponent<TreeItemEditableProps> = observer(props => {
    const {
        labelText,
        LabelIcon,
        fieldName,
        propContainer,
        descr,
        parentId,
        dataRow,
        multiple,
        index,
        disabled,
        ...other
    } = props;

    const dataset = propContainer.dataStock.getDatasetObj(descr.datasetName);

    const dsOptions = useMemo(
        () => (dataset?.descr.options ? JSON.parse(dataset.descr.options) : {}),
        [dataset]
    );

    const handleClick = () => {
        if (!dsOptions.archive && !disabled) {
            dataset.setCurrentDataChunk(dataset.getChunkByKey(parentId));
            dataset.setActiveRec(index).then((activeRec: { [key: string]: string }) => {
                if (!activeRec) {
                    dataset.cdo
                        ?.append(dataset)
                        .catch((err: { message: string }) => console.error(err.message));
                }
            });
            dataset.edit();
        }
    };

    const addField = (event: MouseEvent) => {
        // Переключаемся на нужный набор данных
        handleClick();
        event.stopPropagation();
        dataset.addRecordToMultipleField(fieldName);
        // Обновляем состояние
        handleClick();
    };

    const removeField = (event: MouseEvent, idx: number) => {
        // Переключаемся на нужный набор данных
        handleClick();
        event.stopPropagation();
        dataset.removeRecordFromMultipleField(fieldName, idx);
        // Обновляем состояние
        handleClick();
    };

    const handleSave = () => {
        if (!dsOptions.archive && !disabled) dataset.post(true);
    };

    return (
        <TreeLeafItem onClick={handleClick} onBlur={handleSave} {...other}>
            {multiple ? (
                dataRow[fieldName]
                    ?.map((record: { key: string; val: string }) => ({
                        key: record.key,
                        customDataRow: { [fieldName]: record?.val }
                    }))
                    .map(
                        (
                            data: {
                                key: string;
                                customDataRow: { [key: string]: unknown };
                            },
                            idx: number,
                            arr: {
                                key: string;
                                customDataRow: { [key: string]: unknown };
                            }[]
                        ) => (
                            <MultipleEditWrapper
                                key={data.key ?? idx}
                                index={idx}
                                descr={descr}
                                dataRow={data.customDataRow}
                                propContainer={propContainer}
                                addField={idx === arr.length - 1 ? addField : undefined}
                                removeField={removeField}
                                disabled={dsOptions.archive || !!disabled}
                            />
                        )
                    ) ?? (
                    <MultipleEditWrapper
                        index={0}
                        descr={descr}
                        dataRow={{ val: '' }}
                        propContainer={propContainer}
                        addField={addField}
                        disabled={dsOptions.archive || !!disabled}
                    />
                )
            ) : (
                <ControlTree formDescr={descr} propContainer={propContainer} dataRow={dataRow} />
            )}
        </TreeLeafItem>
    );
});

export default EditableNode;
