import { FunctionComponent } from 'react';

import { Box } from '@mui/material';

interface SplitterType {
    headerHeight?: number;
    index: number;
    activeIndex?: number | null;
    mouseDown?: (index: number) => unknown;
}

const Splitter: FunctionComponent<SplitterType> = ({
    headerHeight = 0,
    index,
    activeIndex,
    mouseDown
}) => (
    <Box
        sx={{
            height: headerHeight - 24,
            display: 'block',
            position: 'absolute',
            cursor: mouseDown ? 'col-resize' : 'inherit',
            width: '7px',
            right: 0,
            top: 0,
            zIndex: 1,
            borderRight: '1px solid transparent',
            margin: '12px 0',
            borderColor: '#e0e0e0',

            '&:hover': {
                margin: '10px 0',
                height: headerHeight - 20,
                borderRight: '2px solid',
                borderColor: '#ccc'
            },

            '&.active': {
                borderColor: '#517ea5'
            }
        }}
        onMouseDown={() => mouseDown && mouseDown(index)}
        className={activeIndex === index ? 'active' : 'idle'}
    />
);

export default Splitter;
