import { ReportType, SimpleObject } from 'forms/interfaces';
import PropContainer from 'dataObj/PropContainer';
import DataStock from 'dataObj/DataStock';

import { downloadBlobFile, jsonFetch } from 'utils';

import NotificationStore, { BackdropInstance } from 'store/notificationStore';
import ConfigurationStore from '../configurationStore';

type ResultType = {
    filename: string;
    file: any;
    created: Date;
    parObj: SimpleObject | null;
};

export default class Report {
    private dataStock: DataStock;
    private propContainer: PropContainer;

    private requestName: string;
    private view: string;
    private kind: string;
    private backdrop: BackdropInstance | null = null;
    private waitType: 'sync' | 'async';
    private waitTime: number;
    private resultFormat: 'pdf' | 'html';
    private useFilter = false;
    private autoSave = false;
    private keepAllResults = false;

    public results: ResultType[] = [];

    // Сессия асинхронного запроса
    private session?: string;

    constructor(report: ReportType, propContainer: PropContainer, session?: string) {
        this.propContainer = propContainer;
        this.dataStock = propContainer.dataStock;

        this.requestName = report.requestName;
        this.view = report.view;
        this.kind = report.kind;
        this.waitType = report.waitType || 'sync';
        this.waitTime = report.waitTime || 15000;
        this.resultFormat = report.resultFormat || 'html';
        this.autoSave = !!report.autoSave;
        this.keepAllResults = !!report.keepAllResults;

        this.session = session;
    }

    private lockScreen(timeout?: number) {
        if (this.backdrop) {
            this.backdrop.updateBackdrop(timeout);
        } else {
            this.backdrop = new BackdropInstance(timeout);
        }
    }

    private unlockScreen() {
        this.backdrop?.clearBackdrop();
    }

    async run(parObj: SimpleObject | null = {}): Promise<any> {
        const { waitType, waitTime, requestName, view, kind, resultFormat } = this;

        const body = {
            reportParams: {
                wait: waitTime,
                waitType,
                requestName,
                view,
                kind,
                resultFormat
            },
            parObj
        };

        if (this.waitType === 'sync') {
            this.lockScreen(this.waitTime);

            try {
                const file = await jsonFetch('task/report2', 'POST', body, {}, 'blob');

                this.unlockScreen();

                if (file) {
                    const filename = `report${Math.floor(Math.random() * 1000000)}.${
                        this.resultFormat
                    }`;

                    const result = {
                        file,
                        filename,
                        created: new Date(),
                        parObj
                    };

                    if (this.keepAllResults) {
                        this.results.unshift(result);
                    } else this.results = [result];

                    if (this.autoSave) {
                        NotificationStore.showConfirmation(
                            `${
                                ConfigurationStore.content.application.reports.saveReport as string
                            } «${filename}»?`,
                            (confirm: boolean) => {
                                confirm && downloadBlobFile(file, filename);
                            }
                        );
                    } else
                        NotificationStore.enqueueSnackbar({
                            message: ConfigurationStore.content.application.reports.done,
                            options: { variant: 'success' }
                        });

                    return result;
                }
            } catch (err: any) {
                NotificationStore.showAlert(err.message);
            }
        } else if (this.waitType === 'async') {
            try {
                const res: { [key: string]: string } = await jsonFetch(
                    'task/report2',
                    'POST',
                    body,
                    {},
                    'json'
                );

                if (res.session) {
                    this.session = res.session;

                    return res.session;
                }
            } catch (err: any) {
                NotificationStore.showAlert(err.message);
            }
        }

        return false;
    }
}
