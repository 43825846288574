import PropContainer from '../dataObj/PropContainer';

export default function useCtrlProps(propContainer: PropContainer, guid: string) {
    const res: {
        ctrlVisible: boolean;
        ctrlEnabled: boolean;
        ctrlHidden: boolean;
        ctrlOptions?: { [key: string]: any };
    } = {
        ctrlVisible: true,
        ctrlEnabled: true,
        ctrlHidden: false,
        ctrlOptions: undefined
    };

    const el = propContainer.find(guid);
    if (el) {
        res.ctrlVisible = Boolean(propContainer.getElemProperty(el, 'visible') ?? true);
        res.ctrlEnabled = Boolean(propContainer.getElemEnabledProperty(el) ?? true);
        res.ctrlHidden = Boolean(propContainer.getElemProperty(el, 'hidden') ?? true);
        res.ctrlOptions = propContainer.getElemProperty(el, 'options');
    }
    return res;
}
