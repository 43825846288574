import React, {
    forwardRef,
    useImperativeHandle,
    FunctionComponent,
    useEffect,
    useState,
    useMemo,
    SyntheticEvent
} from 'react';
import { observer } from 'mobx-react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import Loading from 'components/utils/Loading/Loading';
import CustomDataset from 'dataObj/customDataset';

import ConfigurationStore from 'store/configurationStore';
import ActionStore from 'store/actionStore';

import { jsonFetch } from 'utils';

import { ControlPropsType, DBLookupType } from 'forms/interfaces';
import useCtrlData from 'hooks/ctrlData';

import LookupGeneratedForm from './LookupGeneratedForm';
import LookupForm from './LookupForm';

interface LookupDialogProps extends ControlPropsType {
    ref: any;
    descr: DBLookupType;
    dataVal: string[] | number[] | null;
    index?: number;
}

const LookupDialog: FunctionComponent<LookupDialogProps> = forwardRef(
    ({ descr, propContainer, dataVal, index }, ref) => {
        const { datasetName, fieldName } = descr;

        const { dataset, dataType } = useCtrlData(propContainer, datasetName, fieldName);

        const { content } = ConfigurationStore;

        const [dsLookup, setDSLookup] = useState<CustomDataset | null>(null);
        const [lookupResource, setLookupResource] = useState<any | null>(null);
        const [formDescr, setFormDescr] = useState<any | null>(null);
        const [generatedFormGuid, setGeneratedFrmGuid] = useState<any | null>(null);
        const [error, setError] = useState<boolean>(false); // Отметка о корректности ссылки на ресурс
        const [open, setOpen] = useState(false);
        const [callback, setCallback] = useState<(state: boolean) => unknown>();

        const formActions = ActionStore.getFormActions(formDescr?.guid || generatedFormGuid);

        useImperativeHandle(
            ref,
            () =>
                ({
                    setOpen: (value: boolean, cb?: () => (state: boolean) => unknown) => {
                        setOpen(value);
                        value && setCallback(cb);
                    }
                } as any)
        );

        const readResource = async (resourceLink: string) =>
            jsonFetch(`resources/${resourceLink}`, 'GET');

        const renderLookupForm = () => <LookupForm descr={formDescr} />;

        const renderGeneratedForm = () => (
            <LookupGeneratedForm
                resource={{
                    ...lookupResource,
                    ...{
                        multiSelect: descr?.flMultiSelect
                    },
                    ...{ splitField: descr.splitField, splitValue: descr.splitValue },
                    ...{ params: descr.params ?? [] }
                }}
                propContainer={propContainer}
                setGeneratedFrmGuid={setGeneratedFrmGuid}
            />
        );

        const getDialogContent = useMemo(() => {
            if (error)
                return <Loading message={content.controls.lookup.incorrectLink} type="error" />;

            if (!lookupResource) {
                return <Loading message={content.controls.data.loading} />;
            }

            return formDescr ? renderLookupForm() : renderGeneratedForm();
        }, [error, formDescr, lookupResource]);

        const handleClose = (state = false) => {
            setOpen(false);
            setError(false);
            callback && callback(state);
        };

        /*
         * Для корректной работы выбора значения в диалоговом окне должно быть корректно настроено
         * соответствие multiselect: если у dbLookup это свойство выставлено в true, то и у датасета
         * формы диалогового окна тоже это свойство должно быть true.
         * Для динамически генерируемой из стат. запроса формы данное свойство наследуется в датасет
         * от дескриптора лукапа
         * Кроме того, имеется прямая зависимость в типом поля, которое редактирует лукап: для мультиселекта
         * тип редактируемого поля должен быть KRN_ARRAY для хранения в виде массива или KRN_MEMO,
         * для хранения значений через запятую
         * */
        const handleOkClick = () => {
            let values = descr.flMultiSelect ? [] : null;

            if (formActions?.getDataStock) {
                const ds = formActions.getDataStock().getMainDataset() as any;

                if (descr.flMultiSelect) {
                    if (dataType === 'KRN_ARRAY') {
                        values = ds ? ds.selectedIDs : [];
                    } else {
                        values = ds ? ds.selectedIDs.join(',') : '';
                    }
                } else {
                    values = ds ? ds.getFieldValue(ds.keyField) : null;
                }

                propContainer.lookup.setValue(
                    descr,
                    values,
                    ds.data.filter((record: { [field: string]: string | number | null }) => {
                        if (descr.flMultiSelect)
                            return ds.selectedIDs.includes(record[ds.keyField]);

                        return values === record[ds.keyField];
                    })
                );
            }

            dataset?.setFieldValue(fieldName, values, index);

            callback && callback(true);
            handleClose(true);
        };

        const preparePopupControl = async (resourceLinkName: string, lookupFormName?: string) => {
            const resourceLink = propContainer.getResourceLink(resourceLinkName);
            const formLink = lookupFormName ? propContainer.getResourceLink(lookupFormName) : null;

            if (resourceLink) {
                try {
                    if (formLink) setFormDescr(await readResource(formLink.resource?.guid));

                    const resource: any = await readResource(resourceLink.resource?.guid);
                    setLookupResource({
                        ...resource,
                        ...{ resourceLinkName }
                    });
                } catch (err: any) {
                    console.error(err.message);
                }
            } else setError(true);
        };

        // Считываем событие открытия/закрытия диалога
        useEffect(() => {
            if (open) {
                if (descr.lookupResourceName) {
                    preparePopupControl(descr.lookupResourceName, descr.lookupFormName).catch(err =>
                        console.error(err.message)
                    );
                } else {
                    setError(true);
                }
            }
            if (!open) {
                setOpen(false);
                setError(false);
            }
        }, [open, descr.lookupResourceName]);

        useEffect(() => {
            formActions?.getDataStock &&
                setDSLookup(formActions.getDataStock().getMainDataset() as any);
        }, [formActions]);

        useEffect(() => {
            if (dsLookup && dsLookup.hasData() && dataVal?.length) {
                if (descr.flMultiSelect) {
                    const recordIdx = dsLookup.data
                        .map((rec: any) => rec[dsLookup.keyField])
                        .filter((rec: any) => dataVal?.indexOf(rec as never) !== -1);

                    if (recordIdx?.length) {
                        recordIdx.forEach((idx: any) => dsLookup.setSelectedField(idx, true));

                        dsLookup.setActiveRec(null).catch(err => console.error(err.message));
                    }
                } else {
                    const recordIdx = dsLookup.data
                        .map((rec: any) => rec[dsLookup.keyField])
                        .indexOf(dataVal[0]);

                    if (recordIdx !== -1)
                        dsLookup.setActiveRec(recordIdx).catch(err => console.error(err.message));
                }
            }
        }, [dsLookup?.hasData(), dataVal, dsLookup]);

        return (
            <Dialog open={open} onClose={() => handleClose()} maxWidth="lg" fullWidth>
                <DialogTitle>{descr.label}</DialogTitle>
                <DialogContent
                    style={{ height: '100vh' }}
                    // Если лукап без мультиселекта, то включаем double-click
                    onDoubleClick={(event: SyntheticEvent) => {
                        const target = event.target as HTMLElement;

                        if (
                            !descr.flMultiSelect &&
                            target.className.search('MuiTableCell-root') !== -1
                        ) {
                            handleOkClick();
                        }
                    }}
                >
                    {open && getDialogContent}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose()}>{content.controls.buttons.cancel}</Button>
                    <Button onClick={handleOkClick}>{content.controls.buttons.ok}</Button>
                </DialogActions>
            </Dialog>
        );
    }
);

export default observer(LookupDialog);
