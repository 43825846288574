import React, { FunctionComponent, useCallback, useState } from 'react';
import { observer } from 'mobx-react';

import Box from '@mui/material/Box';
import { TreeView }  from '@mui/x-tree-view'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import PropContainer from 'dataObj/PropContainer';

import DatasetTreeNode from './DatasetTreeNode';

interface PropsType {
    descr: any;
    propContainer: PropContainer;
}

const DatasetTreeControl: FunctionComponent<PropsType> = observer(({ descr, propContainer }) => {
    const { nodes, afName } = descr;
    const { dataStock } = propContainer;
    const [selected, setSelected] = useState<string>('');

    const setActiveFilter = useCallback(
        value => dataStock.setActiveFilter(afName, value),
        [afName]
    );

    const handleNodeSelect = (nodeId: string) => {
        setSelected(nodeId);
    };

    return (
        <Box>
            {nodes?.length
                ? nodes.map((node: any) => (
                      <TreeView
                          key={node.guid}
                          defaultCollapseIcon={<ExpandMoreIcon />}
                          defaultExpandIcon={<ChevronRightIcon />}
                          defaultEndIcon={<ArrowRightIcon />}
                          selected={selected}
                      >
                          <DatasetTreeNode
                              node={node}
                              dataStock={dataStock}
                              setActiveFilter={setActiveFilter}
                              handleNodeSelect={handleNodeSelect}
                          />
                      </TreeView>
                  ))
                : null}
        </Box>
    );
});

export default DatasetTreeControl;
