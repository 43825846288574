export default {
    guid: '9d033082-82ce-4e55-b5f0-f227bbea5763',
    name: 'FRM_TEXT_FIELDS_TEST',
    type: 'wuiForm',
    datasets: [
        {
            guid: 'ad41364e-d7a4-460f-8dee-1962abf71fb9',
            name: 'testDS',
            type: 'clientDataset',
            keyField: '',
            fields: [
                {
                    guid: 'a6ca6ca0-6ee5-4628-ac21-760420381f49',
                    name: 'integerTest',
                    type: 'datasetField',
                    caption: 'Integer',
                    dataType: 'KRN_INTEGER',
                    dataLen: 0,
                    scale: 0,
                    isKey: false,
                    required: false,
                    readOnly: false,
                    multiple: false,
                    hashable: false,
                    hidden: false,
                    archive: false,
                    protected: false,
                    masterField: '',
                    defaultFormula: '',
                    valueFormula: '',
                    enabledFormula: '',
                    lookupData: '',
                    lookupSource: '',
                    typeId: 0,
                    glossField: ''
                },
                {
                    guid: '18c2710c-9562-410f-be44-ae8c1844f3f9',
                    name: 'numericTest',
                    type: 'datasetField',
                    caption: 'Numeric',
                    dataType: 'KRN_NUMERIC',
                    dataLen: 0,
                    scale: 0,
                    isKey: false,
                    required: false,
                    readOnly: false,
                    multiple: false,
                    hashable: false,
                    hidden: false,
                    archive: false,
                    protected: false,
                    masterField: '',
                    defaultFormula: '',
                    valueFormula: '',
                    enabledFormula: '',
                    lookupData: '',
                    lookupSource: '',
                    typeId: 0,
                    glossField: ''
                },
                {
                    guid: '76538ee7-b75a-4952-bcf4-62c9415299a3',
                    name: 'numericScaleTest',
                    type: 'datasetField',
                    caption: 'NumericScale',
                    dataType: 'KRN_NUMERIC',
                    dataLen: 0,
                    scale: 3,
                    isKey: false,
                    required: false,
                    readOnly: false,
                    multiple: false,
                    hashable: false,
                    hidden: false,
                    archive: false,
                    protected: false,
                    masterField: '',
                    defaultFormula: '',
                    valueFormula: '',
                    enabledFormula: '',
                    lookupData: '',
                    lookupSource: '',
                    typeId: 0,
                    glossField: ''
                },
                {
                    guid: '7195e27f-664d-4f8c-8795-3c33e6c511ad',
                    name: 'numericLengthTest',
                    type: 'datasetField',
                    caption: 'NumericLength',
                    dataType: 'KRN_NUMERIC',
                    dataLen: 10,
                    scale: 0,
                    isKey: false,
                    required: false,
                    readOnly: false,
                    multiple: false,
                    hashable: false,
                    hidden: false,
                    archive: false,
                    protected: false,
                    masterField: '',
                    defaultFormula: '',
                    valueFormula: '',
                    enabledFormula: '',
                    lookupData: '',
                    lookupSource: '',
                    typeId: 0,
                    glossField: ''
                },
                {
                    guid: '7ef7653e-8336-4bd6-a951-a3cdd805b16a',
                    name: 'numericScaleLengthTest',
                    type: 'datasetField',
                    caption: 'NumericScaleLength',
                    dataType: 'KRN_NUMERIC',
                    dataLen: 10,
                    scale: 4,
                    isKey: false,
                    required: false,
                    readOnly: false,
                    multiple: false,
                    hashable: false,
                    hidden: false,
                    archive: false,
                    protected: false,
                    masterField: '',
                    defaultFormula: '',
                    valueFormula: '',
                    enabledFormula: '',
                    lookupData: '',
                    lookupSource: '',
                    typeId: 0,
                    glossField: ''
                },
                {
                    guid: 'b1b755b1-415c-4593-8c31-864b388312c3',
                    name: 'stringTest',
                    type: 'datasetField',
                    caption: 'String',
                    dataType: 'KRN_STRING',
                    dataLen: 0,
                    scale: 0,
                    isKey: false,
                    required: false,
                    readOnly: false,
                    multiple: false,
                    hashable: false,
                    hidden: false,
                    archive: false,
                    protected: false,
                    masterField: '',
                    defaultFormula: '',
                    valueFormula: '',
                    enabledFormula: '',
                    lookupData: '',
                    lookupSource: '',
                    typeId: 0,
                    glossField: ''
                },
                {
                    guid: 'f69fb1b6-483d-4490-b101-1e35d31b1c4d',
                    name: 'maskTest',
                    type: 'datasetField',
                    caption: 'Mask',
                    dataType: 'KRN_STRING',
                    dataLen: 0,
                    scale: 0,
                    isKey: false,
                    required: false,
                    readOnly: false,
                    multiple: false,
                    hashable: false,
                    hidden: false,
                    archive: false,
                    protected: false,
                    masterField: '',
                    defaultFormula: '',
                    valueFormula: '',
                    enabledFormula: '',
                    lookupData: '',
                    lookupSource: '',
                    typeId: 0,
                    glossField: ''
                },
                {
                    guid: '4159697a-2154-454a-ad0d-36539377c160',
                    name: 'internationalTest',
                    type: 'datasetField',
                    caption: 'International',
                    dataType: 'KRN_STRING_INTERNATIONAL',
                    dataLen: 0,
                    scale: 0,
                    isKey: false,
                    required: false,
                    readOnly: false,
                    multiple: false,
                    hashable: false,
                    hidden: false,
                    archive: false,
                    protected: false,
                    masterField: '',
                    defaultFormula: '',
                    valueFormula: '',
                    enabledFormula: '',
                    lookupData: '',
                    lookupSource: '',
                    typeId: 0,
                    glossField: ''
                }
            ],
            activeFields: [],
            appendFormula: '',
            caption: '',
            editFormula: '',
            editType: '',
            deleteFormula: '',
            datasets: [],
            typeId: 0,
            options: ''
        }
    ],
    classes: '',
    styles: 'height:100vh;',
    gridWidth: '',
    gridOrder: '',
    enabledFormula: '',
    visibleFormula: '',
    hiddenFormula: '',
    tags: '',
    version: '3',
    sourceId: '',
    createDate: '2023-04-25T05:44:28.600Z',
    updateDate: '2023-05-10T03:43:28.415Z',
    versionId: 0,
    resourceId: 0,
    dataObjects: [],
    ownerGuid: '',
    extends: {},
    caption: '',
    isEditor: false,
    isSubForm: false,
    withGloss: false,
    lang: '',
    translation: '',
    formulaLang: '',
    editFormula: '',
    validateFormula: '',
    controls: [
        {
            guid: '4cb8a8d8-6614-4510-8e1b-e28bb9f6938e',
            name: 'pMain',
            type: 'panel',
            classes: 'paper',
            styles: '',
            gridWidth: '',
            gridOrder: '',
            enabledFormula: '',
            visibleFormula: '',
            hiddenFormula: '',
            caption: '',
            layout: 'vertical',
            controls: [
                {
                    guid: '5c0bc3d2-15ec-4abe-91cf-131432438dc2',
                    name: 'pTextFields',
                    type: 'panel',
                    classes: 'groupBox',
                    styles: '',
                    gridWidth: 'content',
                    gridOrder: '',
                    enabledFormula: '',
                    visibleFormula: '',
                    hiddenFormula: '',
                    caption: 'Text fields',
                    layout: '',
                    controls: [
                        {
                            guid: '2287354a-5af1-4c74-ae30-f4ceb5126fad',
                            name: 'integerTest',
                            type: 'dbEdit',
                            classes: '',
                            styles: '',
                            gridWidth: '',
                            gridOrder: '',
                            enabledFormula: '',
                            visibleFormula: '',
                            hiddenFormula: '',
                            datasetName: 'testDS',
                            required: false,
                            label: 'Integer',
                            fieldName: 'integerTest'
                        },
                        {
                            guid: '15a08eef-3975-4afc-babc-8b279120304e',
                            name: 'numericTest',
                            type: 'dbEdit',
                            classes: '',
                            styles: '',
                            gridWidth: '',
                            gridOrder: '',
                            enabledFormula: '',
                            visibleFormula: '',
                            hiddenFormula: '',
                            datasetName: 'testDS',
                            required: false,
                            label: 'Numeric',
                            fieldName: 'numericTest'
                        },
                        {
                            guid: '6e9a7a00-913e-47e9-ae5e-ea1687faea16',
                            name: 'numericScaleTest',
                            type: 'dbEdit',
                            classes: '',
                            styles: '',
                            gridWidth: '',
                            gridOrder: '',
                            enabledFormula: '',
                            visibleFormula: '',
                            hiddenFormula: '',
                            datasetName: 'testDS',
                            required: false,
                            label: 'NumericScale',
                            fieldName: 'numericScaleTest'
                        },
                        {
                            guid: '111980f9-397e-4036-ab49-2b3d04cc976b',
                            name: 'numericLengthTest',
                            type: 'dbEdit',
                            classes: '',
                            styles: '',
                            gridWidth: '',
                            gridOrder: '',
                            enabledFormula: '',
                            visibleFormula: '',
                            hiddenFormula: '',
                            datasetName: 'testDS',
                            required: false,
                            label: 'NumericLength',
                            fieldName: 'numericLengthTest'
                        },
                        {
                            guid: '56c55d14-2f85-4401-9965-5a686e05249e',
                            name: 'numericScaleLengthTest',
                            type: 'dbEdit',
                            classes: '',
                            styles: '',
                            gridWidth: '',
                            gridOrder: '',
                            enabledFormula: '',
                            visibleFormula: '',
                            hiddenFormula: '',
                            datasetName: 'testDS',
                            required: false,
                            label: 'NumericScaleLength',
                            fieldName: 'numericScaleLengthTest'
                        },
                        {
                            guid: '05446eff-ab28-4853-8fdd-3b5250a831bb',
                            name: 'stringTest',
                            type: 'dbEdit',
                            classes: '',
                            styles: '',
                            gridWidth: '',
                            gridOrder: '',
                            enabledFormula: '',
                            visibleFormula: '',
                            hiddenFormula: '',
                            datasetName: 'testDS',
                            required: false,
                            label: 'String',
                            fieldName: 'stringTest'
                        },
                        {
                            guid: 'ad45b4c7-effe-42c7-ad7d-cdeec013a5b4',
                            name: 'maskTest',
                            type: 'dbTextEdit',
                            classes: '',
                            styles: '',
                            gridWidth: '',
                            gridOrder: '',
                            enabledFormula: '',
                            visibleFormula: '',
                            hiddenFormula: '',
                            datasetName: 'testDS',
                            required: false,
                            label: 'Mask',
                            fieldName: 'maskTest',
                            mask: '000-000-000 00',
                            password: false
                        },
                        {
                            guid: 'c995aae8-2344-4a3a-b3bf-26658c260ec6',
                            name: 'internationalTest',
                            type: 'dbEdit',
                            classes: '',
                            styles: '',
                            gridWidth: '',
                            gridOrder: '',
                            enabledFormula: '',
                            visibleFormula: '',
                            hiddenFormula: '',
                            datasetName: 'testDS',
                            required: false,
                            label: 'International',
                            fieldName: 'internationalTest'
                        }
                    ],
                    events: []
                }
            ],
            events: []
        }
    ],
    extraData: '{\n\t"formulaGroups": {}\n}',
    serverData: '{\n\t"serverFormulas": {}\n}',
    scripts: [],
    events: [],
    resourceLinks: [],
    subForms: [],
    editDatasetName: '',
    extParams: [],
    serverScripts: []
};
