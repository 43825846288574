import React, { SyntheticEvent, FunctionComponent, useCallback } from 'react';
import { observer } from 'mobx-react';

import { TableCell, TableRow, Checkbox } from '@mui/material';

import { getFieldValueByNamePrefix, convertTColorToRGB, getContrastColor } from 'utils';

import DataStore from 'store/dataStore';
import ConfigurationStore from 'store/configurationStore';

import styles from 'forms/form.module.scss';

import PropContainer from 'dataObj/PropContainer';

import { DataLineType, HeaderColumnType } from '../interface';

import Cell from './Cell';

const AF_LAST_SELECTED_GRID_LINE_KEY = 'system.lastSelectedGridLineKey';

interface PropsType extends DataLineType {
    isColored: boolean;
    propContainer: PropContainer;
}

const DataLine: FunctionComponent<PropsType> = observer(
    ({ dataset, gridDescr, columns, dataRow, enabled, controlGrid, isColored, propContainer }) => {
        const getLineKeys = () => dataRow[dataset.keyField];

        const keys = getLineKeys();
        const checked = dataset.getIsSelected(keys);

        function lineSelectHandler(event: SyntheticEvent<HTMLTableRowElement>) {
            const lastSelectedLineKey: string = DataStore.AF.getAF(AF_LAST_SELECTED_GRID_LINE_KEY);
            const lineKey = `${gridDescr.name}_${gridDescr.guid}_${
                event.currentTarget.dataset?.id0 ?? ''
            }`;

            const forceLoadDeps = lastSelectedLineKey !== lineKey;

            DataStore.AF.setAF(AF_LAST_SELECTED_GRID_LINE_KEY, lineKey, { noDistribute: true });

            dataset.findById(event.currentTarget.dataset.id0, forceLoadDeps);
        }

        const isSelected =
            dataset.activeRec && dataRow[dataset.keyField] === dataset.activeRec[dataset.keyField];

        const renderCellText = useCallback(
            (fieldName: string, raw?: boolean) => dataset.displayValue(dataRow, fieldName, raw),
            [dataset, dataRow]
        );

        const getColor = (color: number | string) => {
            const cachedColor = ConfigurationStore.getColor(color);
            if (cachedColor) return cachedColor;

            const bgColor = convertTColorToRGB(color);
            const textColor = getContrastColor(bgColor);
            ConfigurationStore.setColor(color, bgColor, textColor);
            return { color, bgColor, textColor };
        };

        // Получаем цвет ячейки для закрашивания
        const getCellColor = (fieldName: string) => {
            if (!isColored) return;
            const color = dataRow[`CLR_CELLFOR_${fieldName}`];
            if (color) return getColor(color);
        };

        // Закрашиваем строку (вместе с колонкой чекбоксов при мультиселекте)
        const getRowColor = () => {
            if (!isColored) return;
            const color = getFieldValueByNamePrefix(dataRow, 'CLR_ROW_');
            if (color) return getColor(color);
        };

        // Убираем колонки со служебными данными о цвете ячеек и строк
        const visibleColumns = columns.filter(
            (col: any) =>
                // !col.fieldName.startsWith('CLR_CELL_') &&
                !col.fieldName.startsWith('CLR_CELLFOR_') && !col.fieldName.startsWith('CLR_ROW_')
        );

        return (
            <TableRow
                key={JSON.stringify(keys)}
                className={
                    enabled
                        ? [styles.tableRow, isSelected ? styles.selectedRow : null].join(' ')
                        : styles.tableRow
                }
                onClick={enabled ? lineSelectHandler : undefined}
                data-id0={dataset.getValueStr(dataRow, dataset.keyField)}
                sx={{ backgroundColor: getRowColor()?.bgColor }}
            >
                {dataset.multiSelect ? (
                    <TableCell
                        padding="checkbox"
                        style={{ borderBottom: controlGrid ? '' : 'none' }}
                    >
                        <Checkbox
                            color="primary"
                            checked={checked}
                            onChange={(event, isChecked) => {
                                dataset.setSelectedField(keys, isChecked);
                            }}
                        />
                    </TableCell>
                ) : null}
                {controlGrid
                    ? controlGrid(dataRow)
                    : visibleColumns.map((col: HeaderColumnType, index) => (
                          <Cell
                              key={index}
                              col={col}
                              getCellColor={getCellColor}
                              renderCellText={renderCellText}
                              isColored={isColored}
                              propContainer={propContainer}
                          />
                      ))}
            </TableRow>
        );
    }
);

export default DataLine;
