import React, { CSSProperties, forwardRef, useState } from 'react';

import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import ConfigurationStore from 'store/configurationStore';

const filter = createFilterOptions<any>();

interface AutocompleteProps {
    id?: string;
    value: string | undefined;
    store: Array<unknown>;
    onChange: any;
    nameField: string;
    valueField: string;
    name?: string;
    label: string;
    disabled?: boolean;
    style?: CSSProperties | undefined;
    clearable?: boolean;
    setNewValue: any;
    editable?: boolean;
    required?: boolean;
    freeSolo?: boolean;
}

const EditableComboBox = forwardRef<HTMLLIElement | null, AutocompleteProps>((props, ref) => {
    const {
        id,
        disabled,
        clearable,
        nameField,
        valueField,
        label,
        name,
        style,
        setNewValue,
        onChange,
        store,
        value,
        editable = false,
        required = false,
        freeSolo = true
    } = props;

    const { content } = ConfigurationStore;

    const [curValue, setCurValue] = useState<string | null>(null);

    const renderAutocompleteValue = () =>
        store.filter(
            (option: any) => option[valueField] === value || option[nameField] === value
        )[0];

    const renderValue = renderAutocompleteValue() as any;

    const handleChange: any = (event: any, option: any, reason: string) => {
        if (event.target?.value || event.target?.value === '')
            return setCurValue(event.target?.value);

        setCurValue(null);

        onChange({
            target: {
                value: reason === 'clear' ? '' : option[valueField],
                name
            }
        });
    };

    const handleBlur = () => {
        if (curValue || curValue === '') {
            const isExistingValue = store.some((el: any) => el[valueField] === curValue);
            // добавляем новые значения в массив store только в случае свободного
            // добавления новых элементов и при условии, что такой элемент не существует
            if (freeSolo && !isExistingValue)
                setNewValue({
                    [nameField]: curValue,
                    [valueField]: curValue
                });

            onChange({
                target: {
                    value: curValue,
                    name
                }
            });
        }
    };

    return (
        <Autocomplete
            id={id}
            value={renderValue ? renderValue[nameField] ?? renderValue : ''}
            disabled={disabled}
            disableClearable={!clearable}
            getOptionLabel={(option: any) => option[nameField] ?? option}
            options={store}
            onChange={handleChange}
            freeSolo={editable && freeSolo}
            style={style}
            sx={{
                '& .MuiInputLabel-root': {
                    transform: 'translate(14px ,8px) scale(1)'
                },
                '& .Mui-focused.MuiInputLabel-root': {
                    transform: 'translate(14px ,-9px) scale(.75)'
                },
                '& .MuiInputLabel-root.MuiFormLabel-filled': {
                    transform: 'translate(14px ,-9px) scale(.75)'
                }
            }}
            renderInput={params => (
                <TextField
                    {...params}
                    inputRef={ref}
                    error={required && !renderValue}
                    label={label || undefined}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    sx={theme => ({
                        '& .Mui-disabled': {
                            backgroundColor: theme.palette.mode === 'light' ? '#f1f1f1' : '#1A2027',
                            '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.6)'
                        }
                    })}
                />
            )}
            filterOptions={(options, params) => filter(options, params)}
            noOptionsText={content.components.inputs.autocomplete.noOptions}
        />
    );
});

export default EditableComboBox;
