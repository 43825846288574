import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';

import { Dialog, DialogActions, DialogTitle, DialogContent, Button } from '@mui/material';
import { DialogProps } from '@mui/material/Dialog';

import ConfigurationStore from 'store/configurationStore';

export interface SimpleDialogPropsType extends Omit<DialogProps, 'open'> {
    open?: boolean;
    editorName?: string;
    handleClose: (state: boolean) => void;
    style?: { [key: string]: string | number };
    customActions?: () => unknown;
    disabled?: boolean;
}

const SimpleDialog: FunctionComponent<SimpleDialogPropsType> = observer(
    ({
        editorName,
        handleClose,
        style,
        maxWidth,
        children,
        customActions,
        disabled,
        ...otherProps
    }) => {
        const { content } = ConfigurationStore;

        return (
            <Dialog
                open
                fullWidth
                style={style}
                maxWidth={maxWidth ?? 'md'}
                onClose={() => handleClose(false)}
                {...otherProps}
            >
                <DialogTitle id="rowEditor">{editorName}</DialogTitle>
                <DialogContent>{children}</DialogContent>
                <DialogActions>
                    {customActions ? customActions() : null}
                    <Button
                        key="cancel"
                        autoFocus
                        onClick={() => handleClose(false)}
                        color="primary"
                    >
                        {content.controls.buttons.cancel}
                    </Button>
                    <Button
                        key="ok"
                        onClick={() => handleClose(true)}
                        color="primary"
                        disabled={!!disabled}
                    >
                        {content.controls.buttons.ok}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
);

export default SimpleDialog;
