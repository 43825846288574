import { FunctionComponent, useMemo, useState } from 'react';
import { observer } from 'mobx-react';

import CustomDataset from 'dataObj/customDataset';

import Tree from '../../index';

import RootNode from './RootNode';
import RootDetailNode from './RootDetailNode';
import EditableNodeContainer from '../Editable/EditableNodeContainer';

import { TreeItemRootContainer } from '../../../interfaces';
import { getDetailDS } from '../../../utils';

const RootNodeContainer: FunctionComponent<TreeItemRootContainer> = observer(props => {
    const { index, dataset, parentId, currentChunk, disabled } = props;

    const [appendLoading, setAppendLoading] = useState(false);

    if (dataset.editType === 'detail')
        return (
            <RootDetailNode
                key={`${dataset.guid}-${parentId}`}
                dataset={dataset}
                currentChunk={currentChunk}
                parentId={parentId}
                appendLoading={appendLoading}
                setAppendLoading={setAppendLoading}
                disabled={disabled}
            />
        );

    const detailDSList: CustomDataset[] = getDetailDS(dataset);

    const dsOptions = useMemo(
        () => (dataset.descr.options ? JSON.parse(dataset.descr.options) : {}),
        [dataset]
    );

    return (
        <RootNode
            key={`${dataset.guid}-${parentId}`}
            nodeId={`${dataset.guid}-${parentId}`}
            index={index}
            labelText={dataset.descr.caption || dataset.name}
            dataset={dataset}
            parentId={parentId}
            appendLoading={appendLoading}
            setAppendLoading={setAppendLoading}
            disabled={dsOptions.archive || disabled}
        >
            {currentChunk.data?.length ? (
                <EditableNodeContainer
                    dataset={dataset}
                    index={0}
                    parentId={parentId}
                    dataRow={currentChunk.data[0]}
                    disabled={dsOptions.archive || disabled}
                />
            ) : (
                // Пустой элемент для создания нового
                <EditableNodeContainer
                    dataset={dataset}
                    index={0}
                    parentId={parentId}
                    dataRow={{}}
                    disabled={dsOptions.archive || disabled}
                />
            )}
            {detailDSList.map(detailDS => (
                <Tree
                    key={detailDS.guid}
                    dataset={detailDS}
                    parentId={
                        detailDS.currentDataChunk?.key ||
                        detailDS.masterDS.activeRec?.[detailDS.masterDS.keyField]
                    }
                    disabled={dsOptions.archive || disabled}
                />
            ))}
        </RootNode>
    );
});

export default RootNodeContainer;
