import React, { FunctionComponent, useState } from 'react';
import { observer } from 'mobx-react';

import { Box, Button } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import useCtrlProps from '../../../../hooks/ctrlProps';

import NestedMenu from '../NestedMenu';

import { ControlPropsType, MenuType } from '../../../interfaces';
import SVGIcon from '../../SVGIcon';

interface DropDownMenuProps extends ControlPropsType {
    descr: MenuType;
}

const DropDownButton: FunctionComponent<DropDownMenuProps> = observer(
    ({ descr, propContainer }) => {
        const { guid, caption, items } = descr;
        const { ctrlVisible, ctrlEnabled } = useCtrlProps(propContainer, guid);

        const childrenEnabled = propContainer.getIsChildrenEnabled(propContainer.find(guid));

        const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

        const handleClick = (event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        return (
            <>
                <Box sx={{ p: 2, visibility: ctrlVisible ? 'inherit' : ('hidden' as any) }}>
                    <Button
                        key={`dropdown-menu-button-${guid}`}
                        aria-controls={anchorEl ? 'dropdown-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={anchorEl ? 'true' : undefined}
                        variant="contained"
                        fullWidth
                        onClick={handleClick}
                        disabled={!ctrlEnabled || !childrenEnabled}
                        startIcon={
                            descr.icon ? (
                                <SVGIcon
                                    descr={descr}
                                    propContainer={propContainer}
                                    fontSize="small"
                                />
                            ) : null
                        }
                        endIcon={<KeyboardArrowDownIcon />}
                    >
                        {caption}
                    </Button>
                </Box>
                <NestedMenu
                    key={`dropdown-menu-button-nested-${guid}`}
                    anchorEl={anchorEl}
                    items={items}
                    propContainer={propContainer}
                    handleClose={handleClose}
                    minWidth={anchorEl?.offsetWidth ?? 'inherit'}
                />
            </>
        );
    }
);

export default DropDownButton;
