import React, { CSSProperties, forwardRef, useEffect, useState } from 'react';

import { InputLabel, FormControl, MenuItem, ListItemText, Checkbox, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';

type ObjectType = { [key: string]: any };

interface PropsType {
    label?: string;
    name?: string;
    store: ObjectType[];
    nameField: string;
    valueField: string;
    required?: boolean;
    disabled?: boolean;
    defaultValue?: string[] | string;
    style?: CSSProperties | undefined;
    onConfirm?: (event: { target: { name?: string; value?: string[] } }) => void;
}

const DBListbox = forwardRef<HTMLLIElement | null, PropsType>(
    (
        {
            label,
            name,
            store,
            nameField = 'key',
            valueField = 'val',
            required,
            disabled,
            defaultValue,
            style,
            onConfirm
        },
        ref
    ) => {
        const prepareValue = (value?: string[] | string) =>
            Array.isArray(value) ? value.filter(el => el !== '') : [];

        const [selected, setSelected] = useState<string[]>(prepareValue(defaultValue));

        useEffect(() => {
            setSelected(prepareValue(defaultValue));
        }, [defaultValue]);

        const handleChange = (event: SelectChangeEvent<typeof selected>) => {
            const {
                target: { value }
            } = event;

            setSelected(typeof value === 'string' ? value.split(',') : value);
        };

        const handleConfirm = () => {
            if (onConfirm)
                //  && selected.length
                onConfirm({
                    target: {
                        name,
                        value: selected
                    }
                });
        };

        const renderMenu = (menuStore: Array<ObjectType>) =>
            menuStore.map((it, index) => (
                <MenuItem key={`LBMenuItem-${index}`} value={it[valueField]}>
                    <Checkbox checked={selected.indexOf(it[valueField]) !== -1} />
                    <ListItemText primary={it[nameField]} />
                </MenuItem>
            ));

        return (
            <FormControl fullWidth variant="outlined" size="small">
                {label ? <InputLabel>{label}</InputLabel> : null}
                <Select
                    ref={ref}
                    value={selected}
                    renderValue={selectedArr =>
                        selectedArr
                            .map(val => store?.find(el => el[valueField] === val)?.[nameField])
                            .join(', ')
                    }
                    multiple
                    label={label || undefined}
                    onChange={handleChange}
                    onClose={handleConfirm}
                    disabled={disabled}
                    error={required && !selected.length}
                    style={style}
                    sx={theme => ({
                        '& .Mui-disabled': {
                            backgroundColor: theme.palette.mode === 'light' ? '#f1f1f1' : '#1A2027',
                            '& .MuiInputBase-input': {
                                '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.6)'
                            }
                        }
                    })}
                >
                    {renderMenu(store)}
                </Select>
            </FormControl>
        );
    }
);

export default DBListbox;
