import React from 'react';
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from 'recharts';
import Konva from 'konva';
import { Grid, Typography } from '@mui/material';

import ConfigurationStore from 'store/configurationStore';

interface PropsType {
    data: any | undefined;
    columns?: any | undefined;
    settings: any;
}

const AreaChartComponent = (props: PropsType) => {
    const { content } = ConfigurationStore;

    const { data, columns, settings } = props;
    const { chartElements, xAxis, chartHeight, chartWidth } = settings;

    const renderLegend = (value: string, entry: any) => {
        const { color, dataKey } = entry;
        const legend = columns.find((item: any) => item.name === dataKey);

        return <span style={{ color }}>{legend ? legend.caption : value}</span>;
    };

    return (
        <Grid container sx={{ margin: 'auto' }}>
            {chartElements?.length > 0 && xAxis ? (
                <ResponsiveContainer width="100%" height={chartHeight}>
                    <AreaChart
                        width={chartWidth}
                        height={chartHeight}
                        data={data}
                        margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <YAxis />
                        <XAxis dataKey={xAxis.fieldName} />
                        <Tooltip />
                        {chartElements.map((el: any) => (
                            <Area
                                dataKey={el.fieldName}
                                type="monotone"
                                stackId="1"
                                stroke={el.color ? el.color : Konva.Util.getRandomColor()}
                                fill={el.color ? el.color : Konva.Util.getRandomColor()}
                            />
                        ))}
                        <Legend formatter={renderLegend} />
                    </AreaChart>
                </ResponsiveContainer>
            ) : (
                <Grid item sx={{ margin: 'auto' }}>
                    <Typography>{content.controls.data.noDataset}</Typography>
                </Grid>
            )}
        </Grid>
    );
};

export default AreaChartComponent;
