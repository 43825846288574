import React, { FunctionComponent, useMemo } from 'react';
import { observer } from 'mobx-react';

import { Add, AddCircle, Delete, Edit, Visibility } from '@mui/icons-material';

import Toolbar from 'forms/controls/Toolbar/Toolbar';
import { CustomToolbarButtonType } from 'forms/controls/Toolbar/interface';
import { ToolbarButtonType } from 'components/resources/ResEditor/gridSettings';

import ConfigurationStore from 'store/configurationStore';
import NotificationStore from 'store/notificationStore';
import ActionStore from 'store/actionStore';

import { getResourceGuid } from 'utils';

import FastFilters from './FastFilters';
import UserFilter from './UserFilter';

import { GridToolbarType } from '../interface';

const GridToolbar: FunctionComponent<GridToolbarType> = observer(
    ({ gridDescr, dataset, fastFilters, gridWidth, propContainer, owner }) => {
        const breakpointWidth = 600;

        const configButtons = useMemo(
            () => (owner?.getOptions()?.toolbar?.length ? owner.getOptions().toolbar : []),
            [owner]
        );

        const formActions = ActionStore.getFormActions(propContainer?.getMainForm()?.descr?.guid);

        const { content } = ConfigurationStore;
        const { showConfirmation } = NotificationStore;

        const datasetActions = ActionStore.getDatasetActions(dataset.guid);

        const filters =
            dataset && fastFilters?.length ? (
                <FastFilters filters={fastFilters} gridDescr={gridDescr} dataset={dataset} />
            ) : null;

        const userFilter = dataset?.descr?.flUserFilter ? (
            <UserFilter dataset={dataset} gridWidth={gridWidth} breakpointWidth={breakpointWidth} />
        ) : null;

        const defaultButtons: CustomToolbarButtonType[] | undefined = useMemo(() => {
            if (datasetActions && dataset.checkMasterDSSaved())
                return [
                    {
                        type: 'add',
                        variant: 'contained',
                        color: 'primary',
                        caption: content.controls.buttons.add,
                        icon: <Add />,
                        iconBtn: <AddCircle />,
                        iconPosition: 'end',
                        onClick: () => datasetActions.addRecord(propContainer)
                    },
                    {
                        type: 'view',
                        variant: 'outlined',
                        caption: content.controls.buttons.view,
                        disabled: !dataset.activeRec,
                        icon: <Visibility />,
                        onClick: () => datasetActions.viewRecord(propContainer)
                    },
                    {
                        type: 'edit',
                        variant: 'outlined',
                        caption: content.controls.buttons.change,
                        disabled: !dataset.activeRec,
                        icon: <Edit />,
                        onClick: () => datasetActions.editRecord(propContainer)
                    },
                    {
                        type: 'delete',
                        variant: 'outlined',
                        color: 'warning',
                        caption: content.controls.buttons.delete,
                        disabled: !dataset.activeRec,
                        icon: <Delete />,
                        onClick: () => {
                            showConfirmation(
                                content.controls.grid.delConfirmText,
                                datasetActions.deleteRecord(propContainer)
                            );
                        }
                    }
                ];
        }, [
            datasetActions,
            dataset.activeRec,
            dataset.masterDS?.activeRec,
            dataset.masterDS?.cloneRec
        ]);

        return (
            <Toolbar
                caption={gridDescr.caption}
                dataset={dataset}
                customButtons={
                    !owner?.getOptions()?.configuration?.hideToolbar &&
                    formActions?.editMode !== 'view' &&
                    !!getResourceGuid(dataset.descr.editForm)
                        ? defaultButtons?.filter(
                              button =>
                                  configButtons.find(
                                      (conf: ToolbarButtonType) => conf.name === button.type
                                  )?.visible ?? true
                          )
                        : undefined
                }
                buttons={
                    !owner?.getOptions()?.configuration?.hideToolbar &&
                    formActions?.editMode !== 'view'
                        ? gridDescr.buttons?.filter(
                              button =>
                                  configButtons.find(
                                      (conf: ToolbarButtonType) => conf.name === button.name
                                  )?.visible ?? true
                          )
                        : undefined
                }
                propContainer={propContainer}
                containerWidth={gridWidth}
            >
                {filters}
                {userFilter}
            </Toolbar>
        );
    }
);

export default GridToolbar;
