import React, { useRef, useState, useEffect, FunctionComponent, useCallback } from 'react';
import { observer } from 'mobx-react';

import { Box } from '@mui/material';

import useDBEdit from 'hooks/dbEdit';

import { getLocalizedString } from 'utils';

import { ControlPropsType, DBLookupType } from 'forms/interfaces';

import Lookup from './Lookup';
import LookupDialog from './LookupDialog';

interface PropsType extends ControlPropsType {
    descr: DBLookupType;
    dataRow: any;
    index?: number;
}

type DataValType = string[] | number[] | null;

const DBLookup: FunctionComponent<PropsType> = observer(
    ({ descr, propContainer, dataRow, index }) => {
        const {
            dataset,
            dataVal,
            fieldName,
            fieldRequired,
            onScreen,
            ctrlEnabled,
            ctrlVisible,
            editRef
        } = useDBEdit(descr, propContainer, dataRow);

        const { formGuid } = propContainer.dataStock;

        const dialogRef = useRef(null) as any;

        const setIsOpen = (value: boolean, cb?: () => (state: boolean) => unknown) => {
            dialogRef?.current?.setOpen(value, cb);
        };

        const getValue = () => {
            if (dataVal) {
                if (Array.isArray(dataVal)) {
                    return dataVal;
                }

                if (typeof dataVal === 'string') {
                    return dataVal?.split(',').map(idx => Number(idx)) ?? [Number(dataVal)];
                }

                return [dataVal];
            }

            return [];
        };

        const [value, setValue] = useState<DataValType>(getValue());
        const [loading, setLoading] = useState(false);
        const [codeLabel, setCodeLabel] = useState({ code: '', label: '' });

        useEffect(() => {
            propContainer.lookup.setLookup(descr, {
                setOpen: setIsOpen
            });
        }, []);

        useEffect(() => {
            propContainer.setPageRequirement(
                descr.name,
                fieldRequired && ctrlEnabled && !value?.length
            );
        }, [value, fieldRequired, ctrlEnabled]);

        /**
         * Генерация текстового поля лукапа по перечню полей
         * @param {any} rec
         * @param {string} fields
         */
        const getKeyFields = (rec: any, fields: string) => {
            const keys = fields.split(/,|;/);

            return keys
                .map(key => rec[key.trim()])
                .filter(str => !!str)
                .join(' ');
        };

        const generateCodeLabel = (data: any, variant: string) =>
            data
                .map((rec: any) => getKeyFields(rec, variant))
                .filter((rec: string) => !!rec)
                .join(', ');

        useEffect(() => {
            setValue(getValue());
        }, [dataset?.getFieldValue(fieldName)]);

        useEffect(() => {
            async function updateCodeLabel(val: DataValType) {
                const resourceLink = propContainer.getResourceLink(descr.lookupResourceName);

                if (val?.length && resourceLink?.resource?.name && !loading) {
                    setLoading(true);

                    try {
                        const data = await propContainer.lookup.getValue(
                            descr,
                            val,
                            formGuid,
                            resourceLink.resource.guid
                        );

                        if (data?.length > 0) {
                            setCodeLabel({
                                code: generateCodeLabel(data, descr.codeFields),
                                label: generateCodeLabel(data, descr.labelFields)
                            });
                        }
                    } catch (err: any) {
                        console.error(err.message);
                    } finally {
                        setLoading(false);
                    }
                } else {
                    setCodeLabel({ code: '', label: '' });
                }
            }

            if (descr) {
                updateCodeLabel(value).catch(err => console.error(err.message));
            }
        }, [
            descr,
            dataset,
            value,
            formGuid,
            propContainer.dataStock.formDescr.resourceLinks,
            propContainer.customResourceLinks
        ]);

        const openLookup = useCallback(() => {
            setIsOpen(true);
        }, [descr]);

        const clearValues = useCallback(() => {
            dataset.setFieldValue(fieldName, null, index);
            setValue(null);
        }, [descr]);

        return (
            <Box
                ref={editRef}
                sx={{
                    height: '100%',
                    width: '100%',
                    boxSizing: 'border-box',
                    alignItems: 'center',
                    pr: 4,
                    visibility: ctrlVisible ? 'inherit' : 'hidden'
                }}
                className={fieldRequired && onScreen ? 'required-field' : ''}
            >
                <Lookup
                    key={descr.guid}
                    label={getLocalizedString(descr.label)}
                    variant="outlined"
                    value={getLocalizedString(codeLabel.code)}
                    helperText={getLocalizedString(codeLabel.label)}
                    inputProps={{ style: { color: '#888' } }}
                    onOpenClick={openLookup}
                    onClear={clearValues}
                    disabled={!ctrlEnabled}
                    loading={loading}
                    error={fieldRequired && !(codeLabel.code || codeLabel.label)}
                />
                <LookupDialog
                    ref={dialogRef}
                    descr={descr}
                    propContainer={propContainer}
                    dataVal={value}
                />
            </Box>
        );
    }
);

export default DBLookup;
