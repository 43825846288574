import { Locale } from './interfaces';

export default class InternationalContent {
    /**
     * Перебор локалей для случаев, когда нет перевода строки для текущей
     * @param {object} content
     * @private
     */
    private juggleLocale(content: { [locale: string]: string }) {
        return content[Object.keys(content).filter(locale => !!content[locale])[0]] ?? '';
    }

    /**
     * Проверка поля на то, что оно содержит в себе несколько локалей
     * @param {string} content
     * @return {boolean}
     */
    private static checkLocalizedString(content: string) {
        const localeTemplatePattern = /#[a-z]{3}#/;

        return localeTemplatePattern.test(content);
    }

    private static makeLocalizedContent(content: string): { [locale in Locale]: string } {
        const localePayrs = content
            .split(/(?=(#[a-z]{3})#)/)
            .map(str => str.replace(/#/, '').split(/#/));
        return Object.fromEntries(localePayrs) as { [locale in Locale]: string };
    }

    checkInternationalField(dataType: string | null) {
        return (
            typeof dataType === 'string' &&
            ['KRN_STRING_INTERNATIONAL', 'KRN_MEMO_INTERNATIONAL'].includes(dataType)
        );
    }

    /**
     * Проверить строку на наличие шаблонов локализации
     * и вернуть строку с учетом установленной локали.
     * Пример строки с шаблонами локализации:
     * '#rus#Финансовый модуль#eng#Financial module#fra#Module financière'
     * @param {string} content
     * @param {Locale} locale
     * @param {boolean} juggle
     * @returns {string}
     */
    getLocalizedString(content: string, locale: Locale, juggle = true): string {
        if (InternationalContent.checkLocalizedString(content)) {
            const localizedContent = InternationalContent.makeLocalizedContent(content);

            if (!localizedContent[locale] && juggle) return this.juggleLocale(localizedContent);

            return localizedContent[locale];
        }

        return content;
    }

    updateInternationalContent(value: string, content: string, locale: Locale) {
        if (InternationalContent.checkLocalizedString(content)) {
            const localizedContent = InternationalContent.makeLocalizedContent(content);
            localizedContent[locale] = value;

            return Object.keys(localizedContent)
                .map(lang => `#${lang}#${localizedContent[lang as Locale]}`)
                .join('');
        }

        return value;
    }
}
