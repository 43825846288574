import { action, makeObservable, observable } from 'mobx';

import { CDOType, DatasetType, EditModeType, FormType } from 'forms/interfaces';

import DataStock from 'dataObj/DataStock';
import PropContainer from 'dataObj/PropContainer';
import Dataset from 'dataObj/customDataset';
import { ResAccum } from 'dataObj/dataInterfaces';

import { collectDatasetDescriptors } from 'forms/form-utils';

import NotificationStore from 'store/notificationStore';

import { view, save, edit, cancel, getName, getDataStock } from './actions';

const calcEditMode = (formDescr: FormType, editMode?: EditModeType): EditModeType => {
    const { isEditor } = formDescr;

    if (isEditor) {
        return editMode || null;
    }

    if (isEditor && editMode) {
        console.error(`Form "${formDescr.name}" is not editor!`);
        return null;
    }

    return null;
};

const calcAvailableActions = (editMode?: EditModeType) =>
    ['edit', 'create', 'cdoCreate'].includes(editMode || '')
        ? [view, save, edit, cancel, getName, getDataStock]
        : [view, getName, getDataStock];

/**
 * Класс действий формы
 */
export default class FormActions {
    public type = 'form';

    private formDescr: FormType;
    private isSlave?: boolean;
    private propContainer: PropContainer;
    private dataStock: DataStock;
    private setLoading: (loading: boolean) => unknown;
    private availableActions?: string[];

    @observable editMode: EditModeType;
    @observable validated: boolean;
    @observable isReady: boolean;

    constructor(
        formDescr: FormType,
        propContainer: PropContainer,
        setLoading: (loading: boolean) => unknown,
        editMode?: EditModeType,
        actions?: string[]
    ) {
        this.formDescr = formDescr;
        this.isSlave = formDescr.isSubForm;
        this.propContainer = propContainer;
        this.dataStock = propContainer.dataStock;
        this.editMode = calcEditMode(formDescr, editMode);
        this.validated = true;
        this.isReady = false;

        this.setLoading = setLoading;

        this.availableActions = actions || calcAvailableActions(this.editMode);

        makeObservable(this);
    }

    checkLocalData = async () => {
        const errAccum = { err: [], warn: [] };

        await this.dataStock.checkData(this.propContainer, errAccum);

        if (errAccum.err.length === 0) return true;

        if (errAccum.err.length || errAccum.warn.length) {
            NotificationStore.showAlert(`${errAccum.err.join('\n')}\n${errAccum.warn.join('\n')}`);
        }

        return false;
    };

    @action setEditMode = (mode?: EditModeType) => {
        this.availableActions = calcAvailableActions(mode);

        this.editMode = mode || null;
    };

    @action save = async () => {
        if (this.availableActions?.length && this.availableActions.includes(save)) {
            this.setLoading(true);

            const resAccum: ResAccum = [];
            const errAccum = { err: [], warn: [] };

            await this.dataStock.checkData(this.propContainer, errAccum);

            if (errAccum.err.length === 0) {
                if (this.isSlave) {
                    const arrDSDescr: DatasetType[] = [];

                    collectDatasetDescriptors(this.formDescr.datasets, arrDSDescr);

                    if (this.formDescr.editDatasetName) {
                        const editDataset = this.propContainer.dataStock.getDatasetObj(
                            this.formDescr.editDatasetName
                        )?.descr;

                        if (editDataset) {
                            collectDatasetDescriptors([editDataset], arrDSDescr);
                        }
                    }

                    arrDSDescr.forEach(dsDescr => {
                        this.dataStock.getDatasetObj(dsDescr.name)?.post();
                    });
                } else {
                    await this.dataStock.saveData(errAccum, resAccum);
                }
            }

            if (errAccum.err.length || errAccum.warn.length) {
                NotificationStore.showAlert(
                    `${errAccum.err.join('\n')}\n${errAccum.warn.join('\n')}`
                );
            }

            this.setLoading(false);

            return {
                isValid: errAccum.err.length === 0,
                noReload: this.formDescr.isSubForm,
                resAccum,
                ...errAccum
            };
        }
    };

    @action cancel = () => {
        const cancelEdit = (datasets: DatasetType[]) => {
            datasets?.forEach((dsDescr: DatasetType) => {
                const ds = this.dataStock.getDatasetObj(dsDescr.name);

                if (ds) {
                    ds.cancelEdit(this.editMode === 'create')
                        .catch(err => console.error(err.message))
                        .finally(() => cancelEdit(ds.descr.datasets));
                }
            });
        };

        if (this.availableActions?.length && this.availableActions.includes(cancel)) {
            cancelEdit(this.formDescr.datasets);
            this.formDescr.dataObjects?.forEach((cdoDescr: CDOType) =>
                cancelEdit(cdoDescr.datasets)
            );
        }
    };

    @action edit = async () => {
        const setDSStates = async (arrDS: Dataset[]) => {
            for (let i = 0; i < arrDS.length; i++) {
                const ds = arrDS[i];

                if (this.editMode === 'view') {
                    ds.view();
                } else if (ds.index === 0 || ds.editable) {
                    if (this.editMode === 'create') {
                        // добавление записей необходимо производить строго
                        // последовательно для учета зависимости по иерархии
                        if (ds.cdo) {
                            // В случае CDO добавляем только запись редактируемую сабформой
                            if (
                                ds.index === 0 ||
                                ['root', 'detail'].includes(ds.editType as string)
                            ) {
                                if (this.isSlave && this.formDescr.editDatasetName === ds.name) {
                                    if (ds.currentDataChunk?.key >= 0) {
                                        return ds.cdo.append(ds);
                                    }
                                    await ds.append();
                                }
                            }
                        } else if (
                            ds.index === 0 ||
                            ['root', 'extension'].includes(ds.editType as string) ||
                            (this.isSlave && this.formDescr.editDatasetName === ds.name)
                        ) {
                            await ds.append();
                        }
                    }
                    // editMode === 'edit'
                    else if (
                        ds.index === 0 ||
                        ds.editType === 'root' ||
                        (this.isSlave && this.formDescr.editDatasetName === ds.name) // ?
                    ) {
                        ds.edit();
                    } else if (ds.editType === 'extension') {
                        if (ds.recCount === 0) {
                            await ds.append();
                        } else {
                            ds.edit();
                        }
                    }
                }
            }
        };

        if (
            this.availableActions?.length &&
            this.availableActions.some(avAct => [edit, view].includes(avAct))
        ) {
            // Для сабформы-редактора датасета
            const checkDatasetEditor = (dsName?: string) => (ds: Dataset) => {
                if (dsName) {
                    return ds.name === dsName;
                }

                return true;
            };

            for (let i = 0; i < this.dataStock.CDOs.length; i++) {
                await setDSStates(
                    this.dataStock.CDOs[i].datasets.filter(
                        checkDatasetEditor(this.formDescr.editDatasetName)
                    )
                );
            }
            await setDSStates(
                this.dataStock
                    .getFreeDatasets()
                    .filter(checkDatasetEditor(this.formDescr.editDatasetName))
            );
        }
    };

    @action clearDiff = () => {
        this.dataStock.datasets.forEach(ds => {
            ds.delta = {};
            ds.markForDelete = [];
        });
    };

    @action setValidation = (validate: boolean) => {
        this.validated = validate;
    };

    @action setIsReady = () => {
        this.isReady = true;
    };

    getName = () => this.formDescr.caption ?? this.formDescr.name;

    getDataStock = () => this.dataStock;

    hasAction = (checkAction?: string | null) => this.availableActions?.includes(checkAction || '');

    getFormDescr = () => this.formDescr;
}
