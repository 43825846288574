import React, { useState, useEffect, FunctionComponent, useMemo } from 'react';
import { observer } from 'mobx-react';

import { Box } from '@mui/material';

import useDBEdit from 'hooks/dbEdit';
import { getLocalizedString } from 'utils';

import { ControlPropsType, DBCheckBoxType } from 'forms/interfaces';

import { CssCheckBox, CssFormControlLabel } from '../StyledCtrl';

interface PropsType extends ControlPropsType {
    descr: DBCheckBoxType;
    dataRow: any;
    index?: number;
}

const DBCheckBox: FunctionComponent<PropsType> = observer(
    ({ descr, propContainer, dataRow, index }) => {
        const {
            dataset,
            fieldName,
            dataVal,
            dataType,
            ctrlEnabled,
            ctrlVisible,
            fieldRequired,
            onScreen
        } = useDBEdit(descr, propContainer, dataRow);

        const [val, setVal] = useState(() => dataVal);

        const label = useMemo(() => getLocalizedString(descr.label), [descr.label]);

        useEffect(() => {
            if (dataset) setVal(dataVal);
        }, [dataset, dataVal]);

        const inputChangeHandler = (event: any) => {
            if (dataset?.inEdit) {
                const v = event.target.checked;
                setVal(v);
                dataset.setFieldValue(fieldName, dataType === 'KRN_LOGICAL' ? Number(v) : v, index);
            }
        };

        return (
            <Box
                sx={{
                    width: '100%',
                    p: 2,
                    boxSizing: 'border-box',
                    visibility: ctrlVisible ? 'inherit' : 'hidden'
                }}
                className={fieldRequired && onScreen && ctrlEnabled ? 'required-field' : ''}
            >
                <CssFormControlLabel
                    control={
                        <CssCheckBox
                            size="small"
                            color="primary"
                            onChange={inputChangeHandler}
                            checked={val}
                            disabled={!ctrlEnabled || !dataset?.inEdit}
                        />
                    }
                    disabled={!ctrlEnabled || !dataset?.inEdit}
                    label={label}
                    sx={{ pl: 2 }}
                />
            </Box>
        );
    }
);

export default DBCheckBox;
