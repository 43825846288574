import React, { FunctionComponent, useState } from 'react';
import { observer } from 'mobx-react';
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';

import { FormType } from 'forms/interfaces';

import { ResourceClass } from 'store/resourceStore';

import ResEditor from 'components/resources/ResEditor';

interface PropsType {
    formDescr: FormType;
}

const FormHelper: FunctionComponent<PropsType> = observer(({ formDescr }) => {
    const { guid, name } = formDescr;

    const [opacity, setOpacity] = useState(0);
    const [resEditor, setResEditor] = useState(false);
    const [resStore, setResStore] = useState<ResourceClass | null>(null);

    const handleClick = () => {
        const RS = new ResourceClass();
        RS.selectResource(guid).catch((err: any) => {
            console.error(err.message);
        });
        setResStore(RS);
        setResEditor(true);
    };

    const handleClose = () => {
        setResStore(null);
        setResEditor(false);
    };

    const handleHover = () => {
        setOpacity(0.2);
    };

    const handleUnhover = () => {
        setOpacity(0);
    };

    return (
        <>
            {resStore && (
                <Dialog
                    open={resEditor}
                    fullWidth
                    maxWidth="xl"
                    sx={{ '& .MuiDialog-paper': { height: '90vh' } }}
                >
                    <DialogContent>
                        <ResEditor ResourceStore={resStore} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                    </DialogActions>
                </Dialog>
            )}
            {!!opacity && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'red',
                        opacity,
                        zIndex: 100,
                        border: 'dotted 2px black',
                        borderRadius: 1
                    }}
                />
            )}

            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    display: 'flex',
                    width: '75%',
                    cursor: 'pointer'
                }}
            >
                <Box
                    sx={{
                        zIndex: 999,
                        color: 'black',
                        ml: 'auto',
                        backgroundColor: '#e7e7e7',
                        border: 'solid 1px grey',
                        borderRadius: 1,
                        p: 1
                    }}
                    onMouseEnter={handleHover}
                    onMouseLeave={handleUnhover}
                    onClick={handleClick}
                >
                    <Typography variant="subtitle2" sx={{ fontSize: '0.7rem' }}>
                        {name}
                    </Typography>
                </Box>
            </Box>
        </>
    );
});

export default FormHelper;
