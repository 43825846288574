import React, { useMemo } from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Konva from 'konva';
import { Grid, Typography } from '@mui/material';

import ConfigurationStore from 'store/configurationStore';

interface PropsType {
    data: any | undefined;
    columns?: any | undefined;
    settings: any;
}

interface CustomLabelType {
    cx: number;
    cy: number;
    midAngle: number;
    innerRadius: number;
    outerRadius: number;
    percent: number;
    name?: string;
}

const RADIUS_RATIO = 2.5;

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = (label: CustomLabelType) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, percent } = label;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text
            x={x}
            y={y}
            fill="white"
            textAnchor={x > cx ? 'start' : 'end'}
            dominantBaseline="central"
        >
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

const PieChartComponent = (props: PropsType) => {
    const { content } = ConfigurationStore;

    const { data, columns, settings } = props;
    const { xAxis, chartOptions, chartHeight, chartWidth } = settings;

    const valueKey: any = useMemo(() => {
        const optColumns = chartOptions?.columns;
        if (optColumns) {
            return optColumns.find((column: any) => !column.visible && column.axis === '');
        }
    }, [chartOptions]);

    const renderLegend = (value: string, entry: any) => {
        const { color, dataKey } = entry;
        const legend = columns.find((item: any) => item.name === dataKey);

        return <span style={{ color }}>{legend ? legend.caption : value}</span>;
    };

    return (
        <Grid container sx={{ margin: 'auto' }}>
            {xAxis && valueKey ? (
                <ResponsiveContainer width="100%" height={chartHeight}>
                    <PieChart width={chartWidth} height={chartHeight}>
                        <Pie
                            data={data}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            label={renderCustomizedLabel}
                            // innerRadius={90} // TODO: Doughnut chart
                            outerRadius={chartWidth / RADIUS_RATIO}
                            fill="#8884d8"
                            dataKey={valueKey.fieldName}
                            nameKey={xAxis.fieldName}
                            startAngle={-270}
                            // endAngle={90} // TODO: диаграмма на часть окружности
                        >
                            {data.map((el: any, index: number) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={el.color ? el.color : Konva.Util.getRandomColor()}
                                />
                            ))}
                        </Pie>
                        <Tooltip />
                        <Legend formatter={renderLegend} />
                    </PieChart>
                </ResponsiveContainer>
            ) : (
                <Grid item sx={{ margin: 'auto' }}>
                    <Typography>{content.controls.data.noDataset}</Typography>
                </Grid>
            )}
        </Grid>
    );
};

export default PieChartComponent;
