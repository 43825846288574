import { jsonFetch, splitFullFieldName } from '../utils';

export default class ExternFuncContainer {
    constructor(calculator) {
        this.calculator = calculator;

        this.externFuncContainerName = 'efc';

        this.arrWarnings = [];
        this.arrErrors = [];

        this._resultCache = new Map();

        this.arrAsyncFunc = ['SVR_FORMULA'];
        this.names = new Map();

        this.names.set('_UNKNOWN', this._UNKNOWN.name); //заглушка для неизвестных функций
        this.names.set('_NULLVAL', this._NULLVAL.name); //эквивалент NULL для расчетов (KRN_INTEGER = 0, KRN_STRING=''...)

        this.names.set('ASNUM', this.ASNUM.name);
        this.names.set('ASSTRING', this.ASSTRING.name); // какая разница с STRNUM ??
        this.names.set('CHAR', this.CHAR.name);
        this.names.set('CHECK_SNILS', this.CHECK_SNILS.name);
        this.names.set('COALESCE', this.COALESCE.name);
        this.names.set('COS', this.COS.name);
        this.names.set('CURRENT_DATE', this.CURRENT_DATE.name);
        this.names.set('CURRENT_DATE_TIME', this.CURRENT_DATE_TIME.name);
        this.names.set('DAY', this.DAY.name);
        this.names.set('DEGREE', this.DEGREE.name);
        this.names.set('DSFIND', this.DSFIND.name);
        this.names.set('GETTARGETPARAM', this.GETTARGETPARAM.name);
        this.names.set('IF', this.IF.name);
        this.names.set('ISNULL', this.ISNULL.name);
        this.names.set('ISUNDEFIGNED', this.ISUNDEFIGNED.name);
        this.names.set('HOUR', this.HOUR.name);
        this.names.set('LEN', this.LEN.name);
        this.names.set('LOWER', this.LOWER.name);
        this.names.set('MINUTE', this.MINUTE.name);
        this.names.set('MSGERROR', this.MSGERROR.name);
        this.names.set('MSGWARN', this.MSGWARN.name);
        this.names.set('NOW', this.NOW.name);
        this.names.set('NUMSTR', this.NUMSTR.name);
        this.names.set('NUMTEXT', this.NUMTEXT.name); // какая разница с NUMSTR ??
        this.names.set('MONTH', this.MONTH.name);
        this.names.set('POS', this.POS.name);
        this.names.set('ROUND', this.ROUND.name);
        this.names.set('SECOND', this.SECOND.name);
        this.names.set('SIN', this.SIN.name);
        this.names.set('SQRT', this.SQRT.name);
        this.names.set('STRNUM', this.STRNUM.name);
        this.names.set('SUBSTR', this.SUBSTR.name);
        this.names.set('TAN', this.TAN.name);
        this.names.set('TRUNC', this.TRUNC.name);
        this.names.set('UPPER', this.UPPER.name);
        this.names.set('YEAR', this.YEAR.name);

        this.names.set('SVR_FORMULA', this.SVR_FORMULA.name);
    }

    clearWarnAndErr() {
        this.arrErrors = [];
        this.arrWarnings = [];
    }

    setCachedResult(keyArray, res) {
        this._resultCache.set(keyArray.toString(), res);
    }

    getCashedResult(keyArray) {
        return this._resultCache.get(keyArray.toString());
    }

    _UNKNOWN(str) {
        //заглушка для неизвестных функций
        return str;
    }

    _NULLVAL(paramName) {
        let res = this.getCashedResult(['_NULLVAL', paramName]);
        if (res === undefined) {
            let type = this.calculator.getParamType(paramName);
            if (type === 'int') {
                res = 0;
            } else {
                res = '';
            }
            this.setCachedResult(['_NULLVAL', paramName], res);
        }
        return res;
    }

    DEGREE(X, Y) {
        return Math.pow(X, Y);
    }

    DSFIND(fullFieldName, reserved, searchFields, ...args) {
        let [datasetName, resField] = splitFullFieldName(fullFieldName);

        const ds = this.calculator.dataStock.getDatasetObj(datasetName);
        if (ds) {
            const fields = searchFields.split(';').map(field => field.trim());
            const rec = ds.data.find(rec => {
                let res = true;
                for (let i = 0; i < fields.length; i++) {
                    if (rec[fields[i]] !== args[i]) {
                        res = false;
                        break;
                    }
                }
                return res;
            });
            if (rec) return rec[resField];
        } else {
            console.error(`Dataset ${datasetName} not found`);
        }
    }

    GETTARGETPARAM(TARGET, paramName) {
        let params;
        if (TARGET?.descr?.params) {
            params = this.calculator.dataStock.getParams(TARGET.descr.params);
        } else if (TARGET?.descr?.dataParams) {
            params = this.calculator.dataStock.getParams(TARGET.descr.dataParams);
        }

        if (params) return params[paramName];
    }

    MSGWARN(str) {
        this.arrWarnings.push(str);
        return 0;
    }

    MSGERROR(str) {
        this.arrErrors.push(str);
        return 1;
    }

    LIKEREGEXPR(text, strReg) {
        return text.match(new RegExp(strReg));
    }

    NOW() {
        return new Date();
    }

    SECOND(ms) {
        return new Date(ms).getSeconds();
    }

    MINUTE(ms) {
        return new Date(ms).getMinutes();
    }

    HOUR(ms) {
        return new Date(ms).getHours();
    }

    DAY(ms) {
        return new Date(ms).getDay();
    }

    MONTH(ms) {
        return new Date(ms).getMonth();
    }

    YEAR(ms) {
        return new Date(ms).getFullYear();
    }

    POS(substr, str) {
        return str.indexOf(substr) + 1;
    }

    INSSTR(str, substr, pos) {
        //Порядок аргументов
        return str.slice(0, pos - 1) + substr + str.slice(pos - 1);
    }

    SQRT(x) {
        return Math.sqrt(x);
    }

    LEN(s) {
        return s.length;
    }

    STRNUM(s) {
        return parseFloat(s);
    }

    ROUND(x) {
        return Math.round(x);
    }

    LOWER(s) {
        return s.toLowerCase();
    }

    UPPER(s) {
        return s.toUpperCase();
    }

    CURRENT_DATE() {
        return +new Date().setHours(0, 0, 0, 0);
    }

    CURRENT_DATE_TIME() {
        return +new Date();
    }

    COALESCE() {
        for (let i = 0; i < arguments.length; i++) {
            if (arguments[i] !== null) {
                return arguments[i];
            }
            return null;
        }
    }

    IF(cond, a, b) {
        if (cond) {
            return a;
        } else {
            return b;
        }
    }

    ASNUM(text, caseError) {
        let res = parseFloat(text); //преобразует в 3.14 числе "3.14другие символы" - хорошо ли????
        if (isNaN(res)) {
            res = caseError;
        }
        return res;
    }

    ASSTRING(val) {
        return '' + val;
    }

    NUMSTR(text) {
        return String(text);
    }

    NUMTEXT(text) {
        return String(text);
    }

    SUBSTR(text, beg, len) {
        //('Моя строка', 1, 3) = 'Моя'
        return text.substring(beg - 1, beg + len - 1);
    }

    ISNULL(x, defaultValue) {
        return x === null ? defaultValue : x;
    }

    ISUNDEFIGNED(x, defaultValue) {
        return x === undefined ? defaultValue : x;
    }

    CHAR(code) {
        return String.fromCharCode(code);
    }

    TRUNC(x) {
        return Math.trunc(x);
    }

    SIN(x) {
        return Math.sin(x);
    }

    COS(x) {
        return Math.cos(x);
    }

    TAN(x) {
        return Math.tan(x);
    }

    //Проверка СНИЛС на корректность (перевод с Дельфи)
    CHECK_SNILS(snils) {
        if (snils.length !== 14) return false;

        const n0 = Number(snils[12]);
        const nn = Number(snils[13]);
        const cc = n0 * 10 + nn;
        const n1 = Number(snils[10]);
        const n2 = Number(snils[9]);
        const n3 = Number(snils[8]);
        const n4 = Number(snils[6]);
        const n5 = Number(snils[5]);
        const n6 = Number(snils[4]);
        const n7 = Number(snils[2]);
        const n8 = Number(snils[1]);
        const n9 = Number(snils[0]);
        const sm =
            ((n1 + 2 * n2 + 3 * n3 + 4 * n4 + 5 * n5 + 6 * n6 + 7 * n7 + 8 * n8 + 9 * n9) % 101) %
            100;

        return sm === cc;
    }

    /**
     * Серверная формула
     * @param {string} formulaName
     * @param  {any[]} args
     */
    async SVR_FORMULA(formulaName, ...args) {
        const parObj = args.reduce((acc, val, index) => {
            acc[index] = val;
            return acc;
        }, {});

        const { result, errors, warnings } = await jsonFetch('formula/calc', 'POST', {
            guid: this.calculator.dataStock.formGuid,
            formulaName,
            parObj
        });

        this.arrWarnings = this.arrWarnings.concat(warnings);
        this.arrErrors = this.arrErrors.concat(errors);

        return result;
    }
}
