import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { FunctionComponent } from 'react';

const StyledSwitch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16
        },
        '&:before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main)
            )}" d="M22 12 20.607 10.565 13 18.172 13 2 11 2 11 18.172 3.414 10.586 2 12C5.661 15.661 8.496 18.496 12 22 15.966 18.034 13.166 20.834 22 12"/></svg>')`,
            left: 12
        },
        '&:after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main)
            )}" d="M12 2 10.565 3.393 18.172 11 2 11 2 13 18.172 13 10.586 20.586 12 22C15.661 18.339 18.496 15.504 22 12L12 2"/></svg>')`,
            right: 12
        }
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2
    }
}));

interface DirectionSwitchType {
    checked: boolean;
    handleChange: (event: any) => unknown;
}

const DirectionSwitch: FunctionComponent<DirectionSwitchType> = ({ checked, handleChange }) => (
    <FormControlLabel
        sx={{ position: 'absolute', zIndex: 10, ml: 3 }}
        control={<StyledSwitch checked={checked} onChange={handleChange} />}
        label=""
    />
);

export default DirectionSwitch;
