import { FunctionComponent, MouseEvent, useEffect, useState } from 'react';
import { Box, IconButton, Typography, CircularProgress } from '@mui/material';

import { Folder, FolderOpen, Add } from '@mui/icons-material';

interface PropsType {
    labelText: string;
    handleOpen: (open: boolean) => unknown;
    defaultExpanded?: boolean;
    archive?: boolean;
    disabled?: boolean;
    addRootNode?: () => unknown;
    appendLoading?: boolean;
}

const TreeRootItem: FunctionComponent<PropsType> = ({
    labelText,
    handleOpen,
    defaultExpanded = false,
    addRootNode,
    appendLoading,
    archive,
    disabled,
    children
}) => {
    const [expanded, setExpanded] = useState(defaultExpanded);

    useEffect(() => {
        handleOpen(expanded);
    }, [expanded]);

    const handleExpanded = () => {
        setExpanded(prevState => !prevState);
    };

    const handleAddRoot = (event: MouseEvent) => {
        event.stopPropagation();
        addRootNode && addRootNode();
    };

    const folderIcon = () => {
        if (appendLoading && !addRootNode) {
            return CircularProgress;
        }

        return expanded ? FolderOpen : Folder;
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    p: 0.5,
                    pr: 0,
                    cursor: 'pointer',
                    '&:hover': { background: '#ededed' }
                }}
                onClick={handleExpanded}
            >
                <Box
                    component={folderIcon()}
                    color="inherit"
                    sx={{
                        mr: 1,
                        '&.MuiCircularProgress-root': {
                            width: '16px !important',
                            height: '16px !important'
                        }
                    }}
                    fontSize="medium"
                />
                <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                    {`${labelText}${archive ? ' (архив)' : ''}`}
                </Typography>
                {addRootNode && !archive && !disabled && (
                    <Box color="inherit" sx={{ mr: 1 }} fontSize="medium">
                        {appendLoading ? (
                            <IconButton size="small" disabled>
                                <CircularProgress size={18} />
                            </IconButton>
                        ) : (
                            <IconButton size="small" onClick={handleAddRoot}>
                                <Add fontSize="inherit" />
                            </IconButton>
                        )}
                    </Box>
                )}
            </Box>
            {expanded && (
                <Box sx={{ pl: 4, display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                    {children}
                </Box>
            )}
        </>
    );
};

export default TreeRootItem;
