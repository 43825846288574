import React, {
    FunctionComponent,
    MutableRefObject,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import { observer } from 'mobx-react';

import { Box } from '@mui/material';
import { Save, Clear } from '@mui/icons-material';

import ConfigurationStore from 'store/configurationStore';
import NotificationStore from 'store/notificationStore';
import Loading from 'components/utils/Loading/Loading';

import CustomForm from 'forms/CustomForm';
import Toolbar from 'forms/controls/Toolbar/Toolbar';

import useNodeResize from 'hooks/nodeResize';

import { ControlPropsType, EditorContainerType } from 'forms/interfaces';
import { CustomToolbarButtonType } from 'forms/controls/Toolbar/interface';

import ActionStore from 'store/actionStore';

interface PropsType extends ControlPropsType {
    descr: EditorContainerType;
}

const EditorContainer: FunctionComponent<PropsType> = observer(({ descr, propContainer }) => {
    const { content } = ConfigurationStore;
    const { datasetName } = descr;

    const dataset = propContainer.dataStock.getDatasetObj(datasetName);

    const editorRef = useRef(null) as MutableRefObject<any>;

    const [formGuid, setFormGuid] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    const [editorExtParamVals, setEditorExtParamVals] = useState(null);

    const formActions = formGuid ? ActionStore.getFormActions(formGuid) : null;

    const [editorWidth] = useNodeResize(editorRef);

    const calcOperationFormula = async (formulaName: string) => {
        let allowed = true;
        const code = `ds.${dataset.name as string}.${formulaName}`;
        const errAccum = { err: [], warn: [] };
        const payload =
            await dataset.dataStock.ownerPropContainer.formulaCalculator.calcFormulaByCode(
                code,
                errAccum
            );

        if (errAccum.err.length) {
            allowed = false;
            NotificationStore.showAlert(errAccum.err.join('\n'));
        }
        return { allowed, payload };
    };

    const editRecord = async () => {
        if (dataset.activeRec) {
            const res = await calcOperationFormula('editFormula');
            if (res.allowed) {
                const extParamVals =
                    res.payload && typeof res.payload === 'object' ? res.payload : {};

                extParamVals[dataset.keyField] = dataset.activeRec[dataset.keyField];

                if (res.payload.__EDITOR) {
                    setFormGuid(res.payload.__EDITOR ?? dataset?.descr.editForm?.guid);
                    setEditorExtParamVals(extParamVals);
                }
            }
        }
    };

    const selectRecord = useCallback(() => {
        setLoading(true);
        setFormGuid(null);
        editRecord()
            .then(() => {
                setError(null);
                setLoading(false);
            })
            .catch(err => console.error(err.message));
    }, [datasetName]);

    useEffect(() => {
        selectRecord();
    }, [dataset?.activeRec]);

    const buttons: CustomToolbarButtonType[] | undefined = useMemo(() => {
        if (formActions)
            return [
                {
                    type: 'save',
                    variant: 'contained',
                    caption: content.resource.buttons.save,
                    icon: <Save />,
                    disabled: !formActions?.validated,
                    onClick: async () => {
                        const result = await formActions.save();
                        if (result?.err.length === 0) {
                            if (result.noReload) {
                                dataset.accum.init();
                            } else {
                                dataset.loadData();
                            }
                        }
                    }
                },
                {
                    type: 'cancel',
                    variant: 'outlined',
                    caption: content.resource.buttons.undo,
                    disabled: !dataset.activeRec,
                    icon: <Clear />,
                    onClick: async () => {
                        selectRecord();
                    }
                }
            ];
    }, [formActions, formActions?.validated]);

    if (loading) return <Loading delay />;

    if (formGuid && dataset?.activeRec)
        return (
            <Box ref={editorRef} sx={{ height: formActions ? 'calc(100% - 64px)' : '100%' }}>
                {formActions && (
                    <Toolbar
                        key={`toolbar-${dataset?.activeRec[dataset.keyField] as number}`}
                        customButtons={buttons}
                        propContainer={propContainer}
                        containerWidth={editorWidth}
                    />
                )}
                <CustomForm
                    key={`form-${dataset?.activeRec[dataset.keyField] as number}`}
                    formGuid={formGuid}
                    extParamVals={editorExtParamVals}
                    initEditMode="edit"
                    parentDataStock={dataset?.dataStock}
                    parentPropContainer={propContainer}
                />
            </Box>
        );

    if (!dataset?.activeRec)
        return <Loading type="empty" message={content.controls.data.choiceRecord} />;

    if (!formGuid) return <Loading type="empty" message={content.controls.data.noEditor} />;

    return null;
});

export default EditorContainer;
