import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';

import { Box, Badge } from '@mui/material';

import styles from 'forms/form.module.scss';
import { ControlPropsType } from 'forms/interfaces';

import useCtrlProps from 'hooks/ctrlProps';

import DataStore from 'store/dataStore';

import TabPanel from './TabPanel';
import StyledTabs from './StyledTabs';
import StyledTab from './StyledTab';

interface PageControlProps extends ControlPropsType {
    descr: any;
    drawChildren: any;
}

const PageControl = observer(({ descr, propContainer, drawChildren }: PageControlProps) => {
    const [activePage, setActivePage] = useState(0);
    const { ctrlVisible } = useCtrlProps(propContainer, descr.guid);

    const setActiveFilter = useCallback(
        (index: number) => {
            if (DataStore?.AF && descr.afName) {
                DataStore.AF.setAF(descr.afName, descr.pages[index].name);
                DataStore.taskQueue
                    .loadAFDependencies([descr.afName])
                    .catch(err => console.error(err.message));
            }
        },
        [descr]
    );

    useEffect(() => {
        setActiveFilter(0);
    }, [setActiveFilter]);

    function handleChange(event: Event, newValue: number) {
        setActivePage(newValue);
        setActiveFilter(newValue);
    }

    return (
        <Box
            className={styles.pageControlWrapper}
            sx={{ visibility: ctrlVisible ? 'inherit' : ('hidden' as any) }}
        >
            {descr.caption ? <Box className={styles.caption}>{descr.caption}</Box> : null}
            <Box className={styles.tabContainer}>
                <StyledTabs value={activePage} onChange={handleChange as any}>
                    {descr.pages.map((page: any, index: number) => (
                        <StyledTab
                            key={index}
                            label={
                                <Badge
                                    color="warning"
                                    variant="dot"
                                    invisible={!propContainer.getPageRequirement(page.guid)}
                                    sx={{
                                        '& .MuiBadge-badge': {
                                            right: -8,
                                            padding: '0 4px'
                                        }
                                    }}
                                >
                                    {page.caption}
                                </Badge>
                            }
                        />
                    ))}
                </StyledTabs>
            </Box>
            <Box className={styles.tabPanel}>
                {descr.pages.map((page: any, index: number) => (
                    <TabPanel key={index} index={index} value={activePage}>
                        {drawChildren(page)}
                    </TabPanel>
                ))}
            </Box>
        </Box>
    );
});

export default PageControl;
