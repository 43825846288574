import { useEffect, useRef, useState } from 'react';

export default function useWindowResize() {
    const isClient = typeof window === 'object';
    const lastWidth = useRef();

    function getSize() {
        return isClient ? window.innerWidth : undefined;
    }

    const [windowSize, setWindowSize] = useState(getSize);

    const handleResize = () => {
        if (window?.innerWidth !== lastWidth.current) {
            const width = getSize();
            lastWidth.current = width as any;
            setWindowSize(width);
        }
    };

    useEffect(() => {
        if (isClient) {
            window.addEventListener('resize', handleResize);

            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }
    }, []);

    return windowSize;
}
