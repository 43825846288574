import applicationContent from './application/enApplicationLocale';
import resourceContent from './resources/enResourceLocale';
import controlsContent from './controls/enControlsLocale';
import componentsContent from './components/enComponentLocale';

export default {
    language: 'English',
    application: applicationContent,
    controls: controlsContent,
    resource: resourceContent,
    components: componentsContent
};
