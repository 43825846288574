import { FunctionComponent } from 'react';

import { ListItemText, ListItemIcon, Menu } from '@mui/material';

import menuItemsWrapper from './menuItems';
import NestedMenuItem from './NestedMenuItem';
import MenuItem from '../MenuItem';

import { ControlPropsType, MenuItemType } from '../../../interfaces';
import PropContainer from '../../../../dataObj/PropContainer';
import SVGIcon from '../../SVGIcon';

export const menuItem = (
    item: MenuItemType,
    open: boolean,
    propContainer: PropContainer,
    closeMenu: any
) => {
    const { caption, items } = item;

    if (items?.length)
        return (
            <NestedMenuItem
                key={item.guid}
                descr={item}
                propContainer={propContainer}
                parentMenuOpen={open}
                closeMenu={closeMenu}
            >
                {menuItemsWrapper(items, open, propContainer, closeMenu)}
            </NestedMenuItem>
        );

    return (
        <MenuItem key={item.guid} descr={item} propContainer={propContainer} closeMenu={closeMenu}>
            {item.icon ? (
                <ListItemIcon style={{ color: 'inherit' }}>
                    <SVGIcon descr={item} propContainer={propContainer} fontSize="small" />
                </ListItemIcon>
            ) : null}
            <ListItemText>{caption}</ListItemText>
        </MenuItem>
    );
};

interface PropsType extends ControlPropsType {
    anchorEl: HTMLElement | null;
    handleClose: any;
    items: Array<MenuItemType>;
    minWidth?: string | number;
}

const NestedMenu: FunctionComponent<PropsType> = ({
    anchorEl,
    items,
    propContainer,
    handleClose,
    minWidth
}) => {
    const closeMenu = () => {
        handleClose();
    };

    return (
        <Menu
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={closeMenu}
            sx={{ '& .MuiMenu-paper': { minWidth: minWidth ?? 'inherit' } }}
        >
            {menuItemsWrapper(items, !!anchorEl, propContainer, closeMenu)}
        </Menu>
    );
};

export default NestedMenu;
