const langEng = {
    inputs: {
        autocomplete: {
            add: 'Add',
            noOptions: 'No options'
        },
        filterControl: {
            conditions: [
                'In the interval',
                'Outside the interval',
                'Fronts with',
                'Contains',
                'Does not contain',
                'Ends on',
                'Equals',
                'Not equal',
                'Filled',
                'Empty',
                'Including',
                'Excluding',
                'Doesnt matter',
                'Yes',
                'Not'
            ]
        },
        date: {
            today: 'Today',
            cancel: 'Cancel'
        }
    }
};

export default langEng;
