import { FunctionComponent } from 'react';
import { observer } from 'mobx-react';

import { Box, Typography } from '@mui/material';

import ConfigurationStore from 'store/configurationStore';

interface PropType {
    color?: string;
    position: 'left' | 'right';
    padding?: number;
}

const AppVersion: FunctionComponent<PropType> = ({ color, position = 'right', padding = 5 }) => (
    <Box sx={{ position: 'fixed', pb: 2, pr: padding, pl: padding, bottom: 0, [position]: 0 }}>
        <Typography color={color ?? 'initial'} variant="caption">{`version: ${
            process.env.REACT_APP_VERSION as string
        }${
            ConfigurationStore?.apiVersion ? ` / ${ConfigurationStore.apiVersion}` : ''
        }`}</Typography>
    </Box>
);

export default observer(AppVersion);
