import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import './App.css';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { createTheme } from '@mui/material/styles';
import ThemeProvider from 'mui-themes-extand';

import ConfigurationStore from 'store/configurationStore';

import AuthPage from 'pages/Auth/AuthPage';
import MainPage from 'pages/MainPage/MainPage';
import ExternalForm from 'pages/ExternalForm/ExternalForm';
import TokenAuthPage from 'pages/Auth/TokenAuthPage';
import TestPage from 'pages/TestPage/TestPage';

import Notification from 'components/Feedback/Notification';
import AlertDialog from 'components/Feedback/AlertDialog';
import ErrorList from 'components/Feedback/ErrorList';
import Confirmation from 'components/Feedback/Confirmation';
import BackdropPanel from 'components/Feedback/BackdropPanel';
import Editors from 'components/Editors';
import Loading from 'components/utils/Loading/Loading';

import { jsonFetch } from 'utils';
import useBackdrop from 'hooks/backdrop';

import calypsoTheme from 'theme/calypso';
import teamTaskTheme from 'theme/teamtask';

const getTheme = () => {
    switch (process.env?.REACT_APP_DEFAULT_THEME) {
        case 'teamtask':
            return teamTaskTheme;

        case 'calypso':
            return calypsoTheme;

        default:
            return calypsoTheme;
    }
};

const theme = createTheme(getTheme());

const queryParams = new URLSearchParams(window.location.search);
const token = queryParams.get('token');
const themeName = queryParams.get('theme');

type InfoResponseType = {
    basename?: string;
    version: string;
};

const routing = () => {
    if (token) {
        return (
            <Switch>
                <Route path="/medialog/:form">
                    {ConfigurationStore.isAuthenticated ? (
                        <ExternalForm />
                    ) : (
                        <TokenAuthPage
                            token={token}
                            refreshToken={queryParams.get('refreshToken') || ''}
                        />
                    )}
                </Route>
            </Switch>
        );
    }
    if (ConfigurationStore.isAuthenticated) {
        return (
            <Switch>
                <Route path="/" component={MainPage} />
            </Switch>
        );
    }
    return (
        <Switch>
            <Route exact path="/" component={AuthPage} />
            {process.env.REACT_APP_TEST_PAGE ? (
                <Route exact path="/test/*" component={TestPage} />
            ) : null}
            <Route render={() => <Redirect to="/" />} />
        </Switch>
    );
};

const App = observer(() => {
    const { basename, setBasename, backdrop } = ConfigurationStore;

    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        jsonFetch<InfoResponseType>('info')
            .then(info => {
                setBasename(info?.basename || process.env.PUBLIC_URL);
                ConfigurationStore.setApiVersion(info.version);
            })
            .catch(err => {
                console.error(err.message);
                setBasename(process.env.PUBLIC_URL);
            })
            .finally(() => setIsReady(true));

        document.addEventListener('medialog', (event: any) =>
            console.log(JSON.stringify(event.detail))
        );
    }, []);

    useBackdrop(backdrop, 30000);

    return (
        <ThemeProvider theme={theme} name={themeName ?? ''}>
            {isReady ? (
                <BrowserRouter basename={basename}>
                    <AlertDialog />
                    <Confirmation />
                    <Notification />
                    <ErrorList />
                    <BackdropPanel />
                    <Editors />
                    {routing()}
                </BrowserRouter>
            ) : (
                <Loading />
            )}
        </ThemeProvider>
    );
});

export default App;
