import React, { FunctionComponent, useState } from 'react';
import { observer } from 'mobx-react';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Tooltip
} from '@mui/material';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';

import ConfigurationStore from '../../../store/configurationStore';
import { ResourceClass } from '../../../store/resourceStore';

interface PropsType {
    ctrl: any;
    listType: string;
    ResourceStore: ResourceClass;
}

const ResCtrlListClear: FunctionComponent<PropsType> = observer(
    ({ ctrl, listType = 'controls', ResourceStore }) => {
        const { content } = ConfigurationStore;
        const { curRes, updateCurRes } = ResourceStore;

        const [open, setOpen] = useState(false);

        const handleOpen = () => setOpen(true);

        const handleClose = () => setOpen(false);

        const handleDelete = () => {
            ctrl[listType] = [];

            updateCurRes(curRes);

            handleClose();
        };

        return (
            <>
                <Tooltip title={content.resource.buttons.clear}>
                    <IconButton size="small" onClick={handleOpen}>
                        <DeleteSweepIcon />
                    </IconButton>
                </Tooltip>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>{content.resource.clearList.title}</DialogTitle>
                    <DialogContent>{content.resource.clearList.content}</DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>{content.resource.buttons.cancel}</Button>
                        <Button onClick={handleDelete}>{content.resource.buttons.ok}</Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
);

export default ResCtrlListClear;
