import { useState, FC } from 'react';
import { observer } from 'mobx-react';
import { Button, IconButton, Tooltip } from '@mui/material';
import { FilterAlt, FilterAltOff } from '@mui/icons-material';
import CustomDataset from '../../../../dataObj/customDataset';
import FilterDialog from '../../Filter/FilterDialog';
import { FilterValues } from '../../../../dataObj/CustomFilter';

import ConfigurationStore from '../../../../store/configurationStore';

interface PropTypes {
    dataset: CustomDataset;
    gridWidth: number;
    breakpointWidth: number;
}

const UserFilter: FC<PropTypes> = observer(({ dataset, gridWidth, breakpointWidth }: PropTypes) => {
    const { content, log } = ConfigurationStore;

    const [openDialog, setOpenDialog] = useState<boolean>(false);

    const openUserFilter = () => setOpenDialog(true);

    const handleClose = (state: boolean, result?: FilterValues) => {
        setOpenDialog(false);
        if (result) {
            log(`Пользователь собрал фильтр: ${JSON.stringify(result)}`, 'log');
            dataset.filter.values = result;
            dataset.loadData();
        }
    };

    return (
        <>
            {gridWidth >= breakpointWidth ? (
                <Button
                    variant="outlined"
                    aria-label="filter"
                    onClick={openUserFilter}
                    startIcon={dataset.filter.isEmpty() ? <FilterAltOff /> : <FilterAlt />}
                >
                    {content.controls.grid.filter}
                </Button>
            ) : (
                <Tooltip title={content.controls.grid.filter}>
                    <IconButton onClick={openUserFilter}>
                        {dataset.filter.isEmpty() ? <FilterAltOff /> : <FilterAlt />}
                    </IconButton>
                </Tooltip>
            )}
            {openDialog ? <FilterDialog handleClose={handleClose} dataset={dataset} /> : null}
        </>
    );
});

export default UserFilter;
