import React, { useState, FunctionComponent } from 'react';
import { observer } from 'mobx-react';

import { DataGrid, GridSelectionModel } from '@mui/x-data-grid';

import ConfigurationStore from 'store/configurationStore';

import SimpleDialog from 'components/Feedback/SimpleDialog';

import { SelectGridListType } from '../index';

const SelectFields: FunctionComponent<SelectGridListType> = observer(
    ({ columns, rows, handleClose, open }: SelectGridListType) => {
        const content = ConfigurationStore.content.resource.gridProps;
        const [selection, setSelection] = useState<GridSelectionModel>();

        return open ? (
            <SimpleDialog
                editorName={content.selectFields}
                handleClose={state => handleClose(state ? selection : null)}
            >
                <DataGrid
                    columns={columns}
                    hideFooter
                    rows={rows}
                    style={{ height: 500 }}
                    checkboxSelection
                    onSelectionModelChange={(newSelection: GridSelectionModel) => {
                        setSelection(newSelection);
                    }}
                />
            </SimpleDialog>
        ) : null;
    }
);

export default SelectFields;
