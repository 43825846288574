import React, { useState, useEffect, useMemo, FunctionComponent, useRef, useCallback } from 'react';
import { observer } from 'mobx-react';

import { Grid } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';

import ConfigurationStore from 'store/configurationStore';
import DescriptorStore from 'store/descriptorStore';
import DataStore from 'store/dataStore';
import ActionStore from 'store/actionStore';
import FormActions from 'store/actionStore/form/FormActions';

import { getResourceDescriptor, applyFormExtParams } from 'forms/form-utils';
import Translation from 'localization/translation';

import PropContainer from 'dataObj/PropContainer';

import Loading from 'components/utils/Loading/Loading';
import Empty from 'components/utils/Empty/Empty';
import FormHelper from 'components/utils/FormHelper';

import useBackdrop from 'hooks/backdrop';

import { CustomFormType } from 'forms/interfaces';
import Output from 'forms/controls/ScriptButton/Output';
import Glossary from 'forms/controls/glossary/Glossary';
import ControlTree from 'forms/ControlTree';

const CustomForm: FunctionComponent<CustomFormType> = observer(
    ({
        formGuid,
        tableId,
        formConfig,
        extParamVals,
        initEditMode,
        parentDataStock,
        parentPropContainer,
        cdoData
    }) => {
        const { locale, content, isReady } = ConfigurationStore;
        const [dataLoaded, setDataLoaded] = useState(false);
        const [dataReady, setDataReady] = useState(false);
        const [initFormDescr, setInitFormDescr] = useState<null | any>(null);
        const [formDescr, setFormDescr] = useState<null | any>(null);
        const [dataStock, setDataStock] = useState<null | any>(null);
        const [error, setError] = useState<null | string>(null);
        const [loading, setLoading] = useState(false);

        const propContainerRef = useRef<null | any>();

        const formActions: FormActions | null = useMemo(() => {
            if (formDescr && propContainerRef?.current) {
                // Основные действия формы
                // Для внешнего управления формой складываем их в стор
                return ActionStore.addFormActions(
                    formDescr,
                    propContainerRef.current,
                    setLoading,
                    initEditMode
                );
            }

            return null;
        }, [formDescr, propContainerRef?.current]);

        useEffect(() => {
            if (initFormDescr) {
                setDataReady(false);

                const rebuildDescr = applyFormExtParams(initFormDescr, extParamVals);

                // Проверка текущей локали
                if (rebuildDescr.lang && rebuildDescr.lang !== locale) {
                    setFormDescr(
                        new Translation(locale, rebuildDescr.translation).run(rebuildDescr)
                    );
                } else {
                    setFormDescr({ ...rebuildDescr });
                }
            }
        }, [initFormDescr, JSON.stringify(extParamVals)]);

        // создание propContainer при получении дескриптора
        useEffect(() => {
            if (formDescr) {
                const pC = new PropContainer(
                    formDescr,
                    formDescr.isSubForm ? parentDataStock : null,
                    formDescr.isSubForm ? parentPropContainer : null
                );

                propContainerRef.current = pC;
                setDataStock(pC.dataStock);

                DataStore.registerForm(pC);

                return () => {
                    if (propContainerRef.current) {
                        DataStore.unregisterForm(propContainerRef.current);
                        ActionStore.removeActions(formDescr.guid);
                    }
                };
            }
        }, [formDescr]);

        // Имеет ли форма Глоссарий
        const [withGloss, setWithGloss] = useState(false);

        useBackdrop(loading, 30000);

        // Загрузка ресурса
        useEffect(() => {
            if (formConfig) {
                setInitFormDescr(formConfig);
            } else {
                setError(null);
                getResourceDescriptor(formGuid, tableId, setInitFormDescr, DescriptorStore, {
                    logout: ConfigurationStore.logout
                }).catch((err: any) => {
                    console.error(err.message);
                    setError(err?.message);
                });
            }
        }, [formGuid, tableId, formConfig]);

        useEffect(() => {
            if (formDescr?.isSubForm) {
                setDataLoaded(true);
            } else if (formDescr && formDescr.datasets && dataStock) {
                dataStock
                    .loadData(
                        extParamVals,
                        formActions?.editMode &&
                            ['create', 'cdoCreate'].includes(formActions.editMode),
                        cdoData
                    )
                    .then(() => setDataLoaded(true))
                    .catch((err: Error) => {
                        console.error(err.message);
                        setError(err.message);
                    });
            }
        }, [formDescr, dataStock, extParamVals, formActions?.editMode]);

        useEffect(() => {
            if (dataStock && propContainerRef.current.formulaCalculator) {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                DataStore.taskQueue.addFormulaTask({
                    taskType: 'onFormShow',
                    calculator: propContainerRef.current.formulaCalculator
                });
            }
        }, [dataReady]);

        useEffect(() => {
            // Включаем режим редактирования
            if (formActions && dataLoaded) {
                setDataLoaded(false);

                if (formActions.editMode) {
                    setWithGloss(formDescr.withGloss);
                    formActions
                        .edit()
                        .then(() => {
                            setDataReady(true);
                            formActions.setIsReady();
                        })
                        .catch(err => console.error(err.message));
                } else {
                    setDataReady(true);
                    formActions.setIsReady();
                }
            }
        }, [formDescr, dataLoaded, formActions, formActions?.editMode]);

        const renderLoading = useCallback(() => {
            if (error) return <Empty message={error} />;

            return <Loading delay />;
        }, [error, isReady, formDescr]);

        const renderControl = useCallback(() => {
            if (withGloss)
                return (
                    <Grid container sx={{ height: '100%' }}>
                        <Grid item xs={9} sx={{ height: '100%' }}>
                            <ControlTree
                                key={formGuid}
                                formDescr={formDescr}
                                propContainer={propContainerRef.current}
                            />
                        </Grid>
                        <Grid item xs={3} sx={{ height: '100%', position: 'relative' }}>
                            <Glossary
                                formDescr={formDescr}
                                propContainer={propContainerRef.current}
                            />
                        </Grid>
                    </Grid>
                );

            return (
                <ControlTree
                    key={formGuid}
                    formDescr={formDescr}
                    propContainer={propContainerRef.current}
                />
            );
        }, [withGloss, formGuid, formDescr, propContainerRef]);

        return (
            <StyledEngineProvider injectFirst>
                {dataReady && process.env.REACT_APP_FORM_DEV_MODE && (
                    <FormHelper formDescr={formDescr} />
                )}
                {dataReady ? (
                    <>
                        {renderControl()}
                        {
                            // отображение скриптовых форм
                            Object.keys(propContainerRef.current?.scripts).map(scriptName => (
                                <Output
                                    key={scriptName}
                                    scriptButtonStore={propContainerRef.current.scripts[scriptName]}
                                    propContainer={propContainerRef.current}
                                />
                            ))
                        }
                    </>
                ) : (
                    renderLoading()
                )}
            </StyledEngineProvider>
        );
    }
);

export default CustomForm;
