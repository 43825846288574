import { action, makeObservable, observable } from 'mobx';

import CustomDataset from 'dataObj/customDataset';
import PropContainer from 'dataObj/PropContainer';

import { EditModeType } from 'forms/interfaces';

import NotificationStore from 'store/notificationStore';
import ConfigurationStore from 'store/configurationStore';

import { getResourceGuid } from 'utils';

import { add, del, edit, view } from './actions';

export default class DatasetActions {
    private defaultEditor: { guid: string; propContainer?: PropContainer };

    public dataset;
    public type = 'dataset';

    @observable editor: {
        guid: string;
        extParamVals?: any;
        datasets?: any;
        propContainer?: PropContainer;
    } | null = null;

    @observable creator: {
        guid: string;
        extParamVals?: any;
        propContainer?: PropContainer;
    } | null = null;

    @observable editAction: EditModeType = null;

    constructor(dataset: CustomDataset) {
        this.dataset = dataset;
        this.defaultEditor = { guid: getResourceGuid(dataset.descr.editForm) };

        makeObservable(this);
    }

    private calcOperationFormula = async (formulaName: string) => {
        let allowed = true;
        const code = `ds.${this.dataset.name}.${formulaName}`;
        const errAccum = { err: [], warn: [] };
        const payload =
            await this.dataset.dataStock.ownerPropContainer.formulaCalculator.calcFormulaByCode(
                code,
                errAccum
            );

        if (errAccum.err.length) {
            allowed = false;
            NotificationStore.showAlert(errAccum.err.join('\n'));
        }
        return { allowed, payload };
    };

    @action setDefaultEditor = (
        editForm: { guid: string; name: string; type: string },
        propContainer?: PropContainer
    ) => {
        this.defaultEditor = { guid: getResourceGuid(editForm), propContainer };
    };

    /**
     * Добавление записи в датасет
     *
     * @param propContainer - propContainer формы, инициировавшей действие
     */
    @action addRecord = async (propContainer?: PropContainer) => {
        if (this.dataset.checkMasterDSSaved()) {
            const res = await this.calcOperationFormula('appendFormula');
            if (res.allowed) {
                const extParamVals = res.payload;

                this.editAction = res.payload?.__ACTION || 'create';
                if (res.payload?.__CREATOR) {
                    this.setCreator(true, res.payload.__CREATOR, extParamVals, propContainer);
                } else {
                    this.setEditor(
                        true,
                        res.payload?.__EDITOR || this.defaultEditor?.guid,
                        extParamVals,
                        undefined,
                        propContainer
                    );
                }
            }
        } else {
            NotificationStore.showAlert(ConfigurationStore.content.controls.data.saveMasterFirst);
        }
    };

    /**
     * Просмотр текущей записи
     *
     * @param propContainer - propContainer формы, инициировавшей действие
     */
    @action viewRecord = async (propContainer?: PropContainer) => {
        await this.editRecord(propContainer, 'view');
    };

    /**
     * Редактирование текущей записи
     *
     * @param [propContainer] - propContainer формы, инициировавшей действие
     * @param [editAction] - параметр, определяющий режим редактирования или просмотра
     */
    @action editRecord = async (
        propContainer?: PropContainer,
        editAction: EditModeType = 'edit'
    ) => {
        if (this.dataset.activeRec) {
            const res = await this.calcOperationFormula('editFormula');
            if (res.allowed) {
                const extParamVals =
                    res.payload && typeof res.payload === 'object' ? res.payload : {};

                extParamVals[this.dataset.keyField] = this.dataset.activeRec[this.dataset.keyField];

                this.editAction = editAction;
                this.setEditor(
                    true,
                    res.payload?.__EDITOR || this.defaultEditor?.guid,
                    extParamVals,
                    undefined,
                    propContainer
                );
            }
        }
    };

    @action deleteRecord = (propContainer?: PropContainer) => async (result: boolean) => {
        if (result) {
            this.editAction = 'delete';
            await this.dataset.delete();

            if (!this.dataset.masterDS) {
                ConfigurationStore.setBackdrop(true);
                await this.dataset.dataStock.saveData();
                ConfigurationStore.setBackdrop(false);
                this.dataset.loadData({});
            }
        }
    };

    @action setEditAction = (mode: EditModeType) => {
        this.editAction = mode;
    };

    @action setEditor = (
        show: boolean,
        guid?: string,
        extParamVals?: any,
        datasets?: any,
        parentPropContainer?: PropContainer
    ) => {
        if (show) {
            this.editor = {
                guid: guid || this.defaultEditor?.guid || '',
                extParamVals: extParamVals || this.editor?.extParamVals,
                datasets,
                propContainer: parentPropContainer
            };
        } else this.editor = null;
    };

    @action setCreator = (
        show: boolean,
        guid?: string,
        extParamVals?: any,
        parentPropContainer?: PropContainer
    ) => {
        if (show) {
            this.creator = guid
                ? {
                      guid,
                      extParamVals,
                      propContainer: parentPropContainer
                  }
                : null;
        } else this.creator = null;
    };

    @action setEditorExtParamVals = (vals: any, assign = false) => {
        const newVals = assign ? { ...(this.editor?.extParamVals || {}), ...vals } : vals;

        if (this.editor) this.editor.extParamVals = newVals;
    };

    getAction = (type: string) => {
        switch (type) {
            case add:
                return this.addRecord;

            case view:
                return this.viewRecord;

            case edit:
                return this.editRecord;

            case del:
                return this.deleteRecord;

            default:
                return null;
        }
    };
}
