import React, { FunctionComponent, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { ButtonGroup } from '@mui/material';
import MUIButton from '@mui/material/Button';
import { ArrowDropDown } from '@mui/icons-material';

import { ButtonType } from '../interfaces';
import NestedMenu from './NestedMenu';

const MenuButton: FunctionComponent<ButtonType> = observer(
    ({ children, descr, propContainer, style, icon }) => {
        const { variant, items } = descr;

        const anchorRef = useRef<HTMLDivElement>(null);

        const [open, setOpen] = useState(false);

        const handleToggle = () => {
            setOpen(prevOpen => !prevOpen);
        };

        const handleClose = (event: Event) => {
            if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
                return;
            }

            setOpen(false);
        };

        return (
            <>
                <ButtonGroup
                    variant={variant || 'contained'}
                    ref={anchorRef}
                    aria-label="split button"
                    sx={{
                        height: '100%',
                        width: '100%',
                        ...style
                    }}
                >
                    {children}
                    <MUIButton
                        size="small"
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={handleToggle}
                        sx={{ whiteSpace: 'nowrap' }}
                    >
                        <ArrowDropDown />
                    </MUIButton>
                </ButtonGroup>
                {!!items?.length && (
                    <NestedMenu
                        open={open}
                        items={items}
                        anchorRef={anchorRef}
                        handleClose={handleClose}
                        parentClose={handleToggle}
                        propContainer={propContainer}
                        menuPlacement="bottom-start"
                    />
                )}
            </>
        );
    }
);

export default MenuButton;
