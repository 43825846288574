import React, { FunctionComponent, Children, useMemo } from 'react';
import { observer } from 'mobx-react';

import MUIToolbar from '@mui/material/Toolbar';
import { Typography, Stack } from '@mui/material';

import CustomDataset from 'dataObj/customDataset';

import { ControlPropsType, ToolButtonType } from 'forms/interfaces';

import { toolbarStyles } from './styles';

import Buttons from './Buttons';
import { CustomToolbarButtonType } from './interface';
import CustomButtons from './CustomButtons';

interface PropsType extends ControlPropsType {
    containerWidth: number;
    caption?: string;
    buttons?: ToolButtonType[];
    customButtons?: CustomToolbarButtonType[];
    breakpoint?: number;
    dataset?: CustomDataset;
}

const Toolbar: FunctionComponent<PropsType> = observer(
    ({
        caption,
        buttons,
        customButtons,
        containerWidth,
        breakpoint,
        dataset,
        children,
        propContainer
    }) => {
        const breakpointWidth = breakpoint || 600;

        const styles = toolbarStyles({ containerWidth, breakpointWidth });

        const checkChildren = useMemo(() => {
            if (children) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                return Children.map(children, child => !!child).reduce(
                    (acc, child) => acc || !!child
                );
            }

            return false;
        }, [children]);

        return (
            <>
                {caption ? (
                    <MUIToolbar sx={styles.toolbar} id="caption-toolbar" style={{ minHeight: 50 }}>
                        <Typography variant="h6" className="title" component="div">
                            {caption}
                        </Typography>
                    </MUIToolbar>
                ) : null}
                {buttons?.length || customButtons?.length || checkChildren ? (
                    <MUIToolbar id="controls-toolbar" sx={styles.toolbar}>
                        <Stack
                            spacing={1}
                            direction="row"
                            justifyContent="space-between"
                            style={{ width: '100%' }}
                        >
                            {!buttons?.length && !!customButtons?.length && (
                                <CustomButtons
                                    buttons={customButtons}
                                    propContainer={propContainer}
                                    containerWidth={containerWidth}
                                />
                            )}
                            {!!buttons?.length && (
                                <Buttons
                                    buttons={buttons}
                                    propContainer={propContainer}
                                    containerWidth={containerWidth}
                                    dataset={dataset}
                                />
                            )}
                            {children}
                        </Stack>
                    </MUIToolbar>
                ) : null}
            </>
        );
    }
);

export default Toolbar;
