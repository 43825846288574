import React, { useState, useEffect, FunctionComponent, useMemo } from 'react';
import { observer } from 'mobx-react';

import { Box } from '@mui/material';

import useDBEdit from 'hooks/dbEdit';
import useLookupData from 'hooks/lookupData';
import { getLocalizedString } from 'utils';

import ComboBox from 'components/Inputs/ComboBox';

import { ControlPropsType, DBComboBoxType } from 'forms/interfaces';

interface PropsType extends ControlPropsType {
    descr: DBComboBoxType;
    dataRow: any;
    index?: number;
}

const DBComboBox: FunctionComponent<PropsType> = observer(
    ({ descr, propContainer, dataRow, index }) => {
        const {
            dataset,
            fieldName,
            dataVal,
            dataType,
            fieldRequired,
            ctrlEnabled,
            ctrlVisible,
            editRef,
            onScreen
        } = useDBEdit(descr, propContainer, dataRow);
        const lookupData = useLookupData(descr, dataset);

        const [val, setVal] = useState<string | string[] | null>(() => dataVal);

        const label = useMemo(() => getLocalizedString(descr.label), [descr.label]);

        useEffect(() => {
            if (dataset) setVal(dataVal);
        }, [dataset, dataVal]);

        useEffect(() => {
            propContainer.setPageRequirement(descr.name, fieldRequired && ctrlEnabled && !val);
        }, [val, fieldRequired, ctrlEnabled]);

        const handleOnChange = (event: any) => {
            if (dataset?.inEdit) {
                const {
                    target: { value }
                } = event;

                setVal(value);
                dataset.setFieldValue(
                    fieldName,
                    dataType === 'KRN_INTEGER' ? Number(value) : value,
                    index
                );
            }
        };

        const handleOnConfirm = (event: { target: { name?: string; value?: string[] } }) => {
            const {
                target: { value }
            } = event;

            if (dataset?.inEdit && dataType === 'KRN_ARRAY') {
                dataset.setFieldValue(fieldName, value, index);
            }
        };

        return (
            <Box
                ref={editRef}
                sx={{ width: '100%', p: 2, boxSizing: 'border-box' }}
                className={fieldRequired && onScreen && ctrlEnabled ? 'required-field' : ''}
            >
                <ComboBox
                    style={{
                        width: '100%',
                        boxSizing: 'border-box',
                        visibility: ctrlVisible ? 'inherit' : 'hidden'
                    }}
                    size="small"
                    store={lookupData}
                    label={label}
                    nameField={descr.nameField}
                    valueField={descr.valueField}
                    variant="outlined"
                    disabled={!ctrlEnabled}
                    onChange={handleOnChange}
                    onConfirm={handleOnConfirm}
                    value={val !== null ? val : ''}
                    clearable={descr.clearable}
                    editable={descr.editable}
                    multiselect={descr.multiselect}
                    required={fieldRequired}
                />
            </Box>
        );
    }
);

export default DBComboBox;
