import { MutableRefObject, useEffect } from 'react';

import EventsStore from 'store/eventsStore';
import { onShow, onHide, onScroll } from 'store/eventsStore/events';

import { EventHandlerType } from 'forms/interfaces';
import PropContainer from 'dataObj/PropContainer';

import useOnScreen from './onScreen';

export default function useEvent(
    ref: MutableRefObject<any>,
    descr: EventHandlerType,
    propContainer: PropContainer
) {
    const onScreen = useOnScreen(ref);

    const handleOnScroll = () => {
        EventsStore.getEvents(descr.guid)
            ?.triggerEvent(onScroll)
            .catch(err => console.error(err.message));
    };

    useEffect(() => {
        EventsStore.addEvents(descr, descr.events || [], propContainer);
    }, []);

    useEffect(() => {
        if (onScreen) {
            EventsStore.getEvents(descr.guid)
                ?.triggerEvent(onShow)
                .catch(err => console.error(err.message));
        } else {
            EventsStore.getEvents(descr.guid)
                ?.triggerEvent(onHide)
                .catch(err => console.error(err.message));
        }
    }, [onScreen]);

    return {
        onScroll: handleOnScroll
    };
}
