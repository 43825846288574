import { VariantType } from 'notistack';

import DataStock from 'dataObj/DataStock';
import CustomDataset from 'dataObj/customDataset';
import PropContainer from 'dataObj/PropContainer';

import ConfigurationStore from 'store/configurationStore';
import DataStore from 'store/dataStore';
import ReportStore from 'store/reportStore';
import NotificationStore, { BackdropInstance } from 'store/notificationStore';

import { ParamType } from 'forms/interfaces';

import { jsonFetch, dateToString, encryptRSA, checkGuid, goToPath } from 'utils';

interface SetModalMessageParam {
    type: string;
    text: string;
}

interface CsbTaskParams {
    wait?: number;
    type: string;
    payload: any;
}

interface CsbTaskResult {
    error?: {
        code: string;
        text: string;
    };
    result?: any;
    id?: number;
    guid?: string;
    tag?: string;
}

type DynamicFormParams = {
    type: 'glossedit';
    dateformat: string;
    emptySkip?: boolean;
};

class ScriptClient {
    private params: ParamType[];
    private propContainer: PropContainer;
    private dataStock: DataStock;
    private values: any;
    private backdrop: BackdropInstance | null;
    private context: {
        setForm: (
            guid: null | string,
            dataStock?: DataStock,
            propContainer?: PropContainer
        ) => void;
        setFormDescr: (config: any) => void;
        setCallback: (cb: any) => void;
        setValues: (values: Array<{ [key: string]: string }> | undefined) => void;
        setEditMode: (mode: string | undefined) => void;
        setModalMessage: (param: SetModalMessageParam) => void;
    };

    constructor(propContainer: PropContainer, values: any, context: any, params?: ParamType[]) {
        this.propContainer = propContainer;
        this.dataStock = propContainer.dataStock;
        this.params = params || [];
        this.values = values;
        this.backdrop = null;
        this.context = context;
    }

    #getDataSet = (dsName: string): CustomDataset => this.dataStock.getDatasetObj(dsName);

    getFieldValue = (dsName: string, fieldName: string) =>
        this.#getDataSet(dsName).getFieldValue(fieldName);

    setFieldValue = (dsName: string, fieldName: string, value: string) => {
        this.#getDataSet(dsName).setFieldValue(fieldName, value);
        this.#getDataSet(dsName).post(true);
    };

    postChanges = (dsName: string, options?: { keepInEdit: boolean }) =>
        this.#getDataSet(dsName).post(options?.keepInEdit);

    appendRecord = async (dsName: string) => {
        const ds = this.#getDataSet(dsName);

        if (ds) {
            ds.cdo && ds.currentDataChunk?.key >= 0 ? await ds.cdo.append(ds) : await ds.append();
        }
    };

    openLookup = async (lookupName: string, options?: { append?: boolean }) => {
        const lookup = this.propContainer.lookup.getLookup(lookupName);
        const ds = this.#getDataSet(lookup?.descr.datasetName);

        if (options?.append) {
            ds.cdo ? await ds.cdo.append(ds) : await ds.append();
        } else ds.edit();

        return new Promise(resolve => {
            lookup?.actions.setOpen(true, () => (state: boolean) => {
                ds.post(true);
                resolve(state);
            });
        });
    };

    getLookupData = (lookupName: string, recordNumber = 0) =>
        this.propContainer.lookup.getData(lookupName, recordNumber);

    getLookupDataArray = (lookupName: string) => this.propContainer.lookup.getData(lookupName);

    selectRow = (dsName: string, value: string) => this.#getDataSet(dsName).findById(value);

    getAF = (key: string) => DataStore.AF.getAF(key);

    setAF = (key: string, value: string) => DataStore.AF.setAF(key, value);

    refreshDataset = async (dsName: string) => {
        const ds = this.#getDataSet(dsName);

        const keepPosition = { info: { keepPosition: true } };
        ds.requestParams = { ...ds.requestParams, ...keepPosition };

        return ds.loadData();
    };

    setData(dsName: string, data: object[]) {
        const dsObj = this.#getDataSet(dsName);
        if (!dsObj) {
            throw new Error(`не найден датасет ${dsName}`);
        }

        dsObj.setData(data);
    }

    getRecCount = (dsName: string) => this.#getDataSet(dsName).recCount;

    checkData = async () => {
        const errAccum = { err: [], warn: [] };

        await this.dataStock.checkData(this.propContainer, errAccum);

        if (errAccum.err.length || errAccum.warn.length) {
            NotificationStore.showAlert(`${errAccum.err.join('\n')}\n${errAccum.warn.join('\n')}`);

            return false;
        }

        return true;
    };

    /** Методы для получения параметров обьектов */
    getParam(name: string) {
        return (this.params || []).find((p: any) => p.name === name);
    }

    getParamValues() {
        return this.params?.length ? this.dataStock.getParams(this.params) : null;
    }

    /**
     * Получить значение параметра
     * @param name
     * @returns {any}
     */
    getParamValue(name: string) {
        const paramValues = this.getParamValues();
        return paramValues ? paramValues[name] : null;
    }

    // Работать умею только с константой
    setParamValue(name: string, value: any) {
        const param = this.getParam(name);
        if (param && param.paramType === 'const') {
            param.value = value;
        }
    }

    getValues() {
        return this.values;
    }

    showFormByGuid(
        guid: string,
        values?: Array<{ [key: string]: string }>,
        editMode?: string,
        isSubForm = false
    ) {
        const setForm = (
            formGuid: string,
            formValues: Array<{ [key: string]: string }> | undefined,
            formEditMode?: string,
            callback?: any
        ) => {
            this.context.setForm(
                formGuid,
                isSubForm ? this.dataStock : undefined,
                isSubForm ? this.propContainer : undefined
            );
            callback && this.context.setCallback(() => callback);
            values && this.context.setValues(formValues);
            editMode && this.context.setEditMode(formEditMode);
        };

        return new Promise(resolve => {
            setForm(guid, values, editMode, (state: boolean) => {
                resolve(state);
            });
        });
    }

    async showSubFormByGuid(
        guid: string,
        values?: Array<{ [key: string]: string }>,
        editMode?: string
    ) {
        return this.showFormByGuid(guid, values, editMode, true);
    }

    #getFormGuidByName(name: string): string {
        if (checkGuid(name)) return name;

        let guid: string | undefined = this.dataStock?.formDescr?.resourceLinks?.filter(
            (resource: any) => resource?.name === name
        )[0]?.resource?.guid;

        if (guid) return guid;

        guid = this.dataStock?.formDescr?.subForms?.filter(
            (subFormDescr: any) => subFormDescr.name === name
        )[0]?.guid;

        return guid || '';
    }

    async showForm(name: any | string, values?: Array<{ [key: string]: string }>) {
        if (typeof name !== 'string') return this.context.setFormDescr(name);

        return this.showFormByGuid(this.#getFormGuidByName(name), values);
    }

    async showSubForm(name: string, values?: Array<{ [key: string]: string }>) {
        return this.showFormByGuid(this.#getFormGuidByName(name), values, undefined, true);
    }

    async modalMessage(type: string, text: string) {
        return this.context.setModalMessage({ type, text });
    }

    async showEditor(name: string, values?: Array<{ [key: string]: string }>) {
        return this.showFormByGuid(this.#getFormGuidByName(name), values, 'edit');
    }

    showApiErrors() {
        NotificationStore.showApiErrors();
    }

    /**
     * пересчет формул свойств контролов
     * вызываем обработчик onFormShow(),
     * т.к. он обновляет все свойства контролов
     */
    async recalcFormulas() {
        await this.propContainer.formulaCalculator.onFormShow();
    }

    /**
     * Шифровать данные
     * @param {string} data Данные для шифрования
     * @param {string} key Открытый ключ
     * @returns {Promise<string>} base64 шифрованная строка
     */
    async encodeData(data: string, key: string) {
        return encryptRSA(key, data);
    }

    /**
     * Вывод сообщения
     * @param message
     * @param variant
     */
    notification(message: string, variant: VariantType = 'success') {
        NotificationStore.enqueueSnackbar({ message, options: { variant } });
    }

    /**
     * Открыть динамическую форму модально и дождаться результат
     * @param {any} descr Описатель формы
     * @param {DynamicFormParams} params
     * @returns {Promise<any>}
     */
    async executeDynamicForm(descr: any, params: DynamicFormParams) {
        const getValues = (dataStock: DataStock) => {
            if (dataStock) {
                const { extraData } = dataStock.formDescr;
                const ds = dataStock.getDatasetObj('Cache');
                const extraDataObj =
                    extraData && typeof extraData === 'string' ? JSON.parse(extraData) : {};

                const { glossConfig } = extraDataObj;

                return (glossConfig || [])
                    .map((it: any) => {
                        const field = ds.getFieldDescr(it.fieldName);
                        const value = ds.getFieldValue(it.fieldName);

                        let str = '';
                        if (value) {
                            str = ['KRN_DATE', 'KRN_DATETIME'].includes(field.dataType)
                                ? dateToString(value, params.dateformat || 'DD.MM.YYYY')
                                : value;
                        }

                        return params.emptySkip && str === ''
                            ? ''
                            : `${it.before as string}${str}${it.after as string}`;
                    })
                    .join('');
            }

            return null;
        };

        const setForm = (formDescr: object, editMode: string, callback?: any) => {
            this.context.setFormDescr(formDescr);
            editMode && this.context.setEditMode(editMode);
            callback && this.context.setCallback(() => callback);
        };

        return new Promise(resolve =>
            setForm(descr, 'edit', (state: any, dataStock: any) => {
                if (params.type === 'glossedit') {
                    resolve(state ? getValues(dataStock) : state);
                } else {
                    resolve(state);
                }
            })
        );
    }

    getExtParam(name: string) {
        const extParamVals = this.dataStock?.extParamVals as any;
        return extParamVals ? extParamVals[name] : null;
    }

    async logout() {
        return ConfigurationStore.serverLogout();
    }

    lockScreen(timeout?: number) {
        if (this.backdrop) {
            this.backdrop.updateBackdrop(timeout);
        } else {
            this.backdrop = new BackdropInstance(timeout);
        }
    }

    unlockScreen() {
        this.backdrop?.clearBackdrop();
    }

    async wait(ms = 500) {
        this.lockScreen();
        return new Promise<void>(resolve => {
            setTimeout(() => {
                this.unlockScreen();
                resolve();
            }, ms);
        });
    }

    async executeCsbTask(
        recType: string,
        dataParams: CsbTaskParams,
        wait: number
    ): Promise<CsbTaskResult> {
        this.lockScreen(wait * 1000);

        const body = {
            taskType: 'csb',
            taskParams: { recType, wait },
            dataParams
        };

        try {
            const result = await jsonFetch(`task/csb`, 'POST', body, {}, 'json');
            return result as any;
        } catch (e: any) {
            return {
                error: {
                    code: '501',
                    text: e.message as string
                }
            };
        } finally {
            this.unlockScreen();
        }
    }

    /**
     * Выполнить серверный скрипт
     * @param ownerGuid Владелец скрипта (cdo)
     * @param actionName Имя или guid скрипта
     * @param params Набор параметров
     */
    async executeServerScript(ownerGuid: string, actionName?: string, parObj?: object) {
        const body = {
            guid: ownerGuid,
            actionName,
            // scriptGuid,
            parObj
        };
        return jsonFetch('dataobject/execute', 'POST', body, {}, 'json');
    }

    /**
     * Выполнить BP
     * @param guid Владелец скрипта (cdo/form/package)
     * @param bptype_id
     * @param name Имя скрипта
     * @param parObj Набор параметров
     */
    async executeBusinessProcess(guid: string, bptype_id?: number, name?: string, parObj?: object) {
        const body = {
            guid,
            name,
            bptype_id,
            parObj
        };
        return jsonFetch('dataobject/businessprocess', 'POST', body, {}, 'json');
    }

    /**
     * Выполнить серверный скрипт формы
     * @param ownerGuid Владелец скрипта (форма)
     * @param actionName Имя или guid скрипта
     * @param params Набор параметров
     */
    async executeFormScript(scriptName?: string, parObj?: object) {
        const body = {
            formGuid: this.dataStock?.formDescr?.guid,
            scriptName,
            // scriptGuid,
            parObj
        };
        return jsonFetch('script/calc', 'POST', body, {}, 'json');
    }

    async runReport(reportName: string) {
        return ReportStore.runReport(reportName, this.getParamValues());
    }

    getReportResult(reportName: string, index?: number) {
        return ReportStore.getReportResult(reportName, index);
    }

    getReportResultList(reportName: string) {
        return ReportStore.getReportResultList(reportName) || [];
    }

    saveReport(reportName: string, index?: number) {
        ReportStore.saveReport(reportName, index);
    }

    goToPath(path: string) {
        goToPath(path);
    }
}

const executeScript = async (
    propContainer: PropContainer,
    actionName: string,
    senderParams?: ParamType[],
    values?: any,
    context?: any,
    event?: { [key: string]: any }
) => {
    if (!actionName) {
        return;
    }

    try {
        const objScript = propContainer.find(actionName, 'script');
        if (objScript?.func) {
            return objScript.func(
                new ScriptClient(propContainer, values, context, senderParams),
                event
            );
        }
    } catch (err: any) {
        console.error(err.message);
        alert(`Ошибка выполнения скрипта: ${err.message as string}`);
    }
};

export default executeScript;
