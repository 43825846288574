import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import {
    AppBar,
    TextField,
    Box,
    Typography,
    Container,
    CssBaseline,
    Toolbar,
    SelectChangeEvent
} from '@mui/material';
import Button from '@mui/lab/LoadingButton';

import LoginIcon from '@mui/icons-material/Login';

import ConfigurationStore from 'store/configurationStore';
import LocaleSwitcher from 'components/utils/Locale/LocaleSwitcher';
import ComboBoxContainer from 'components/Inputs/ComboBox/index';
import NotificationStore from 'store/notificationStore';

const IGNORED_ERROR_MESSAGE = 'jwt expired';

const getApiVersion = () => {
    const apiVersion = localStorage.getItem('apiVersion');
    if (apiVersion) return ` / ${apiVersion}`;

    return '';
};

const AuthPage = observer(() => {
    const { authorize, content, UserPosts, error, loading, locale } = ConfigurationStore;
    const { showAlert } = NotificationStore;

    const [form, setForm] = useState({
        login: '',
        password: '',
        bindingId: undefined,
        lang: locale
    });

    const inputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.value });
    };

    const selectChangeHandler = (event: SelectChangeEvent) => {
        setForm({ ...form, [event.target.name]: event.target.value });
    };

    const loginHandler = (event: React.SyntheticEvent) => {
        event.preventDefault();
        authorize('auth/login', 'POST', form).catch((err: Error) => {
            showAlert(err.message);
        });
    };

    // Реакция на смену локали
    useEffect(() => {
        setForm({ ...form, lang: locale });
    }, [locale]);

    useEffect(() => {
        error && !error.includes(IGNORED_ERROR_MESSAGE) && showAlert(error);
    }, [error]);

    return (
        <Container component="div" maxWidth="xs" style={{ height: '100vh', display: 'flex' }}>
            <Container component="main" maxWidth="xs" style={{ margin: 'auto' }}>
                <CssBaseline />
                <Box
                    style={{
                        margin: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <Typography component="h1" variant="h5">
                        {content.application.auth.signIn}
                    </Typography>
                    <Box
                        component="form"
                        noValidate
                        onSubmit={(event: React.SyntheticEvent) => loginHandler(event)}
                        width="100%"
                        sx={{ mt: 1 }}
                    >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="login"
                            label={content.application.auth.username}
                            name="login"
                            autoComplete="login"
                            autoFocus
                            size="small"
                            onChange={inputChangeHandler}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={content.application.auth.password}
                            type="password"
                            id="password"
                            size="small"
                            autoComplete="current-password"
                            onChange={inputChangeHandler}
                        />
                        {UserPosts ? (
                            <Box sx={{ mt: 4, mb: 2 }}>
                                <ComboBoxContainer
                                    clearable
                                    name="bindingId" // Наименование должности
                                    label={content.application.auth.post}
                                    id="filial"
                                    store={UserPosts}
                                    nameField="label"
                                    valueField="bindingId"
                                    onChange={selectChangeHandler}
                                    value={form.bindingId}
                                />
                            </Box>
                        ) : null}
                        <Button
                            id="submit-btn"
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 4, mb: 2 }}
                            loadingPosition="start"
                            startIcon={<LoginIcon />}
                            loading={loading}
                        >
                            {content.application.auth.enter}
                        </Button>
                    </Box>
                </Box>
            </Container>
            <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
                <Toolbar>
                    <Typography color="white" variant="caption">{`version: ${
                        process.env.REACT_APP_VERSION as string
                    }${getApiVersion()}`}</Typography>
                    <LocaleSwitcher color="#fff" />
                </Toolbar>
            </AppBar>
        </Container>
    );
});

export default AuthPage;
