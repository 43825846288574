import React, { FunctionComponent, useState } from 'react';

import { Typography } from '@mui/material';
import styled from '@mui/material/styles/styled';

import Loading from 'components/utils/Loading/Loading';

const Img = styled('img')({
    '@keyframes materialize': {
        '0%': {
            filter: 'saturate(20%) contrast(50%) brightness(120%)'
        },
        '75%': {
            filter: 'saturate(60%) contrast(100%) brightness(100%)'
        },
        '100%': {
            filter: 'saturate(100%) contrast(100%) brightness(100%)'
        }
    }
});

interface PropTypes {
    src: string;
    alt?: string;
    title?: boolean;
    height?: number | string;
    width?: number | string;
    style?: { [key: string]: string };
    className?: string;
    showLoading?: any;
    errorIcon?: any;
    shift?: false | null | 'top' | 'bottom' | 'left' | 'right';
    distance?: number | string;
    shiftDuration?: number;
    bgColor?: string;
    wrapperStyle?: { [key: string]: string };
    iconWrapperStyle?: { [key: string]: string };
    wrapperClassName?: string;
    iconWrapperClassName?: string;
    duration?: number;
    easing?: string;
    onLoad?: any;
    onError?: any;
    position?:
        | 'static'
        | 'relative'
        | 'absolute'
        | 'fixed'
        | 'sticky'
        | 'inherit'
        | 'initial'
        | 'revert'
        | 'unset';
    fit?:
        | 'contain'
        | 'cover'
        | 'fill'
        | 'none'
        | 'scale-down'
        | 'inherit'
        | 'initial'
        | 'revert'
        | 'unset';
}

const Image: FunctionComponent<PropTypes> = props => {
    const {
        src,
        alt = '',
        title = false,
        height = '100%',
        width = '100%',
        position = 'relative',
        fit = 'scale-down',
        style,
        className = '',
        showLoading = true,
        errorIcon = true,
        shift = false,
        distance = 100,
        shiftDuration = null,
        bgColor = 'inherit',
        wrapperStyle,
        iconWrapperStyle,
        wrapperClassName = '',
        iconWrapperClassName = '',
        duration = 500,
        easing = 'cubic-bezier(0.7, 0, 0.6, 1)',
        onLoad: onLoadProp,
        onError: onErrorProp,
        ...rest
    } = props;

    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);

    const handleLoad = () => {
        setLoaded(true);
        setError(false);
        if (onLoadProp) onLoadProp();
    };

    const handleError = () => {
        setError(true);
        setLoaded(false);
        if (onErrorProp) onErrorProp();
    };

    const shiftStyles = {
        [shift as any]: loaded ? 0 : distance
    };

    const styles = {
        root: {
            width,
            height,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            overflow: 'hidden',
            backgroundColor: bgColor,
            ...wrapperStyle
        },
        image: {
            position,
            width: '100%',
            height: '100%',
            objectFit: fit,
            transitionProperty: `${shift ? `${shift}, ` : ''}opacity`,
            transitionDuration: `${shift ? `${shiftDuration || duration * 0.3}ms, ` : ''}${
                duration / 2
            }ms`,
            transitionTimingFunction: easing,
            opacity: loaded ? 1 : 0,
            animation: loaded ? `materialize ${duration}ms 1 ${easing}` : '',
            ...(Boolean(shift) && shiftStyles),
            ...style
        },
        icons: {
            width: '100%',
            marginLeft: '-100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: loaded ? 0 : 1,
            ...iconWrapperStyle
        }
    } as const;

    return (
        <div style={styles.root} className={`mui-image-wrapper ${wrapperClassName}`}>
            <Img
                src={src}
                alt={alt}
                style={styles.image}
                onLoad={handleLoad}
                onError={handleError}
                {...rest}
            />
            {(Boolean(showLoading) || Boolean(error)) && (
                <div
                    style={styles.icons}
                    className={`mui-image-iconWrapper ${iconWrapperClassName}`}
                >
                    {error && <Loading type="broken" />}
                    {Boolean(showLoading) && !error && !loaded && <Loading delay />}
                </div>
            )}
            {title && alt && (
                <div>
                    <Typography variant="caption">{alt}</Typography>
                </div>
            )}
        </div>
    );
};

export default Image;
