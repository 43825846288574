import { useEffect, useState } from 'react';
import { ComponentType } from '../forms/interfaces';
import CustomDataset from '../dataObj/customDataset';

export default function useLookupData(descr: any, dataset: CustomDataset) {
    const [lookupData, setLookupData] = useState([]);

    useEffect(() => {
        const datafieldDescr = (dataset?.descr?.fields || []).find(
            (fld: ComponentType) => fld.name === descr.fieldName
        );
        let list = [];
        try {
            if (datafieldDescr) {
                list =
                    typeof datafieldDescr.lookupData === 'string'
                        ? JSON.parse(datafieldDescr.lookupData)
                        : datafieldDescr.lookupData;
            }
        } catch (err) {
            console.log(`Can\`t parse json: ${datafieldDescr?.lookupData ?? 'undefined'}`);
        }

        setLookupData(list);
    }, [descr, dataset]);

    return lookupData;
}
