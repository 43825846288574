import { useMemo } from 'react';

import { v4 as uuidv4 } from 'uuid';

import {
    FilterControlType,
    DBComboBoxType,
    MedialogDataType,
    ControlType,
    ResourceInfo,
    DBEditType,
    LabelType,
    DBLookupType
} from '../../interfaces';
import { DrawResourceType } from './interfaces';
import clientResValidator from '../../../components/resources/clientResValidator';
import { FilterValue, unarConditions } from '../../../dataObj/CustomFilter';

const ParamControl: Record<string, string | null> = {
    KRN_INTEGER: 'dbNumEdit',
    KRN_AUTOINC: 'dbNumEdit',
    KRN_STRING: 'dbTextEdit',
    KRN_DATETIME: 'dbDateTime',
    KRN_DATE: 'dbDate',
    KRN_TIME: 'dbDateTime',
    KRN_MONEY: null,
    KRN_NUMERIC: 'dbNumEdit',
    KRN_MEMO: 'dbMemoEdit',
    KRN_BLOB: null,
    KRN_LOGICAL: 'dbCheckBox',
    KRN_GRAPHIC: null,
    KRN_COURS: null,
    KRN_TAUX: null,
    KRN_STRING_INTERNATIONAL: 'dbTextEdit',
    KRN_DURATION_MINUTES: null,
    KRN_BIT: null,
    KRN_MEMO_INTERNATIONAL: 'dbMemoEdit',
    KRN_RTF: null,
    KRN_JSON: null,
    KRN_ARRAY: null
};

function buildComponentRes(
    descr: FilterControlType,
    dataType: MedialogDataType,
    fValue: FilterValue,
    fakeGuid: string,
    fieldName: string
): ControlType | null {
    let type;
    const controlDescr = { ...descr } as ResourceInfo;

    (controlDescr as DBEditType).fieldName = fieldName;
    if (unarConditions.includes(fValue.condition)) {
        type = 'label'; // Унарные операторы
        // Скрываем вывод текста в гриде, показываем только label
        // (controlDescr as LabelType).text = (controlDescr as DBEditType).label;
    } else if (descr.type === 'filterLookup') {
        type = 'dbLookup';
        const dbLookup = controlDescr as DBLookupType;
        // Лукап идет с условием in, not in
        dbLookup.flMultiSelect = true;
    } else if (descr.inputType === 'deflist') {
        const combo = controlDescr as DBComboBoxType;
        combo.nameField = combo.nameField || 'val';
        combo.valueField = 'val';
        combo.multiselect = true;
        type = 'dbComboBox';
    } else if (dataType === MedialogDataType.logical) {
        type = 'label'; // Компонент не нужен, управляем через condition
        (controlDescr as LabelType).text = (controlDescr as DBEditType).label;
    } else {
        type = ParamControl[dataType];
    }

    if (!type) {
        return null;
    }

    controlDescr.type = type;
    controlDescr.guid = fakeGuid;
    // Выводим label в FilterControl как элемент грида
    (controlDescr as DBEditType).label = '';

    return clientResValidator.validate(controlDescr);
}

type FilterItemPropTypes = {
    drawResource: DrawResourceType;
    dataType: MedialogDataType;
    fValue: FilterValue;
    fieldName: string;
};

export default function FilterItem({
    drawResource,
    dataType,
    fValue,
    fieldName
}: FilterItemPropTypes) {
    const { drawComponent, setActiveCtrlDescr, descr } = drawResource;
    const fakeGuid = useMemo(() => uuidv4(), []);
    const descrEditor = buildComponentRes(
        descr as FilterControlType,
        dataType,
        fValue,
        fakeGuid,
        fieldName
    );
    return descrEditor ? drawComponent(descrEditor, null, setActiveCtrlDescr) : null;
}
