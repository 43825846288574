import { ReactElement } from 'react';
import PropContainer from 'dataObj/PropContainer';
import { CDODataStruct } from 'dataObj/dataInterfaces';
import DataStock from 'dataObj/DataStock';

/**
 * Типы для ControlTree, вынесено для избежания цикических ссылок
 */
export type SetActiveCtrlDescrType = () => null;
export type DrawComponentType = (
    descr: any,
    dataRow: any,
    setActiveCtrlDescr?: SetActiveCtrlDescrType
) => ReactElement | null;

type TypographyVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2';

export interface SimpleObject {
    [key: string]: any;
}

/**
 * Типы для контролов
 */

// Общий тип для контролов
export interface ControlPropsType {
    propContainer: PropContainer;
}

export interface DBEditPropsType extends ControlPropsType {
    required?: boolean;
}

/**
 * Типы для ресурсов
 */

// Общее представление ресурса
export interface ResourceInfo {
    guid: string; // идентификатор
    type: string; // тип
    name: string; // имя
}

export interface ComponentType extends ResourceInfo {
    // type: string;
    name: string;
    guid: string;
    createDate: Date;
    isGenerated?: boolean;
    isModified?: boolean;
    parentForm?: FormType;
}

export interface AppComponentType extends ComponentType {
    tags: string;
    version: string;
    sourceId: string;
    updateDate: Date;
}

export interface ControlType extends ComponentType {
    classes: string;
    styles: string;
    gridWidth: string | number;
}

export interface ContainerType extends ControlType, ParamOwnerType {
    sourceNameType: 'name' | 'af' | 'guid';
    sourceName: string;
}

export interface EditorContainerType extends ControlType {
    datasetName: string;
}

export interface ControlSetType extends ComponentType {
    code: string;
    caption: string;
    rolesTypes: string;
    roles: string;
    controls: ControlType[];
}

export interface DatasetContainerType extends ComponentType {
    datasets: DatasetType[];
}

export interface DataObjectContainerType extends ComponentType {
    dataObjects: any[];
}

export type EventTriggerType = 'onNewRecord' | 'onScroll' | 'onShow';

export interface ResourceEventType extends ComponentType {
    trigger: EventTriggerType;
    script: string;
    params?: ParamType[];
}

export interface FormEventType extends ResourceEventType {
    dataset: string;
}

export interface EventHandlerType extends ControlType {
    events: ResourceEventType[];
}

interface FormExtParamType extends ResourceInfo {
    name: string;
    kind: 'property' | 'value';
    paramType: 'simple' | 'resourceGuid';
    placeholder: string;
}

export interface FormType
    extends AppComponentType,
        ControlType,
        DatasetContainerType,
        DataObjectContainerType {
    caption: string;
    lang: string;
    translation: string;
    isEditor: boolean;
    isSubForm?: boolean;
    editFormula: string;
    validateFormula: string;
    controls: ControlType[];
    controlSets: ControlSetType[];
    extraData: string;
    scripts: Array<{ guid: string; name: string; type: string }>;
    events: FormEventType[];
    resourceLinks: ResourceLinkType[];
    extParams?: FormExtParamType[];
    editDatasetName?: string;
    ownerGuid?: string;
    subForms?: FormType[];
}

export interface ResourceLinkType extends ComponentType {
    resource: ComponentType; // 'wuiForm' | 'wuiStatQuery' | 'wuiQuery' | 'table';
}

export interface ScriptType extends ComponentType {
    text: string;
}

export interface GlossEditType extends FormType {
    descriptor: string;
}

export interface ClientDatasetType extends ComponentType {
    keyField: string;
    fields: DatasetFieldType[];
    activeFields: ActiveFieldType[];
    masterDatasetName: string;
}

export interface ParamOwnerType {
    params: ParamType[];
}

export interface DatasetType extends ClientDatasetType, ParamOwnerType {
    caption: string;
    request: any;
    autoOpen: boolean;
    editForm: any;
    editable: boolean;
    pageSize: number;
    settingsName: string;
    required: boolean;
    flMainSelect?: boolean;
    flUserFilter?: boolean;
    datasets: DatasetType[];
    cacheType: 'none' | 'master' | 'cache' | 'join' | 'global';
    editType?: 'root' | 'extension' | 'detail' | 'subform' | 'none';
    options?: string;
    maxCacheSize?: number;
    saveMasterFirst: boolean;
}

export interface CDOType extends DatasetContainerType, AppComponentType {
    validateScript: string;
    options: any;
    hashable: boolean;
}

export interface ActiveFieldType extends ComponentType {
    fieldName: string;
    afName: string;
}

export interface ParamType extends ComponentType {
    paramType: string;
    paramName: string;
    sourceDataset: string;
    sourceField: string;
    sourceAF: string;
    value: string;
}

export interface DataFormulaType extends ComponentType {
    text: string;
    asDefault: boolean;
    asValue?: boolean;
}
export interface DatasetFieldType extends ComponentType {
    dataType: MedialogDataType;
    defaultFormula: string;
    valueFormula: string;
    enabledFormula: string;
    lookupData: string;
    lookupSource: string;
    caption: string;
    hidden: boolean;
    multiple: boolean;
    archive?: boolean;
    glossField?: string;
    masterField?: string;
}

export interface PanelType extends ControlType {
    caption: string;
    layout?: 'vertical' | 'horizontal';
    controls: ControlType[];
    events: ResourceEventType[];
}

export interface SplitterType extends ControlType {
    caption: string;
    split: 'vertical' | 'horizontal';
    minSize: number | number[];
    controls: ControlType[];
}

export interface PageControlType extends ControlType {
    caption: string;
    afName: string;
    pages: PageType[];
}

export interface PageType extends ControlType {
    caption: string;
    controls: ControlType[];
}

export interface ScriptButtonType extends ControlType, ParamOwnerType {
    iconSet: string;
    icon: string;
    caption: string;
    onClickScript: string;
    variant: 'contained' | 'outlined' | 'text';
    iconButton?: boolean;
    items?: ScriptButtonType[];
}

export interface TaskButtonType extends ScriptButtonType {
    taskType: 'report' | 'csb';
    onResultScript: string;
    taskParams: ParamType[];
    dataParams: ParamType[];
}

export interface ReportType extends ControlType {
    requestName: string;
    view: string;
    kind: string;
    resultFormat: 'pdf' | 'html';
    useFilter: boolean;
    waitType: 'sync' | 'async';
    waitTime: number;
    params: ParamType[];
    autoSave?: boolean;
    keepAllResults?: boolean;
}

// export interface BreakLineType extends ControlType {}

export interface LabelType extends ControlType {
    text: string;
    variant: TypographyVariant;
}

export interface IconSetType extends AppComponentType {
    items: IconType[];
}

export interface IconType extends ComponentType {
    content: string;
    fullColor?: boolean;
}

export interface DBControlType extends ControlType {
    datasetName: string;
    labelPos: 'top' | 'left' | 'right' | 'bottom';
    readonly: boolean;
    required: boolean;
    visible: boolean;
    fieldName: string;
}

export interface DBEditType extends DBControlType {
    label: string;
    fieldName: string;
    rows?: number;
}

export interface DBMultipleEditType extends DBEditType {
    ctrlType: 'dbEdit' | 'dbLookup';
}

export interface DBLabelType extends DBEditType {
    variant: TypographyVariant;
}

export interface DBTextEditType extends DBEditType {
    mask: string;
    password: boolean;
}

export interface DBImageType extends DBControlType {
    showList: boolean;
    fieldName: string;
    labelFieldName: string;
    showTitle: boolean;
    srcType?: 'fileName' | 'blob';
    position?:
        | 'static'
        | 'relative'
        | 'absolute'
        | 'fixed'
        | 'sticky'
        | 'inherit'
        | 'initial'
        | 'revert'
        | 'unset';
    fit?:
        | 'contain'
        | 'cover'
        | 'fill'
        | 'none'
        | 'scale-down'
        | 'inherit'
        | 'initial'
        | 'revert'
        | 'unset';
    controls?: DBLabelType[];
}

export interface FileUploaderType extends DBControlType {
    fieldName: string;
}

export interface DBPDFViewer extends DBControlType {
    fieldName: string;
    srcType?: 'fileName' | 'blob';
    fillWidth?: boolean;
}

export interface DBIframeType extends DBControlType {
    label: string;
    fieldName: string;
}

// export interface DBMemoEditType extends DBEditType {}

export interface DBComboBoxType extends DBControlType {
    label: string;
    fieldName: string;
    nameField: string;
    valueField: string;
    clearable: boolean;
    editable: boolean;
    multiselect: boolean;
}

export interface DBCheckBoxType extends DBControlType {
    label: string;
    fieldName: string;
}

export interface DBNumEditType extends DBControlType {
    label: string;
    fieldName: string;
}

export interface DBDateType extends DBControlType {
    label: string;
    fieldName: string;
}

export interface DBDateTimeType extends DBEditType {
    type: 'dbDate' | 'dbDateTime' | 'dbTime';
    withTime?: boolean;
}

export interface DBLookupType extends DBControlType, DBEditType {
    codeFields: string;
    labelFields: string;
    lookupResourceName: string;
    lookupFormName?: string;
    flMultiSelect: boolean;
    splitField?: string;
    splitValue?: string;
    params?: ParamType[];
}

export interface DBDocumentViewerType extends DBControlType {
    label: string;
    fieldName: string;
}

export interface DBHtmlViewer extends DBControlType {
    label: string;
    fieldName: string;
}

export interface GridType extends DBControlType {
    caption: string;
    columns: ColumnType[];
    buttons?: ToolButtonType[];
    controls: ControlType[];
    fastFilters: FastFilterType[];
    options: string;
}

export interface FlowChartType extends DBControlType {
    direction: 'top-bottom' | 'left-right';
    zoom?: number;
    directionSwitcher?: boolean;
    fitView?: boolean;
    showMap?: boolean;
    showControls?: boolean;
}

export interface FastFilterType extends ControlType {
    fieldName: string;
    caption: string;
    operator: string;
}

export interface ColumnType extends ResourceInfo {
    fieldName: string;
    caption: string;
    iconSet?: string;
}

export interface QueryType extends ComponentType {
    sql: string;
    tables: QueryTableType[];
}

export interface StartQueryType extends ComponentType {
    sql: string;
    fields: QueryFieldType[];
    params: QueryParamType[];
    tables: QueryTableType[];
    filters: QueryFilterType[];
}

// export interface TableType extends QueryTableType {}

export interface QueryTableType extends ComponentType {
    keyField: string;
    fields: QueryFieldType[];
}

export enum MedialogDataType {
    unknown = '',
    integer = 'KRN_INTEGER',
    autoinc = 'KRN_AUTOINC',
    string = 'KRN_STRING',
    datetime = 'KRN_DATETIME',
    date = 'KRN_DATE',
    time = 'KRN_TIME',
    money = 'KRN_MONEY',
    numeric = 'KRN_NUMERIC',
    memo = 'KRN_MEMO',
    blob = 'KRN_BLOB',
    logical = 'KRN_LOGICAL',
    graphic = 'KRN_GRAPHIC',
    cours = 'KRN_COURS',
    taux = 'KRN_TAUX',
    string_int = 'KRN_STRING_INTERNATIONAL',
    minutes = 'KRN_DURATION_MINUTES',
    bit = 'KRN_BIT',
    memo_int = 'KRN_MEMO_INTERNATIONAL',
    rtf = 'KRN_RTF',
    json = 'KRN_JSON',
    array = 'KRN_ARRAY'
}

export const DTDatatypes = [
    MedialogDataType.datetime,
    MedialogDataType.date,
    MedialogDataType.time
];

export interface QueryFieldType extends ComponentType {
    alias: string;
    name: string;
    caption: string;
    dataType: MedialogDataType;
    dataLen: number;
    isKey: boolean;
    sourceField: string;
    sourceTable: string;
    filterId: number;
    lookupData: string;
}

export interface QueryParamType extends ComponentType {
    name: string;
    caption: string;
    dataType: MedialogDataType;
    filterId: number;
    afType: any[];
}

export interface QueryFilterType extends ComponentType {
    filterId: number;
    fieldName: string;
    paramName: string;
}

export interface ToolbarType extends ComponentType, ParamOwnerType {
    caption?: string;
    buttons?: ToolButtonType[];
    breakpoint?: number;
    dataset?: string;
}

export interface ToolButtonType extends ScriptButtonType {
    action?: string;
}

export enum FilterDataType {
    other = 'other',
    memo = 'memo',
    bool = 'bool',
    string = 'string',
    lookup = 'lookup',
    unknown = ''
}

export interface FilterControlType extends DBEditType {
    filterId: number;
    filterDataType: FilterDataType;
    filterType: 'interval' | 'value';
    inputType: 'manual' | 'existlist' | 'deflist';
    afName: 'meddepId' | 'orgId' | 'userId' | 'specId' | 'filialId' | 'roleId';
}

export interface MenuType extends ControlType {
    iconSet: string;
    icon: string;
    caption: string;
    variant: 'top' | 'header' | 'footer' | 'button' | 'side' | 'side-fixed';
    items: MenuItemType[];
}

export interface MenuItemType extends Omit<ScriptButtonType, 'variant' | 'items'> {
    caption: string;
    type: string;
    items: MenuItemType[];
    variant: 'contained' | 'outlined' | 'text' | 'divider';
    script: string;
    afName: string;
    value: string;
    onClickScript: string;
}

export type EditModeType = null | 'view' | 'create' | 'edit' | 'delete' | 'cdoCreate';

export interface FormActionsType {
    getName?: any;
    saveAction?: () => any;
    cancelAction?: () => any;
    getDataStock?: () => any /* DataStock */;
}

export interface CustomFormType {
    formGuid?: string;
    tableId?: undefined | string;
    formConfig?: any;
    extParamVals?: any;
    initEditMode?: EditModeType;
    setFormActions?: any;
    parentDataStock?: DataStock;
    parentPropContainer?: PropContainer;
    cdoData?: CDODataStruct;
}

/**
 * Типы для описателя
 */
interface DescriptorFieldType {
    type: 'date' | 'string' | 'integer' | 'array' | 'guid' | 'boolean' | 'resource';
    caption?: string;
    hidden?: boolean;
    defaultFunc?: string;
    defVal?: number | string | ResourceInfo;
    readOnly?: boolean;
    children?: string[];
    multiLine?: boolean;
    render?: string;
    list?: string[]; // Список для вывода
    getList?: string; // Функция выбора значения
    propertyEditor?: string; // Редактор свойства
    maxCount?: number;
}

/**
 * Описатель элемента
 */
interface DescriptorType {
    parent?: string[] | string | null;
    caption: string;
    hidden?: boolean;
    fields?: { [key: string]: DescriptorFieldType };
}

export type Descriptor = { [key: string]: DescriptorType };

export type ScriptGlossaryItemType = {
    name?: string;
    description?: string | null;
    methods?: Record<string, ScriptGlossaryItemType>;
    properties?: ScriptGlossaryItemType[];
    type?: string | ScriptGlossaryItemType;
    params?: ScriptGlossaryItemType[];
    return?: ScriptGlossaryItemType;
    dataType?: string;
    // Свойства параметров
    unionType?: ScriptGlossaryItemType[];
    complexType?: ScriptGlossaryItemType[];
    value?: string;
};

export type ScriptGlossaryObjectType = {
    description?: string | null;
    type: string | ScriptGlossaryItemType;
    dataType: string;
};

export type ScriptGlossaryType = {
    functions?: Record<string, ScriptGlossaryItemType>;
    types?: Record<string, ScriptGlossaryItemType>;
    objects?: Record<string, ScriptGlossaryObjectType>;
};
