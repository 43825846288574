import React, { FunctionComponent } from 'react';
import CustomForm from 'forms/CustomForm';
import SimpleDialog from 'components/Feedback/SimpleDialog';

import { ExtFormPropsType } from './interfaces';

const ExtForm: FunctionComponent<ExtFormPropsType> = ({
    guid,
    descr,
    formName,
    values,
    editMode,
    handleClose,
    parentDataStock,
    parentPropContainer
}) => (
    <SimpleDialog editorName={formName ?? ''} handleClose={handleClose}>
        <CustomForm
            key={guid}
            formGuid={guid}
            formConfig={descr}
            initEditMode={editMode}
            extParamVals={values}
            parentDataStock={parentDataStock}
            parentPropContainer={parentPropContainer}
        />
    </SimpleDialog>
);

export default ExtForm;
