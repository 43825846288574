import React, { FunctionComponent, useCallback } from 'react';
import { observer } from 'mobx-react';

import { Grid, Theme, Typography } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';

import ConfigurationStore from 'store/configurationStore';

interface PropsType {
    error?: boolean;
    disabled?: boolean;
}

const Title: FunctionComponent<PropsType> = observer(({ error, disabled }) => {
    const { content } = ConfigurationStore;

    const getFontColor = useCallback(
        (theme: Theme) => {
            if (disabled) return theme.palette.action.disabled;

            if (error) return theme.palette.error.main;

            return '#0000008a';
        },
        [error, disabled]
    );

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{
                minHeight: 130,
                color: theme => getFontColor(theme)
            }}
        >
            <Grid item>
                <ImageIcon sx={{ fontSize: 60 }} />
            </Grid>
            <Grid item>
                <Typography variant="h6">{content.controls.fileUploader.select}</Typography>
            </Grid>
        </Grid>
    );
});

export default Title;
