import { FunctionComponent, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { Box, Grid, FormControlLabel, Checkbox, Paper } from '@mui/material';

import ConfigurationStore from 'store/configurationStore';

import { Configuration } from '../index';

interface PropsType {
    configuration: Configuration;
    setConfiguration: (configuration: { [key: string]: boolean }) => unknown;
}

const GridConfiguration: FunctionComponent<PropsType> = observer(
    ({ configuration, setConfiguration }) => {
        const content = ConfigurationStore.content.resource.gridProps.configuration;
        const { hideRefresh, hideToolbar } = configuration;

        const [refresh, setRefresh] = useState(!!hideRefresh);
        const [toolbar, setToolbar] = useState(!!hideToolbar);

        const handleRefreshChange = () => {
            setRefresh(!refresh);
        };

        const handleToolbarChange = () => {
            setToolbar(!toolbar);
        };

        useEffect(() => setRefresh(!!hideRefresh), [hideRefresh]);

        useEffect(() => setToolbar(!!hideToolbar), [hideToolbar]);

        useEffect(() => {
            setConfiguration({ hideRefresh: refresh, hideToolbar: toolbar });
        }, [setConfiguration, refresh, toolbar]);

        return (
            <Grid container direction="column" sx={{ height: '100%' }}>
                <Grid item flex="1 1">
                    <Paper elevation={0} variant="outlined" sx={{ height: '100%' }}>
                        <Box sx={{ p: 4 }}>
                            <Grid container direction="column">
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={refresh}
                                                onChange={handleRefreshChange}
                                            />
                                        }
                                        label={content.hideRefresh}
                                    />
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={toolbar}
                                                onChange={handleToolbarChange}
                                            />
                                        }
                                        label={content.hideToolbar}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        );
    }
);

export default GridConfiguration;
