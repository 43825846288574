import withStyles from '@mui/styles/withStyles';
import CheckBox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

const baseBlue = '#6157ff';

export const CssSelectFormControl = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            height: '40px',
            '& fieldset': {
                borderColor: '#D9DEEC'
            },
            '&:hover fieldset': {
                borderColor: '#D9DEEC'
            },
            '&.Mui-focused fieldset': {
                borderColor: baseBlue,
                borderWidth: '1px'
            }
        }
    }
})(FormControl);

export const CssCheckBox = withStyles({
    root: {
        '&$checked': {
            color: baseBlue
        }
    },
    checked: {}
})(CheckBox);

export const CssFormControlLabel = withStyles({
    root: {
        color: 'black'
    }
})(FormControlLabel);
