import { observable, action, makeObservable } from 'mobx';

import { MenuItemType } from '../forms/interfaces';

import ConfigurationStore from './configurationStore';

const getPath = (name: string, items: MenuItemType[], path: string[] = []): any => {
    for (let i = 0; i < items.length; i++) {
        const item = items[i];

        if (item.name === name) {
            return [...path, ...[name]];
        }

        if (item.items.length) {
            const nestedPath = getPath(name, item.items, [...path, ...[item.name]]);
            if (nestedPath) return nestedPath;
        }
    }
};

class MainMenu {
    @observable menuVariant?: string;
    @observable menuPosition: { [key: string]: string | number } = {};
    @observable currentMenuItem = '';
    @observable selectedItems: string[] = [];
    @observable selectedPath: string[] = [];
    @observable open = false;

    constructor() {
        makeObservable(this);
    }

    @action setMenuVariant = (menuVariant: string) => {
        this.menuVariant = menuVariant;

        if (menuVariant === 'side-fixed') {
            this.open = true;
        }
    };

    @action setMenuPosition = () => {
        if (this.menuVariant === 'top') {
            this.menuPosition = { paddingTop: 80 };
        }

        if (this.menuVariant === 'side-fixed') {
            this.menuPosition = { paddingTop: 80, paddingLeft: this.open ? 312 : 65 };
        }
    };

    @action setCurrentItem = (guid: string) => {
        this.currentMenuItem = guid;
    };

    @action initMenuSelections = () => {
        const path = [...ConfigurationStore.currentRouterPath];

        this.selectedItems = path;
        this.selectedPath = path;
        this.currentMenuItem = path[path.length - 1];
    };

    @action setSelectedItems = (path: string[]) => {
        this.selectedItems = path;
    };

    @action checkSelectedItem = (name: string) =>
        this.selectedItems ? this.selectedItems.includes(name) : false;

    @action checkItemCallPath = (name: string) => {
        if (this.selectedPath[0] === name) {
            return this.selectedPath.shift();
        }

        return false;
    };

    @action setOpen = (open: boolean) => {
        this.open = open;
        this.setMenuPosition();
    };
}

const MainMenuStore = new MainMenu();
export default MainMenuStore;
