import React from 'react';
import Typography from '@mui/material/Typography';
import styles from './Empty.module.scss';

interface PropsType {
    message: string;
}

const Empty = (props: PropsType) => {
    const { message } = props;

    return (
        <div className={styles.empty_page_wrapper}>
            <div>
                <Typography variant="h6" component="div">
                    {message}
                </Typography>
            </div>
        </div>
    );
};

export default Empty;
