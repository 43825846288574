import { DatasetType } from 'forms/interfaces';
import { getResourceDescriptor } from 'forms/form-utils';

import DescriptorStore from 'store/descriptorStore';
import ConfigurationStore from 'store/configurationStore';
import { CDODataStruct, IQueryRow } from './dataInterfaces';

const makeDatasetConfig = (
    cdoDatasets: DatasetType[],
    cdoData: CDODataStruct,
    makeInitRecord = false,
    parentKey?: string,
    parentVal?: string | number | null,
    count = -1
) => {
    const makeFields = (ds: DatasetType) => {
        const tmpFields: IQueryRow = {};

        ds.fields.forEach(fld => {
            tmpFields[fld.name] = null;
        });

        tmpFields[ds.keyField] = count;
        count--;

        if (parentKey) tmpFields[parentKey] = parentVal || null;

        return tmpFields;
    };

    cdoDatasets.forEach(ds => {
        if (cdoData[ds.name]) {
            cdoData[ds.name].forEach(detDS => {
                if (!detDS.fields) detDS.fields = makeFields(ds);

                if (!detDS.datasets && ds.datasets) {
                    detDS.datasets = {};

                    makeDatasetConfig(
                        ds.datasets,
                        detDS.datasets,
                        makeInitRecord,
                        ds.keyField,
                        detDS.fields[ds.keyField],
                        count
                    );
                }
            });
        } else {
            const fields = makeInitRecord && ds.required ? makeFields(ds) : undefined;

            cdoData[ds.name] = [
                {
                    fields
                }
            ];

            if (ds.datasets) {
                makeDatasetConfig(
                    ds.datasets,
                    cdoData,
                    makeInitRecord,
                    ds.keyField,
                    fields?.[ds.keyField],
                    count
                );
            }
        }
    });
};

export const fillCDOStruct = async (
    cdoGuid: string,
    cdoData: CDODataStruct,
    makeInitRecord = false
) => {
    const cdoDescr = await getResourceDescriptor(cdoGuid, undefined, undefined, DescriptorStore, {
        logout: ConfigurationStore.logout
    });

    const tmpDS = { ...cdoData };
    makeDatasetConfig(cdoDescr.datasets, tmpDS, makeInitRecord);

    return tmpDS;
};
