import React, { FunctionComponent, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { SvgIconProps } from '@mui/material/SvgIcon';

import CustomSvgIcon from 'components/Image/CustomSvgIcon';

import PropContainer from 'dataObj/PropContainer';

import IconSetStore from 'store/iconSetStore';

import useIsMounted from 'hooks/isMounted';
import { Tooltip } from '@mui/material';

type SVGIconProps = SvgIconProps & {
    descr: { iconSet: string; icon: string; label?: string };
    propContainer: PropContainer;
};

const SVGIcon: FunctionComponent<SVGIconProps> = observer(({ descr, propContainer, ...props }) => {
    const { getLoadingIconSet, getIcon, checkIconSet, getLinkedIconSet, getLoadingIcon } =
        IconSetStore;

    const [icon, setIcon] = useState(descr.iconSet && descr.icon ? getLoadingIcon() : '');
    const [iconSetID, setIconSetID] = useState('');

    const isLoading = getLoadingIconSet(iconSetID);
    const isMounted = useIsMounted();

    const iconSetter = (newIcon: string) => {
        isMounted() && setIcon(newIcon);
    };

    useEffect(() => {
        if (descr.iconSet && descr.icon) {
            const iconSetGuid = propContainer.getResourceLink(descr.iconSet, 'wuiIconSet')?.resource
                .guid;

            if (iconSetGuid) setIconSetID(iconSetGuid);
        }
    }, [descr, propContainer]);

    useEffect(() => {
        if (iconSetID && !isLoading) {
            const cachedIcon = getIcon(iconSetID, descr.icon);

            if (cachedIcon) {
                iconSetter(cachedIcon);
            } else if (!checkIconSet(iconSetID)) {
                getLinkedIconSet(iconSetID)
                    .then(() => iconSetter(getIcon(iconSetID, descr.icon)))
                    .catch(err => console.error(err.message));
            } else iconSetter('');
        } else {
            isLoading && iconSetter(getLoadingIcon());
        }
    }, [isLoading, descr, iconSetID]);

    return descr.label ? (
        <Tooltip title={descr.label}>
            <span>
                <CustomSvgIcon icon={icon} {...props} />
            </span>
        </Tooltip>
    ) : (
        <CustomSvgIcon icon={icon} {...props} />
    );
});

export default SVGIcon;
