import React, { useState, FunctionComponent, useEffect } from 'react';
import { observer } from 'mobx-react';

import { Button, Stack } from '@mui/material';
import RestoreIcon from '@mui/icons-material/Restore';

import SimpleDialog from 'components/Feedback/SimpleDialog';

import ConfigurationStore from 'store/configurationStore';

import { PropertyEditorProps } from './ResInterfaces';
import GridSettings from './gridSettings/GridSettings/GridSettings';
import ChartSettings from './ChartSettings/ChartSettings';
import TableLinks from './TableLinks';

import CDOSettings from './CDOSettings/CDOSettings';

const ResPropertyEditor: FunctionComponent<PropertyEditorProps> = observer(props => {
    const { content } = ConfigurationStore;

    const [open, setOpen] = useState(false);
    const [editorName, setEditorName] = useState<string>('');
    const [editorActions, setEditorActions] = useState<any>(null);

    const handleClose = (state: boolean) => {
        if (state && editorActions) {
            const target = { name: props.prop, value: editorActions.getValue() };
            props.onResult({ target });
        }
        setOpen(false);
    };

    const handleUndo = () => {
        const target = { name: props.prop, value: '' };
        props.onResult({ target });
    };

    const getEditor = () => {
        const eProps = {
            ...props,
            setActions: setEditorActions,
            onShow: (name: string) => setEditorName(name)
        };

        // Определить редактор
        switch (props.type) {
            case 'grid':
            case 'dbLookup':
                return <GridSettings {...eProps} />;
            case 'cdo':
                return <CDOSettings {...eProps} />;
            case 'tableLink':
                return <TableLinks {...eProps} />;
            case 'chart':
                return <ChartSettings {...eProps} />;
            default:
                return null;
        }
    };

    useEffect(() => {
        if (editorActions?.getName) {
            setEditorName(editorActions?.getName());
        }
    }, [editorActions]);

    return (
        <>
            <Stack spacing={1} direction="row">
                <Button type="button" fullWidth variant="contained" onClick={() => setOpen(true)}>
                    {content.resource.buttons.editor}
                </Button>
                <Button variant="outlined" startIcon={<RestoreIcon />} onClick={handleUndo}>
                    {content.application.settings.clear}
                </Button>
            </Stack>
            {open ? (
                <SimpleDialog
                    editorName={editorName} // ConfigurationStore.content.resource
                    handleClose={handleClose}
                >
                    {getEditor()}
                </SimpleDialog>
            ) : null}
        </>
    );
});

export default ResPropertyEditor;
