import applicationContent from './application/frApplicationLocale';
import resourceContent from './resources/frResourceLocale';
import controlsContent from './controls/frControlsLocale';
import componentsContent from './components/frComponentLocale';

export default {
    language: 'Français',
    application: applicationContent,
    controls: controlsContent,
    resource: resourceContent,
    components: componentsContent
};
