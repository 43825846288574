import { makeObservable, observable, action, set, get } from 'mobx';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import DataStock from './DataStock';

import FormulaCalculator from '../formulas/formula-calculator';

import ScriptStore from '../store/scriptStore';
import LookupStore from '../store/lookupStore';
import ReportStore from '../store/reportStore';

const cKind = { control: 0, data: 1, script: 2, subForm: 3 };

/**
 * @typedef {object} controlElement
 * @property {import('../forms/interfaces').ControlType} descr
 * @property {string} guid
 * @property {string} type
 * @property {string} name
 * @property {number} parentIndex
 * @property {boolean} enabled
 * @property {boolean} visible
 * @property {boolean} hidden
 * @property {string} kind
 * @property {string} [color]
 * @property {function} [func]
 * @property {object} [options]
 *
 */

class PropContainer {
    constructor(formDescr, parentDataStock, parentPropContainer) {
        this.guid = uuidv4();
        this.formDescr = formDescr;
        this.formGuid = formDescr.guid;
        this.formName = formDescr.name;
        this.isSubForm = !!parentDataStock;
        this.activeCtrlDescr = null;
        this.customResourceLinks = [];
        this.parentPropContainer = parentPropContainer;

        /** @type {controlElement[]} */
        this.ctrlArr = [];

        /** @type {Object.<string, ScriptStore>} */
        this.scripts = {};

        this.#scanDescr(formDescr, undefined, cKind.control);

        this.requiredPages = {};

        makeObservable(this, {
            ctrlArr: observable,
            scripts: observable,
            setProperty: action,
            setElemProperty: action,
            requiredPages: observable,
            setPageRequirement: action,
            activeCtrlDescr: observable,
            setActiveCtrlDescr: action,
            customResourceLinks: observable,
            setCustomResourceLinks: action
        });

        this.dataStock = parentDataStock ? parentDataStock : new DataStock(formDescr, this);
        this.formulaCalculator = new FormulaCalculator(formDescr, this.dataStock, this);
        this.formulaCalculator.init();

        this.lookup = new LookupStore();

        // в masterForm. Установить свойства dataset.editForm для subForm
        if (!this.isSubForm) {
            const editForms = this.ctrlArr.filter(
                c => c.kind === cKind.subForm && c.descr.editDatasetName
            );
            this.dataStock.setEditForms(editForms, this);
        }

        ReportStore.setFormReports(formDescr, this);
    }

    #awaitFunctionsTransfer(script) {
        //массив асинхронных функций
        const awaitFuncArr = [
            'showForm',
            'showSubForm',
            'showEditor',
            'executeCsbTask',
            'executeServerScript',
            'executeFormScript',
            'modalMessage',
            'executeDynamicForm',
            'encodeData',
            'refreshDataset',
            'recalcFormulas',
            'wait',
            'logout',
            'checkData',
            'openLookup',
            'appendRecord',
            'executeBusinessProcess',
            'runReport'
        ];

        awaitFuncArr.map(func => {
            const re = new RegExp(`Client.${func}`, 'g');
            script = script.replace(re, `await Client.${func}`);
        });

        return script;
    }

    #createFunction(script) {
        const AsyncFunction = Object.getPrototypeOf(async function () {}).constructor;
        return new AsyncFunction(
            'Client',
            'Event',
            `async function f(){${this.#awaitFunctionsTransfer(script)}}; return f()`
        );
    }

    #scanDescr(descr, parentIndex, kind) {
        const el = {};
        el.descr = _.cloneDeep(descr);
        el.parentIndex = parentIndex;

        el.guid = descr.guid;
        el.type = descr.type;
        if (el.type === 'datasetField' || el.type === 'column') {
            const parent = this.ctrlArr[parentIndex];
            el.name = `${parent.name}.${descr.name}`;
        } else el.name = descr.name;

        el.enabled = !descr.enabledFormula;
        el.visible = !descr.visibleFormula;
        el.hidden = !!descr.hiddenFormula;
        el.kind = kind;

        if (kind === cKind.control) {
            el.color = null;
        }

        if (el.type === 'script') {
            let f;
            try {
                f = this.#createFunction(descr.text);

                this.scripts[descr.name] = new ScriptStore();
            } catch {
                f = undefined;
                console.error(`Script ${el.name} cannot be compiled!`);
            }
            el.func = f;
        }

        if (el.type === 'grid' || el.type === 'chart') {
            function gridGetOptions(descr) {
                return descr.options;
            }

            const optObj = JSON.parse(gridGetOptions(descr) || '""');
            if (typeof optObj === 'object') {
                el.options = optObj;
                const dsName = descr.datasetName;
                if (dsName) {
                    const dsElem = this.find(dsName, 'dataset');
                    if (dsElem) dsElem.options = optObj;
                }
            }
        }

        let index = this.ctrlArr.push(el) - 1;

        // Сканирование только верхнего уровеня
        if (el.type === 'wuiForm') {
            descr.subForms?.forEach(c => this.#scanDescr(c, index, cKind.subForm));
            if (kind === cKind.subForm) {
                return;
            }
        }

        // сканируем дочерние коллекции
        descr.dataObjects?.forEach(c => this.#scanDescr(c, index, cKind.data));
        descr.datasets?.forEach(c => this.#scanDescr(c, index, cKind.data));
        [descr.controls, descr.items, descr.pages, descr.columns].forEach(collection =>
            collection?.forEach(c => this.#scanDescr(c, index, cKind.control))
        );
        descr.scripts?.forEach(c => this.#scanDescr(c, index, cKind.script));

        if (['dataset', 'clientDataset'].includes(el.type)) {
            descr.fields?.forEach(c => this.#scanDescr(c, index, cKind.data));
        }
    }

    find(sNameOrGuid, sType) {
        const el = this.ctrlArr.find(
            el =>
                (el.name === sNameOrGuid || el.guid === sNameOrGuid) &&
                (!sType || sType === el.type)
        );

        // Ищем в родительском PropContainer'е
        return el ?? this.parentPropContainer?.find(sNameOrGuid, sType);
    }

    setElemProperty(propElem, propName, value) {
        function normalizeResult(propName, val) {
            if (['visible', 'enabled', 'hidden'].includes(propName)) {
                return Boolean(val);
            }
            return val;
        }

        if (propElem) {
            if (propElem.hasOwnProperty(propName)) {
                propElem[propName] = normalizeResult(propName, value);
            } else {
                console.error(`Component ${propElem.name} does not have property '${propName}'`);
            }
        } else {
            console.error(`Cannot set property to Undefined element`);
        }
    }

    setProperty(ctrlName, propName, value) {
        const propElem = this.find(ctrlName);
        if (propElem) {
            this.setElemProperty(propElem, propName, value);
        } else {
            console.error(`Component ${ctrlName} not found`);
        }
    }

    /**
     * метод вычисляет свойство доступности по иерархии, если какой-либо из родителей элемента
     * недоступен, то сам элемент также считается enabled = false
     * @param {*} el
     * @returns
     */
    getElemEnabledProperty(el) {
        if (el) {
            let res = el.enabled;
            while (res) {
                if (el.parentIndex !== undefined) {
                    el = this.ctrlArr[el.parentIndex];
                    res = el.enabled;
                } else break;
            }
            return res;
        }
    }

    getElemProperty(el, propName) {
        if (el) return el[propName];
    }

    /**
     * метод вычисляет свойство доступности по иерархии, если какой-либо из родителей элемента
     * недоступен, то сам элемент также считается enabled = false
     * @param {string} ctrlName
     * @returns
     */
    getEnabledProperty(ctrlName) {
        let el = this.find(ctrlName);
        if (el) {
            return this.getElemEnabledProperty(el);
        }
    }

    getChildrenCtrl(el) {
        if (el) {
            const elIndex = this.ctrlArr.map(ctrl => ctrl.descr.guid).indexOf(el.descr.guid);
            const children = this.ctrlArr.filter(ctrl => ctrl.parentIndex === elIndex);

            if (children.length) return children;

            return [];
        }
    }

    getIsChildrenEnabled(el, enabled = false) {
        if (el) {
            let children = this.getChildrenCtrl(el);

            if (children.length) {
                return children.reduce((prev, current) => {
                    return prev || current.enabled;
                }, enabled);
            }

            return enabled;
        }

        return enabled;
    }

    getProperty(ctrlName, propName) {
        const el = this.find(ctrlName);
        if (el) {
            return el[propName];
        }
    }

    /**
     * Метод получения описателя родительского элемента
     * @param {string} ctrlName
     * @param {string} [parentCtrlType]
     * @returns {*}
     */
    getParent = (ctrlName, parentCtrlType) => {
        let el = this.find(ctrlName);
        if (el) {
            const parent = this.ctrlArr[el.parentIndex];

            if (!parent) return null;

            if (!parentCtrlType || parent?.type === parentCtrlType) {
                return parent;
            } else {
                return this.getParent(parent?.name, parentCtrlType);
            }
        }
    };

    /**
     * метод вовращает элемент родительской формы
     * @returns {*}
     */
    getCurrentForm = () => {
        return this.ctrlArr[0];
    };

    /**
     * метод вовращает элемент первичной родительской формы
     * @returns {*}
     */
    getMainForm = () => {
        if (this.parentPropContainer) return this.parentPropContainer.getMainForm();

        return this.ctrlArr[0];
    };

    /**
     *
     * @param {string} guid
     * @returns {import('../forms/interfaces').FormType}
     */
    getSubForm = guid => {
        return this.subForms.find(form => form.guid === guid);
    };
    /**
     * Метод возвращает описатель поля
     * @param {string} fieldName
     * @param {string} datasetName
     */
    getFieldDescr = (fieldName, datasetName) => {
        const dataset = this.dataStock.getDatasetObj(datasetName);
        return dataset?.descr?.fields?.find(fld => fld.name === fieldName);
    };

    /**
     * Метод возвращает обязательность заполнения поля датасета
     * @param {string} fieldName
     * @param {string} datasetName
     *
     * @return {boolean} field requirement
     */
    getFieldRequirement = (fieldName, datasetName) =>
        this.dataStock.getDatasetObj(datasetName)?.needToSave() &&
        this.getFieldDescr(fieldName, datasetName)?.required;

    /**
     * Метод возвращает количество чисел для поля
     * @param {string} fieldName
     * @param {string} datasetName
     *
     * @return {number} field size
     */
    getFieldSize = (fieldName, datasetName) => this.getFieldDescr(fieldName, datasetName)?.dataLen;

    /**
     * Метод возвращает количество чисел после запятой для поля
     * @param {string} fieldName
     * @param {string} datasetName
     *
     * @return {number} field scale
     */
    getFieldScale = (fieldName, datasetName) => this.getFieldDescr(fieldName, datasetName)?.scale;

    /**
     * Метод присваивания странице статуса обязательного заполнения
     * @param {string} name - имя контрола, содержащего проверку на обязательность
     * @param {boolean} required - обязательность с учётом текущей заполненности
     */
    setPageRequirement = (name, required) => {
        const pageControl = this.getParent(name, 'page');

        if (pageControl) {
            const controls = get(this.requiredPages, pageControl.guid);

            if (controls?.length) {
                set(this.requiredPages, {
                    [pageControl.guid]: [
                        ...controls.filter(ctrl => ctrl.name !== name),
                        ...[{ name, required }]
                    ]
                });
            } else
                set(this.requiredPages, {
                    [pageControl.guid]: [{ name, required }]
                });
        }
    };

    /**
     * Метод получения статуса обязательного заполнения страницы
     * @param guid - идентификатор страницы
     * @return {boolean}
     */
    getPageRequirement = guid => {
        const controls = get(this.requiredPages, guid);

        if (controls?.length) {
            return controls.reduce((prev, current) => current.required || prev, false);
        }

        return false;
    };

    /**
     * Метод фиксации описателя активного поля ввода
     * @param descr -описатель поля
     */
    setActiveCtrlDescr = descr => {
        this.activeCtrlDescr = descr;
    };

    /**
     * Метод добавления динамических ссылок на ресурсы
     * @param resourceLinks
     */
    setCustomResourceLinks = resourceLinks => {
        this.customResourceLinks = resourceLinks;
    };

    /**
     * Метод получения ссылки на ресурс
     *
     * @param {string} rlName
     * @param {string} [rlType]
     * @returns {{resource: {guid: string, name: string}}}
     */
    getResourceLink = (rlName, rlType) => {
        return (
            (this.formDescr.resourceLinks.find(
                RL => RL.name === rlName && (!rlType || RL.resource?.type === rlType)
            ) ||
                this.customResourceLinks.find(
                    RL => RL.name === rlName && (!rlType || RL.resource?.type === rlType)
                )) ??
            this.parentPropContainer?.getResourceLink(rlName, rlType)
        );
    };
}

export default PropContainer;
