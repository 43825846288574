import { useState, useEffect, useCallback } from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';

import { Box } from '@mui/material';

import SimpleDialog from 'components/Feedback/SimpleDialog';
import CustomForm from 'forms/CustomForm';
import { jsonFetch } from 'utils';
import CustomDataset from 'dataObj/customDataset';

import ComplexButton, { ComplexButtonConfig } from 'components/Inputs/ComplexButton/ComplexButton';
import CustomFilter, { FilterValues } from 'dataObj/CustomFilter';
import { FormType } from 'forms/interfaces';

import { SettingType } from 'store/resourceStore';
import configurationStore from 'store/configurationStore';
import ActionStore from 'store/actionStore';

type PropType = {
    dataset: CustomDataset;
    handleClose: (state: boolean, vals?: FilterValues) => void;
};

function FilterDialog(props: PropType) {
    const { dataset, handleClose } = props;
    const { filter } = dataset; // Это фильтр клиентского датасета
    const [formConfig, setFormConfig] = useState<FormType>();
    const [filterFilter, setFilterFilter] = useState<CustomFilter | null>(null);
    // Это датасет формы с редакторами
    const [filterDataset, setFilterDataset] = useState<CustomDataset | null>(null);

    const { content, log } = configurationStore;

    const formActions = ActionStore.getFormActions(formConfig?.guid);

    const setFilterValues = useCallback(
        (vals: FilterValues | null): void => {
            if (filterFilter && filterDataset) {
                const jsVals = vals ? toJS(vals) : [];

                // Сначало устаналиваем значения по умолчанию и остальные свойства
                filterFilter.setValues(jsVals);

                // Потом устанавливаю значения датасета, через них контролы
                filterDataset.recCount > 0
                    ? filterDataset.edit()
                    : filterDataset.append().catch(err => console.error(err.message));
                jsVals.forEach(jsV => {
                    const { value: v1, value2: v2, id } = jsV;
                    const name = `@__flt_${id}`;
                    filterDataset.setFieldValue(name, 'value' in v1 ? v1.value : undefined);
                    filterDataset.setFieldValue(
                        `${name}_2`,
                        v2 && 'value' in v2 ? v2.value : undefined
                    );
                });
            }
        },
        [filterFilter, filterDataset]
    );

    useEffect(() => {
        if (formActions) {
            const filterDataStock = formActions.getDataStock();
            const fDataset = filterDataStock.getDatasetObj('Cache');

            setFilterDataset(fDataset);
            setFilterFilter(fDataset.filter);
            setFilterValues(dataset.filter.values);
        }
    }, [formActions, dataset, setFilterValues]);

    useEffect(() => {
        if (typeof dataset?.dataStock?.formGuid === 'string') {
            const { formGuid } = dataset.dataStock;

            if (typeof formGuid === 'string') {
                jsonFetch(`views/${formGuid}/userFilter/${dataset.name}`)
                    .then(data => setFormConfig(data as FormType))
                    .catch(err => log(err.message));
            }
        }
    }, [dataset]);

    const getCustomActions = () => {
        const settingActions: ComplexButtonConfig[] = [
            {
                id: 'clear',
                label: content.application.settings.clear,
                onClick: () => setFilterValues([])
            },
            {
                id: 'load',
                label: content.application.settings.load,
                actions: [
                    {
                        id: 'load_user',
                        label: content.application.settings.user,
                        onClick: () =>
                            filter.load(SettingType.user).then(values => setFilterValues(values))
                    },
                    {
                        id: 'load_global',
                        label: content.application.settings.global,
                        onClick: () =>
                            filter.load(SettingType.global).then(values => setFilterValues(values))
                    }
                ]
            },
            {
                id: 'save',
                label: content.application.settings.save,
                actions: [
                    {
                        id: 'save_user',
                        label: content.application.settings.user,
                        onClick: () => filter.save(SettingType.user, filterFilter?.values)
                    },
                    {
                        id: 'save_global',
                        label: content.application.settings.global,
                        onClick: () => filter.save(SettingType.global, filterFilter?.values)
                    }
                ]
            },
            {
                id: 'del',
                label: content.application.settings.delete,
                actions: [
                    {
                        id: 'del_user',
                        label: content.application.settings.user,
                        onClick: () => filter.drop(SettingType.user)
                    },
                    {
                        id: 'del_all_user',
                        label: content.application.settings.allUsers,
                        onClick: () => filter.drop(SettingType.allUsers)
                    },
                    {
                        id: 'del_global',
                        label: content.application.settings.global,
                        onClick: () => filter.drop(SettingType.global)
                    }
                ]
            }
        ];

        return settingActions.map(action => <ComplexButton key={action.id} config={action} />);
    };

    const localHandleClose = (result: boolean) => {
        handleClose(result, filterFilter ? filterFilter.getNotEmpty() : []);
    };

    return (
        <SimpleDialog
            // open
            // fullWidth
            // editorName={content.controls.grid.filter}
            handleClose={result => localHandleClose(result)}
            customActions={getCustomActions}
        >
            <Box sx={{ minHeight: 300 }}>
                {formConfig ? (
                    <CustomForm
                        key={formConfig.guid}
                        formConfig={formConfig}
                        /* editMode={editAction} */
                    />
                ) : null}
            </Box>
        </SimpleDialog>
    );
}

export default observer(FilterDialog);
