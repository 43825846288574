import { FunctionComponent } from 'react';
import { observer } from 'mobx-react';

import { TreeView } from '@mui/x-tree-view';
import {
    Folder,
    FolderOpen,
    Report,
    Clear,
    CheckBox,
    ArrowDropDown,
    ArrowRight,
    SmartDisplay,
    CheckBoxOutlineBlank,
    FiberManualRecordOutlined,
    FormatListNumbered,
    DensityMedium
} from '@mui/icons-material';

import Loading from 'components/utils/Loading/Loading';

import ConfigurationStore from 'store/configurationStore';

import { calcWorkDays, getLocalizedString } from 'utils';

import TreeItem from './TreeItem';

import { StateType, TaskTreePropsType, TTNodeType } from './interface';

const TaskTree: FunctionComponent<TaskTreePropsType> = observer(
    ({ parentTask, tree, nodeSelect }) => {
        const { content } = ConfigurationStore;

        const calcIcon = (state: StateType): any => {
            switch (state) {
                case 'A':
                    return SmartDisplay;

                case 'P':
                    return FolderOpen;

                case 'C':
                    return Clear;

                case 'F':
                    return CheckBox;

                case 'I':
                    return Report;

                case 'W':
                    return FiberManualRecordOutlined;

                case 'R':
                    return CheckBoxOutlineBlank;

                default:
                    return FolderOpen;
            }
        };

        const calcExecModeIcon = (exec_mode: 'P' | 'C'): any =>
            exec_mode === 'P' ? DensityMedium : FormatListNumbered;

        const generateParentTaskLabel = () => {
            const taskType = getLocalizedString(parentTask.label);
            const taskNumber = parentTask.task_number ? `[№ ${parentTask.task_number}]` : null;
            const taskName = getLocalizedString(parentTask.task_name);

            return `${taskType}${taskNumber || taskName ? ':' : ''} ${taskNumber || ''} ${
                taskName || ''
            }`;
        };

        const generateTaskLabel = (node: TTNodeType) => {
            const execType =
                node.exec_mode === 'P' ? 'Параллельное выполнение' : 'Последовательное выполнение';
            const label = getLocalizedString(node.label ?? '');
            const taskNumber = node.task_number ? `[№ ${node.task_number}]` : '';
            const taskName = getLocalizedString(node.task_name ?? '');

            return (
                `${label}${taskNumber || taskName ? `: ` : ''}${taskNumber}${taskName}` || execType
            );
        };

        const getWorkTime = (time: number) => {
            const workDays = calcWorkDays(time);
            const { days, hours, minutes } = workDays;

            const daysLabel = days ? `${days} дн. ` : '';
            const hoursLabel = hours ? `${hours} ч. ` : '';
            const minutesLabel = minutes ? `${minutes} мин. ` : '';

            return `${daysLabel}${hoursLabel}${minutesLabel}`;
        };

        const generateWorkDaysLabel = (plan: number, elapsed: number, state: string) => {
            if (!plan) return '';

            if (elapsed || elapsed === 0) {
                const remain = plan - elapsed;

                if (state === 'F') return `(потрачено: ${getWorkTime(elapsed)})`;

                return `(потрачено: ${getWorkTime(elapsed)}, осталось: ${getWorkTime(remain)})`;
            }

            return `(нужно: ${getWorkTime(plan)})`;
        };

        const generateWorkLabel = (node: TTNodeType) => {
            const label = getLocalizedString(node.label ?? '');
            const taskName = getLocalizedString(node.task_name ?? '');
            const executor = `${getLocalizedString(node.surname)} ${getLocalizedString(node.name)}`;
            const workTime = generateWorkDaysLabel(
                node.time_by_executor,
                node.elapsed_time,
                node.state
            );

            return `${node.node_num}. ${label} (${taskName}) - ${executor} ${workTime}`;
        };

        const renderTree = (nodeList: TTNodeType[], parentNode = '1') =>
            nodeList
                .slice()
                .sort((n1, n2) => {
                    if (n1?.exec_order > n2?.exec_order) return 1;

                    return n1?.exec_order < n2?.exec_order ? -1 : 0;
                })
                .map((node, index) => {
                    const nodeId = `${parentNode}-${index}`;

                    const handleNodeSelect = () => {
                        nodeSelect && nodeSelect(node.tt_node_id);
                    };

                    return (
                        <TreeItem
                            key={index}
                            nodeId={nodeId}
                            labelText={
                                node.node_type === 'T'
                                    ? generateTaskLabel(node)
                                    : generateWorkLabel(node)
                            }
                            labelIcon={
                                node.node_type === 'T' &&
                                !node.label &&
                                !node.task_number &&
                                !node.task_name
                                    ? calcExecModeIcon(node.exec_mode)
                                    : calcIcon(node.state)
                            }
                            onClick={handleNodeSelect}
                        >
                            {node?.child_nodes ? renderTree(node?.child_nodes, nodeId) : null}
                        </TreeItem>
                    );
                });

        return tree?.length ? (
            <TreeView
                aria-label="taskTree"
                defaultExpanded={['0', '1']}
                defaultCollapseIcon={<ArrowDropDown />}
                defaultExpandIcon={<ArrowRight />}
                defaultEndIcon={<div style={{ width: 24 }} />}
                sx={{ flexGrow: 1, padding: 2 }}
            >
                <TreeItem
                    nodeId="0"
                    labelText={content.controls.taskTree.rootNode}
                    labelIcon={Folder}
                >
                    {parentTask ? (
                        <TreeItem
                            nodeId="1"
                            labelText={generateParentTaskLabel()}
                            labelIcon={calcIcon(parentTask.state)}
                        >
                            {renderTree(tree)}
                        </TreeItem>
                    ) : (
                        renderTree(tree)
                    )}
                </TreeItem>
            </TreeView>
        ) : (
            <Loading type="empty" message="Нет данных для построения" />
        );
    }
);

export default TaskTree;
