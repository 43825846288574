import React, { useState, FunctionComponent, useEffect } from 'react';
import { observer } from 'mobx-react';

import {
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Tooltip
} from '@mui/material';

import { Theaters } from '@mui/icons-material';

import Loading from 'components/utils/Loading/Loading';

import { ResourceClass } from 'store/resourceStore';
import ConfigurationStore from 'store/configurationStore';

import SearchPanel from './SearchPanel';

type RowItem = { guid: string; name: string; type: string };

interface ResListType {
    types: Array<string>;
    ResourceStore: ResourceClass;
    onResourceCreate?: any;
    onResourceSelect?: any;
}

const ResList: FunctionComponent<ResListType> = observer(
    ({ types, ResourceStore, onResourceCreate, onResourceSelect }) => {
        const {
            curGrp,
            setCurGrp,
            resList,
            resListLoading,
            parentResource,
            setPreviewResource,
            selectResource,
            setResLoading,
            initResource
        } = ResourceStore;
        const { resource } = ConfigurationStore.content;

        const [selectedItem, setSelectedItem] = useState<string | null>(null);

        useEffect(() => {
            if (curGrp) {
                setCurGrp(curGrp);
            } else {
                setCurGrp(types?.length ? types[1] : '');
            }
        }, []);

        const showResource = (guid: string) => {
            setSelectedItem(guid);

            if (curGrp === 'subForm') {
                initResource(
                    parentResource?.getSubForms()?.find(subForm => subForm.guid === guid) ?? null
                );
            } else {
                setResLoading(true);
                selectResource(guid).finally(() => {
                    setResLoading(false);
                });
                if (onResourceSelect && guid) onResourceSelect(guid);
            }
        };

        const handleClick = (guid: string) => {
            showResource(guid);
        };

        const renderRow = (item: RowItem, idx: number) => (
            <ListItem
                button
                key={idx}
                component="div"
                onClick={() => handleClick(item.guid)}
                selected={item.guid === selectedItem}
            >
                <ListItemText primary={item.name} />
                {item.type === 'wuiForm' ? (
                    <ListItemSecondaryAction>
                        <Tooltip title={resource.resList.show} aria-label="show">
                            <IconButton
                                edge="end"
                                aria-label="Theaters"
                                onClick={() => setPreviewResource(item.guid)}
                                size="large"
                            >
                                <Theaters />
                            </IconButton>
                        </Tooltip>
                    </ListItemSecondaryAction>
                ) : null}
            </ListItem>
        );

        const renderList = () => {
            if (!curGrp || !resList) {
                return null;
            }
            if (curGrp === 'subForm' && parentResource)
                return parentResource
                    ?.getSubForms()
                    .map((item: RowItem, idx: number) => renderRow(item, idx));

            const list = Object.values(resList);
            return list.map((item: RowItem, idx: number) => renderRow(item, idx));
        };

        return (
            <>
                <SearchPanel
                    types={types}
                    createResource={onResourceCreate}
                    showResource={showResource}
                    ResourceStore={ResourceStore}
                />
                <div
                    style={{
                        position: 'relative',
                        marginTop: 16,
                        overflow: 'auto',
                        height: `calc(100% - ${
                            16 +
                            (document.getElementById(`resource-search-panel`)?.offsetHeight ?? 0)
                        }px)`
                    }}
                >
                    {resListLoading ? (
                        <Loading message={resource.loading} />
                    ) : (
                        <List>{renderList()}</List>
                    )}
                </div>
            </>
        );
    }
);

export default ResList;
