import React, { FunctionComponent, ChangeEvent, useState, useEffect } from 'react';

import { TextField, InputAdornment } from '@mui/material';
import { Search } from '@mui/icons-material';

interface FastFilterPropsType {
    index: number;
    caption: string;
    value: string;
    ffObj: {
        fieldName: string;
        value: string;
        operator: string;
    };
    setFFObj: (index: number, ffObj: { [key: string]: any }) => void;
}

const FastFilter: FunctionComponent<FastFilterPropsType> = ({
    index,
    caption,
    value,
    ffObj,
    setFFObj
}) => {
    const [filterVal, setFilterVal] = useState(value);

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFilterVal(event.target.value);
        setFFObj(index, { ...ffObj, value: event.target.value });
    };

    useEffect(() => {
        if (!value) setFilterVal('');
    }, [value]);

    return (
        <TextField
            size="small"
            label={caption}
            variant="outlined"
            onChange={handleChange}
            value={filterVal}
            fullWidth
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <Search />
                    </InputAdornment>
                )
            }}
        />
    );
};

export default FastFilter;
