import { DatasetType, FormEventType } from 'forms/interfaces';

import PropContainer from 'dataObj/PropContainer';

import Event from './Event';

export default class ResourceEvents {
    private descr;
    private events: { [triggerName: string]: Event[] } = {};

    constructor(descr: DatasetType, frmEvents: FormEventType[], propContainer: PropContainer) {
        this.descr = descr;

        if (frmEvents?.length) {
            frmEvents.map(event => {
                const events = this.events[event.trigger];

                if (events?.length) {
                    return [...events, ...[new Event(event, propContainer)]];
                }

                this.events[event.trigger] = [new Event(event, propContainer)];
                return;
            });
        }
    }

    triggerEvent = async (
        triggerName: string,
        eventPayload?: { [key: string]: string | boolean }
    ) => {
        const trigger = this.events[triggerName];

        if (trigger?.length) {
            return Promise.all(trigger.map(async event => event.execute(eventPayload)));
        }
    };
}
