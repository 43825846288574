import React, { useState, FunctionComponent } from 'react';
import { observer } from 'mobx-react';

import { Grid, IconButton } from '@mui/material';
import { Remove, Add, ArrowUpward, ArrowDownward } from '@mui/icons-material';
import {
    DataGrid,
    GridSelectionModel,
    GridRowId,
    GridCellEditCommitParams
} from '@mui/x-data-grid';

import { SimpleObject } from 'forms/interfaces';
import { arrayMove } from 'utils';
import ConfigurationStore from 'store/configurationStore';

import { fieldListColumns } from '../GridSettingsConst';

import SelectFields from './SelectFields';

import { GridListType } from '../index';

type ActionButtonCfgType = {
    color:
        | 'inherit'
        | 'default'
        | 'primary'
        | 'secondary'
        | 'error'
        | 'info'
        | 'success'
        | 'warning';
    icon: FunctionComponent;
    onlySelected?: boolean;
    onClick: () => unknown;
};

const GridList: FunctionComponent<GridListType> = observer(
    ({ columns, rows, setRows, fieldList, actions, defaultItem }: GridListType) => {
        const content = ConfigurationStore.content.resource.gridProps;

        const [openSelect, setOpenSelect] = useState(false);
        const [selectedRows, setSelectedRows] = useState<GridRowId[]>([]);
        actions = actions || [];
        defaultItem = defaultItem || {};

        const getIndex = (arr: SimpleObject[], id: unknown) => rows.findIndex(row => row.id === id);

        const moveRecord = (up: boolean) => {
            const arr = [...rows];
            selectedRows.forEach(row => {
                const idx = getIndex(arr, row);
                const toIdx = (up ? -1 : 1) + idx;
                arrayMove(arr, idx, toIdx);
            });
            setRows(arr);
        };
        const removeRecord = () => {
            const arr = [...rows];
            selectedRows.forEach(row => arr.splice(getIndex(arr, row), 1));
            setRows(arr);
        };

        const actionButtons = () => {
            if (!actions) {
                return null;
            }

            const actionButtonCfg: { [key: string]: ActionButtonCfgType } = {
                create: {
                    color: 'primary',
                    icon: Add,
                    onClick: () =>
                        setRows([
                            ...rows,
                            typeof defaultItem === 'function' ? defaultItem() : defaultItem
                        ])
                },
                add: { color: 'primary', icon: Add, onClick: () => setOpenSelect(true) },
                remove: {
                    color: 'secondary',
                    icon: Remove,
                    onlySelected: true,
                    onClick: () => removeRecord()
                },
                up: {
                    color: 'default',
                    icon: ArrowUpward,
                    onlySelected: true,
                    onClick: () => moveRecord(true)
                },
                down: {
                    color: 'default',
                    icon: ArrowDownward,
                    onlySelected: true,
                    onClick: () => moveRecord(false)
                }
            };
            return (
                <Grid item>
                    {Object.keys(actionButtonCfg)
                        .filter((name: string) => actions?.includes(name))
                        .map((name, idx) => {
                            const item = actionButtonCfg[name];
                            return (
                                <IconButton
                                    key={idx}
                                    color={item.color}
                                    onClick={item.onClick}
                                    disabled={item.onlySelected && selectedRows.length === 0}
                                >
                                    {React.createElement(item.icon)}
                                </IconButton>
                            );
                        })}
                </Grid>
            );
        };

        const SelectionModelChange = (model: GridSelectionModel) => setSelectedRows(model);

        const onCellEditCommit = (params: GridCellEditCommitParams) => {
            // params
            const row = Object.values(rows).find(item => item.id === params.id);
            if (row) {
                row[params.field] = params.value;
            }
            setRows(rows);
        };

        const selectFieldsClose = (result: unknown) => {
            setOpenSelect(false);
            // Исключить повтор выбора
            if (fieldList && result && Array.isArray(result) && result.length > 0) {
                const dataRows = [...rows];
                result.forEach((newId: unknown) => {
                    if (rows.find(row => row.id === newId)) {
                        return;
                    }
                    const newRow = fieldList.find(field => field.id === newId);

                    const item = typeof defaultItem === 'function' ? defaultItem() : defaultItem;
                    dataRows.push({ ...newRow, ...item });
                });
                setRows(dataRows);
            }
        };

        return (
            <Grid container direction="column" sx={{ height: '100%' }}>
                {actionButtons()}
                <Grid item flex="1 1">
                    <DataGrid
                        columns={columns}
                        rows={rows}
                        onSelectionModelChange={SelectionModelChange}
                        onCellEditCommit={onCellEditCommit}
                        // style={{ height: '100%' }}
                        hideFooter
                        getRowId={row => row.id ?? row.name}
                    />
                    <SelectFields
                        setRows={() => {}}
                        open={openSelect}
                        columns={fieldListColumns(content)}
                        rows={fieldList || []}
                        handleClose={selectFieldsClose}
                    />
                </Grid>
            </Grid>
        );
    }
);

export default GridList;
