import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';

import Typography from '@mui/material/Typography';

import SimpleDialog from 'components/Feedback/SimpleDialog';

import ActionStore from 'store/actionStore';

import { OutputType, ResultType } from './interfaces';

import ExtForm from './ExtForm';

const ScriptButtonOutput: FunctionComponent<OutputType> = observer(({ scriptButtonStore }) => {
    const {
        form,
        formDescr,
        cb,
        parentPropContainer,
        parentDataStock,
        formName,
        values,
        editMode,
        modalMessage,
        setForm,
        setFormDescr,
        setValues,
        setModalMessage
    } = scriptButtonStore;

    const formActions = ActionStore.getFormActions(formDescr?.guid || form);

    const handleFormClose = (state: boolean | object) => {
        const hideForm = () => {
            setForm(null);
            setFormDescr(null);
        };

        if (state && typeof state === 'boolean' && formActions) {
            formActions
                .save()
                .then((result?: ResultType) => {
                    if (!result?.err?.length) {
                        hideForm();
                    }
                })
                .catch(err => console.error(err.message));
        } else if (state) {
            setValues([state]);
            hideForm();
        } else {
            hideForm();
        }

        cb && cb()(state, formActions ? formActions.getDataStock() : null);
    };

    return (
        <>
            {(form || formDescr) && (
                <ExtForm
                    guid={form}
                    descr={formDescr}
                    formName={formName}
                    values={values}
                    editMode={editMode}
                    handleClose={handleFormClose}
                    parentDataStock={parentDataStock}
                    parentPropContainer={parentPropContainer}
                />
            )}
            {modalMessage && modalMessage.type ? (
                <SimpleDialog editorName="Сообщение" handleClose={() => setModalMessage({})}>
                    <Typography>{modalMessage.text}</Typography>
                </SimpleDialog>
            ) : null}
        </>
    );
});

export default ScriptButtonOutput;
