import DataStore from '../store/dataStore';

//ГРУППА ФОРМУЛ
export class FormulaGroup {
    constructor(key) {
        this.key = key;
        this.arrFormula = [];
        this.arrParam = [];
        this.arrParamVal = [];
        this.arrSortedFormulaIndexes = [];
    }

    findParamByText(text) {
        return this.arrParam.find(p => p.text === text);
    }

    /**
     * Ищем параметры которые беруться из полей данного датасета
     * @param {*} datasetName
     * @returns
     */
    findParamsByDataset(datasetName) {
        return this.arrParam.filter(p => p.datasetName === datasetName);
    }

    /**
     * Ищем формулы которые заполняют данный датасет
     * @param {*} datasetName
     */
    findFormulasOfDataset(datasetName) {
        return this.arrSortedFormulaIndexes
            .filter(fIdx => this.arrFormula[fIdx].datasetName === datasetName)
            .map(fIdx => this.arrFormula[fIdx]);
    }

    loadParametersValues(paramIndexes) {
        const loadParam = par => {
            if (par.ds) {
                this.arrParamVal[par.index] = par.ds.getFieldValue(par.fieldName);
            } else if (par.paramType === 'af') {
                this.arrParamVal[par.index] = DataStore.AF.getAF(par.fieldName);
            } else if (par.paramType === 'extern') {
                this.arrParamVal[par.index] = par.dataStock.extParamVals[par.fieldName];
            }
        };

        if (!paramIndexes) {
            //читаем все параметры группы
            this.arrParam.forEach((par, ind) => {
                loadParam(par);
            });
        } else {
            //читаем параметры по списку индексов
            paramIndexes.forEach(ind => {
                let par = this.arrParam[ind];
                loadParam(par);
            });
        }
    }

    writeFormulasResults(arrFormulaIndexes) {
        arrFormulaIndexes.forEach(ind => {
            let f = this.arrFormula[ind];
            if (f.resultType === 'prop') {
                if (f.propContainer) {
                    f.propContainer.setElemProperty(f.propElem, f.propName, f.lastResult);
                }
            } else if (f.ds) {
                if (f.ds.inEdit) {
                    f.ds.setFieldValue(f.resField, f.lastResult);
                }
            }
        });
    }

    async recalcFormulas(arrFormulaIndexes) {
        if (!arrFormulaIndexes) arrFormulaIndexes = this.arrSortedFormulaIndexes;

        for (let i = 0; i < arrFormulaIndexes.length; i++) {
            let ind = arrFormulaIndexes[i];
            await this.arrFormula[ind].calcAsync(this.arrParamVal);
        }

        return arrFormulaIndexes; //возвращаем индексы формул, которые пересчитали
    }
} //class FormulaGroup

//КЛАСС ФОРМУЛЫ
export class FormulaObj {
    constructor() {
        this.index = -1;
        this.code = '';
        this.originText = '';
        this.calculator = undefined;
        this.group = undefined; //undefined - чтобы не сериализовать при экспорте в JSON
        this.parsedText = undefined;
        this.ownedParamIndexes = []; //массив номеров параметров для импорта и последующей сериализации
        this.parentFormulaIndexes = [];
        this.func = undefined;
        this.lastResult = undefined;
        this.lastError = undefined;

        this.resultType = undefined; // field | prop
        // for prop
        this.ctrlGuid = undefined;
        this.propName = undefined;
        // for field
        this.datasetName = undefined;
        this.resField = undefined;

        this.resultParamIndex = -1;
    }

    get name() {
        return 'F' + this.index;
    }

    createFunction() {
        let f;
        let formulaText;

        if (this.group.formulaLang === 'js') {
            formulaText = this.parsedText.trim();
            if (!formulaText.match(/\breturn\b/gim)) {
                formulaText = 'return ' + formulaText;
            }
        } else {
            formulaText = 'return ' + this.parsedText;
        }

        try {
            /*jshint evil: true */
            let AsyncFunction = Object.getPrototypeOf(async function () {}).constructor;
            f = new AsyncFunction(
                '$PAR',
                this.calculator.externFuncContainer.externFuncContainerName,
                'TARGET',
                formulaText
            );
        } catch (e) {
            console.error(`FrmFormulaObj error: ${e.message}; ${this.name} = ${formulaText}`);
            f = undefined;
        }

        this.func = f;
    }

    async calcAsync(arrArg) {
        let error = '';
        let res;

        if (this.func) {
            try {
                const propContainer = this?.calculator?.propContainer;
                let target;
                if (propContainer && this.resultType) {
                    target = propContainer.find(this.ctrlGuid);
                }
                res = await this.func(arrArg, this.calculator.externFuncContainer, target);
            } catch (e) {
                error = 'Error: ' + this.name + ': ' + e.message;
                console.error(error);
            }
        } else {
            error = 'FrmFormulaObj: function undefined: ' + this.name;
            console.error(error);
        }

        this.lastError = error;
        this.lastResult = res;
        if (this.resultParamIndex !== -1) {
            arrArg[this.resultParamIndex] = res;
        }
        return res;
    }
} //class FrmFormulaObj

//КЛАСС ПАРАМЕТРА ФОРМУЛЫ
export class FormulaParam {
    constructor(text) {
        this.text = text;
        this.index = -1;
        this.group = undefined;
        this.ownedFormulaIndexes = []; //Формулы, в которых присутствует данный параметр
        this.dependedFormulaIndexes = [];
        this.sourceFormulaIndex = -1; //Если какая-то формула вычисляет данный параметр здесь хранится ее индекс
        this.friendlyParams = []; //если изменился данный параметр, то для вычислений формул нужно запросить данный набор параметров.

        this.paramType = undefined; // field | prop
        // for prop
        this.ctrlGuid = undefined;
        this.propName = undefined;

        // for field
        this.datasetName = undefined;
        this.fieldName = undefined;
    }
} //class FrmFormulaParam
