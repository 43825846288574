import React, { useState, useMemo, FunctionComponent } from 'react';
import { observer } from 'mobx-react';

import { Table, TableBody, TableRow, TableCell, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import PropContainer from 'dataObj/PropContainer';

import styles from '../../form.module.scss';

import DataLine from './DataLine/DataLine';

interface PropsType {
    index: number;
    dataGroup: any;
    gridDescr: any;
    gridWidth: number;
    dataset: any;
    columns: Array<any>;
    enabled: boolean;
    controlGrid?: any;
    propContainer: PropContainer;
}

const DataGroup: FunctionComponent<PropsType> = observer(
    ({
        index,
        dataGroup,
        gridDescr,
        gridWidth,
        dataset,
        columns,
        enabled,
        controlGrid,
        propContainer
    }) => {
        const theme = useTheme();

        const [opened, setOpened] = useState(true);

        const label: any = useMemo(
            () =>
                gridDescr?.columns?.find((col: any) => col.fieldName === dataGroup?.grFld)
                    ?.caption || '',
            [gridDescr]
        );

        const isColoredDataLine = useMemo(
            () =>
                dataset.data.some((obj: any) =>
                    Object.keys(obj).some(
                        key =>
                            key.startsWith('CLR_CELLFOR_') ||
                            key.startsWith('CLR_ROW_') ||
                            key.startsWith('CLR_CELL_')
                    )
                ),
            [dataset]
        );

        const cldGroups: Array<any> = [];
        if (dataGroup?.cldGroups) dataGroup.cldGroups.forEach((gr: any) => cldGroups.push(gr));

        const getGroupContent = () => (
            <>
                {dataGroup?.level ? (
                    <TableRow className={styles.groupHeader}>
                        <TableCell
                            colSpan={columns.length + (dataset.multiSelect ? 1 : 0)}
                            sx={{ p: 2, pl: 1 }}
                        >
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => setOpened(!opened)}
                                sx={{ mr: 1 }}
                            >
                                {opened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                            <strong>
                                {label ? `${label as string}: ` : null}
                                {dataGroup.grVal}
                            </strong>
                        </TableCell>
                    </TableRow>
                ) : null}
                {opened && cldGroups
                    ? cldGroups.map((group: any, i: number) => (
                          <DataGroup
                              key={i}
                              index={i}
                              dataGroup={group}
                              gridDescr={gridDescr}
                              gridWidth={gridWidth}
                              dataset={dataset}
                              columns={columns}
                              enabled={enabled}
                              controlGrid={controlGrid}
                              propContainer={propContainer}
                          />
                      ))
                    : null}
                {opened &&
                    dataGroup?.recs.map((num: number) => (
                        <DataLine
                            key={num}
                            dataset={dataset}
                            gridDescr={gridDescr}
                            columns={columns}
                            dataRow={{ ...dataset.data[num] }}
                            enabled={enabled}
                            controlGrid={controlGrid}
                            isColored={isColoredDataLine}
                            propContainer={propContainer}
                        />
                    ))}
                {opened && dataGroup?.accum?.aggrFields?.length ? (
                    <TableRow className={styles.groupFooter}>
                        {dataset.multiSelect ? (
                            <TableCell padding="checkbox" style={{ borderBottom: 'none' }} />
                        ) : null}
                        {columns.map((col: any, i: number) => (
                            <TableCell
                                key={i}
                                size="small"
                                style={{
                                    textAlign: 'right',
                                    borderBottom: 'none'
                                }}
                            >
                                <strong>{dataGroup.aggr[col.fieldName]?.value}</strong>
                            </TableCell>
                        ))}
                    </TableRow>
                ) : null}
            </>
        );

        return dataGroup?.level > 1 ? (
            <TableRow>
                <TableCell sx={{ p: 0, borderBottom: 'none' }}>
                    <Table
                        sx={{
                            position: 'relative',
                            m: (dataGroup.level - 1) * 4,
                            mt: index > 0 ? 0 : 4,
                            mb: index === (dataGroup.parent.cldGroups?.size ?? 0) - 1 ? 4 : 0,
                            width: `calc(${gridWidth}px - ${theme.spacing(
                                (dataGroup.level - 1) * 12
                            )})`,
                            border: '1px solid #d9deec'
                        }}
                    >
                        <TableBody>{getGroupContent()}</TableBody>
                    </Table>
                </TableCell>
            </TableRow>
        ) : (
            getGroupContent()
        );
    }
);

export default DataGroup;
