import React, { useState, FunctionComponent } from 'react';
import { observer } from 'mobx-react';

import { Grid, IconButton, FormControl, InputLabel, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Remove, Add, ArrowUpward, ArrowDownward } from '@mui/icons-material';
import {
    DataGrid,
    GridSelectionModel,
    GridRowId,
    GridCellEditCommitParams
} from '@mui/x-data-grid';

import { DatasetFieldType, SimpleObject } from 'forms/interfaces';

import { arrayMove } from 'utils';

import { FieldInfoType, GridListType } from '../index';

import ImportFields from '../../ResCtrlImportFields/ImportFields'; // ?

type ActionButtonCfgType = {
    color:
        | 'inherit'
        | 'default'
        | 'primary'
        | 'secondary'
        | 'error'
        | 'info'
        | 'success'
        | 'warning';
    icon: FunctionComponent;
    onlySelected?: boolean;
    onClick: () => unknown;
};

const GridList: FunctionComponent<GridListType> = observer(
    ({ columns, rows, setRows, actions, defaultItem, ResourceStore }: GridListType) => {
        const [openSelect, setOpenSelect] = useState(false);
        const [selectedRows, setSelectedRows] = useState<GridRowId[]>([]);
        const [dsFilter, setDsFilter] = useState('Все');

        actions = actions || [];
        defaultItem = defaultItem || {};

        const getIndex = (arr: SimpleObject[], id: unknown) => rows.findIndex(row => row.id === id);

        const moveRecord = (up: boolean) => {
            const arr = [...rows];
            selectedRows.forEach(row => {
                const idx = getIndex(arr, row);
                const toIdx = (up ? -1 : 1) + idx;
                arrayMove(arr, idx, toIdx);
            });
            setRows(arr);
        };
        const removeRecord = () => {
            const arr = [...rows];
            selectedRows.forEach(row => arr.splice(getIndex(arr, row), 1));
            setRows(arr);
        };

        const actionButtons = () => {
            if (!actions) {
                return null;
            }

            const actionButtonCfg: { [key: string]: ActionButtonCfgType } = {
                create: {
                    color: 'primary',
                    icon: Add,
                    onClick: () =>
                        setRows([
                            ...rows,
                            typeof defaultItem === 'function' ? defaultItem() : defaultItem
                        ])
                },
                add: { color: 'primary', icon: Add, onClick: () => setOpenSelect(true) },
                remove: {
                    color: 'secondary',
                    icon: Remove,
                    onlySelected: true,
                    onClick: () => removeRecord()
                },
                up: {
                    color: 'default',
                    icon: ArrowUpward,
                    onlySelected: true,
                    onClick: () => moveRecord(true)
                },
                down: {
                    color: 'default',
                    icon: ArrowDownward,
                    onlySelected: true,
                    onClick: () => moveRecord(false)
                }
            };
            return (
                <Grid item container direction="row">
                    {Object.keys(actionButtonCfg)
                        .filter((name: string) => actions?.includes(name))
                        .map((name, idx) => {
                            const item = actionButtonCfg[name];
                            return (
                                <Grid item sx={{ mt: 'auto', mb: 'auto' }}>
                                    <IconButton
                                        key={idx}
                                        color={item.color}
                                        onClick={item.onClick}
                                        disabled={item.onlySelected && selectedRows.length === 0}
                                    >
                                        {React.createElement(item.icon)}
                                    </IconButton>
                                </Grid>
                            );
                        })}
                    <Grid item sx={{ ml: 'auto' }}>
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel id="dataset-selector-label">Датасет</InputLabel>
                            <Select
                                id="dataset-selector"
                                value={dsFilter}
                                label="Датасет"
                                onChange={(e: SelectChangeEvent) => setDsFilter(e.target.value)}
                            >
                                <MenuItem value="Все">
                                    <em>Все</em>
                                </MenuItem>
                                {rows
                                    .map(row => row?.dataset)
                                    .filter((ds, index, array) => array.indexOf(ds) === index)
                                    .map(ds => (
                                        <MenuItem value={ds}>{ds}</MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            );
        };

        const SelectionModelChange = (model: GridSelectionModel) => setSelectedRows(model);

        const onCellEditCommit = (params: GridCellEditCommitParams) => {
            // params
            const row = Object.values(rows).find(item => item.id === params.id);
            if (row) {
                row[params.field] = params.value;
            }
            setRows(rows);
        };

        const checkFields = (dsName: string, field: DatasetFieldType) =>
            !rows.find(row => row.id === field.name) && !field.hidden;

        const handleConfirm = (
            ds: string[],
            fld: string[],
            fieldsList: { [dsName: string]: DatasetFieldType[] }
        ) => {
            let newFields: FieldInfoType[] = [];

            ds.map(dsName => {
                newFields = [
                    ...newFields,
                    ...fieldsList[dsName]
                        .filter(field => fld.includes(field.guid))
                        .map(field => ({
                            id: field.name,
                            name: field.name,
                            dataset: dsName,
                            width: ''
                        }))
                ];
                return;
            });

            setRows([...rows, ...newFields]);
        };

        return (
            <Grid container direction="column" sx={{ height: '100%' }}>
                {actionButtons()}
                <Grid item flex="1 1">
                    <DataGrid
                        columns={columns}
                        rows={rows.filter(row =>
                            dsFilter !== 'Все' ? row.dataset === dsFilter : true
                        )}
                        onSelectionModelChange={SelectionModelChange}
                        onCellEditCommit={onCellEditCommit}
                        hideFooter
                        getRowId={row => row.id ?? row.name}
                    />
                    <ImportFields
                        open={openSelect}
                        setOpen={setOpenSelect}
                        ResourceStore={ResourceStore}
                        checkFields={checkFields}
                        onConfirm={handleConfirm}
                    />
                </Grid>
            </Grid>
        );
    }
);

export default GridList;
