import { action, makeAutoObservable, observable, set, get, remove } from 'mobx';

import PropContainer from 'dataObj/PropContainer';

import ResourceEvents from './ResourceEvents';
import { filterEventType } from './eventTriggers';

export class Events {
    @observable events: { [key: string]: ResourceEvents } = {};

    constructor() {
        makeAutoObservable(this);
    }

    @action addEvents = (descr: any, events: Array<any>, propContainer: PropContainer) => {
        const availableEvents = events.filter(event => filterEventType(descr.type, event));

        if (availableEvents?.length) {
            set(this.events, { [descr.guid]: new ResourceEvents(descr, events, propContainer) });
        }

        return this.getEvents(descr.guid);
    };

    @action removeEvents = (guid: string) => {
        for (const key in this.events) {
            if (key.includes(guid)) {
                remove(this.events, key);
            }
        }
    };

    getEvents = (guid?: string): ResourceEvents => {
        const eventEmitter = guid ? get(this.events, guid) : null;
        return eventEmitter;
    };
}

const EventsStore = new Events();
export default EventsStore;
