import useMediaQuery from '@mui/material/useMediaQuery';
import PropContainer from '../dataObj/PropContainer';

import useCtrlProps from './ctrlProps';

export default function useHidden(
    descr: any,
    gridBreakpoint: { [key: string]: any },
    propContainer: PropContainer
) {
    const { guid } = descr;
    const { ctrlHidden } = useCtrlProps(propContainer, guid);

    if (ctrlHidden) return true;

    if (descr.gridWidth?.toLowerCase().replace(/\s/g, '') === 'hidden') return true;

    let result = false;

    Object.keys(gridBreakpoint)
        .filter(key => gridBreakpoint[key] === 'hidden')
        .forEach(breakpoint => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            if (useMediaQuery((theme: any) => theme.breakpoints.only(breakpoint))) {
                result = true;
            }
        });

    return result;
}
