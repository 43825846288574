import React, { FunctionComponent, useState } from 'react';
import { observer } from 'mobx-react';

import { Box, Button } from '@mui/material';

import SimpleDialog from 'components/Feedback/SimpleDialog';

import { ResourceClass } from 'store/resourceStore';
import ConfigurationStore from 'store/configurationStore';

import GridList from '../../gridSettings/GridSettings/GridList';

import { GroupFieldsListType, GroupRowProps } from '../index';
import { fieldListColumns } from '../CDOSettingsConst';

interface PropsType {
    group: GroupRowProps;
    ResourceStore: ResourceClass;
    updateGroup: (fields: GroupFieldsListType[]) => unknown;
}

const GroupFieldsList: FunctionComponent<PropsType> = observer(
    ({ group, ResourceStore, updateGroup }) => {
        const content = ConfigurationStore.content.resource.cdoProps;

        const [open, setOpen] = useState(false);
        const [fields, setFields] = useState<GroupFieldsListType[]>([]);
        const [fieldList, setFieldList] = useState<GroupFieldsListType[]>([]);

        const handleClose = (result: boolean) => {
            if (result) {
                updateGroup(fields);
            }

            setOpen(false);
        };

        return (
            <>
                <Button
                    onClick={() => {
                        const curFields = ResourceStore.getDatasetFields(group.row.dataset)
                            .filter(
                                field =>
                                    !field.hidden &&
                                    !group.value?.find((fld: any) => fld.name === field.name)
                            )
                            .map(field => ({ id: field.name, caption: field.name }));

                        setFields(group.value);
                        setFieldList(curFields);
                        setOpen(true);
                    }}
                >
                    {content.selectFields}
                </Button>
                {open ? (
                    <SimpleDialog handleClose={handleClose}>
                        <Box sx={{ height: 500 }}>
                            <GridList
                                columns={fieldListColumns(content)}
                                rows={fields}
                                setRows={setFields}
                                actions={['add', 'remove', 'up', 'down']}
                                fieldList={fieldList}
                            />
                        </Box>
                    </SimpleDialog>
                ) : null}
            </>
        );
    }
);

export default GroupFieldsList;
