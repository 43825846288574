import enLocale from './enLocale';
import ruLocale from './ruLocale';
import frLocale from './frLocale';
import esLocale from './esLocale';

const content = {
    rus: ruLocale,
    fra: frLocale,
    esp: esLocale,
    eng: enLocale
};

export default content;
