import { FunctionComponent, useState } from 'react';
import { observer } from 'mobx-react';

import { Stack, Button, IconButton, Tooltip } from '@mui/material';
import { Refresh, Settings } from '@mui/icons-material';

import SimpleDialog from 'components/Feedback/SimpleDialog';
import GridSettings, {
    GridSettingActions
} from 'components/resources/ResEditor/gridSettings/GridSettings/GridSettings';
import ComplexButton, { ComplexButtonConfig } from 'components/Inputs/ComplexButton/ComplexButton';
import ChartSettings from 'components/resources/ResEditor/ChartSettings/ChartSettings';

import { SettingType } from 'store/resourceStore';
import ConfigurationStore from 'store/configurationStore';
import DescriptorStore from 'store/descriptorStore';

import styles from 'forms/form.module.scss';

import { SettingsPropsType } from './interface';

const BREAKPOINT_WIDTH = 400;

const getCustomActions = (
    optionsActions: GridSettingActions,
    contentSettings: Record<string, string>
) => {
    const settingActions: ComplexButtonConfig[] = [
        {
            id: 'load',
            label: contentSettings.load,
            actions: [
                {
                    id: 'load_user',
                    label: contentSettings.user,
                    onClick: () => optionsActions.load(SettingType.user)
                },
                {
                    id: 'load_global',
                    label: contentSettings.global,
                    onClick: () => optionsActions.load(SettingType.global)
                }
            ]
        },
        {
            id: 'save',
            label: contentSettings.save,
            actions: [
                {
                    id: 'save_user',
                    label: contentSettings.user,
                    onClick: () => optionsActions.save(SettingType.user)
                },
                {
                    id: 'save_global',
                    label: contentSettings.global,
                    onClick: () => optionsActions.save(SettingType.global)
                }
            ]
        },
        {
            id: 'del',
            label: contentSettings.delete,
            actions: [
                {
                    id: 'del_user',
                    label: contentSettings.user,
                    onClick: () => optionsActions.delete(SettingType.user)
                },
                {
                    id: 'del_all_user',
                    label: contentSettings.allUsers,
                    onClick: () => optionsActions.delete(SettingType.allUsers)
                },
                {
                    id: 'del_global',
                    label: contentSettings.global,
                    onClick: () => optionsActions.delete(SettingType.global)
                }
            ]
        }
    ];

    return settingActions.map(action => <ComplexButton key={action.id} config={action} />);
};

const SettingsBar: FunctionComponent<SettingsPropsType> = observer((props: SettingsPropsType) => {
    const { dataset, refreshGrid, gridWidth, owner } = props;
    const { content } = ConfigurationStore;

    const [optionsActions, setOptionsActions] = useState<GridSettingActions>();
    const [showOptions, setShowOptions] = useState(false);

    const handleCloseOptions = (state: boolean) => {
        if (state && optionsActions && owner?.resource) {
            const value = optionsActions.getValue() as string;

            owner
                .setOptions(JSON.parse(value) as object)
                .then(() => {
                    if (dataset?.dataStock?.formGuid) {
                        DescriptorStore.removeDescriptor(dataset.dataStock.formGuid);
                    }
                })
                .catch(error => console.error(error));
        }
        setShowOptions(false);
    };

    const isChart = owner.resource.type === 'chart';

    const getEditor = () =>
        showOptions && owner ? (
            <SimpleDialog
                editorName={content.controls.buttons.edit}
                handleClose={handleCloseOptions}
                customActions={() =>
                    optionsActions
                        ? getCustomActions(optionsActions, content.application.settings)
                        : undefined
                }
            >
                {isChart ? (
                    <ChartSettings
                        setActions={setOptionsActions}
                        type={owner.resource.type}
                        resource={dataset.dataStock.formDescr}
                        control={owner.resource}
                        prop="options"
                        value={JSON.stringify(owner.getOptions())}
                    />
                ) : (
                    <GridSettings
                        setActions={setOptionsActions}
                        type={owner.resource.type}
                        resource={dataset.dataStock.formDescr}
                        control={owner.resource}
                        prop="options"
                        value={JSON.stringify(owner.getOptions())}
                    />
                )}
            </SimpleDialog>
        ) : null;

    return (
        <>
            <Stack
                spacing={1}
                direction="row"
                className={styles.gridRefresh}
                sx={{
                    display: gridWidth >= BREAKPOINT_WIDTH ? 'flex' : 'none'
                }}
            >
                {!owner.getOptions()?.configuration?.hideRefresh ? (
                    <Button variant="text" onClick={refreshGrid} startIcon={<Refresh />}>
                        {content.controls.buttons.refresh}
                    </Button>
                ) : null}
                {dataset?.dataStock && owner?.resource ? (
                    <Button
                        variant="text"
                        onClick={() => setShowOptions(true)}
                        startIcon={<Settings />}
                    >
                        {content.controls.buttons.options}
                    </Button>
                ) : null}
            </Stack>
            <Stack
                spacing={1}
                direction="row"
                className={styles.gridRefresh}
                sx={{
                    ml: 'auto',
                    display: gridWidth < BREAKPOINT_WIDTH ? 'flex' : 'none'
                }}
            >
                {!owner.getOptions()?.configuration?.hideRefresh ? (
                    <Tooltip title={content.controls.buttons.refresh}>
                        <IconButton onClick={refreshGrid} color="primary" size="small">
                            <Refresh fontSize="small" />
                        </IconButton>
                    </Tooltip>
                ) : null}
                {dataset?.dataStock && owner?.resource ? (
                    <Tooltip title={content.controls.buttons.options}>
                        <IconButton
                            onClick={() => setShowOptions(true)}
                            color="primary"
                            size="small"
                        >
                            <Settings fontSize="small" />
                        </IconButton>
                    </Tooltip>
                ) : null}
            </Stack>
            {getEditor()}
        </>
    );
});

export default SettingsBar;
