import { useState, FunctionComponent, useEffect } from 'react';

import { ControlPropsType, ResourceInfo } from 'forms/interfaces';
import CustomForm from 'forms/CustomForm';
import { generateGrid } from 'forms/form-utils';

import useGenerateForm from 'hooks/generateForm';

interface LookupFormProps extends ControlPropsType {
    resource: any;
    setGeneratedFrmGuid: any;
}

const LookupForm: FunctionComponent<LookupFormProps> = ({
    resource,
    propContainer,
    setGeneratedFrmGuid
}) => {
    const [formDescr, setFormDescr] = useState<ResourceInfo | null>(null);

    const generatedDescr = useGenerateForm(resource, propContainer, generateGrid, 'lookupForm');

    useEffect(() => {
        if (generatedDescr && !formDescr) {
            setFormDescr(generatedDescr);
            setGeneratedFrmGuid(generatedDescr.guid);
        }
    }, [generatedDescr]);

    return formDescr ? <CustomForm key={formDescr.guid} formConfig={formDescr} /> : null;
};

export default LookupForm;
