import AFContainer from 'dataObj/afContainer';
import Dataset from 'dataObj/customDataset';
import DataStock from 'dataObj/DataStock';
import PropContainer from 'dataObj/PropContainer';
import TaskQueue from 'dataObj/TaskQueue';
import FormulaCalculator from 'formulas/formula-calculator';

type DataCacheObj = { [key: string]: object[] };
export class DataStoreClass {
    readonly AF = new AFContainer(this);
    readonly taskQueue = new TaskQueue();
    readonly #setPropContainers = new Set<PropContainer>();
    readonly #setDataStocks = new Set<DataStock>();
    readonly #globalDataCache: DataCacheObj = {};

    registerForm(propContainer: PropContainer) {
        this.#setPropContainers.add(propContainer);
        this.#setDataStocks.add(propContainer.dataStock);
    }

    unregisterForm(propContainer: PropContainer) {
        // SubForm - не имеет собственного dataStock
        if (!propContainer.isSubForm) this.#setDataStocks.delete(propContainer.dataStock);
        this.#setPropContainers.delete(propContainer);
    }

    get dataStocks() {
        return Array.from(this.#setDataStocks);
    }

    get propContainers() {
        return Array.from(this.#setPropContainers);
    }

    get calculators() {
        return Array.from(this.#setPropContainers).map(pc => pc.formulaCalculator);
    }

    getPropContainersByDataStock(dataStock: DataStock) {
        const res: PropContainer[] = [];
        this.#setPropContainers.forEach((pc: PropContainer) => {
            if (pc.dataStock === dataStock) {
                res.push(pc);
            }
        });
        return res;
    }

    getCalculatorsByDataStock(dataStock: DataStock): FormulaCalculator[] {
        const res: FormulaCalculator[] = [];
        this.#setPropContainers.forEach((pc: PropContainer) => {
            if (pc.dataStock === dataStock) {
                res.push(pc.formulaCalculator);
            }
        });
        return res;
    }

    // Найти все датасеты, зависящие от активных фильтров
    getDependentDatasetsFromAF(afNames: string[]): Dataset[] {
        const res: Dataset[] = [];

        this.#setDataStocks.forEach(dataStock => {
            for (const dsName in dataStock.data) {
                const ds: Dataset = dataStock.getDatasetObj(dsName);
                const found = afNames.some(af => ds.masterAFs.includes(af));
                if (found && !res.includes(ds)) res.push(ds);
            }
        });
        return res;
    }

    distributeAF(afName: string) {
        const depDS = this.getDependentDatasetsFromAF([afName]);

        const recalcFormulas = () => {
            this.taskQueue
                .addFormulaTask({
                    taskType: 'onFieldModified',
                    datasetName: 'AF',
                    fieldName: afName
                })
                .catch(err => console.error(err.message));
        };

        if (depDS.length) {
            this.taskQueue
                .addLoadTask(depDS, recalcFormulas)
                .catch(err => console.error(err.message));
        } else {
            recalcFormulas();
        }
    }

    addDataToCache(key: string, data: object[]) {
        this.#globalDataCache[key] = data;
    }

    getDataFromCache(key: string): object[] {
        return this.#globalDataCache[key];
    }
}

const DataStore = new DataStoreClass();
export default DataStore;
