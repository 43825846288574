import React, { FunctionComponent, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    TextField,
    Tooltip
} from '@mui/material';
import PublicIcon from '@mui/icons-material/Public';

import InternationalContent from 'localization/internationalContent';

import ConfigurationStore from 'store/configurationStore';
import { Locale } from 'localization/interfaces';

const internationalContent = new InternationalContent();

const LOCALE_DIRECTION: Array<Locale> = ['rus', 'fra', 'esp', 'eng'];

const initLocales = (intContent: string, localeDirection: Array<Locale>) => {
    const result = {} as { [locale in Locale]: string };

    localeDirection.map(locale => {
        result[locale] = internationalContent.getLocalizedString(intContent, locale, false);
        return;
    });

    return result;
};

interface PropsType {
    intContent: string;
    fieldName: string;
    multiline?: boolean;
    handleOnChange: any;
}

const InternationalDialog: FunctionComponent<PropsType> = observer(
    ({ intContent, fieldName, multiline, handleOnChange }) => {
        const { content } = ConfigurationStore;

        const [open, setOpen] = useState(false);
        const [locale, setLoc] = useState<{ [locale in Locale]: string }>(
            initLocales(intContent, LOCALE_DIRECTION)
        );

        useEffect(() => {
            setLoc(initLocales(intContent, LOCALE_DIRECTION));
        }, [intContent]);

        const makeIntString = () =>
            LOCALE_DIRECTION.map(loc => (locale[loc] ? `#${loc}#${locale[loc]}` : '')).join('');

        const handleChangeLocale = (value: string, loc: Locale) => {
            setLoc({ ...locale, ...{ [loc]: value } });
        };

        const handleClose = () => {
            setOpen(false);
        };

        const handleSave = () => {
            handleOnChange(makeIntString());
            handleClose();
        };

        return (
            <>
                <Tooltip title={content.controls.internationalDialog.translation}>
                    <IconButton
                        onClick={() => setOpen(true)}
                        edge="end"
                        size="small"
                        sx={{ right: '-4px' }}
                    >
                        <PublicIcon />
                    </IconButton>
                </Tooltip>
                <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
                    <DialogTitle>{`${
                        content.controls.internationalDialog.translation as string
                    }: ${fieldName}`}</DialogTitle>
                    <DialogContent>
                        <Stack spacing={3} sx={{ pt: 2 }}>
                            {LOCALE_DIRECTION.map(loc => (
                                <TextField
                                    key={loc}
                                    label={content.controls.internationalDialog[loc]}
                                    onChange={e => handleChangeLocale(e.target.value, loc)}
                                    fullWidth
                                    multiline={multiline}
                                    size="small"
                                    value={locale[loc]}
                                />
                            ))}
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>{content.controls.buttons.cancel}</Button>
                        <Button onClick={handleSave}>{content.controls.buttons.ok}</Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
);

export default InternationalDialog;
