import { makeObservable, observable, action } from 'mobx';
import CustomDataset, { dsStates } from './customDataset';

class ClientDataset extends CustomDataset {
    keyValues = {};

    /**
     * Наследник CustomDataset
     * @param {*} params // параметр передаются в родительский класс
     */
    constructor(params) {
        super(params);
        this.state = dsStates.dsBrowse;

        makeObservable(this, {
            keyValues: observable,
            setKeyValue: action
        });
    }

    /**
     * key-val хранилище
     * @param {string|number} key
     * @param {unknown} value
     */
    setKeyValue(key, value) {
        this.keyValues[key] = value;
    }

    /**
     * ClientDataset не получает данные из базы
     */
    async loadData() {}

    /**
     * ClientDataset не получает данные из базы
     */
    async getDataByIdList() {}

    /**
     * Добавляем единственную запись и используем ee поля
     * для хранения локальных клиентских данных
     */
    async appendUniqueRecord() {
        if (!this.recCount) await this.append({ loadDependencies: false });
    }

    /**
     * Подкинуть данные
     * @param {object[]} data
     */
    setData(data) {
        this.currentDataChunk.addData(data);
    }
}

export default ClientDataset;
