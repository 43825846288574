import React, { FunctionComponent, useState } from 'react';
import { encode, decode } from 'js-base64';

import { Button, Box, Stack, Tooltip } from '@mui/material';

import IconEditorDialog from './IconEditorDialog';

import CustomSvgIcon from '../../../Image/CustomSvgIcon';

import ConfigurationStore from '../../../../store/configurationStore';
import NotificationStore from '../../../../store/notificationStore';
import { EventType } from '../ResInterfaces';

interface PropTypes {
    name: string;
    text: string;
    onConfirm: (icon: EventType) => unknown;
    fullColor?: boolean;
}

const DEFAULT_COLOR = 'inherit';
const PRIMARY_COLOR = 'primary';
const SECONDARY_COLOR = 'secondary';
const WARNING_COLOR = 'warning';

const colors = [DEFAULT_COLOR, PRIMARY_COLOR, SECONDARY_COLOR, WARNING_COLOR];

const IconPreview = (
    iconCode: string,
    fontSize?: number | 'inherit' | 'large' | 'medium' | 'small',
    props?: any
) => (
    <CustomSvgIcon
        icon={iconCode}
        {...props}
        {...(typeof fontSize === 'number' ? { sx: { fontSize } } : { fontSize })}
    />
);

const ResIconEditor: FunctionComponent<PropTypes> = ({
    name,
    text,
    onConfirm,
    fullColor = false
}) => {
    const { resource }: any = ConfigurationStore.content;

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = (code: string) => {
        try {
            // Проверяем файл svg на наличие тега <image>, не валидируем файл
            const validationPattern = /'<image.*?[^>]+>'/;

            if (!validationPattern.test(code)) {
                onConfirm({
                    target: {
                        name,
                        // Сохраняем в базе в base64
                        // Перед конвертацией заменяем значение fill на currentColor, но оставляем fill="none"
                        value: encode(
                            fullColor ? code : code.replace(/#[a-f0-9]{6}\b/gi, 'currentColor')
                        )
                    }
                } as EventType);
                handleClose();
            } else {
                NotificationStore.showAlert('Тег <image> не поддерживается');
            }
        } catch (err: any) {
            NotificationStore.showAlert(err.message, 'Ошибка');
        }
    };

    return (
        <Box sx={{ width: '100%', display: 'inline-flex' }}>
            <Stack direction="row" spacing={2}>
                {text &&
                    (!fullColor ? (
                        colors.map(color => (
                            <Tooltip title={color}>
                                <Box style={{ marginTop: 'auto' }}>
                                    {IconPreview(decode(text), 'medium', { color })}
                                </Box>
                            </Tooltip>
                        ))
                    ) : (
                        <Box style={{ marginTop: 'auto' }}>
                            {IconPreview(decode(text), 'medium')}
                        </Box>
                    ))}
            </Stack>
            <Button sx={{ ml: 'auto' }} onClick={handleOpen}>
                {resource.scriptEditor.change}
            </Button>
            {open && (
                <IconEditorDialog
                    open={open}
                    iconCode={decode(text)}
                    IconPreview={IconPreview}
                    handleClose={handleClose}
                    handleConfirm={handleConfirm}
                />
            )}
        </Box>
    );
};

export default ResIconEditor;
