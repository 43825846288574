import React, { FunctionComponent, useCallback, useState } from 'react';
import { observer } from 'mobx-react';

import { Box, Stack, Fade, IconButton, TextField, Tooltip } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField';

import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import FullscreenIcon from '@mui/icons-material/Fullscreen';

import ConfigurationStore from '../../../../store/configurationStore';

interface SimpleEditPropsType extends Omit<TextFieldProps, 'input'> {
    guid: string;
    type?: string;
    fullScreen?: boolean;
    visible?: boolean;
    isMuiltiline?: boolean;
    rows?: number;
    editType?: string;
    fieldRequired?: boolean;
    switchFullScreen: any;
    editRef?: any;
    inputRef?: any;
    handleOnChange: any;
    internationalDialog?: any;
    onScreen?: boolean;
}

const SimpleEdit: FunctionComponent<SimpleEditPropsType> = observer(
    ({
        guid,
        type,
        fullScreen,
        visible,
        isMuiltiline,
        rows,
        editType,
        fieldRequired,
        switchFullScreen,
        inputRef,
        editRef,
        handleOnChange,
        internationalDialog,
        onScreen,
        defaultValue,
        ...other
    }) => {
        const { content } = ConfigurationStore;

        const [focused, setFocused] = useState(false);

        const hasScrollbar =
            inputRef.current &&
            inputRef.current.type === 'textarea' &&
            inputRef.current.clientHeight < inputRef.current.scrollHeight;

        const handleMouseEnter = () => {
            if (isMuiltiline) setFocused(true);
        };

        // TODO подумать над решением проблемы с закрытием окна перевода
        const handleMouseLeave = () => {
            setFocused(false);
        };

        const getControlAttr = useCallback(() => {
            if (type === 'dbMemoEdit') {
                const fullScreenRows = 28;
                const inlineRows = rows || 4;
                return {
                    multiline: true,
                    rows: fullScreen ? fullScreenRows : inlineRows
                };
            }
            return null;
        }, [fullScreen]);

        return (
            <Box
                ref={editRef}
                sx={{
                    width: '100%',
                    p: 2,
                    boxSizing: 'border-box',
                    visibility: visible ? 'inherit' : ('hidden' as any),
                    position: 'relative'
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <TextField
                    inputRef={inputRef}
                    style={{ width: '100%', boxSizing: 'border-box' }}
                    size="small"
                    type="text"
                    variant="outlined"
                    onChange={handleOnChange}
                    id={`${type || 'dbEdit'}-${guid}`}
                    className={fieldRequired && onScreen && !other.disabled ? 'required-field' : ''}
                    InputLabelProps={{
                        shrink:
                            !!defaultValue || editType === 'date' || editType === 'datetime-local'
                    }}
                    value={defaultValue}
                    sx={theme => ({
                        '& .Mui-disabled': {
                            '&.MuiFormLabel-root': { background: 'none !important' },
                            backgroundColor: theme.palette.mode === 'light' ? '#f1f1f1' : '#1A2027',
                            '& .MuiInputBase-input': {
                                '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.6)'
                            }
                        }
                    })}
                    {...getControlAttr()}
                    {...other}
                />
                {isMuiltiline && focused && (
                    <Fade in={focused}>
                        <Stack
                            spacing={2}
                            sx={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                margin: 4,
                                marginRight: hasScrollbar ? 12 : 4
                            }}
                        >
                            <Tooltip
                                title={
                                    fullScreen
                                        ? content.controls.panel.fullscreenClose
                                        : content.controls.panel.fullscreenOpen
                                }
                            >
                                <IconButton
                                    onClick={switchFullScreen}
                                    size="small"
                                    sx={{ right: '-4px' }}
                                >
                                    {fullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                                </IconButton>
                            </Tooltip>
                            {internationalDialog}
                        </Stack>
                    </Fade>
                )}
            </Box>
        );
    }
);

export default SimpleEdit;
