import React, { FunctionComponent, useMemo } from 'react';

import { TableCell } from '@mui/material';

import SVGIcon from 'forms/controls/SVGIcon';

import PropContainer from 'dataObj/PropContainer';

import { DataLineCellType } from '../interface';

interface PropsType extends DataLineCellType {
    getCellColor: (fieldName: string) => any | undefined;
    isColored: boolean;
    propContainer: PropContainer;
}

const Cell: FunctionComponent<PropsType> = ({
    col,
    isColored,
    getCellColor,
    renderCellText,
    propContainer
}) => {
    const { iconSet, fieldName, align } = col;

    const label = useMemo(() => renderCellText(fieldName), [fieldName, renderCellText]);
    const icon = useMemo(
        () => (iconSet ? renderCellText(fieldName, true) : null),
        [fieldName, renderCellText, iconSet]
    );
    const cellColors = useMemo(() => getCellColor(fieldName), [fieldName, getCellColor]);

    return (
        <TableCell
            key={fieldName}
            align={align || 'left'}
            style={{
                whiteSpace: 'nowrap',
                borderBottom: 'none',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }}
            sx={{
                backgroundColor: cellColors?.bgColor || 'inherit',
                color: cellColors?.textColor || 'inherit'
            }}
        >
            {iconSet && icon ? (
                <SVGIcon descr={{ iconSet, icon, label }} propContainer={propContainer} />
            ) : (
                label
            )}
        </TableCell>
    );
};

export default Cell;
