import React, {
    FunctionComponent,
    ChangeEvent,
    useCallback,
    useEffect,
    useState,
    MutableRefObject,
    useRef
} from 'react';
import { observer } from 'mobx-react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Box, Pagination } from '@mui/material';

import { useTheme } from '@mui/material/styles';

import useOnScreen from 'hooks/onScreen';
import windowResize from 'hooks/windowResize';
import useCtrlData from 'hooks/ctrlData';
import useCtrlProps from 'hooks/ctrlProps';

import Loading from 'components/utils/Loading/Loading';

import { ControlPropsType, DBPDFViewer } from 'forms/interfaces';

import ConfigurationStore from 'store/configurationStore';

import classes from '../form.module.scss';

const { REACT_APP_API_URL } = process.env;

interface PropsType extends ControlPropsType {
    descr: DBPDFViewer;
}

const DBPdfViewer: FunctionComponent<PropsType> = observer(({ descr, propContainer }) => {
    const { guid, fillWidth, datasetName, fieldName } = descr;
    const { dataset, dataVal } = useCtrlData(propContainer, datasetName, fieldName);
    const { ctrlVisible } = useCtrlProps(propContainer, guid);
    const theme = useTheme();

    const ref = useRef();
    const { token, content } = ConfigurationStore;

    const valueToText = useCallback(
        val => (val === undefined || val === null ? '' : val),
        [dataVal]
    );

    const [val, setVal] = useState(() => valueToText(dataVal));
    const [pageNumber, setPageNumber] = useState(1);
    const [numPages, setNumPages] = useState(0);
    const [width, setWidth] = useState(0);
    const [settled, setSettled] = useState(false);

    const onScreen = useOnScreen(ref);
    const onWidth = windowResize();

    const onDocumentLoadSuccess = (props: any) => {
        setNumPages(props.numPages);
    };

    const handleChangePage = (event: ChangeEvent<unknown>, value: number) => {
        setPageNumber(value);
    };

    useEffect(() => {
        const pdfBoxWidth =
            document.getElementById(`pdf-box-${dataset?.descr?.guid}`)?.clientWidth ?? 0;

        setWidth(pdfBoxWidth);
        setSettled(true);
    }, [onScreen, onWidth]);

    const styles = {
        root: {
            position: 'relative',
            height: '100%',
            p: 2,
            maxHeight: `calc(100% - ${theme.spacing(4)})`,
            maxWidth: `calc(100% - ${theme.spacing(4)})`,
            overflow: 'hidden'
        },
        wrapper: {
            height: `calc(100% - ${numPages > 1 ? '40px' : '0px'})`,
            overflow: settled ? 'auto' : 'hidden'
        },
        footer: {
            position: 'absolute',
            bottom: theme.spacing(2),
            width: `calc(100% - ${theme.spacing(4)}`,
            display: 'flex'
        },
        pagination: {
            marginLeft: 'auto'
        }
    } as const;

    useEffect(() => {
        if (dataset) setVal(valueToText(dataVal));
    }, [dataset, dataVal, valueToText]);

    const renderLoading = () => (dataset ? <Loading delay /> : <Loading type="error" />);

    const onLoadError = (error: any) => {
        console.error(`Error while loading document! ${error.message as string}`);
    };

    return ctrlVisible ? (
        <Box sx={styles.root}>
            {dataset?.hasData() ? (
                <>
                    <Box
                        id={`pdf-box-${dataset?.descr?.guid}`}
                        ref={ref as MutableRefObject<any>}
                        sx={styles.wrapper}
                    >
                        <Document
                            file={
                                descr.srcType === 'blob'
                                    ? val
                                    : `${REACT_APP_API_URL ?? '/'}getfile/${encodeURIComponent(
                                          val
                                      )}/?token=${token ?? ''}`
                            }
                            onLoadSuccess={onDocumentLoadSuccess}
                            loading={<Loading delay />}
                            error={<Loading type="error" message={content.controls.pdf.error} />}
                            noData={<Loading type="error" message={content.controls.pdf.noData} />}
                            onLoadError={onLoadError}
                        >
                            <Page
                                pageNumber={pageNumber}
                                className={classes.dbPdfViewer}
                                width={fillWidth ? width : 0}
                            />
                        </Document>
                    </Box>
                    {numPages > 1 && (
                        <Box style={styles.footer}>
                            <Pagination
                                count={numPages}
                                page={pageNumber}
                                onChange={handleChangePage}
                                sx={styles.pagination}
                            />
                        </Box>
                    )}
                </>
            ) : (
                renderLoading()
            )}
        </Box>
    ) : null;
});

export default DBPdfViewer;
