import React, { useState, useEffect, useRef, FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import {
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    TextField,
    Button
} from '@mui/material';

import ConfigurationStore from '../../../store/configurationStore';
import GlossaryStore from '../../../store/glossaryStore';

import { GlossaryEditorProps } from './interfaces';

const GlossEditor: FunctionComponent<GlossaryEditorProps> = observer(
    ({ glossKey, glossText, setGlossText, setIsEditorOpen }) => {
        const { content } = ConfigurationStore;
        const [val, setVal] = useState('');
        const refText = useRef(null);

        useEffect(() => {
            setVal(glossText);
        }, [glossText]);

        function handleClose(flag: boolean) {
            if (flag && glossKey) {
                setGlossText(val);
                GlossaryStore.setGlossaryText(glossKey, val).catch(err =>
                    console.error(err.message)
                );
            }
            setIsEditorOpen(false);
        }

        const keyDownHandler = (event: any) => {
            if (event.keyCode === 9) {
                // подавляем переход фокуса при нажатии клавиши TAB
                const ctrl = event.target;

                const { value } = ctrl;

                const start: number = ctrl.selectionStart;
                const end: number = ctrl.selectionEnd;
                // присваеваем значение управляемому элементу Rеакт
                setVal(`${value.substring(0, start) as string}\t${value.substring(end) as string}`);
                // перемещаем курсор после символа табуляции
                // синхронно сделать не получается, поэтому setTimeout
                setTimeout(() => {
                    ctrl.selectionStart = start + 1;
                    ctrl.selectionEnd = start + 1;
                }, 0);

                // предотвратим потерю фокуса
                event.preventDefault();
            }
        };

        return (
            <Dialog open fullWidth maxWidth="sm" onClose={() => handleClose(false)}>
                <DialogTitle>Глоссарий</DialogTitle>
                <DialogContent>
                    <TextField
                        ref={refText}
                        style={{ width: '100%' }}
                        value={val || ''}
                        multiline
                        minRows={10}
                        maxRows={20}
                        variant="outlined"
                        onKeyDown={keyDownHandler}
                        onChange={e => {
                            setVal(e.target.value);
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => handleClose(false)} color="primary">
                        {content.controls.buttons.cancel}
                    </Button>
                    <Button onClick={() => handleClose(true)} color="primary">
                        {content.controls.buttons.ok}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
);

export default GlossEditor;
