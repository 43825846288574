export default class HttpError extends Error {
    code: number;

    content: unknown;

    constructor(code: number, message: string, content: unknown = null) {
        super(message);
        this.code = code;
        this.content = content;
    }
}
