import { observable, action, get, set, remove, makeAutoObservable } from 'mobx';

class Descriptor {
    @observable descriptor = {};

    constructor() {
        makeAutoObservable(this);
    }

    @action setDescriptor(guid: string, descriptor: { [key: string]: any }) {
        set(this.descriptor, { [guid]: descriptor });

        if (descriptor.subForms?.length) {
            descriptor.subForms.forEach((formDescr: { [key: string]: any }) =>
                this.setDescriptor(formDescr.guid, formDescr)
            );
        }
    }

    @action removeDescriptor(guid: string) {
        for (const name in this.descriptor) {
            if (name.includes(guid)) {
                remove(this.descriptor, name);
            }
        }
    }

    getDescriptor(guid?: string, tableId?: string) {
        return guid ? get(this.descriptor, `${guid}${tableId ? `/${tableId}` : ''}`) : null;
    }
}

const DescriptorStore = new Descriptor();
export default DescriptorStore;
