import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';

import { Menu, MenuItem, Button } from '@mui/material';
import { Language } from '@mui/icons-material';

import content from '../../../localization/content';

import ConfigurationStore from '../../../store/configurationStore';
import { Locale } from '../../../localization/interfaces';

interface PropsType {
    color?: string;
}

const LocaleSwitcher: FunctionComponent<PropsType> = observer(({ color }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLocale = (locale: Locale) => {
        ConfigurationStore.changeLocale(locale);
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                aria-label="edit"
                onClick={handleClick}
                startIcon={<Language />}
                sx={{ marginLeft: 'auto', display: 'flex', color }}
            >
                {ConfigurationStore.content.resource.buttons.changeLocale}
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {Object.keys(content).map((locale: string) => (
                    <MenuItem key={locale} onClick={() => handleLocale(locale as Locale)}>
                        {content[locale as keyof typeof content].language}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
});

export default LocaleSwitcher;
