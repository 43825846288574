import { MutableRefObject, useLayoutEffect, useState } from 'react';

export default function useOnScreen(ref: MutableRefObject<any>, rootMargin = '0px') {
    // Фиксируем факт отрисовки элемента
    const [isIntersecting, setIntersecting] = useState(false);

    useLayoutEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                // Обновляем состояние
                setIntersecting(entry.isIntersecting);
            },
            {
                rootMargin
            }
        );
        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            ref?.current && observer?.unobserve(ref.current);
        };
    }, []);

    // Возвращаем факт отрисовки компоненты
    return isIntersecting;
}
