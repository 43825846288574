import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';

import useTaskTree from 'hooks/useTaskTree';

import FlowChart from 'components/Charts/FlowChart';
import Loading from 'components/utils/Loading/Loading';

import ConfigurationStore from 'store/configurationStore';

import { makeNodes, makeEdges } from './utils';

import { FlowChartControlType, NodeElement, EdgeElement } from './interfaces';

const DBFlowChart: FunctionComponent<FlowChartControlType> = observer(
    ({ descr, propContainer }) => {
        const { showMap, showControls, direction, directionSwitcher, fitView, zoom } = descr;

        const { content } = ConfigurationStore;

        const [nodes, setNodes] = useState<NodeElement[]>([]);
        const [edges, setEdges] = useState<EdgeElement[]>([]);

        const { dataset, parentTask, loading, loadingType, loadingMessage } = useTaskTree(
            descr,
            propContainer,
            content
        );

        const initDirection = useMemo(() => {
            switch (direction) {
                case 'left-right':
                    return 'LR';

                case 'top-bottom':
                    return 'TB';

                default:
                    return;
            }
        }, [direction]);

        useEffect(() => {
            if (dataset?.recCount && parentTask && !loading) {
                setNodes(makeNodes(parentTask, dataset.data));
                setEdges(makeEdges([...[parentTask], ...dataset.data]));
            } else if (nodes.length || edges.length) {
                setNodes([]);
                setEdges([]);
            }
        }, [loading, dataset, parentTask?.tt_node_id]);

        if (loading) return <Loading delay type={loadingType} message={loadingMessage} />;

        return nodes?.length && edges?.length ? (
            <FlowChart
                initNodes={nodes}
                initEdges={edges}
                zoom={zoom}
                fitChart={fitView}
                showMap={showMap}
                showControls={showControls}
                initDirection={initDirection}
                directionSwitcher={directionSwitcher}
            />
        ) : (
            <Loading type="empty" />
        );
    }
);

export default DBFlowChart;
