import React, { FunctionComponent, useCallback } from 'react';
import { observer } from 'mobx-react';

import { Button, Tooltip } from '@mui/material';
import { Code } from '@mui/icons-material';

import useCtrlProps from 'hooks/ctrlProps';
import useScriptHandler from 'hooks/scriptHandler';

import { ButtonType } from './interfaces';

import SVGIcon from '../SVGIcon';
import MenuButton from './MenuButton';

const ScriptButton: FunctionComponent<ButtonType> = observer(
    ({ buttonRef, variant, descr, propContainer, style, icon = false }) => {
        const { guid, caption, params, onClickScript, items, iconButton } = descr;

        const { ctrlEnabled, ctrlVisible } = useCtrlProps(propContainer, guid);

        const handleOnClick = useScriptHandler(onClickScript, propContainer, params);

        const renderButton = useCallback(
            () =>
                icon || !!iconButton ? (
                    <Tooltip title={caption}>
                        <Button
                            size="small"
                            onClick={() => handleOnClick()}
                            disabled={!ctrlEnabled}
                        >
                            {descr.iconSet && descr.icon ? (
                                <SVGIcon descr={descr} propContainer={propContainer} />
                            ) : (
                                <Code />
                            )}
                        </Button>
                    </Tooltip>
                ) : (
                    <Button
                        ref={buttonRef}
                        variant={variant ?? descr.variant ? descr.variant : 'contained'}
                        onClick={() => handleOnClick()}
                        disabled={!ctrlEnabled}
                        sx={{
                            height: '100%',
                            width: '100%',
                            visibility: ctrlVisible ? 'inherit' : 'hidden',
                            whiteSpace: 'nowrap',
                            ...style
                        }}
                        startIcon={<SVGIcon descr={descr} propContainer={propContainer} />}
                    >
                        {caption}
                    </Button>
                ),
            [ctrlEnabled, variant, descr?.variant, caption, propContainer, icon]
        );

        return items?.length ? (
            <MenuButton descr={descr} propContainer={propContainer} style={style} icon={icon}>
                {renderButton()}
            </MenuButton>
        ) : (
            renderButton()
        );
    }
);

export default ScriptButton;
