import React, { FunctionComponent, useEffect, useCallback, useState, useMemo } from 'react';

import ReactFlow, {
    useNodesState,
    useEdgesState,
    useReactFlow,
    addEdge,
    MiniMap,
    Controls,
    ReactFlowProvider
} from 'reactflow';
import 'reactflow/dist/style.css';

import NodeFlow from './NodeFlow/NodeFlow';

import getLayoutedElements from './layout';
import DirectionSwitch from './DirectionSwitch';

const snapGrid = [20, 20];
const nodeTypes = { selectorNode: NodeFlow };

interface FlowChartType {
    initNodes: any[];
    initEdges: any[];
    zoom?: number;
    fitChart?: boolean;
    initDirection?: 'TB' | 'LR';
    showMap?: boolean;
    showControls?: boolean;
    directionSwitcher?: boolean;
}

const FlowChart: FunctionComponent<FlowChartType> = props => {
    const {
        initNodes,
        initEdges,
        initDirection = 'TB',
        showMap = false,
        showControls = false,
        directionSwitcher = false,
        zoom = 1,
        fitChart = true
    } = props;

    const [direction, setDirection] = useState(initDirection);

    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    const defaultViewport = useMemo(() => ({ x: 0, y: 0, zoom }), [zoom]);

    const { fitView } = useReactFlow();

    useEffect(() => {
        const [layoutedNodes, layoutedEdges] = getLayoutedElements(initNodes, initEdges, direction);

        setNodes([...layoutedNodes]);
        setEdges([...layoutedEdges]);

        setTimeout(() => fitView({ duration: 800 }), 100);
    }, [direction]);

    const onConnect = useCallback(
        params => setEdges(eds => addEdge({ ...params, style: { stroke: '#fff' } }, eds)),
        []
    );

    const handleChangeDirection = () => {
        if (direction === 'TB') {
            setDirection('LR');
        } else setDirection('TB');
    };

    return (
        <div style={{ position: 'absolute', height: '100%', width: '100%' }}>
            {directionSwitcher ? (
                <DirectionSwitch
                    checked={direction === 'TB'}
                    handleChange={handleChangeDirection}
                />
            ) : null}
            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                nodeTypes={nodeTypes}
                // connectionLineStyle={connectionLineStyle}
                snapToGrid
                snapGrid={snapGrid as [number, number]}
                defaultViewport={defaultViewport}
                attributionPosition="bottom-left"
                fitView={fitChart}
                nodesDraggable={false}
                nodesConnectable={false}
            >
                {showMap ? <MiniMap /> : null}
                {showControls ? <Controls showInteractive={false} /> : null}
            </ReactFlow>
        </div>
    );
};

const FlowWithProvider: FunctionComponent<FlowChartType> = props => (
    <ReactFlowProvider>
        <FlowChart {...props} />
    </ReactFlowProvider>
);

export default FlowWithProvider;
