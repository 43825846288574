import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { SplitPane } from '@calypso/react-multi-split-pane';

import {
    Stack,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid
} from '@mui/material';

import Prism from 'prismjs';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-sql';
import 'prismjs/themes/prism.css';

import { ResourceClass } from 'store/resourceStore';
import ConfigurationStore from 'store/configurationStore';

import Editor from '../../../Inputs/HighlightEditor';

import { EventType } from '../ResInterfaces';
import ResScriptGlossary from './ResScriptGlossary';

interface PropTypes {
    name: string;
    text: string;
    language: string;
    onConfirm: any;
    showText?: boolean;
    resourceStore?: ResourceClass;
    bottomContent?: ReactNode;
}

const ResScriptEditor: FunctionComponent<PropTypes> = observer(
    ({ name, text, language, onConfirm, showText = false, resourceStore, bottomContent }) => {
        const useGlossary = language === 'javascript';
        const { resource }: any = ConfigurationStore.content;

        const [open, setOpen] = useState(false);
        const [code, setCode] = useState(text);

        useEffect(() => {
            setCode(text);
        }, [text, open]);

        const handleOpen = () => {
            setOpen(true);
        };

        const handleClose = () => {
            setOpen(false);
            setCode(text);
        };

        const handleConfirm = () => {
            onConfirm({ target: { name, value: code } } as EventType);
            setOpen(false);
        };

        const handleSelectGlossItem = (newItem: string) => {
            setCode(code + newItem);
        };

        const renderTextEditor = () => (
            <>
                <Editor
                    value={code}
                    onValueChange={(codeText: string) => setCode(codeText)}
                    highlight={codeText =>
                        Prism.highlight(codeText || '', Prism.languages[language], language)
                    }
                    style={{
                        fontFamily: '"Fira code", "Fira Mono", monospace',
                        fontSize: 14
                    }}
                />
                {bottomContent}
            </>
        );

        return (
            <>
                <Stack spacing={1} direction="row">
                    <Button onClick={handleOpen} color={text ? 'info' : 'primary'}>
                        {text ? resource.scriptEditor.change : resource.buttons.add}
                    </Button>
                    {!!text && showText && (
                        <Button disabled>
                            {text.length < 50 ? text : `${text.slice(0, 50)}...`}
                        </Button>
                    )}
                </Stack>
                <Dialog open={open} maxWidth="md" fullWidth>
                    <DialogTitle id="script-dialog-title">
                        {resource.scriptEditor.title}
                    </DialogTitle>
                    <DialogContent dividers>
                        {useGlossary && resourceStore ? (
                            <Grid container sx={{ position: 'relative', height: '500px' }}>
                                <SplitPane split="vertical">
                                    <Grid item>{renderTextEditor()}</Grid>
                                    <Grid item>
                                        <ResScriptGlossary
                                            resourceStore={resourceStore}
                                            onSelect={handleSelectGlossItem}
                                        />
                                    </Grid>
                                </SplitPane>
                            </Grid>
                        ) : (
                            renderTextEditor()
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>{resource.scriptEditor.cancel}</Button>
                        <Button onClick={handleConfirm}>{resource.scriptEditor.apply}</Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
);

export default ResScriptEditor;
