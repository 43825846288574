import React, { FunctionComponent, useMemo } from 'react';
import { observer } from 'mobx-react';

import { Button, Checkbox, FormControlLabel, FormGroup, Stack, Typography } from '@mui/material';

import { DatasetFieldType } from 'forms/interfaces';

import ConfigurationStore from 'store/configurationStore';

interface PropsType {
    dsName: string;
    fieldsList: { [dsName: string]: DatasetFieldType[] };
    selectedFields: string[];
    checkFields: (dsName: string, field: DatasetFieldType) => unknown;
    handleSelectField: (event: React.ChangeEvent<HTMLInputElement>) => unknown;
    handleSelectAll: (select: boolean, fields: string[]) => unknown;
}

const FieldsSelection: FunctionComponent<PropsType> = observer(
    ({ dsName, fieldsList, selectedFields, checkFields, handleSelectField, handleSelectAll }) => {
        const { content } = ConfigurationStore;

        const list = useMemo(
            () => fieldsList[dsName].filter(field => checkFields(dsName, field)),
            [fieldsList]
        );

        const selectedAll =
            list.filter(field => selectedFields.includes(field.guid)).length === list.length;

        return (
            <>
                <Button
                    onClick={() =>
                        handleSelectAll(
                            !selectedAll,
                            list.map(field => field.guid)
                        )
                    }
                >
                    {selectedAll
                        ? content.resource.buttons.clearAll
                        : content.resource.buttons.selectAll}
                </Button>
                <FormGroup>
                    {list.map(field => (
                        <FormControlLabel
                            key={field.guid}
                            control={
                                <Checkbox
                                    checked={selectedFields.includes(field.guid)}
                                    onChange={handleSelectField}
                                    name={field.guid}
                                />
                            }
                            sx={{ width: '101%', '& .MuiFormControlLabel-label': { width: '93%' } }}
                            label={
                                <Stack direction="column">
                                    <Stack
                                        direction="row"
                                        spacing={4}
                                        sx={{ top: 4, position: 'relative', pt: 1 }}
                                    >
                                        <Typography
                                            fontWeight={
                                                selectedFields.includes(field.guid) ? 600 : 400
                                            }
                                        >
                                            {field.name}
                                        </Typography>
                                        {field.caption ? (
                                            <Typography
                                                sx={{
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'pre'
                                                }}
                                            >{`(${field.caption})`}</Typography>
                                        ) : null}
                                    </Stack>
                                    <Typography variant="caption" fontSize="0.7rem" color="#B2B6C2">
                                        {field.dataType}
                                    </Typography>
                                </Stack>
                            }
                        />
                    ))}
                </FormGroup>
            </>
        );
    }
);

export default FieldsSelection;
