import React, { useEffect, useState } from 'react';

import { CircularProgress } from '@mui/material';

import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import InboxIcon from '@mui/icons-material/Inbox';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';

import styles from './Loading.module.scss';

interface PropsType {
    message?: string;
    type?: 'loading' | 'warning' | 'error' | 'broken' | 'empty';
    style?: { [key: string]: string };
    delay?: boolean;
    delayTime?: number;
}

const Loading = (props: PropsType) => {
    const { message, type = 'loading', style, delay = false, delayTime = 1500 } = props;

    const [show, setShow] = useState(!delay);

    const iconStyle = { marginRight: 18 };

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;

        if (delay && type === 'loading') {
            timeoutId = setTimeout(() => setShow(true), delayTime);
        } else setShow(true);

        return () => {
            setShow(false);
            clearTimeout(timeoutId);
        };
    }, [type, delay, delayTime, message]);

    const setIcon = () => {
        switch (type) {
            case 'loading':
                return <CircularProgress style={iconStyle} />;

            case 'warning':
                return <WarningIcon fontSize="large" style={iconStyle} color="warning" />;

            case 'error':
                return <ErrorIcon fontSize="large" style={iconStyle} color="warning" />;

            case 'broken':
                return <BrokenImageIcon fontSize="large" style={iconStyle} color="warning" />;

            case 'empty':
                return <InboxIcon fontSize="large" style={iconStyle} color="action" />;

            default:
                return null;
        }
    };

    return show ? (
        <div className={styles.loading_wrapper} style={style}>
            <div>
                {setIcon()}
                {message && (
                    <Typography variant="h6" component="div">
                        {message}
                    </Typography>
                )}
            </div>
        </div>
    ) : null;
};

export default Loading;
