import React, { FunctionComponent, ReactElement } from 'react';
import { observer } from 'mobx-react';

import { Dialog, AppBar, Toolbar, IconButton, Typography, DialogContent } from '@mui/material';
import { Close } from '@mui/icons-material';

import ConfigurationStore from '../../../store/configurationStore';

import { CustomFormType } from '../../../forms/interfaces';

interface PropsType {
    open: boolean;
    content: ReactElement<CustomFormType>;
    onClose: any;
}

const ResModal: FunctionComponent<PropsType> = observer(props => {
    const { open, content, onClose } = props;

    return (
        <Dialog fullScreen open={open} onClose={onClose}>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                        size="large"
                    >
                        <Close />
                    </IconButton>
                    <Typography variant="h6" sx={{ marginLeft: '16px', flex: 1 }}>
                        {ConfigurationStore.content.resource.buttons.close}
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogContent style={{ height: '100vh' }}>{content}</DialogContent>
        </Dialog>
    );
});

export default ResModal;
