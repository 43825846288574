import { action, get, makeAutoObservable, observable, set } from 'mobx';
import { jsonFetch } from '../utils';
import { DBLookupType } from '../forms/interfaces';

type ValueType = string[] | number[] | null;

type LookupSetRecord = {
    value: ValueType;
    descr: DBLookupType;
    data: { [field: string]: string | number | null }[];
    callback?: (state: boolean) => unknown;
    actions: { setOpen: (open: boolean, callback?: (state: boolean) => unknown) => unknown };
};

export class LookupStore {
    @observable lookupSet: { [lookupName: string]: LookupSetRecord } = {};

    constructor() {
        makeAutoObservable(this);
    }

    @action setLookup = (descr: DBLookupType, actions: { setOpen: any }) => {
        set(this.lookupSet, {
            [descr.name]: {
                descr,
                actions
            }
        });
    };

    @action setValue = (
        descr: DBLookupType,
        value: ValueType,
        data: { [field: string]: string | number | null }[]
    ) => {
        const lookup = this.getLookup(descr.name) || {};

        set(this.lookupSet, {
            [descr.name]: {
                ...lookup,
                ...{ value, descr, data }
            }
        });
    };

    @action getValue = async (
        descr: DBLookupType,
        value: ValueType,
        formGuid: string,
        requestGuid: string
    ) => {
        const lookup = this.getLookup(descr.name);

        if (lookup?.data?.length) return lookup.data;

        const answer: { data: { [field: string]: string | number | null }[] } = await jsonFetch(
            'query',
            'POST',
            {
                formGuid,
                requestGuid,
                idList: value
            }
        );

        this.setValue(descr, value, answer?.data || []);

        return answer?.data;
    };

    getLookup = (lookupName: string): LookupSetRecord => get(this.lookupSet, lookupName);

    getData = (lookupName: string, recNo?: number) => {
        const lookup: LookupSetRecord = get(this.lookupSet, lookupName);

        if (lookup?.data?.length) return recNo === undefined ? lookup.data : lookup.data[recNo];
    };
}

export default LookupStore;
