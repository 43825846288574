import { useState, useEffect } from 'react';

type ValueType = string | number | { [key: string]: string };

export default function useDebounce(value: ValueType, delay = 300) {
    const [timer, setTimer] = useState<ReturnType<typeof setTimeout>>();
    const [debVal, setDebVal] = useState(value);

    useEffect(() => {
        if (timer) clearTimeout(timer);

        const handler = setTimeout(() => {
            setDebVal(value);
        }, delay);

        setTimer(handler);

        return () => {
            setTimer(undefined);
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debVal;
}
