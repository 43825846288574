import React, { LegacyRef, useCallback } from 'react';

interface PropsType {
    content: string;
    minWidth?: number;
    styleSheet?: string;
}

const Iframe = (props: PropsType) => {
    const { styleSheet, minWidth, content } = props;

    const defaultStyle =
        '<style type="text/css">html { height: min-content; }</style><style type="text/css">body { height: min-content; }</style>';

    const getWidth = useCallback(
        (width?: number) => {
            if (minWidth && width && minWidth < width) return `${width}px`;

            return '100%';
        },
        [minWidth]
    );

    const writeHTML: LegacyRef<HTMLDivElement> = (frame: HTMLIFrameElement | null) => {
        if (!frame) {
            return;
        }
        const doc = frame.contentDocument;
        doc?.open();
        doc?.write(
            `${content} ${defaultStyle} ${styleSheet ? `<style>${styleSheet}</style>` : ''}`
        );
        doc?.close();
        frame.style.width = getWidth(frame.contentWindow?.document.body.scrollWidth);
        frame.style.height = `${frame.contentWindow?.document.body.scrollHeight ?? 0}px`;
    };

    return (
        <iframe title="iframe" src="about:blank" scrolling="no" frameBorder="0" ref={writeHTML} />
    );
};

export default Iframe;
