import React, { useState, useEffect, FunctionComponent, useMemo, useCallback } from 'react';
import { observer } from 'mobx-react';

import { Box, InputAdornment } from '@mui/material';

import SimpleDialog from 'components/Feedback/SimpleDialog';

import { ControlPropsType, DBTextEditType } from 'forms/interfaces';

import useSimpleEdit from 'hooks/simpleEdit';
import { getLocalizedString } from 'utils';

import TextMask from './TextMask';
import InternationalDialog from './InternationalDialog';
import SimpleEdit from './SimpleEdit';

interface PropsType extends ControlPropsType {
    descr: DBTextEditType;
    fullScreen?: boolean;
    switchFullScreen?: any;
    type?: string;
    dataRow?: any;
    index?: number;
}

const DBSimpleEdit: FunctionComponent<PropsType> = observer(
    ({ descr, propContainer, fullScreen, switchFullScreen, type, dataRow, index }) => {
        const { setActiveCtrlDescr } = propContainer;
        const {
            value,
            dataVal,
            handleOnChange,
            handleOnInternationalChange,
            editType,
            fieldRequired,
            internationalField,
            ctrlEnabled,
            ctrlVisible,
            editRef,
            inputRef,
            onScreen
        } = useSimpleEdit(descr, propContainer, type, dataRow, index);

        const [InputProps, setInputProps] = useState({
            style: { color: ctrlEnabled ? 'black' : '#888' }
        });
        const [focused, setFocused] = useState(false);

        const isMuiltiline = useMemo(() => !!(descr.type && descr.type === 'dbMemoEdit'), [descr]);

        const label = useMemo(() => getLocalizedString(descr.label), [descr.label]);

        const onFocus = () => {
            if (setActiveCtrlDescr) setActiveCtrlDescr(descr);
        };

        const internationalDialog = useCallback(() => {
            if (internationalField && ctrlEnabled)
                return (
                    <InternationalDialog
                        intContent={dataVal}
                        fieldName={label}
                        multiline={isMuiltiline}
                        handleOnChange={handleOnInternationalChange}
                    />
                );

            return null;
        }, [internationalField, ctrlEnabled]);

        useEffect(() => {
            setInputProps({
                ...InputProps,
                ...{ style: { color: ctrlEnabled ? 'black' : '#888' } }
            });
        }, [ctrlEnabled]);

        useEffect(() => {
            if (internationalField && descr.type !== 'dbMemoEdit' && ctrlEnabled) {
                setInputProps({
                    ...InputProps,
                    ...{
                        endAdornment: (
                            <InputAdornment position="end">
                                <InternationalDialog
                                    intContent={dataVal}
                                    fieldName={label}
                                    multiline={isMuiltiline}
                                    handleOnChange={handleOnInternationalChange}
                                />
                            </InputAdornment>
                        )
                    }
                });
            }
        }, [internationalField, dataVal, ctrlEnabled]);

        useEffect(() => {
            if (descr?.mask) {
                const inputComponent = TextMask(descr.mask);
                setInputProps({ ...InputProps, ...{ inputComponent } });
            }
        }, [descr?.mask]);

        useEffect(() => {
            if (fullScreen && inputRef.current?.type === 'textarea' && !focused) {
                inputRef.current.focus();
                setFocused(true);
            }
        }, [fullScreen, inputRef]);

        const renderTextField = (fScreen?: boolean) => (
            <SimpleEdit
                editRef={editRef}
                inputRef={inputRef}
                guid={descr.guid}
                label={label}
                rows={descr.rows}
                defaultValue={value || ''}
                disabled={!ctrlEnabled}
                visible={ctrlVisible}
                isMuiltiline={isMuiltiline}
                type={descr.type}
                editType={editType}
                fullScreen={fScreen}
                fieldRequired={fieldRequired}
                error={fieldRequired && !value}
                InputProps={InputProps}
                internationalDialog={internationalDialog}
                onFocus={onFocus}
                switchFullScreen={switchFullScreen}
                onScreen={onScreen}
                handleOnChange={handleOnChange}
            />
        );

        return fullScreen ? (
            <SimpleDialog handleClose={switchFullScreen}>
                <Box sx={{ p: 2, position: 'relative' }}>{renderTextField(fullScreen)}</Box>
            </SimpleDialog>
        ) : (
            renderTextField()
        );
    }
);

export default DBSimpleEdit;
