import { FunctionComponent } from 'react';
import { Box } from '@mui/material';

const TreeLeafItem: FunctionComponent = ({ children, ...other }) => (
    <Box sx={{ display: 'contents', alignItems: 'center', p: 0.5, pr: 0 }} {...other}>
        {children}
    </Box>
);

export default TreeLeafItem;
