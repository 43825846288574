import React, { FunctionComponent } from 'react';
import { Box, Typography } from '@mui/material';

import useCtrlProps from '../../hooks/ctrlProps';

import { ControlPropsType, LabelType } from '../interfaces';

interface PropsType extends ControlPropsType {
    descr: LabelType;
}

const CustomLabel: FunctionComponent<PropsType> = ({ descr, propContainer }) => {
    const { ctrlVisible } = useCtrlProps(propContainer, descr.guid);

    return (
        <Box
            sx={{
                width: '100%',
                p: 1,
                boxSizing: 'border-box',
                visibility: ctrlVisible ? 'inherit' : 'hidden'
            }}
        >
            {descr.variant ? (
                <Typography variant={descr.variant} id={descr.guid}>
                    {descr?.text ?? ''}
                </Typography>
            ) : (
                <span id={descr.guid}>{descr?.text ?? ''}</span>
            )}
        </Box>
    );
};

export default CustomLabel;
