import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';

import NavBar from './NavBar';
import DropDownButton from './DropDownButton';

import { ControlPropsType, MenuType } from '../../interfaces';
import SideBar from './SideBar';

interface PropsType extends ControlPropsType {
    descr: MenuType;
}

const Menu: FunctionComponent<PropsType> = observer(({ descr, propContainer }) => {
    const props = { descr, propContainer };

    switch (descr.variant) {
        case 'top':
            return <NavBar {...props} />;

        case 'header':
            return <NavBar position="static" {...props} />;

        case 'footer':
            return <NavBar position="relative" sx={{ top: 'auto', bottom: 0 }} {...props} />;

        case 'side':
            return <SideBar {...props} />;

        case 'side-fixed':
            return <SideBar fixed {...props} />;

        case 'button':
            return <DropDownButton {...props} />;

        default:
            return null;
    }
});

export default Menu;
