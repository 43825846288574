import { FunctionComponent, MutableRefObject, useMemo, useRef } from 'react';
import { observer } from 'mobx-react';

import { Box } from '@mui/material';

import { ControlPropsType, ToolbarType } from 'forms/interfaces';
import useNodeResize from 'hooks/nodeResize';

import Toolbar from './Toolbar';

interface PropsType extends ControlPropsType {
    descr: ToolbarType;
}

const ToolbarControl: FunctionComponent<PropsType> = observer(({ descr, propContainer }) => {
    const { caption, buttons, breakpoint } = descr;

    const dataset = useMemo(
        () => propContainer.dataStock.getDatasetObj(descr.dataset),
        [descr?.dataset]
    );

    const ref = useRef(null) as MutableRefObject<any>;

    const [width] = useNodeResize(ref);

    return (
        <Box sx={{ pl: 2 }} ref={ref}>
            <Toolbar
                caption={caption}
                buttons={buttons}
                propContainer={propContainer}
                breakpoint={breakpoint}
                containerWidth={width}
                dataset={dataset}
            />
        </Box>
    );
});

export default ToolbarControl;
