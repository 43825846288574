import { FunctionComponent, useState } from 'react';

import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    DialogActions,
    Paper
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import useBackdrop from 'hooks/backdrop';

type ResDetailType = {
    code: string;
    message: string;
};

type DetailType = {
    guid: string;
    message: string;
    name: string;
    detail: ResDetailType[];
};

interface PropsType {
    title: string;
    details: {
        code: number;
        message: string;
        detail: DetailType[];
    };
    importResource: () => Promise<unknown>;
}

const ImportDetails: FunctionComponent<PropsType> = ({ title, details, importResource }) => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    useBackdrop(loading);

    const handleForceUpload = async () => {
        setLoading(true);
        try {
            await importResource();
        } finally {
            setLoading(false);
            setOpen(false);
        }
    };

    return (
        <>
            <Button size="small" onClick={() => setOpen(true)} sx={{ color: 'white' }}>
                {title}
            </Button>
            <Dialog open={open}>
                <DialogTitle>{details.message}</DialogTitle>
                {details?.detail ? (
                    <DialogContent>
                        {details?.detail.map((detail, index) => (
                            <Paper key={`details-paper-${index}`} variant="outlined" sx={{ p: 2 }}>
                                <Typography variant="h6" gutterBottom>
                                    {detail.name}
                                </Typography>

                                {detail.detail.map((subDetail, idx) => (
                                    <Accordion key={`form-details-${index}`}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls={`form-details-${index}-content`}
                                            id={`form-details-${index}-header`}
                                        >
                                            <Typography>{subDetail.code}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography key={`${index}-${idx}`}>
                                                {subDetail.message}
                                            </Typography>{' '}
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </Paper>
                        ))}
                    </DialogContent>
                ) : null}
                <DialogActions>
                    <Button onClick={handleForceUpload} color="secondary">
                        Force upload
                    </Button>
                    <Button onClick={() => setOpen(false)}>Ok</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ImportDetails;
