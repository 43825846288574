import React, { FC, MutableRefObject, ReactNode, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { Save, Close, Publish, Autorenew, Theaters, Download } from '@mui/icons-material';
import { Box, Button, IconButton, Stack, Tooltip, Typography } from '@mui/material';

import { ResourceClass, SaveOptions } from 'store/resourceStore';
import ConfigurationStore from 'store/configurationStore';
import MessageList from 'components/Feedback/MessageList';

import useNodeResize from 'hooks/nodeResize';

import ResCtrlList from './ResCtrlList';

interface ResTreeType {
    ResourceStore: ResourceClass;
}

type ButtonType = {
    label: string;
    variant?: 'contained' | 'text' | 'outlined' | undefined;
    color?:
        | 'primary'
        | 'default'
        | 'inherit'
        | 'secondary'
        | 'error'
        | 'info'
        | 'success'
        | 'warning'
        | undefined;
    disabled: boolean;
    icon: ReactNode;
    onClick: any;
};

const ResTree: FC<ResTreeType> = observer(({ ResourceStore }) => {
    const {
        newRes,
        curRes,
        modified,
        saveResource,
        cancelEdit,
        exportResource,
        reloadResource,
        updateResource,
        checkIsModified,
        lastError,
        setLastError,
        selectCtrlBy,
        setPreviewResource
    } = ResourceStore;

    const { resource } = ConfigurationStore.content;

    const treeRef = useRef(null) as MutableRefObject<any>;

    const [treeWidth] = useNodeResize(treeRef);

    const BREAKPOINT_WIDTH = 620;
    const RES_TREE_ID = `res-tree-${curRes?.guid as string}`;

    // Нужно дополнительно подумать -
    // не приведёт ли это к бесконечному циклу
    useEffect(() => {
        checkIsModified();
    });

    const getLastErrorItems = (data: any[]) => {
        // Данные развернуть в список

        if (data[0].code === 'object_out_of_date') {
            const { code } = data[0];
            const message = 'Опции сохранения';
            return [
                { code, message, detail: 'Переписать', idx: 'overwrite' },
                { code, message, detail: 'Объединить', idx: 'merge' }
            ];
        }

        const list: any[] = [];
        let idx = 0;
        data.forEach(item => {
            const { message, code } = item;

            if (Array.isArray(item.details)) {
                item.details.forEach((detail: any) =>
                    list.push({ code, message, detail, idx: idx++ })
                );
            } else {
                list.push({ code, message, detail: '', idx: idx++ });
            }
        });
        return list;
    };

    const buttons: Array<ButtonType> = [
        {
            label: newRes ? resource.buttons.create : resource.buttons.save,
            variant: 'contained',
            color: 'primary',
            disabled: !curRes || !modified,
            onClick: () => saveResource(),
            icon: <Save />
        },
        {
            label: resource.buttons.undo as string,
            disabled: !curRes || !modified,
            onClick: () => cancelEdit(),
            icon: <Close />
        },
        {
            label: resource.buttons.export as string,
            disabled: !curRes,
            onClick: () => exportResource(),
            icon: <Publish />
        },
        {
            label: resource.buttons.load as string,
            disabled: !curRes || !['cdo'].includes(curRes.type),
            onClick: () => reloadResource(curRes?.type),
            icon: <Download />
        },
        {
            label: resource.buttons.refresh as string,
            disabled: !curRes || modified,
            onClick: async () => {
                await updateResource(curRes?.guid);
            },
            icon: <Autorenew />
        },
        {
            label: resource.resList.show as string,
            disabled: !curRes || curRes.type !== 'wuiForm',
            onClick: () => setPreviewResource(curRes?.guid),
            icon: <Theaters />
        }
    ];

    return (
        <>
            <div ref={treeRef} style={{ display: 'flex' }} id={RES_TREE_ID}>
                <Typography variant="h5" style={{ marginRight: 'auto' }}>
                    {curRes?.name ?? resource.needSelect}
                </Typography>
            </div>
            {/* Элементы упреавления формой */}
            {/* Для крупных кнопок */}
            <Stack
                spacing={1}
                direction="row"
                sx={{ pt: 2, display: treeWidth > BREAKPOINT_WIDTH ? 'flex' : 'none' }}
            >
                {buttons.map((button, index) => (
                    <Button
                        key={index}
                        variant={button.variant ?? 'outlined'}
                        disabled={button.disabled}
                        onClick={button.onClick}
                        endIcon={button.icon}
                    >
                        {button.label}
                    </Button>
                ))}
            </Stack>
            {/* Для кнопок-иконок */}
            <Stack
                spacing={1}
                direction="row"
                sx={{ pt: 2, display: treeWidth <= BREAKPOINT_WIDTH ? 'flex' : 'none' }}
            >
                {buttons.map((button, index) => (
                    <Tooltip key={index} title={button.label}>
                        <span>
                            <IconButton
                                color={button.color}
                                disabled={button.disabled}
                                onClick={button.onClick}
                            >
                                {button.icon}
                            </IconButton>
                        </span>
                    </Tooltip>
                ))}
            </Stack>
            {/* Дерево контролов */}
            {curRes ? (
                <Box
                    sx={{
                        position: 'relative',
                        mt: 4,
                        width: '100%',
                        height: 'calc(100% - 82px)',
                        overflow: 'auto'
                    }}
                >
                    <ResCtrlList ResourceStore={ResourceStore} parent={null} arr={[curRes]} />
                </Box>
            ) : null}
            {lastError && lastError.detail ? (
                <MessageList
                    key="messageList1"
                    editorName={lastError.message}
                    handleClose={() => setLastError(undefined)}
                    mainName="message"
                    detailName="detail"
                    data={getLastErrorItems(lastError.detail)}
                    selectMessage={(row: Record<string, string>) => {
                        // Переход в дереве на указанный первый элемент
                        switch (row.code) {
                            case 'double_guids':
                                return selectCtrlBy('guid', row.detail);
                            case 'double_names':
                                return selectCtrlBy('name', row.detail);
                            case 'object_out_of_date':
                                if (row.idx !== 'deny') {
                                    saveResource(row.idx as SaveOptions).catch(err =>
                                        console.error(err.message)
                                    );
                                }
                        }
                    }}
                />
            ) : null}
        </>
    );
});

export default ResTree;
