import React, {
    FunctionComponent,
    useCallback,
    forwardRef,
    ReactNode,
    useEffect,
    useRef
} from 'react';
import { observer } from 'mobx-react';

import { useHistory } from 'react-router-dom';

import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import { ListItemText, Divider } from '@mui/material';

import useCtrlProps from 'hooks/ctrlProps';
import useScriptHandler from 'hooks/scriptHandler';

import { ControlPropsType, MenuItemType } from 'forms/interfaces';
import PropContainer from 'dataObj/PropContainer';

import ConfigurationStore from 'store/configurationStore';
import MainMenuStore from 'store/mainMenuStore';
import DataStore from 'store/dataStore';

interface MenuItemContainerPropsType extends ControlPropsType, Omit<MenuItemProps, 'button'> {
    descr: MenuItemType;
    closeMenu?: any;
    children?: ReactNode;
}

const getMenuItemPath = (name: string, propContainer: PropContainer): Array<string> | null => {
    const getPath = (itemName: string, path: string[] = [name]): Array<string> | null => {
        const parentDescr = propContainer.getParent(itemName)?.descr;
        if (parentDescr?.type === 'menuItem') {
            path.unshift(parentDescr.name);
            return getPath(parentDescr.name, path);
        }

        if (['top', 'side', 'side-fixed'].includes(parentDescr.variant)) {
            return path;
        }

        return null;
    };

    return getPath(name);
};

const MenuItemContainer: FunctionComponent<MenuItemContainerPropsType> = forwardRef(
    ({ descr, propContainer, children, closeMenu, ...otherProps }, ref) => {
        const { guid, name, caption, params, onClickScript, afName, items, variant } = descr;

        const { ctrlEnabled, ctrlVisible } = useCtrlProps(propContainer, guid);
        const history = useHistory();
        const scriptHandler = useScriptHandler(onClickScript, propContainer, params);

        const menuItemRef = useRef<HTMLInputElement>(null) as any;

        const setActiveFilter = useCallback(
            (value: string) => {
                if (DataStore?.AF && afName) {
                    DataStore.AF.setAF(afName, value);
                }
            },
            [descr]
        );

        const handleClick = () => {
            setActiveFilter(descr?.value);
            !items.length && closeMenu && closeMenu();

            menuItemRef?.current?.click();

            scriptHandler().catch(err => console.error(err.message));
        };

        const handleMenuItemClick = (event: any) => {
            // отделяем синтетическое нажатие от реального
            if (event.detail) {
                const itemPath = getMenuItemPath(name, propContainer);

                if (itemPath) {
                    const path = itemPath.join('/');
                    const originPath = ConfigurationStore.currentRouterOriginPath.join('/');

                    // Вписываем новое значение в путь только если это последний элемент дерева,
                    // чтобы избежать сохранения в history всех нажатий на пункты меню
                    !items?.length &&
                        history.push(`${originPath ? `/${originPath}/` : '/'}${path}/`);

                    MainMenuStore.setSelectedItems(path.split('/'));
                    MainMenuStore.setCurrentItem(name);
                }
            }

            handleClick();
        };

        // Синтетическое нажатие на пункт меню из адресной строки
        useEffect(() => {
            const selected = MainMenuStore.checkItemCallPath(name);

            if (selected) {
                handleClick();
            }
        }, [MainMenuStore.currentMenuItem, name, handleClick]);

        const getItem = () => {
            if (variant === 'divider')
                return caption ? (
                    <MenuItem disabled>
                        <ListItemText
                            sx={theme => ({
                                '& span': {
                                    width: MainMenuStore.open ? '100%' : theme.spacing(7),
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }
                            })}
                        >
                            <b style={{ fontSize: '.8rem' }}>{caption}</b>
                        </ListItemText>
                    </MenuItem>
                ) : (
                    <Divider />
                );

            return (
                <MenuItem
                    ref={ref || menuItemRef}
                    onClick={handleMenuItemClick}
                    disabled={!ctrlEnabled}
                    selected={MainMenuStore.currentMenuItem === name}
                    {...otherProps}
                >
                    {children ?? <ListItemText>{caption}</ListItemText>}
                </MenuItem>
            );
        };

        return ctrlVisible ? getItem() : null;
    }
);

export default observer(MenuItemContainer);
