import { jsonFetch } from '../utils';
import { dsStates } from './customDataset';
import { fillCDOStruct } from './data-utils';

import DataStock from './DataStock';
import Dataset from './customDataset';
import { DatasetType, CDOType } from '../forms/interfaces';
import {
    CDOAppendResponse,
    CDOCreateResponse,
    CDODataStruct,
    CDODataStructRow,
    CDOReadResponse,
    ICDOParamsType,
    IQueryData,
    IQueryRow
} from './dataInterfaces';

type DataStockProps = {
    dataStock: DataStock;
    descr: CDOType;
};

export default class CDO {
    name: string;
    guid: string;
    dataStock: DataStock;
    descr: CDOType;
    options: any;
    datasets: Dataset[];
    requestParams?: ICDOParamsType;

    constructor({ dataStock, descr }: DataStockProps) {
        this.name = descr.name;
        this.guid = descr.guid;
        this.dataStock = dataStock;
        this.descr = descr;
        this.options = descr.options ? JSON.parse(descr.options) : null;
        this.datasets = [];
    }

    addDataset(ds: Dataset) {
        if (!this.datasets.includes(ds)) {
            this.datasets.push(ds);
        }
    }

    parseDatasetsCollection(key: any, cdoData: CDODataStruct) {
        const parseDataset = (ds: Dataset, dsInputStruct: CDODataStructRow[]) => {
            const chunk: IQueryData = [];
            const hash: Record<any, string> = {};

            dsInputStruct.forEach(row => {
                if (row.fields && ds?.keyField) {
                    const key = row.fields[ds.keyField];
                    const fields = this.#setRecordHash(key, row, hash);

                    chunk.push(fields);
                    row.datasets && this.parseDatasetsCollection(key, row.datasets);
                }
            });

            const curDataChunk = ds.chunkContainer.assignDataChunk(key, chunk, hash);
            ds.setState(dsStates.dsBrowse);
            ds.setCurrentDataChunk(curDataChunk);
        };

        if (cdoData)
            for (const dsName in cdoData) {
                const ds = this.datasets.find(ds => ds.name === dsName);
                ds && parseDataset(ds, cdoData[dsName]);
            }
    }

    async loadData(newRecord: boolean) {
        if (newRecord) {
            const resData = await this.#serverSideCreate();

            if (resData.__ID)
                this.dataStock.extParamVals = {
                    [this.datasets[0]?.serverKeyFields[0]]: resData.__ID
                };

            await this.#appendDSRecords(
                undefined,
                // Дополняем полученную структуру для фиксации родительских ключей detail-датасетов
                await fillCDOStruct(this.guid, resData?.datasets)
            );
        } else {
            const resData = await this.#readDataFromServer();

            this.parseDatasetsCollection(
                undefined,
                // Дополняем полученную структуру для фиксации родительских ключей detail-датасетов
                await fillCDOStruct(this.guid, resData?.datasets)
            );
        }
    }

    async #appendDSRecords(mainKey: any, datasets?: CDODataStruct) {
        if (datasets) {
            await Promise.all(
                Object.keys(datasets).map(async datasetName => {
                    const ds = this.dataStock.getDatasetObj(datasetName);

                    if (ds) {
                        if (datasets[datasetName][0]?.fields) {
                            const { key, parentKey } = ds.getAppendKeys();

                            const {
                                __HASH,
                                [parentKey]: __PARENT_ID,
                                ...fields
                            } = datasets[datasetName][0].fields as IQueryRow; // TS не понимает, что уже проверили наличие fields в if

                            const dataChunk =
                                ds.getChunkByKey(mainKey) ||
                                ds.chunkContainer.assignDataChunk(mainKey, []);

                            ds.setCurrentDataChunk(dataChunk);
                            ds.setProtectedFieldsHash(fields[key], __HASH);

                            await ds.append({
                                record: fields,
                                keepInEdit: true,
                                loadDependencies: false
                            });

                            await this.#appendDSRecords(
                                fields[key],
                                datasets[datasetName][0]?.datasets
                            );
                        } else {
                            // При заполнении родительского датасета меняем ключ пустого дочернего на актуальный
                            const parentId = ds.masterDS?.activeRec[ds.masterDS?.keyField];

                            if (ds.currentDataChunk.key === '#' && parentId) {
                                ds.currentDataChunk.key = parentId;
                            }
                        }
                    }
                })
            );
        }
    }

    async append(dataset: Dataset) {
        const { key, parentId, parentKey } = dataset.getAppendKeys();

        const response = await jsonFetch<CDOAppendResponse>('dataobject/append', 'POST', {
            guid: this.guid,
            __ID: this.dataStock.extParamVals[this.datasets[0]?.serverKeyFields[0]],
            parObj: {
                __PARENT_ID: parentId
            },
            hash: dataset.getAppendFieldsHash(),
            datasetName: dataset.name
        });

        if (response?.fields) {
            const { __HASH, [parentKey]: __PARENT_ID, ...fields } = response.fields;

            dataset.setProtectedFieldsHash(fields[key], __HASH);

            await dataset.append({ record: fields });

            await this.#appendDSRecords(fields[key], response.datasets);
        }
    }

    get parObj() {
        return this.requestParams?.parObj ?? {};
    }

    #setRecordHash(key: any, row: CDODataStructRow, hash: Record<any, string>): IQueryRow {
        if (row.fields && this.descr.hashable) {
            const { __HASH, ...fields } = row.fields;
            hash[key] = __HASH;
            return fields;
        }
        return row.fields!;
    }

    #getRequestParams(): ICDOParamsType {
        let parObj: Record<string, any> = {};
        const keyField = this.datasets[0]?.serverKeyFields[0];

        if (keyField) {
            const extParam: Record<string, any> | number | null = this.dataStock.extParamVals;
            if (typeof extParam === 'number' || extParam === null) {
                parObj[keyField] = extParam;
            } else if (typeof extParam === 'object') {
                parObj[keyField] = extParam[keyField] || null;
            }
        }
        return {
            guid: this.guid,
            parObj
        };
    }

    async #readDataFromServer() {
        this.requestParams = this.#getRequestParams();
        return await jsonFetch<CDOReadResponse>('dataobject/read', 'POST', this.requestParams);
    }

    async #serverSideCreate() {
        this.requestParams = this.#getRequestParams();
        return await jsonFetch<CDOCreateResponse>('dataobject/create', 'POST', this.requestParams);
    }
}
