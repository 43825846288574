import PropContainer from 'dataObj/PropContainer';

import { DBControlType } from 'forms/interfaces';

export const addTextToField = (
    activeCtrlDescr: DBControlType,
    propContainer: PropContainer,
    text: string,
    withSpace: string | boolean = true
) => {
    if (activeCtrlDescr) {
        const ctrlId = `${activeCtrlDescr.type}-${activeCtrlDescr.guid}`;

        const ctrlEnabled = propContainer.getEnabledProperty(activeCtrlDescr.guid) ?? true;
        if (!ctrlEnabled) return;

        const { fieldName } = activeCtrlDescr;

        const dataset = propContainer.dataStock.getDatasetObj(activeCtrlDescr.datasetName);
        const fieldEnabled = dataset?.getEnabledStatus(fieldName);

        if (fieldEnabled) {
            const ctrl = document.getElementById(ctrlId) as HTMLInputElement;
            if (ctrl) {
                const oldText = ctrl.value;
                const start = ctrl.selectionStart as number;
                const end = ctrl.selectionEnd as number;
                const before = oldText.substring(0, start);
                const after = oldText.substring(end);

                const prevSimbol = before.slice(-1);
                withSpace = before && withSpace && prevSimbol !== '\n';
                const insertedText = (withSpace ? ' ' : '') + text;
                const newText = before + insertedText + after;
                dataset.setFieldValue(fieldName, newText);
                setTimeout(() => {
                    ctrl.focus();
                    ctrl.click();
                    ctrl.selectionStart = before.length + insertedText.length;
                    ctrl.selectionEnd = ctrl.selectionStart;
                }, 0);
            }
        }
    }
};

export const getNextElem = (formDescr: any, guid: string, direction = 1) => {
    let parentElem: any = null;

    function scanDescr(parent: any, elem: any, descrGuid: string) {
        if (elem.controls) {
            elem.controls.forEach((el: any) => {
                scanDescr(elem, el, descrGuid);
            });
        }
        if (elem.guid === descrGuid) parentElem = parent;
    }

    scanDescr(null, formDescr, guid);
    if (parentElem?.controls) {
        let ind = parentElem.controls.findIndex((control: any) => control.guid === guid);
        if (ind !== -1) {
            ind += direction;
            if (ind >= parentElem.controls.length) ind = 0;
            if (ind < 0) ind = parentElem.controls.length - 1;
            // TODO: надо искать не просто следующий контрол, а контрол, который может получить фокус
            return parentElem.controls[ind];
        }
    }
};
