import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';

import {
    Button,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Stack
} from '@mui/material';
import { DeleteOutline } from '@mui/icons-material';

import InputField from './InputField';
import NewStringDialog from './NewStringDialog';

import { TranslationScheme, Localisation, Locale } from '../interfaces';

import ConfigurationStore from '../../../../../store/configurationStore';
import Translation from '../../../../../localization/translation';

interface PropsType {
    open: boolean;
    formRes: any;
    localisations: string[];
    handleConfirm: (translation: { [key: string]: Locale }) => unknown;
    handleClose: () => unknown;
}

const TranslationEditorDialog: FunctionComponent<PropsType> = observer(
    ({ open, formRes, localisations, handleConfirm, handleClose }) => {
        const { resource }: any = ConfigurationStore.content;

        const lang = useMemo(
            () => formRes.lang ?? ConfigurationStore.locale,
            [formRes.lang, ConfigurationStore.locale]
        );

        const translation = useMemo(
            () => (open ? new Translation(lang, formRes.translation, localisations) : null),
            [open]
        );

        const [newTranslation, setNewTranslation] = useState<{ [key: string]: Locale }>({});
        const [translationScheme, setTranslationScheme] = useState<TranslationScheme>({});
        const [deletion, setDeletion] = useState<string[]>([]);

        const handleEdit = (key: string, loc: Localisation, value?: string) => {
            const tmpTranslation: { [key: string]: Locale } = { ...newTranslation };

            if (loc === 'init')
                tmpTranslation[key] = { ...(tmpTranslation[key] ?? {}), ...{ [lang]: value } };

            tmpTranslation[key] = { ...(tmpTranslation[key] ?? {}), ...{ [loc]: value } };

            setNewTranslation(tmpTranslation);
        };

        const handleAddString = ({
            key,
            path,
            localisation
        }: {
            key: string;
            path: string;
            localisation: Locale;
        }) => {
            // Фиксируем набор данных для сохранения
            setNewTranslation({ ...newTranslation, ...{ [key]: localisation } });

            // Фиксируем схему перевода для редактирования
            setTranslationScheme({
                ...translationScheme,
                ...{ [key]: { path, localisation, custom: true } }
            });
        };

        const handleDeleteString = (key: string) => {
            // Фиксируем удаляемое поле
            setDeletion([...deletion, key]);

            // Фиксируем схему перевода для редактирования
            const tmpScheme = { ...translationScheme };
            delete tmpScheme[key];
            setTranslationScheme(tmpScheme);
        };

        // При открытии диалогового окна генерируем объект схемы перевода
        useEffect(() => {
            if (open && translation) {
                setTranslationScheme(translation.getScheme(formRes));
            }
        }, [open, translation]);

        return (
            <Dialog open={open} onClose={handleClose} maxWidth="lg">
                <DialogTitle id="script-dialog-title">{resource.scriptEditor.title}</DialogTitle>
                <DialogContent style={{ paddingTop: 16 }}>
                    <Table sx={{ tableLayout: 'fixed' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell width={150}>Контрол</TableCell>
                                <TableCell width={150}>init</TableCell>
                                {localisations.map((localisation: string) =>
                                    lang !== localisation ? (
                                        <TableCell key={`${localisation}_header`} width={150}>
                                            {localisation}
                                        </TableCell>
                                    ) : null
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(translationScheme).map((key, row) => (
                                <TableRow key={`${key}_${row}`}>
                                    <TableCell key={`${key}_${row}_1`}>
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            {translationScheme[key].path}
                                            {translationScheme[key].custom ? (
                                                <IconButton onClick={() => handleDeleteString(key)}>
                                                    <DeleteOutline color="error" />
                                                </IconButton>
                                            ) : null}
                                        </Stack>
                                    </TableCell>
                                    <TableCell key={`${key}_${row}_2`}>
                                        {/* Если поле добавлено вручную оставляем возможность его редактирования */}
                                        {translationScheme[key].custom ? (
                                            <InputField
                                                size="small"
                                                fullWidth
                                                defaultValue={
                                                    translationScheme[key].localisation.init
                                                }
                                                changeValue={value =>
                                                    handleEdit(key, 'init', value)
                                                }
                                            />
                                        ) : (
                                            translationScheme[key].localisation.init
                                        )}
                                    </TableCell>
                                    {localisations
                                        .filter(localisation => localisation !== lang)
                                        .map((localisation: string, cell) =>
                                            translationScheme[key].localisation[
                                                localisation as Localisation
                                            ] !== undefined ? (
                                                <TableCell key={`${key}_${row}_${cell + 2}`}>
                                                    <InputField
                                                        size="small"
                                                        fullWidth
                                                        defaultValue={
                                                            translationScheme[key].localisation[
                                                                localisation as Localisation
                                                            ]
                                                        }
                                                        changeValue={value =>
                                                            handleEdit(
                                                                key,
                                                                localisation as Localisation,
                                                                value
                                                            )
                                                        }
                                                    />
                                                </TableCell>
                                            ) : null
                                        )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <NewStringDialog
                        localisations={localisations}
                        translationScheme={translationScheme}
                        lang={lang}
                        onConfirm={handleAddString}
                    />
                    <Button onClick={handleClose}>
                        {ConfigurationStore.content.resource.buttons.cancel}
                    </Button>
                    <Button
                        onClick={() =>
                            translation &&
                            handleConfirm(translation.updateTranslation(newTranslation, deletion))
                        }
                    >
                        {ConfigurationStore.content.resource.buttons.ok}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
);

export default TranslationEditorDialog;
