import { useState, FunctionComponent, useEffect } from 'react';

import { ResourceInfo } from 'forms/interfaces';

import CustomForm from 'forms/CustomForm';

interface LookupFormProps {
    descr?: any;
}

const LookupForm: FunctionComponent<LookupFormProps> = ({ descr }) => {
    const [formDescr, setFormDescr] = useState<ResourceInfo | null>(null);

    useEffect(() => {
        if (descr) {
            setFormDescr(descr);
        }
    }, [descr]);

    return formDescr ? <CustomForm key={formDescr.guid} formConfig={formDescr} /> : null;
};

export default LookupForm;
