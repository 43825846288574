import { FunctionComponent, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { autorun } from 'mobx';

import { withSnackbar, ProviderContext } from 'notistack';

import NotificationStore, { SnackbarType } from 'store/notificationStore';

const Notification: FunctionComponent<ProviderContext> = observer(({ enqueueSnackbar }) => {
    const { notifications, removeSnackbar } = NotificationStore;
    const [displayed, setDisplayed] = useState<Array<number>>([]);

    const storeDisplayed = (key: number) => {
        const newDisplayed = [...displayed];

        newDisplayed.unshift(key);
        if (newDisplayed.length > 10) newDisplayed.length = 10;

        setDisplayed([...newDisplayed]);
    };

    useEffect(() => {
        autorun(() => {
            notifications.forEach((notification: SnackbarType) => {
                if (displayed.includes(notification.key)) return;
                enqueueSnackbar(notification.message, notification.options);
                storeDisplayed(notification.key);
                removeSnackbar(notification.key);
            });
        });
    });

    return null;
});

export default withSnackbar(Notification);
