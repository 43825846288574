import React, { FunctionComponent, useEffect, useState, useMemo } from 'react';
import { observer } from 'mobx-react';

import useCtrlProps from '../../hooks/ctrlProps';

import { ContainerType, ControlPropsType } from '../interfaces';

import CustomForm from '../CustomForm';

import DataStore from '../../store/dataStore';

import { checkGuid } from '../../utils';

interface PropsType extends ControlPropsType {
    descr: ContainerType;
}

const Container: FunctionComponent<PropsType> = observer(({ descr, propContainer }) => {
    const { ctrlVisible } = useCtrlProps(propContainer, descr.guid);

    const [formGuid, setFormGuid] = useState('');
    const [extParamVals, setExtParamVals] = useState({});

    const extParamAFKeys = useMemo(
        () =>
            descr.params
                .filter(par => par.paramType === 'af')
                .map(par => par.sourceField || par.sourceAF),
        [descr.params]
    );

    const extParamAFVals = extParamAFKeys.map(key => DataStore.AF.getAF(key));

    const checkSourceType = (source: string) => {
        if (checkGuid(source)) {
            return source;
        }

        const resLink = propContainer.getResourceLink(source);

        if (resLink) return resLink.resource.guid;

        return source;
    };

    useEffect(() => {
        if (descr.sourceNameType === 'af') setFormGuid(DataStore.AF.getAF(descr.sourceName));
        if (descr.sourceNameType === 'guid') setFormGuid(checkSourceType(descr.sourceName));
        if (descr.sourceNameType === 'name') setFormGuid(checkSourceType(descr.sourceName));
    }, [descr.sourceName, DataStore.AF.getAF(descr.sourceName)]);

    useEffect(() => {
        if (descr.params?.length) setExtParamVals(propContainer.dataStock.getParams(descr.params));
    }, [JSON.stringify(extParamAFVals)]);

    if (formGuid) {
        return ctrlVisible ? (
            <CustomForm
                key={formGuid}
                formGuid={formGuid}
                extParamVals={extParamVals}
                parentDataStock={propContainer.dataStock}
                parentPropContainer={propContainer}
            />
        ) : (
            <div />
        );
    }

    return <div />;
});

export default Container;
