import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';

import MUIButton from '@mui/material/Button';
import DataObjectIcon from '@mui/icons-material/DataObject';

import { IconButton, Tooltip } from '@mui/material';

import { CustomToolbarButtonType } from '../interface';

interface PropsType {
    button: CustomToolbarButtonType;
    fullWidth?: boolean;
}

const CustomToolbarButton: FunctionComponent<PropsType> = observer(({ button, fullWidth }) => {
    const { caption, variant, icon, iconBtn, iconPosition, color, disabled, onClick } = button;

    return fullWidth ? (
        <MUIButton
            variant={variant || 'contained'}
            onClick={() => onClick()}
            disabled={disabled}
            sx={{ whiteSpace: 'nowrap' }}
            startIcon={!iconPosition || iconPosition === 'start' ? icon : null}
            endIcon={iconPosition === 'end' ? icon : null}
            color={color}
        >
            {caption}
        </MUIButton>
    ) : (
        <Tooltip title={caption}>
            <span>
                <IconButton onClick={() => onClick()} disabled={disabled} color={color}>
                    {iconBtn || icon ? iconBtn || icon : <DataObjectIcon />}
                </IconButton>
            </span>
        </Tooltip>
    );
});

export default CustomToolbarButton;
