import React, { useEffect, useCallback, useState, FunctionComponent } from 'react';
import { observer } from 'mobx-react';

import { Typography } from '@mui/material';

import useCtrlData from '../../hooks/ctrlData';
import useCtrlProps from '../../hooks/ctrlProps';

import { ControlPropsType, DBLabelType } from '../interfaces';
import { getLocalizedString, dateToString } from '../../utils';

interface BDLabelProps extends ControlPropsType {
    descr: DBLabelType;
    dataRow: any;
}

const DBLabel: FunctionComponent<BDLabelProps> = observer(({ descr, propContainer, dataRow }) => {
    const { dataVal, dataType, internationalField, fieldEnabled } = useCtrlData(
        propContainer,
        descr.datasetName,
        descr.fieldName,
        dataRow
    );
    const { ctrlEnabled, ctrlVisible } = useCtrlProps(propContainer, descr.guid);

    const enabled = fieldEnabled && (ctrlEnabled === null ? true : ctrlEnabled);

    const isDate = dataType === 'KRN_DATE' || dataType === 'KRN_DATETIME';
    const dateFormat = dataType === 'KRN_DATE' ? 'DD.MM.YYYY' : 'DD.MM.YYYY HH:mm:ss';

    const valueToText = useCallback(
        val => {
            if (val === undefined || val === null) return null;
            if (internationalField) return getLocalizedString(val);
            if (isDate) return dateToString(val, dateFormat);
            return val;
        },
        [dataVal]
    );

    const [val, setVal] = useState(() => valueToText(dataVal));

    useEffect(() => {
        setVal(valueToText(dataVal));
    }, [dataVal, valueToText]);

    return (
        <div
            style={{
                width: '100%',
                padding: '4px',
                boxSizing: 'border-box',
                visibility: ctrlVisible ? 'inherit' : 'hidden',
                color: enabled ? 'inherit' : '#888'
            }}
        >
            {descr.variant ? (
                <Typography variant={descr.variant} id={descr.guid}>
                    {val ?? ''}
                </Typography>
            ) : (
                <span id={descr.guid}>{val ?? ''}</span>
            )}
        </div>
    );
});

export default DBLabel;
