import React from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from 'recharts';
import Konva from 'konva';
import { Grid, Typography } from '@mui/material';

import ConfigurationStore from 'store/configurationStore';

interface PropsType {
    data: any | undefined;
    columns?: any | undefined;
    settings: any;
}

const VerticalBarChartComponent = (props: PropsType) => {
    const { content } = ConfigurationStore;

    const { data, columns, settings } = props;
    const { chartElements, xAxis, yAxis, chartHeight, chartWidth } = settings;

    const renderLegend = (value: string, entry: any) => {
        const { color, dataKey } = entry;
        const legend = columns.find((item: any) => item.name === dataKey);

        return <span style={{ color }}>{legend ? legend.caption : value}</span>;
    };

    return (
        <Grid container sx={{ margin: 'auto' }}>
            {chartElements?.length > 0 && yAxis ? (
                <ResponsiveContainer width="100%" height={chartHeight}>
                    <BarChart
                        width={chartWidth}
                        height={chartHeight}
                        layout="vertical"
                        data={data}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 80,
                            bottom: 5
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <YAxis type="category" dataKey={xAxis.fieldName} />
                        <XAxis type="number" />
                        <Tooltip />
                        <Legend formatter={renderLegend} />
                        {chartElements.map((el: any) => (
                            <Bar
                                dataKey={el.fieldName}
                                fill={el.color ? el.color : Konva.Util.getRandomColor()}
                            />
                        ))}
                    </BarChart>
                </ResponsiveContainer>
            ) : (
                <Grid item sx={{ margin: 'auto' }}>
                    <Typography>{content.controls.data.noDataset}</Typography>
                </Grid>
            )}
        </Grid>
    );
};

export default VerticalBarChartComponent;
