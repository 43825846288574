const langRus = {
    inputs: {
        autocomplete: {
            add: 'Добавить',
            noOptions: 'Нет вариантов'
        },
        filterControl: {
            conditions: [
                'В интервале',
                'Вне интервала',
                'Начинается с',
                'Содержит',
                'Не содержит',
                'Заканчивается на',
                'Равно',
                'Не равно',
                'Заполнено',
                'Пусто',
                'Включая',
                'Исключая',
                'Не важно',
                'Да',
                'Нет'
            ]
        },
        date: {
            today: 'Сегодня',
            cancel: 'Отмена'
        }
    }
};

export default langRus;
