import { FunctionComponent, useMemo } from 'react';
import { observer } from 'mobx-react';

import CustomDataset from 'dataObj/customDataset';
import NotificationStore from 'store/notificationStore';

import Tree from '../../index';

import RootNode from './RootNode';
import EditableNodeContainer from '../Editable/EditableNodeContainer';

import { TreeItemDetailRoot } from '../../../interfaces';

import { getDetailDS } from '../../../utils';

const RootDetailNode: FunctionComponent<TreeItemDetailRoot> = observer(props => {
    const { dataset, parentId, currentChunk, appendLoading, setAppendLoading, disabled } = props;

    const detailDSList: CustomDataset[] = getDetailDS(dataset);

    const addRootNode = () => {
        dataset.setCurrentDataChunk(currentChunk);
        setAppendLoading(true);

        dataset.cdo
            ?.append(dataset)
            .catch((err: { message: string }) => {
                console.log(err.message);
                NotificationStore.enqueueSnackbar({
                    message: err.message,
                    options: { variant: 'error' }
                });
            })
            .finally(() => setAppendLoading(false));
    };

    const dsOptions = useMemo(
        () => (dataset.descr.options ? JSON.parse(dataset.descr.options) : {}),
        [dataset]
    );

    return currentChunk.data?.length ? (
        <>
            {currentChunk.data.map((record, recIndex, data) => (
                <RootNode
                    key={`${dataset.guid}-${parentId}-${recIndex}`}
                    nodeId={`${dataset.guid}-${parentId}-${recIndex}`}
                    index={recIndex}
                    labelText={dataset.descr.caption}
                    dataset={dataset}
                    parentId={parentId}
                    addRootNode={recIndex === data.length - 1 ? addRootNode : undefined}
                    appendLoading={appendLoading}
                    setAppendLoading={setAppendLoading}
                    disabled={dsOptions.archive || disabled}
                >
                    <EditableNodeContainer
                        dataset={dataset}
                        index={recIndex}
                        parentId={parentId}
                        dataRow={record}
                        disabled={dsOptions.archive || disabled}
                    />
                    {detailDSList.map(detailDS => (
                        <Tree
                            key={detailDS.guid}
                            dataset={detailDS}
                            parentId={record[dataset.keyField]}
                            disabled={dsOptions.archive || disabled}
                        />
                    ))}
                </RootNode>
            ))}
        </>
    ) : (
        <RootNode
            key={`${dataset.guid}-${parentId}-empty`}
            nodeId={`${dataset.guid}-${parentId}-empty`}
            index={0}
            labelText={dataset.descr.caption}
            dataset={dataset}
            parentId={parentId}
            addRootNode={addRootNode}
            appendLoading={appendLoading}
            setAppendLoading={setAppendLoading}
            disabled={dsOptions.archive || disabled}
        />
    );
});

export default RootDetailNode;
