import React from 'react';

import EventsStore from 'store/eventsStore';
import { onClose } from 'store/eventsStore/events';
import PropContainer from 'dataObj/PropContainer';
import SimpleDialog, { SimpleDialogPropsType } from 'components/Feedback/SimpleDialog';

interface ModalPropsType extends SimpleDialogPropsType {
    formGuid: string;
    handleClose: (state: boolean, result?: any[]) => Promise<void>;
    propContainer?: PropContainer;
}

const ModalForm = (props: ModalPropsType) => {
    const { formGuid, handleClose, children, ...otherProps } = props;

    const modalClose = async (state: boolean) => {
        const resultArr = await EventsStore.getEvents(formGuid)?.triggerEvent(onClose, {
            success: state
        });

        await handleClose(state, resultArr);
    };

    const injProps = {
        handleClose: modalClose,
        children
    };

    return <SimpleDialog {...otherProps} {...injProps} />;
};

export default ModalForm;
