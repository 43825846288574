import { makeObservable, observable, action } from 'mobx';

import ClientDataset from './ClientDataset';
import { DataStoreClass } from '../store/dataStore';

export default class AFContainer {
    readonly DataStore: DataStoreClass;
    readonly data: any;

    constructor(DataStore: DataStoreClass) {
        this.DataStore = DataStore;
        this.data = {};

        makeObservable(this, {
            data: observable,
            setAF: action
        });
    }

    initAFDataset(dsAF: ClientDataset) {
        for (const afName in this.data) {
            dsAF.setFieldValue(afName, this.data[afName]);
        }
    }

    /**
     * Установить значение активного фильтра
     * @param afName - имя активного фильтра
     * @param value - значение
     */
    setAF(afName: string, value: any, options: any = {}) {
        if (this.data[afName] !== value) {
            this.data[afName] = value;
            if (!options?.noDistribute) this.DataStore.distributeAF(afName);
        }
    }

    /**
     * Получить значение активного фильтра
     * @param afName - имя активного фильтра
     * @returns - значение
     */
    getAF(afName: string) {
        return this.data[afName];
    }
}
