import React, { FunctionComponent, useMemo } from 'react';
import { observer } from 'mobx-react';

import { Button, Checkbox, FormControlLabel, FormGroup } from '@mui/material';

import { DatasetFieldType, DatasetType } from '../../../../forms/interfaces';

import ConfigurationStore from '../../../../store/configurationStore';

interface PropsType {
    datasetList: DatasetType[];
    selectedDataset: string[];
    checkFields: (dsName: string, field: DatasetFieldType) => unknown;
    handleSelectDataset: (event: React.ChangeEvent<HTMLInputElement>) => unknown;
    handleSelectAll: (datasets: string[] | null) => unknown;
}

const DatasetSelection: FunctionComponent<PropsType> = observer(
    ({ datasetList, selectedDataset, checkFields, handleSelectDataset, handleSelectAll }) => {
        const { content } = ConfigurationStore;

        const list = useMemo(
            () =>
                datasetList.filter(
                    dataset =>
                        // Показываем только датасеты, у которых есть поля
                        dataset.fields?.length &&
                        // И эти поля ещё не добавлены на форму
                        dataset.fields?.filter(field => checkFields(dataset.name, field)).length
                ),
            [datasetList]
        );

        const selectedAll =
            list.filter(dataset => selectedDataset.includes(dataset.name)).length === list.length;

        return (
            <>
                <Button
                    onClick={() => handleSelectAll(selectedAll ? null : list.map(ds => ds.name))}
                >
                    {selectedAll
                        ? content.resource.buttons.clearAll
                        : content.resource.buttons.selectAll}
                </Button>
                <FormGroup>
                    {list.map(dataset => (
                        <FormControlLabel
                            key={dataset.guid}
                            control={
                                <Checkbox
                                    checked={selectedDataset.includes(dataset.name)}
                                    onChange={handleSelectDataset}
                                    name={dataset.name}
                                />
                            }
                            label={dataset.name}
                        />
                    ))}
                </FormGroup>
            </>
        );
    }
);

export default DatasetSelection;
