import { makeStyles } from 'tss-react/mui';

const useLookupStyles = makeStyles()(theme => ({
    root: {
        paddingRight: 8,
        '& label.Mui-focused': {
            color: theme.palette.primary.main
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: theme.palette.primary.main
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#D9DEEC'
            },
            '&:hover fieldset': {
                borderColor: '#D9DEEC'
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary.main,
                borderWidth: '1px'
            }
        }
    },
    form: {
        width: '100%',
        boxSizing: 'border-box',
        margin: theme.spacing(2)
    },
    margin: {
        margin: theme.spacing(1)
    },
    divider: {
        height: 28,
        margin: 4
    },
    iconButton: {
        marginRight: 0
    },
    helperText: {
        paddingLeft: 8,
        color: '#6157ff'
    },
    input: {
        textOverflow: 'ellipsis',
        width: '100%'
    },
    helperTextInput: {
        width: '36%'
    },
    helperWrapper: {
        display: 'contents'
    },
    helperTextWrapper: {
        overflow: 'hidden',
        width: 'calc(100% - 49px)',
        '& p': {
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        }
    },
    helperFade: {
        position: 'absolute',
        top: 0,
        right: 43,
        width: 65,
        height: '100%'
    }
}));

export default useLookupStyles;
