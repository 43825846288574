import { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { TreeView, TreeItem } from '@mui/x-tree-view';
import { ExpandMore, ChevronRight } from '@mui/icons-material';
import {
    Box,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Table,
    Typography,
    Button
} from '@mui/material';
import { SplitPane } from '@calypso/react-multi-split-pane';

import { ResourceClass } from 'store/resourceStore';
import { ScriptGlossaryType, ScriptGlossaryItemType } from 'forms/interfaces';

type ScriptGlossaryProps = {
    onSelect?: (item: any) => void;
    resourceStore: ResourceClass;
};

const simpleTypes = new Set<string>([
    'string',
    'number',
    'object',
    'boolean',
    'null',
    'undefined',
    'Promise',
    'void',
    'any'
]);

const ResScriptGlossary: FunctionComponent<ScriptGlossaryProps> = ({ onSelect, resourceStore }) => {
    const { curRes, editRes, curCtrl, getJsDocInfo } = resourceStore;

    const [curDocInfo, setCurDocInfo] = useState<ScriptGlossaryType>();
    const [selectedItem, setSelectedItem] = useState<ScriptGlossaryItemType>();
    const [selected, setSelected] = useState<string>();

    const listDocInfo: ScriptGlossaryItemType[] = [];

    useEffect(() => {
        if (curCtrl && curRes) {
            getJsDocInfo(curRes.guid, curCtrl.type === 'serverScript')
                .then(data => setCurDocInfo(data))
                .catch((e: Error) => console.error(e.message));
        }
    }, [curRes, curCtrl]);

    const renderTreeItem = (rootNode: any, nameNode?: string) => {
        if (nameNode && rootNode.type) {
            rootNode.name = nameNode;
        }
        return rootNode
            ? Object.keys(rootNode).map(name => {
                  const item = rootNode[name];
                  item.name = name;
                  const idx = listDocInfo.push(item);

                  return (
                      <TreeItem key={idx} nodeId={String(idx)} label={name}>
                          {item.type === 'class' ? renderTreeItem(item.methods) : undefined}
                      </TreeItem>
                  );
              })
            : undefined;
    };

    const renderScriptGlossary = (rootNode: any) => {
        const rootNodes: Record<string, string> = {
            types: 'Типы',
            functions: 'Функции',
            objects: 'Объекты'
        };
        return rootNode
            ? Object.keys(rootNode).map(name => {
                  const item = rootNode[name];
                  const idx = listDocInfo.push(item);
                  const label = rootNodes[name] || name;
                  return (
                      <TreeItem key={idx} nodeId={String(idx)} label={label}>
                          {typeof item === 'object'
                              ? renderTreeItem(rootNode[name], name)
                              : undefined}
                      </TreeItem>
                  );
              })
            : undefined;
    };

    const selectNode = (event: any, nodeId: string | string[]) => {
        if (typeof nodeId !== 'string') {
            return;
        }

        const item = listDocInfo[+nodeId - 1];
        setSelectedItem(item.type ? item : undefined);
    };

    const selectNodeByType = (event: any, typeName: string) => {
        const idx = listDocInfo.findIndex(
            item =>
                typeof item.type === 'string' &&
                ['class', 'type'].includes(item.type) &&
                item.name === typeName
        );
        if (idx) {
            setSelected(String(idx + 1));
        }
    };

    const renderTypeName = (typeName: string) =>
        simpleTypes.has(typeName) ? (
            <span>{typeName}</span>
        ) : (
            <button type="button" onClick={event => selectNodeByType(event, typeName)}>
                {typeName}
            </button>
        );

    const renderType = (typeItem: ScriptGlossaryItemType): ReactElement | ReactElement[] => {
        const { type, unionType, complexType, value } = typeItem;

        if (type === 'const' && value) {
            return <span>{`'${value}'`}</span>;
        }

        const typeStr = typeof type === 'string' ? type : '';

        if (unionType) {
            return unionType.map((ut, idx, arr) => (
                <>
                    {renderType(ut)}
                    {idx !== arr.length - 1 && <span> | </span>}
                </>
            ));
        }

        if (complexType?.length) {
            const complexList = complexType.map(ct => renderType(ct));

            return (
                <>
                    <span>{typeStr} &lt;</span>
                    {complexList}
                    <span>&gt;</span>
                </>
            );
        }

        return renderTypeName(typeStr);
    };

    const renderParamString = (methodItem: ScriptGlossaryItemType) => {
        const { params, return: returnType, name = '' } = methodItem;
        const names = params ? params.map(p => p.name).join(', ') : '';
        const text = `${name}(${names})`;

        const res = returnType ? (
            <>
                <span>: </span>
                {renderType(returnType)}
            </>
        ) : (
            ''
        );

        return (
            <>
                <span>{text}</span>
                {res}
            </>
        );
    };

    const renderParamList = (params: ScriptGlossaryItemType[], subject: string) => {
        const list = params.map((p, idx) => (
            <TableRow key={idx}>
                <TableCell key="1">{p.name}</TableCell>
                <TableCell key="2">{renderType(p)}</TableCell>
                <TableCell key="3">{p.description}</TableCell>
            </TableRow>
        ));

        return (
            <>
                <Typography variant="h6">{subject}</Typography>
                <Table>
                    <TableHead>
                        <TableCell>Наименование</TableCell>
                        <TableCell>Тип</TableCell>
                        <TableCell>Описание</TableCell>
                    </TableHead>
                    <TableBody>{list}</TableBody>
                </Table>
            </>
        );
    };

    const renderMethod = (methodItem: ScriptGlossaryItemType) => (
        <>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {renderParamString(methodItem)}
            </Typography>
            <div>
                {methodItem.params?.length && renderParamList(methodItem.params, 'Параметры')}
            </div>
        </>
    );

    const renderDefType = (typeItem: ScriptGlossaryItemType) =>
        typeItem.properties?.length && renderParamList(typeItem.properties, 'Свойства');

    const renderObject = (item: ScriptGlossaryItemType) => (
        <Typography>Тип: {item.dataType ? renderTypeName(item.dataType) : ''}</Typography>
    );

    const renderCustomItem = (item: ScriptGlossaryItemType) => {
        switch (item.type) {
            case 'method':
                return renderMethod(item);
            case 'type':
                return renderDefType(item);
            case 'object':
                return renderObject(item);
        }
    };

    const renderItem = (item: ScriptGlossaryItemType) =>
        item.type ? (
            <>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {item.type}
                </Typography>
                <Typography variant="h5">{item.name}</Typography>
                <Typography variant="body2">{item.description}</Typography>

                {renderCustomItem(item)}
            </>
        ) : (
            <Typography variant="h5" component="div">
                {item.name}
            </Typography>
        );

    const handleInsertText = (item: ScriptGlossaryItemType) => {
        if (!onSelect) return;

        onSelect(item.name);
        return;

        // TODO: добавить расширить
        /* if (item.type === 'method') {
            onSelect(renderParamString(item));
        } else if (item.type === 'type') {
            onSelect(renderDefType(item));
        } else {
            onSelect(item.name);
        } */
    };

    return (
        <SplitPane split="horizontal" minSize={100}>
            <TreeView
                selected={selected}
                onNodeSelect={selectNode}
                defaultCollapseIcon={<ExpandMore />}
                defaultExpandIcon={<ChevronRight />}
                sx={{ overflow: 'auto', width: '100%' }}
            >
                {renderScriptGlossary(curDocInfo)}
            </TreeView>

            {selectedItem ? (
                <Box sx={{ overflow: 'auto', width: '100%' }}>
                    {renderItem(selectedItem)}
                    <Button size="small" onClick={() => handleInsertText(selectedItem)}>
                        Добавить в код
                    </Button>
                </Box>
            ) : (
                <div />
            )}
        </SplitPane>
    );
};

export default ResScriptGlossary;
