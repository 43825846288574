import React, { FunctionComponent, useCallback, useState } from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

interface PropsType {
    caption?: string;
    paper: boolean;
    accordion: boolean;
    groupBox: boolean;
    style: { [key: string]: any };
}

const PanelWrapper: FunctionComponent<PropsType> = ({
    children,
    caption,
    accordion,
    paper,
    groupBox,
    style
}) => {
    const [expanded, setExpanded] = useState(true);

    const handleExpanded = () => {
        setExpanded(prev => !prev);
    };

    const getContent = useCallback(
        () =>
            paper || groupBox ? (
                <Paper
                    elevation={paper ? 1 : 0}
                    variant={groupBox ? 'outlined' : 'elevation'}
                    sx={style}
                >
                    {children}
                </Paper>
            ) : (
                <Box sx={style}>{children}</Box>
            ),
        [children, accordion, paper, groupBox, style]
    );

    return accordion && caption ? (
        <Accordion
            disableGutters
            expanded={expanded}
            onChange={handleExpanded}
            elevation={2}
            variant="outlined"
            sx={{
                '&.MuiPaper-root': { m: 2, borderRadius: 1 },
                '&:before': {
                    display: 'none'
                }
            }}
        >
            <AccordionSummary
                expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
                aria-controls="panel-content"
                id="panel-header"
                sx={theme => ({
                    backgroundColor:
                        theme.palette.mode === 'dark'
                            ? 'rgba(255, 255, 255, .12)'
                            : 'rgba(0, 0, 0, .09)',
                    flexDirection: 'row-reverse',
                    '& .MuiAccordionSummary-expandIconWrapper': {
                        marginLeft: theme.spacing(1)
                    },
                    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                        transform: 'rotate(90deg)'
                    },
                    '& .MuiAccordionSummary-content': {
                        marginLeft: theme.spacing(2)
                    }
                })}
            >
                <Typography>{caption}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ pt: 4, borderTop: '1px solid rgba(0, 0, 0, .125)' }}>
                {getContent()}
            </AccordionDetails>
        </Accordion>
    ) : (
        getContent()
    );
};
export default PanelWrapper;
