import { FunctionComponent, MutableRefObject, useEffect, useRef, useState } from 'react';

import isString from 'lodash/isString';
import toNumber from 'lodash/toNumber';

import { Box } from '@mui/material';
import { SplitPane } from '@calypso/react-multi-split-pane';

import useCtrlProps from '../../hooks/ctrlProps';
import useOnScreen from '../../hooks/onScreen';

import ControlTree from '../ControlTree';

import { SplitterType, ControlType, ControlPropsType } from '../interfaces';

interface SplitterProps extends ControlPropsType {
    descr: SplitterType;
}

const DEFAULT_MIN_SIZE = 250;

const Splitter: FunctionComponent<SplitterProps> = ({ descr, propContainer }) => {
    const { guid, split, minSize = DEFAULT_MIN_SIZE } = descr;
    const { ctrlVisible } = useCtrlProps(propContainer, guid);

    const ref = useRef();
    const onScreen = useOnScreen(ref);

    const [settled, setSettled] = useState(false);
    const [defaultSizes, setDefaultSizes] = useState<number[] | undefined>();

    const getWidth = ({ gridWidth }: ControlType) => {
        const width = document.getElementById(`splitter-box-${descr.guid}`)?.offsetWidth ?? 0;

        if (isString(gridWidth)) {
            const params = gridWidth?.toLowerCase().replace(/\s/g, '')?.split(',');

            if (params?.length) {
                return (width / 12) * toNumber(params[0]?.split(':')[1]);
            }
        } else {
            return (width / 12) * gridWidth;
        }

        return DEFAULT_MIN_SIZE;
    };

    const getDefaultSizes = (controls: ControlType[]) => controls.map(control => getWidth(control));

    useEffect(() => {
        if (onScreen) {
            setDefaultSizes(getDefaultSizes(descr?.controls));
            setSettled(true);
        }
    }, [onScreen]);

    return (
        <Box
            id={`splitter-box-${descr.guid}`}
            ref={ref as MutableRefObject<any>}
            sx={{
                height: '100%',
                position: 'relative',
                visibility: ctrlVisible && settled ? 'inherit' : 'hidden'
            }}
        >
            <SplitPane split={split} defaultSizes={defaultSizes} minSize={minSize}>
                {descr.controls?.map((control: any) => (
                    <ControlTree
                        key={`split-child-${control.guid as string}`}
                        formDescr={{ ...control, ...{ gridWidth: '' } }}
                        propContainer={propContainer}
                    />
                ))}
            </SplitPane>
        </Box>
    );
};

export default Splitter;
