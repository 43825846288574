import React, {
    FocusEvent,
    FunctionComponent,
    KeyboardEvent,
    MouseEvent,
    useRef,
    useState
} from 'react';
import { observer } from 'mobx-react';

import Box from '@mui/material/Box';
import MUIMenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import useCtrlProps from 'hooks/ctrlProps';
import useScriptHandler from 'hooks/scriptHandler';

import SVGIcon from 'forms/controls/SVGIcon';

import { NestedButtonType } from '../interfaces';
import NestedMenu from './NestedMenu';

const MenuItem: FunctionComponent<NestedButtonType> = observer(
    ({ descr, propContainer, children, parentClose }) => {
        const { guid, caption, onClickScript, params, items, icon } = descr;

        const [open, setOpen] = useState(false);

        const { ctrlEnabled } = useCtrlProps(propContainer, guid);

        const menuItemRef = useRef<any>(null);

        const containerRef = useRef<HTMLLIElement>(null);

        const menuContainerRef = useRef<HTMLLIElement>(null);

        const handleScript = useScriptHandler(onClickScript, propContainer, params);

        const handleOnClick = () => {
            handleScript().catch(err => console.error(err.message));
            parentClose();
        };

        const handleClose = () => {
            setOpen(false);
        };

        const handleParentClose = () => {
            handleClose();
            parentClose();
        };

        const handleMouseEnter = (e: MouseEvent<HTMLElement>) => {
            setOpen(true);
        };
        const handleMouseLeave = (e: MouseEvent<HTMLElement>) => {
            setOpen(false);
        };

        const handleFocus = (e: FocusEvent<HTMLElement>) => {
            if (e.target === containerRef.current) {
                setOpen(true);
            }
        };

        const isSubmenuFocused = () => {
            const active = containerRef?.current?.ownerDocument?.activeElement;
            if (menuContainerRef?.current?.children) {
                for (const child of menuContainerRef.current.children) {
                    if (child === active) {
                        return true;
                    }
                }
            }
            return false;
        };

        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                return;
            }

            if (isSubmenuFocused()) {
                e.stopPropagation();
            }

            const active = containerRef?.current?.ownerDocument.activeElement;

            if (e.key === 'ArrowLeft' && isSubmenuFocused()) {
                containerRef?.current?.focus();
            }

            if (
                e.key === 'ArrowRight' &&
                e.target === containerRef.current &&
                e.target === active
            ) {
                const firstChild = menuContainerRef?.current?.children[0] as any;
                firstChild?.focus();
            }
        };

        return (
            <Box
                ref={containerRef as any}
                onFocus={handleFocus}
                // tabIndex={tabIndex}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onKeyDown={handleKeyDown}
            >
                <MUIMenuItem ref={menuItemRef} disabled={!ctrlEnabled} onClick={handleOnClick}>
                    {!!icon && (
                        <ListItemIcon>
                            <SVGIcon descr={descr} propContainer={propContainer} />
                        </ListItemIcon>
                    )}
                    <ListItemText>{caption}</ListItemText>
                    {!!items?.length && <ChevronRightIcon />}
                </MUIMenuItem>
                {!!items?.length && (
                    <NestedMenu
                        open={open}
                        items={items}
                        anchorRef={menuItemRef}
                        handleClose={handleClose}
                        parentClose={handleParentClose}
                        propContainer={propContainer}
                        menuPlacement="right-start"
                    >
                        <div ref={menuContainerRef as any} style={{ pointerEvents: 'auto' }}>
                            {children}
                        </div>
                    </NestedMenu>
                )}
            </Box>
        );
    }
);

export default MenuItem;
