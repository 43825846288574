import React, { FunctionComponent } from 'react';

import { Box, Typography } from '@mui/material';

interface PropsType {
    guid: string;
    caption?: string;
}

const GroupBoxTitle: FunctionComponent<PropsType> = ({ guid, caption }) => (
    <Box
        width="fit-content"
        maxWidth="80%"
        sx={{
            position: 'absolute',
            top: -5,
            zIndex: 2,
            left: 16,
            backgroundColor: 'white',
            whiteSpace: 'nowrap'
        }}
    >
        <Typography
            id={`group-box-caption-${guid}`}
            variant="body1"
            align="center"
            sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden'
            }}
        >
            {caption}
        </Typography>
    </Box>
);

export default GroupBoxTitle;
