import React, { FunctionComponent, ReactNode } from 'react';
import { observer } from 'mobx-react';

import Grid, { GridProps } from '@mui/material/Grid';

import useHidden from 'hooks/hidden';

import {
    getGridBreakpoint,
    getGridOrder,
    hiddenStyle,
    styleStringToStyleObject
} from 'forms/form-utils';

import formStyles from 'forms/formStyles';

import PropContainer from 'dataObj/PropContainer';

interface GridWrapperType extends GridProps {
    descr: any;
    children: ReactNode;
    propContainer: PropContainer;
    className?: string;
}

const GridWrapper: FunctionComponent<GridWrapperType> = observer(
    ({ descr, children, propContainer, className, ...otherProps }) => {
        const gridOrder = getGridOrder(descr);
        const sx = formStyles(descr.type);
        const layout = propContainer.getParent(descr.name)?.descr?.layout;
        const gridBreakpoint = getGridBreakpoint(descr?.gridWidth, layout, sx);

        const hidden = useHidden(descr, gridBreakpoint, propContainer);

        if (hidden && descr.type !== 'dbLookup') return null;

        return (
            <Grid
                item
                className={className}
                sx={sx}
                style={{
                    width: '100%',
                    ...styleStringToStyleObject(descr.styles),
                    ...hiddenStyle(hidden)
                }}
                {...gridBreakpoint}
                {...gridOrder}
                {...otherProps}
            >
                {children}
            </Grid>
        );
    }
);

export default GridWrapper;
