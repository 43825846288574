import React, { useEffect } from 'react';
import ComboBox from '../../../components/Inputs/ComboBox';
import { MedialogDataType, DTDatatypes } from '../../interfaces';

const afNames: Record<string, string> = {
    meddepId: 'Текущая должность',
    orgId: 'Текущая организация',
    userId: 'Текущий пользователь',
    specId: 'Текущая специализация',
    filialId: 'Текущее подразделение',
    depId: 'Текущее подразделение',
    roleId: 'Текущая роль'
};

const dtAFNames: Record<string, string> = {
    curDate: 'Текущая дата',
    curDateTime: 'Текущая дата + время',
    startCurWeek: 'Начало текущей недели',
    endCurWeek: 'Конец текущей недели',
    startCurMonth: 'Начало текущего месяца',
    endCurMonth: 'Конец текущего месяца',
    startCurQuar: 'Начало текущего квартала',
    endCurQuar: 'Конец текущего квартала',
    startCurYear: 'Начало текущего года',
    endCurYear: 'Конец текущего года'
};

type AFListPropTypes = {
    afValue: string;
    afName: string;
    label: string;
    dataType: MedialogDataType;
    onChange: (value: string) => void;
};

export default function AFList(props: AFListPropTypes) {
    const { afValue, afName, onChange, label, dataType } = props;

    const store = React.useMemo(
        () =>
            DTDatatypes.indexOf(dataType) !== -1
                ? Object.keys(dtAFNames).map(key => ({ key, val: dtAFNames[key] }))
                : [{ key: afName, val: afNames[afName] }],
        [dataType, afName]
    );

    // Если значения нет по умолчанию
    useEffect(() => (!afValue ? onChange(store[0].key) : undefined), [afValue, store, onChange]);

    return (
        <ComboBox
            style={{
                width: '100%',
                boxSizing: 'border-box'
            }}
            clearable={false}
            store={store}
            nameField="val"
            valueField="key"
            label={label}
            value={afValue}
            onChange={e => onChange(e.target.value)}
            variant="outlined"
            size="small"
        />
    );
}
