import { ResourceEventType } from 'forms/interfaces';

import { onNewRecord, onShow, onHide, onClose, afterScroll } from './events';

export const triggers = (resourceType: string) => {
    switch (resourceType) {
        case 'formEvent':
            return [onShow, onHide, onNewRecord, afterScroll, onClose];

        case 'wuiForm':
            return [onShow, onHide, onClose];

        case 'panel':
        case 'controlEvent':
            return [onShow, onHide];

        case 'dataset':
        case 'clientDataset':
            return [onNewRecord, afterScroll];

        default:
            return [];
    }
};

export const filterEventType = (resourceType: string, event: ResourceEventType) => {
    const availableTriggers = triggers(resourceType);

    return availableTriggers.includes(event.trigger);
};
