import React from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from 'recharts';
import Konva from 'konva';
import { Grid, Typography } from '@mui/material';

import ConfigurationStore from 'store/configurationStore';

interface PropsType {
    data: any | undefined;
    columns?: any | undefined;
    settings: any;
}

const LineChartComponent = (props: PropsType) => {
    const { content } = ConfigurationStore;

    const { data, columns, settings } = props;
    const { chartElements, xAxis, chartHeight, chartWidth } = settings;

    const renderLegend = (value: string, entry: any) => {
        const { color, dataKey } = entry;
        const legend = columns.find((item: any) => item.name === dataKey);

        return <span style={{ color }}>{legend ? legend.caption : value}</span>;
    };

    return (
        <Grid container>
            {chartElements?.length > 0 && xAxis ? (
                <ResponsiveContainer width="100%" height={chartHeight}>
                    <LineChart
                        width={chartWidth}
                        height={chartHeight}
                        data={data}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <YAxis />
                        <XAxis dataKey={xAxis.fieldName} />
                        <Tooltip />
                        <Legend formatter={renderLegend} />
                        {chartElements.map((el: any, index: number) => (
                            <Line
                                key={index}
                                type="monotone"
                                dataKey={el.fieldName}
                                stroke={el.color ? el.color : Konva.Util.getRandomColor()}
                                activeDot={{ r: 8 }}
                            />
                        ))}
                    </LineChart>
                </ResponsiveContainer>
            ) : (
                <Grid item sx={{ margin: 'auto' }}>
                    <Typography>{content.controls.data.noDataset}</Typography>
                </Grid>
            )}
        </Grid>
    );
};

export default LineChartComponent;
