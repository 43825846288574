import React, { FunctionComponent, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { v4 as uuidv4 } from 'uuid';

import {
    Button,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField,
    Tooltip
} from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';

import ConfigurationStore from '../../../store/configurationStore';
import NotificationStore from '../../../store/notificationStore';
import { ResourceClass } from '../../../store/resourceStore';

import { EventType } from './ResInterfaces';

interface PropTypes {
    onConfirm: any;
    ResourceStore: ResourceClass;
}

const guidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

const ResGuidEditor: FunctionComponent<PropTypes> = observer(({ onConfirm, ResourceStore }) => {
    const { content } = ConfigurationStore;
    const { curCtrl } = ResourceStore;

    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(curCtrl?.guid ?? '');

    useEffect(() => {
        setValue(curCtrl?.guid ?? '');
    }, [open]);

    const handleChange = (event: EventType) => {
        setValue(event.target.value as string);
    };

    const handleGenerate = () => {
        setValue(uuidv4());
    };

    const handleSave = () => {
        if (guidPattern.test(value)) {
            onConfirm({ target: { name: 'guid', value } } as EventType);
            setOpen(false);
        } else {
            NotificationStore.showAlert(
                content.resource.alert.guidErrorMsg,
                content.resource.alert.errorTitle
            );
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <div style={{ alignSelf: 'center' }}>
                <Button onClick={() => setOpen(true)}>{content.resource.buttons.guid}</Button>
            </div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{content.resource.guidEditor}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{content.resource.alert.guidChange}</DialogContentText>
                    <TextField
                        size="small"
                        margin="dense"
                        fullWidth
                        name="guid"
                        value={value}
                        onChange={handleChange}
                        InputProps={{
                            endAdornment: (
                                <Tooltip title={content.resource.buttons.generate}>
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        edge="end"
                                        onClick={handleGenerate}
                                    >
                                        <AutorenewIcon />
                                    </IconButton>
                                </Tooltip>
                            )
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{content.resource.buttons.undo}</Button>
                    <Button onClick={handleSave}>{content.resource.buttons.ok}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
});

export default ResGuidEditor;
