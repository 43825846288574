import { observable, action, makeObservable } from 'mobx';
import { OptionsObject } from 'notistack';

type ConfirmCallbackType = (result: boolean) => unknown;

export type NotificationType = {
    message: string;
    options: OptionsObject;
};

export interface SnackbarType extends NotificationType {
    key: number;
}

class Notification {
    @observable notifications: Array<SnackbarType> = [];

    @observable alertOpen = false;
    @observable alertTitle? = '';
    @observable alertMessage = '';

    @observable confirmation = false;
    @observable confirmationTitle? = '';
    @observable confirmationMessage = '';
    @observable confirmationCallback: ConfirmCallbackType | null = null;

    @observable backdrop: Array<NodeJS.Timeout> = [];
    @observable backdropTimer: NodeJS.Timeout | null = null;

    @observable apiErrors: Array<any> = [];
    @observable showErrors = false;

    constructor() {
        makeObservable(this);
    }

    @action enqueueSnackbar = (note: NotificationType) => {
        this.notifications.push({
            ...note,
            key: new Date().getTime() + Math.random()
        });
    };

    @action removeSnackbar = (key: number) => {
        this.notifications = this.notifications.filter(notification => notification.key !== key);
    };

    @action showAlert = (message: string, title?: string) => {
        this.alertMessage = message;
        this.alertTitle = title;
        this.alertOpen = true;
    };

    @action closeAlert = () => {
        this.alertOpen = false;
    };

    @action showConfirmation = (message: string, callback: ConfirmCallbackType, title?: string) => {
        this.confirmationMessage = message;
        this.confirmationTitle = title;
        this.confirmationCallback = callback;
        this.confirmation = true;
    };

    @action closeConfirmation = () => {
        this.confirmation = false;
    };

    @action setBackdrop = (timeout: NodeJS.Timeout) => {
        this.backdrop = [...this.backdrop, ...[timeout]];
    };

    @action unsetBackdrop = (timeout?: NodeJS.Timeout) => {
        this.backdrop = this.backdrop.filter(id => id !== timeout);
    };

    @action setApiErrors = (error: any) => {
        if (this.apiErrors.length >= 20) {
            this.apiErrors.shift();
        }
        this.apiErrors.push(error);
    };

    @action showApiErrors = () => {
        this.showErrors = true;
    };

    @action hideApiErrors = () => {
        this.showErrors = false;
    };
}

const NotificationStore = new Notification();
export default NotificationStore;

export class BackdropInstance {
    timeoutEnd = 0;
    backdropTimeout: NodeJS.Timeout | null = null;

    constructor(timeout?: number) {
        this.updateBackdrop(timeout);
    }

    timeLeft() {
        if (this.timeoutEnd) {
            return this.timeoutEnd - new Date().getTime();
        }

        return 0;
    }

    updateBackdrop(timeout = 30000) {
        if (this.timeLeft() < timeout) {
            this.clearBackdrop();
            this.backdropTimeout = setTimeout(() => {
                this.clearBackdrop();
            }, timeout);
            this.timeoutEnd = new Date().getTime() + timeout;

            NotificationStore.setBackdrop(this.backdropTimeout);
        }
    }

    clearBackdrop() {
        if (this.backdropTimeout) {
            NotificationStore.unsetBackdrop(this.backdropTimeout);
            clearTimeout(this.backdropTimeout);
            this.timeoutEnd = 0;
        }
    }
}
