import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { Grid, ToggleButton } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import useCtrlData from '../../../hooks/ctrlData';

import { FilterElementValue, FilterValue, unarConditions } from '../../../dataObj/CustomFilter';

import { DrawResourceType } from './interfaces';
import { FilterControlType, MedialogDataType, DTDatatypes } from '../../interfaces';

import AFList from './AFList';
import FilterItem from './FilterItem';

type PropTypes = {
    drawResource: DrawResourceType;
    fieldName: string;
    propName: string;
};

function FilterControlItem({ drawResource, propName, fieldName }: PropTypes) {
    const descr = drawResource.descr as FilterControlType;
    const { datasetName } = descr;
    const { dataset, dataVal } = useCtrlData(drawResource.propContainer, datasetName, fieldName);
    const { filter } = dataset;

    const { fields } = dataset.descr;
    const field = (fields || []).find(f => f.name === fieldName);
    const dataType = field?.dataType ?? MedialogDataType.unknown;

    const fValue = filter.getValue(descr.filterId) as FilterValue;
    const fItemValue = filter.getProperty(descr.filterId, propName) as FilterElementValue;

    const accessUseAf =
        !unarConditions.includes(fValue.condition) &&
        (DTDatatypes.includes(dataType) || !!descr.afName);

    const initUseAf = accessUseAf && 'afName' in fItemValue && !!fItemValue.afName;
    const [useAf, setUseAf] = useState(initUseAf);
    const initAFValue = initUseAf && 'afName' in fItemValue ? fItemValue.afName : '';
    const [afValue, setAfValue] = useState(initAFValue);

    useEffect(
        () =>
            filter.setProperty(
                descr.filterId,
                propName,
                useAf ? { afName: afValue } : { value: dataVal }
            ),
        [filter, propName, descr.filterId, useAf, dataVal, afValue]
    );

    return dataset ? (
        <>
            <Grid item xs={1} style={{ padding: 8 }}>
                {accessUseAf ? (
                    <ToggleButton
                        size="small"
                        selected={useAf}
                        onChange={() => setUseAf(!useAf)}
                        value="check"
                    >
                        <AttachFileIcon />
                    </ToggleButton>
                ) : null}
            </Grid>
            <Grid item xs style={{ padding: useAf ? 8 : 0 }}>
                {useAf ? (
                    <AFList
                        afValue={afValue}
                        label={descr.label}
                        afName={descr.afName}
                        dataType={dataType}
                        onChange={value => setAfValue(value)}
                    />
                ) : (
                    <FilterItem
                        drawResource={drawResource}
                        dataType={dataType}
                        fValue={fValue}
                        fieldName={fieldName}
                    />
                )}
            </Grid>
        </>
    ) : null;
}

const OFilterControlItem: React.FC<PropTypes> = observer(FilterControlItem);
export default OFilterControlItem;
