import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';

import { Grid } from '@mui/material';

import CustomDataset from 'dataObj/customDataset';

import ConfigurationStore from 'store/configurationStore';
import NotificationStore from 'store/notificationStore';
import EventsStore from 'store/eventsStore';
import ActionStore from 'store/actionStore';
import { onNewRecord } from 'store/eventsStore/events';

import CustomForm from 'forms/CustomForm';
import ModalForm from 'hoc/ModalForm';

import { jsonFetch } from 'utils';

import useBackdrop from 'hooks/backdrop';
import useMaxWidth from 'hooks/maxWidth';

import { fillCDOStruct } from 'dataObj/data-utils';
import { getControlClass } from 'forms/form-utils';

interface EditorPropsType {
    dataset: CustomDataset;
}

type ResultType = {
    guid: string;
    editorGuid: string;
    __ID: string;
    datasets: any;
    semdType?: number;
    warn?: never[];
    err?: never[];
};

const Creator: FunctionComponent<EditorPropsType> = observer(({ dataset }) => {
    const [editorName, setEditorName] = useState('Создание записи записи');
    const [loading, setLoading] = useState(false);

    const { content } = ConfigurationStore;
    const datasetActions = ActionStore.getDatasetActions(dataset.guid);

    const { setEditor, creator, setCreator, editAction, setEditAction } = datasetActions;

    const formConfig = dataset?.dataStock?.ownerPropContainer?.find(
        creator?.guid,
        'wuiForm'
    )?.descr;

    const formActions = ActionStore.getFormActions(creator?.guid);

    const handleClose = async (state: boolean, resultArr?: any[]) => {
        if (state) {
            if (formActions && (await formActions.checkLocalData())) {
                try {
                    setLoading(true);

                    const mainResult = resultArr?.[0];
                    const { editorGuid, ...payload } = mainResult;

                    const createResult: ResultType = await jsonFetch(
                        'dataobject/create',
                        'POST',
                        payload
                    );

                    // Если нет ошибок при создании
                    if (!createResult?.err || createResult.err?.length === 0) {
                        EventsStore.getEvents(dataset.descr.guid)
                            ?.triggerEvent(onNewRecord)
                            .catch(err => console.error(err.message));

                        const datasets = payload.guid
                            ? await fillCDOStruct(payload.guid, createResult.datasets, true)
                            : undefined;

                        if (createResult.editorGuid || editorGuid) {
                            setEditAction('edit');
                            setEditor(
                                true,
                                createResult.editorGuid || editorGuid,
                                {
                                    __ID: createResult.__ID,
                                    DATA_STAT_FORMS_ID: createResult.__ID
                                },
                                datasets
                            );
                        } else NotificationStore.showAlert(content.controls.data.noEditor);

                        setLoading(false);
                        setCreator(false);
                    }
                } catch (err: any) {
                    NotificationStore.showAlert(err.message);
                } finally {
                    setLoading(false);
                }
            }
        } else {
            setCreator(false);
        }
    };

    useEffect(() => {
        if (formActions?.getName) {
            setEditorName(formActions?.getName());
        }
    }, [formActions]);

    useBackdrop(loading);

    const maxWidth = useMaxWidth(formActions);

    const minHeight = useMemo(() => {
        if (formActions) {
            const height = getControlClass(formActions.getFormDescr().classes, 'minHeight')?.split(
                '-'
            )?.[1] as string;

            return height ? `calc(${height}vh - 148px)` : undefined;
        }

        return;
    }, [formActions]);

    return creator?.guid ? (
        <ModalForm
            formGuid={creator.guid}
            maxWidth={maxWidth as false | 'xl' | 'md' | 'sm' | 'xs' | 'lg'}
            handleClose={handleClose}
            editorName={editorName}
            sx={{
                '& .MuiPaper-root': {
                    overflow: 'hidden'
                },
                '& .MuiDialogContent-root ': {
                    position: 'relative',
                    minHeight: minHeight ?? (!formActions?.isReady ? 300 : null)
                }
            }}
        >
            <Grid
                item
                sx={
                    minHeight
                        ? {
                              position: 'absolute',
                              height: '100%',
                              width: 'calc(100% - 48px)'
                          }
                        : {}
                }
            >
                <CustomForm
                    key={creator.guid}
                    formGuid={formConfig ? undefined : creator.guid}
                    formConfig={formConfig}
                    extParamVals={creator?.extParamVals}
                    initEditMode={editAction}
                    parentDataStock={dataset?.dataStock}
                    parentPropContainer={creator?.propContainer}
                />
            </Grid>
        </ModalForm>
    ) : null;
});

export default Creator;
