import { ElementType } from 'react';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { TreeItem, TreeItemProps, treeItemClasses } from '@mui/x-tree-view';
import Typography from '@mui/material/Typography';

import { SvgIconProps } from '@mui/material/SvgIcon';

type StyledTreeItemProps = TreeItemProps & {
    bgColor?: string;
    color?: string;
    labelIcon: ElementType<SvgIconProps>;
    labelInfo?: string;
    labelText: string;
};

const TreeItemRoot = styled(TreeItem)(({ theme }) => ({
    color: theme.palette.text.primary,
    [`& .${treeItemClasses.content}`]: {
        color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightRegular,
        width: 'auto',
        '&.Mui-expanded': {
            fontWeight: theme.typography.fontWeightMedium
        },
        '&:hover': {
            backgroundColor: theme.palette.action.hover
        },
        '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
            color: 'var(--tree-view-color)'
        },
        '&.Mui-selected': {
            p: { color: theme.palette.primary.main }
        },
        [`& .${treeItemClasses.label}`]: {
            fontWeight: 'inherit',
            color: theme.palette.text.primary
        }
    }
}));

const StyledTreeItem = (props: StyledTreeItemProps) => {
    const { bgColor, color, labelIcon: LabelIcon, labelInfo, labelText, ...other } = props;

    const style: { [key: string]: string | undefined } = {};

    if (color) style['--tree-view-color'] = color;
    if (bgColor) style['--tree-view-bg-color'] = bgColor;

    return (
        <TreeItemRoot
            label={
                <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
                    <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} fontSize="medium" />
                    <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                        {labelText}
                    </Typography>
                    <Typography variant="caption" color="inherit">
                        {labelInfo}
                    </Typography>
                </Box>
            }
            style={style}
            {...other}
        />
    );
};

export default StyledTreeItem;
