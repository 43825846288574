/* eslint-disable @typescript-eslint/restrict-template-expressions */

const exportStructDescriptor = (components: any[]) => {
    let result = '';

    components.forEach((component: any) => {
        let componentHtml = '';

        componentHtml += `<div style="margin-bottom: 30px;">`;
        componentHtml += `<h3>Компонент: ${component.component}</h3>`;
        componentHtml += `<h4>Тип: ${component.component}</h4>`;
        componentHtml += `<h4>Родитель: ${
            Array.isArray(component.parent) ? component.parent.join(', ') : component.parent
        }</h4>`;
        componentHtml += `<table style="width: 100%; border-collapse: collapse; border-spacing: 0; border-width: 1px; border-color: #000000; border-style: solid; height: auto;">`;
        componentHtml += `<thead style="background-color: #6157FF; color: #fff; height: 35px">`;
        componentHtml += `<tr><th style="border-right: 1px solid #000;">Поля</th><th colspan="2">Свойства</th></tr>`;
        componentHtml += `</thead>`;
        componentHtml += `<tbody>`;

        Object.entries(component.fields)
            .map(([field, props]) => ({ field, ...(props as any) }))
            .forEach(field => {
                componentHtml += `<tr>`;
                componentHtml += `<td style="padding: 4px; border-right: 1px solid #000; min-width: 125px;">${field.field}</td>`;
                componentHtml += `<td style="padding: 4px;">type: ${field.type}</td>`;
                componentHtml += `</tr>`;

                if (field.defaultFunc) {
                    componentHtml += `<tr><td style="border-right: 1px solid #000;"> </td><td style="padding: 4px;">defaultFunc: ${field.defaultFunc}</td></tr>`;
                }
                if (field.readOnly) {
                    componentHtml += `<tr><td style="border-right: 1px solid #000;"> </td><td style="padding: 4px;">readOnly: ${field.readOnly}</td></tr>`;
                }
                if (field.multiLine) {
                    componentHtml += `<tr><td style="border-right: 1px solid #000;"> </td><td style="padding: 4px;">multiline: ${field.multiLine}</td></tr>`;
                }
                if (field.hidden) {
                    componentHtml += `<tr><td style="border-right: 1px solid #000;"> </td><td style="padding: 4px;">hidden: ${field.hidden}</td></tr>`;
                }
                if (field.translate) {
                    componentHtml += `<tr><td style="border-right: 1px solid #000;"> </td><td style="padding: 4px;">translate: ${field.translate}</td></tr>`;
                }
                if (field.getList) {
                    componentHtml += `<tr><td style="border-right: 1px solid #000;"> </td><td style="padding: 4px;">getList: ${field.getList}</td></tr>`;
                }
                if (field.children && field.children.length > 0) {
                    componentHtml += `<tr><td style="border-right: 1px solid #000;"> </td><td style="padding: 4px;">children: ${field.children.join(
                        ', '
                    )}</td></tr>`;
                }
                if (field.list && field.list.length > 0) {
                    componentHtml += `<tr><td style="border-right: 1px solid #000;"> </td><td style="padding: 4px;">list: ${field.list
                        .filter(Boolean)
                        .join(', ')}</td></tr>`;
                }
                if (field.render) {
                    componentHtml += `<tr><td style="border-right: 1px solid #000;"> </td><td style="padding: 4px;">render: ${field.render}</td></tr>`;
                }
                if (field.comment) {
                    componentHtml += `<tr><td style="border-right: 1px solid #000;"> </td><td style="padding: 4px;">comment: ${field.comment}</td></tr>`;
                }

                componentHtml += `<tr style="border-bottom: 1px solid #000;"></tr>`;
            });

        componentHtml += `</tbody></table></div>`;

        result += componentHtml;
    });

    return `<!DOCTYPE html><html>
                <head>
                     <title>StructDescriptor</title>
                </head>
                <body>
                    ${result}
                </body>
            </html>`;
};

export default exportStructDescriptor;
