import React, { CSSProperties, forwardRef } from 'react';
import { observer } from 'mobx-react';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

type ObjectType = { [key: string]: any };

interface ComboBoxProps {
    id?: string;
    value: string | undefined;
    store: Array<ObjectType>;
    onChange: (event: SelectChangeEvent) => void;
    nameField: string;
    valueField: string;
    name?: string;
    label: string;
    variant?: 'filled' | 'standard' | 'outlined' | undefined;
    disabled?: boolean;
    filter?: Array<string>;
    style?: CSSProperties | undefined;
    size?: string;
    clearable?: boolean;
    required?: boolean;
}

const ComboBox = forwardRef<HTMLLIElement | null, ComboBoxProps>((props, ref) => {
    const {
        id,
        nameField,
        valueField,
        label,
        value,
        disabled,
        name,
        style,
        clearable,
        store,
        variant,
        filter,
        size,
        required,
        onChange
    } = props;

    const renderLabel = () =>
        label ? (
            <InputLabel id={`${id ?? 'CB'}-select-label`} error={required && !value && !disabled}>
                {label}
            </InputLabel>
        ) : null;

    const renderMenu = (menuStore: Array<ObjectType>) =>
        menuStore.map((it, idx) => (
            <MenuItem key={`CBMenuItem-${idx}`} value={it[valueField]}>
                {it[nameField]}
            </MenuItem>
        ));

    return (
        <>
            {!style || style?.visibility !== 'hidden' || style?.visibility === undefined
                ? renderLabel()
                : null}
            <Select
                ref={ref}
                labelId={`${id ?? 'CB'}-select-label`}
                id={id}
                value={value}
                disabled={disabled}
                label={label || undefined}
                onChange={onChange}
                name={name}
                style={style}
                error={required && !value}
                sx={theme => ({
                    '& .Mui-disabled': {
                        backgroundColor: theme.palette.mode === 'light' ? '#f1f1f1' : '#1A2027',
                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.6) !important'
                    }
                })}
            >
                {clearable && (
                    <MenuItem value="">
                        <em>{`<None>`}</em>
                    </MenuItem>
                )}
                {renderMenu(store)}
            </Select>
        </>
    );
});

export default observer(ComboBox);
