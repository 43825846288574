import { MutableRefObject, useMemo, useRef } from 'react';
import useCtrlData from './ctrlData';
import useCtrlProps from './ctrlProps';

import useRequiredField from './requiredField';

import PropContainer from '../dataObj/PropContainer';
import useOnScreen from './onScreen';
import { ComponentType } from '../forms/interfaces';

export default function useDBEdit(
    descr: { [key: string]: any },
    propContainer: PropContainer,
    dataRow?: any
) {
    const { guid, datasetName } = descr;

    const {
        dataset,
        fieldName,
        dataVal,
        dataType,
        fieldEnabled,
        fieldRequired,
        internationalField,
        fieldSize,
        fieldScale
    } = useCtrlData(propContainer, datasetName, descr.fieldName, dataRow);
    const { ctrlEnabled, ctrlVisible } = useCtrlProps(propContainer, guid as string);

    const enabled = useMemo(
        () => fieldEnabled && ctrlEnabled && !!dataset?.inEdit,
        [fieldEnabled, ctrlEnabled, dataset?.inEdit]
    );

    const editRef = useRef(undefined) as MutableRefObject<HTMLInputElement | undefined>;

    const onScreen = useOnScreen(editRef);

    const fieldDescr = dataset?.descr?.fields?.find((fld: ComponentType) => fld.name === fieldName);

    useRequiredField(enabled && fieldRequired && !dataVal && onScreen);

    return {
        dataset,
        fieldName,
        fieldDescr,
        dataVal,
        dataType,
        fieldRequired,
        internationalField,
        fieldSize,
        fieldScale,
        ctrlEnabled: enabled,
        ctrlVisible,
        editRef,
        onScreen
    };
}
