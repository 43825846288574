/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useState, useEffect, FunctionComponent, useMemo } from 'react';
import { observer } from 'mobx-react';

import { Box } from '@mui/material';

import { FormType, GridType, ColumnType, ToolButtonType } from 'forms/interfaces';

import TabPanel from 'forms/controls/PageControl/TabPanel';
import styles from 'forms/form.module.scss';
import StyledTab from 'forms/controls/PageControl/StyledTab';
import StyledTabs from 'forms/controls/PageControl/StyledTabs';

import ConfigurationStore from 'store/configurationStore';
import ResourceStore, { SettingType } from 'store/resourceStore';

import {
    ColumnSettingType,
    GridSettingsType,
    FieldInfoType,
    Configuration,
    ToolbarButtonType
} from '../index';

import { EditorProps } from '../../ResInterfaces';

import GridList from './GridList';
import GridConfiguration from './GridConfiguration';

import {
    fieldListColumns,
    tableColumns,
    sortGridColumns,
    AggGridColumns,
    toolbarColumns,
    toolbarButtons
} from '../GridSettingsConst';
import GridToolbar from './GridToolbar';

// Вынес для наглядности actions
export interface GridSettingActions {
    getName: () => string;
    getValue: () => any;
    delete: (type?: SettingType) => Promise<unknown>;
    load: (type?: SettingType) => Promise<unknown>;
    save: (type?: SettingType) => Promise<unknown>;
}

const GridSettings: FunctionComponent<EditorProps> = observer((props: EditorProps) => {
    const content = ConfigurationStore.content.resource.gridProps;

    const { resource, control, setActions } = props;

    const [value, setValue] = useState(props.value as string | object | null);

    const form = resource as FormType;
    const grid = control as unknown as GridType;
    const resGuid: string = form.isGenerated
        ? form.datasets?.filter(ds => ds.name === grid.datasetName)[0]?.request
        : form.guid;
    const ctrlGuid = form.isGenerated ? undefined : grid.guid;

    // Считываю параметры из значения
    const propValue: GridSettingsType = useMemo(() => {
        // Значение по умолчанию
        const defGridSettings = {
            columns: [],
            search: [],
            sort: [],
            group: { fields: [] },
            aggregation: [],
            toolbar: [],
            configuration: {}
        };

        const obj =
            typeof value === 'string' && value ? (JSON.parse(value) as object) : value || {};
        return { ...defGridSettings, ...(obj as object) } as GridSettingsType;
    }, [value]);

    // ActivePageIndex
    const [active, setActive] = useState(0);

    // Список колонки для редактора "столбцы"
    const [fieldList, setFieldList] = useState<FieldInfoType[]>([]);
    const [searchList, setSearchList] = useState<FieldInfoType[]>([]);
    const [groupList, setGroupList] = useState<FieldInfoType[]>([]);
    const [sortList, setSortList] = useState<FieldInfoType[]>([]);
    const [aggList, setAggList] = useState<FieldInfoType[]>([]);
    const [toolbarList, setToolbarList] = useState<ToolbarButtonType[]>([]);
    const [configuration, setConfiguration] = useState<Configuration>({});

    // Список действий которые может выполнять редактор
    const actions: GridSettingActions = useMemo(
        () => ({
            getName: () => grid.caption,
            getValue: () => {
                const res: GridSettingsType = {
                    columns: fieldList.map((field: FieldInfoType) => ({
                        fieldName: field.fieldName,
                        caption: field.caption,
                        visible: !!field.visible,
                        align: field.align || 'left'
                        // width?: field.width
                    })),
                    sort: sortList.map((field: FieldInfoType) => ({
                        fieldName: field.id,
                        direction: field.direction || 'asc'
                    })),
                    search: searchList.map((field: FieldInfoType) => field.id),
                    group: {
                        fields: groupList.map((field: FieldInfoType) => field.id)
                    },
                    aggregation: aggList.map((aggField: FieldInfoType) => ({
                        fieldName: aggField.fieldName,
                        func: aggField.func || 'sum'
                    })),
                    toolbar: toolbarList,
                    configuration
                };
                return JSON.stringify(res);
            },
            delete: async (type?: SettingType) =>
                ResourceStore.dropSettings(resGuid, ctrlGuid, type),
            load: async (type?: SettingType) => {
                const settings = await ResourceStore.loadSettings(resGuid, ctrlGuid, type);
                setValue(settings);
            },
            save: async (type: SettingType = SettingType.user) =>
                ResourceStore.saveSettings(resGuid, ctrlGuid, type, actions.getValue())
        }),
        [
            grid.caption,
            fieldList,
            sortList,
            searchList,
            groupList,
            aggList,
            toolbarList,
            configuration,
            resGuid,
            ctrlGuid
        ]
    );

    useEffect(() => {
        if (setActions) {
            setActions(actions);
        }
    }, [setActions, actions]);

    // Список грида вывожу в DataGrid
    useEffect(() => {
        if (!form || !grid) {
            return;
        }

        // Часть настроек нужно будет брать из ds
        const ds = (form.datasets || []).find(dataset => dataset.name === grid.datasetName);

        // Обьект для быстрого доступа
        const columsObj: { [key: string]: ColumnType } = {};

        // Удаляем колонки, относящиеся к цвету строки и ячеек
        const cleanedGridColumns = (grid.columns || []).filter(
            (column: ColumnType) =>
                // !column.fieldName.startsWith('CLR_CELL_') &&
                !column.fieldName.startsWith('CLR_CELLFOR_') &&
                !column.fieldName.startsWith('CLR_ROW_')
        );
        const fields: FieldInfoType[] = cleanedGridColumns.map((column: ColumnType) => {
            const pvColumn = propValue.columns.find(
                (pvc: ColumnSettingType) => pvc.fieldName === column.fieldName
            );
            columsObj[column.fieldName] = { ...column };
            const dsField = ds?.fields.find(field => field.name === column.fieldName);
            return {
                id: column.fieldName,
                fieldName: column.fieldName,
                caption: pvColumn?.caption || column.caption || column.fieldName,
                visible: pvColumn ? pvColumn.visible : !dsField?.hidden,
                align: pvColumn?.align ?? 'left'
                // width: pvColumn?.width || 150
            };
        });

        setFieldList(fields);

        // Список полей для сортировки
        const sorts = (propValue.sort || []).map(field => {
            const column = columsObj[field.fieldName] || {};
            return {
                ...field,
                id: field.fieldName,
                caption: column.caption
            };
        });
        setSortList(sorts);

        // Список полей для группировки
        const groups = (propValue.group?.fields || []).map(name => {
            const column = columsObj[name] || {};
            return {
                fieldName: name,
                id: name,
                caption: column.caption
            };
        });
        setGroupList(groups);

        // Список полей для поиска
        const searchs = (propValue.search || []).map(name => {
            const column = columsObj[name] || {};
            return {
                fieldName: name,
                id: name,
                caption: column.caption
            };
        });
        setSearchList(searchs);

        // Список полей агрегации
        const aggs = (propValue.aggregation || []).map(aggField => {
            const column = columsObj[aggField.fieldName] || {};
            return {
                ...aggField,
                id: aggField.fieldName,
                caption: column.caption
            };
        });
        setAggList(aggs);

        // Тулбар
        const buttons = grid.buttons?.length
            ? grid.buttons
            : (toolbarButtons(ConfigurationStore.content) as ToolButtonType[]);
        const toolbar = buttons.map((button, index) => ({
            id: `${index}`,
            name: button.name,
            caption: button.caption,
            visible:
                propValue.toolbar?.find(confButton => button.name === confButton.name)?.visible ??
                true
        }));
        setToolbarList(toolbar);

        // Настройки таблицы
        setConfiguration(propValue.configuration ?? {});
    }, [form, grid, propValue]);

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <StyledTabs
                    onChange={(event: any, newValue: number) => setActive(newValue)}
                    value={active}
                >
                    <StyledTab key="1" label={content.tabs.cols} />
                    <StyledTab key="2" label={content.tabs.fastFilter} />
                    <StyledTab key="3" label={content.tabs.sort} />
                    <StyledTab key="4" label={content.tabs.group} />
                    <StyledTab key="5" label={content.tabs.aggregation} />
                    <StyledTab key="6" label={content.tabs.toolbar} />
                    <StyledTab key="7" label={content.tabs.settings} />
                </StyledTabs>
            </Box>
            <div className={styles.tabPanel} style={{ height: 500 }}>
                <TabPanel key="1" index={0} value={active}>
                    <GridList
                        columns={tableColumns(content)}
                        actions={['up', 'down']}
                        rows={fieldList}
                        setRows={setFieldList}
                    />
                </TabPanel>
                <TabPanel key="2" index={1} value={active}>
                    <GridList
                        actions={['up', 'down', 'add', 'remove']}
                        columns={fieldListColumns(content)}
                        rows={searchList}
                        setRows={setSearchList}
                        fieldList={fieldList}
                    />
                </TabPanel>
                <TabPanel key="3" index={2} value={active}>
                    <GridList
                        actions={['up', 'down', 'add', 'remove']}
                        columns={sortGridColumns(content)}
                        rows={sortList}
                        setRows={setSortList}
                        fieldList={fieldList}
                        defaultItem={{ direction: 'asc' }}
                    />
                </TabPanel>
                <TabPanel key="4" index={3} value={active}>
                    <GridList
                        actions={['up', 'down', 'add', 'remove']}
                        columns={fieldListColumns(content)}
                        rows={groupList}
                        setRows={setGroupList}
                        fieldList={fieldList}
                    />
                </TabPanel>
                <TabPanel key="5" index={4} value={active}>
                    <GridList
                        actions={['up', 'down', 'add', 'remove']}
                        columns={AggGridColumns(content)}
                        rows={aggList}
                        setRows={setAggList}
                        fieldList={fieldList}
                        defaultItem={{ func: 'sum' }}
                    />
                </TabPanel>
                <TabPanel key="6" index={5} value={active}>
                    <GridToolbar
                        columns={toolbarColumns(content)}
                        rows={toolbarList}
                        setRows={setToolbarList}
                        defaultItem={{
                            variant: 'outlined'
                        }}
                    />
                </TabPanel>
                <TabPanel key="7" index={6} value={active}>
                    <GridConfiguration
                        configuration={configuration}
                        setConfiguration={setConfiguration}
                    />
                </TabPanel>
            </div>
        </>
    );
});

export default GridSettings;
