const langRus = {
    descriptor: {
        get: 'Получение дескриптора...',
        validator: 'Получение валидатора...'
    },
    data: {
        lazyLoading: 'Загрузка...',
        loading: 'Загрузка данных...',
        error: 'Ошибка загрузки',
        noDataset: 'Не выбран набор данных',
        noData: 'Нет данных',
        choiceRecord: 'Выберите запись для редактирования',
        noEditor: 'Нет подходящей формы-редактора',
        saveMasterFirst: 'Для добавления записи необходимо сохранить данные родительского датасета'
    },
    buttons: {
        add: 'Создать',
        view: 'Просмотреть',
        edit: 'Редактировать',
        change: 'Изменить',
        delete: 'Удалить',
        ok: 'Ok',
        cancel: 'Отмена',
        refresh: 'Обновить',
        options: 'Настройки',
        clear: 'Очистить',
        sync: 'Синхронизировать',
        save: 'Сохранить',
        editMode: 'Режим редактирования',
        viewMode: 'Режим просмотра'
    },
    glossary: {
        public: 'Общий',
        private: 'Личный'
    },
    grid: {
        filter: 'Фильтр',
        editorName: 'Подтверждение',
        delConfirmText: 'Удалить запись?',
        yes: 'Да'
    },
    panel: {
        fullscreenOpen: 'Развернуть',
        fullscreenClose: 'Свернуть'
    },
    pdf: {
        error: 'Не удаётся загрузить PDF-файл',
        noData: 'Не указан PDF-файл'
    },
    lookup: {
        incorrectLink: 'Некорректная ссылка на ресурс'
    },
    canvasDraw: {
        setImage: 'Загрузите изображение'
    },
    taskTree: {
        rootNode: 'Задачи и работы',
        complexTask: 'Комплексная задача',
        emptyTree: 'Нет данных для построения'
    },
    internationalDialog: {
        translation: 'Перевод',
        rus: 'Русский',
        fra: 'Французский',
        spa: 'Испанский',
        eng: 'Английский'
    },
    fileUploader: {
        select: 'Выберите файл',
        add: 'Добавить вложение',
        delete: 'Удалить вложение'
    },
    date: {
        incorrect: 'Некорректные данные'
    }
};

export default langRus;
