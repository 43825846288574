import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { observer } from 'mobx-react';

import {
    AppBar,
    Container,
    Typography,
    Toolbar,
    IconButton,
    Slide,
    Grow,
    Box
} from '@mui/material';

import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuIcon from '@mui/icons-material/Menu';
import useCtrlProps from '../../../../hooks/ctrlProps';

import { ControlPropsType, MenuType } from '../../../interfaces';

import NestedMenu from '../NestedMenu';
import NavBarMenuItem from './NavBarMenuItem';
import SVGIcon from '../../SVGIcon';
import MainMenuStore from '../../../../store/mainMenuStore';

interface NavBarProps extends ControlPropsType {
    descr: MenuType;
    position?: 'absolute' | 'fixed' | 'relative' | 'static' | 'sticky';
    sx?: any;
}

const NavBar: FunctionComponent<NavBarProps> = observer(
    ({ descr, propContainer, position, sx }) => {
        const { guid, caption, items } = descr;
        const { ctrlVisible } = useCtrlProps(propContainer, guid);
        const { initMenuSelections, setMenuVariant, setMenuPosition } = MainMenuStore;

        const history = useHistory();
        const location = useLocation();

        const [show, setShow] = useState(true);
        const [menu, setMenu] = useState<HTMLElement | null>(null);

        const onClickHandler = () => {
            setShow(!show);
        };

        const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
            setMenu(event.currentTarget);
        };

        const handleClose = () => {
            setMenu(null);
        };

        useEffect(() => {
            // Инициализируем только при получении пути
            if (descr.variant === 'top' && history.action === 'POP') {
                initMenuSelections();
                setMenuVariant(descr.variant);
                setMenuPosition();
            }
        }, [history, location]);

        return (
            <>
                <Slide direction="down" in={show} mountOnEnter unmountOnExit>
                    <AppBar
                        position={position ?? 'fixed'}
                        sx={{
                            ...{ mb: 2 },
                            ...sx,
                            ...{ visibility: ctrlVisible ? 'inherit' : ('hidden' as any) }
                        }}
                    >
                        <Toolbar
                            style={{
                                position: 'absolute',
                                margin: 'auto',
                                height: '100%'
                            }}
                        >
                            {position === 'fixed' && (
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    onClick={onClickHandler}
                                    size="large"
                                    sx={{ display: { xs: 'none', lg: 'flex' } }}
                                >
                                    <ArrowUpIcon />
                                </IconButton>
                            )}
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                size="large"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                sx={{ display: { xs: 'flex', lg: 'none' } }}
                            >
                                <MenuIcon />
                            </IconButton>
                        </Toolbar>
                        <Container fixed maxWidth={false} style={{ display: 'flex' }}>
                            <Toolbar style={{ margin: 'auto' }}>
                                <Typography
                                    variant="h4"
                                    style={{ marginRight: 24 }}
                                    sx={{ display: { xs: 'none', xl: 'flex' } }}
                                >
                                    {descr.icon ? (
                                        <SVGIcon
                                            descr={descr}
                                            propContainer={propContainer}
                                            sx={{ m: 'auto', mr: 2 }}
                                            fontSize="inherit"
                                        />
                                    ) : null}
                                    {caption}
                                </Typography>
                                <NestedMenu
                                    key={`nav-bar-menu-${guid}`}
                                    anchorEl={menu}
                                    items={items}
                                    propContainer={propContainer}
                                    handleClose={handleClose}
                                />
                                <Box sx={{ flexGrow: 1, display: { lg: 'flex', xs: 'none' } }}>
                                    {items.map(item => (
                                        <NavBarMenuItem
                                            key={`nav-bar-menu-item-${item.guid}`}
                                            descr={item}
                                            propContainer={propContainer}
                                        />
                                    ))}
                                </Box>
                                <Typography
                                    variant="h6"
                                    noWrap
                                    component="div"
                                    sx={{ flexGrow: 1, display: { xs: 'flex', lg: 'none' } }}
                                >
                                    {caption}
                                </Typography>
                            </Toolbar>
                        </Container>
                    </AppBar>
                </Slide>
                <Grow in={!show && position === 'fixed'} timeout={{ enter: 1000, exit: 0 }}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={onClickHandler}
                        style={{
                            position: 'fixed',
                            margin: 10,
                            top: 0,
                            left: 0
                        }}
                        size="large"
                    >
                        <ArrowDownIcon />
                    </IconButton>
                </Grow>
            </>
        );
    }
);

export default NavBar;
