import { FunctionComponent, MouseEvent } from 'react';
import { observer } from 'mobx-react';

import { Box, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { ControlPropsType, DBEditType, DBLookupType, DBMultipleEditType } from 'forms/interfaces';
import useDBEdit from 'hooks/dbEdit';

import DBEdit from '../DBEdit';
import DBLookup from '../DBLookup';

interface PropsType extends ControlPropsType {
    descr: DBMultipleEditType;
    dataRow: any;
}

const DBMultipleEdit: FunctionComponent<PropsType> = observer(
    ({ descr, propContainer, dataRow }) => {
        const { dataset, fieldName, dataVal } = useDBEdit(descr, propContainer, dataRow);

        const value = dataset.cloneRec?.[fieldName]
            ? dataset.cloneRec[fieldName]
            : dataVal ?? { val: '' };

        const handleAdd = (event: MouseEvent) => {
            event.stopPropagation();
            dataset.addRecordToMultipleField(fieldName);
        };

        const renderMultipleEdit = (
            index: number,
            description: DBMultipleEditType,
            editDataRow: { [key: string]: unknown }
        ) => (
            <Box sx={{ display: 'flex', alignItems: 'center', pl: 2, pr: 0 }}>
                <Box color="inherit" sx={{ mr: 1 }} fontSize="medium">
                    <IconButton size="small">
                        <AddIcon fontSize="inherit" onClick={handleAdd} />
                    </IconButton>
                </Box>
                {description?.ctrlType === 'dbLookup' ? (
                    <DBLookup
                        descr={description as unknown as DBLookupType}
                        dataRow={editDataRow}
                        propContainer={propContainer}
                        index={index}
                    />
                ) : (
                    <DBEdit
                        descr={description as DBEditType}
                        dataRow={editDataRow}
                        propContainer={propContainer}
                        index={index}
                    />
                )}
            </Box>
        );

        if (value?.length) {
            return (
                <>
                    {value
                        .map((record: { key: string; val: string }) => ({
                            [fieldName]: record?.val
                        }))
                        .map((customDataRow: { [key: string]: unknown }, index: number) =>
                            renderMultipleEdit(index, descr, customDataRow)
                        )}
                </>
            );
        }

        return renderMultipleEdit(0, descr, { val: '' });
    }
);

export default DBMultipleEdit;
