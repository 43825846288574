import React, { FunctionComponent } from 'react';

import TableCell, { TableCellProps } from '@mui/material/TableCell';

import Splitter from './Splitter';

interface SelectionCellProps extends TableCellProps {
    headerHeight: number;
}

const SelectionCell: FunctionComponent<SelectionCellProps> = ({ headerHeight, ...props }) => (
    <TableCell width={18} {...props}>
        <Splitter headerHeight={headerHeight} index={0} />
    </TableCell>
);

export default SelectionCell;
