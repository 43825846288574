import PropContainer from '../../../../dataObj/PropContainer';

import { MenuItemType } from '../../../interfaces';
import { menuItem } from './index';

const menuItemsWrapper = (
    menuItems: any[],
    open: boolean,
    propContainer: PropContainer,
    closeMenu: any
) => menuItems.map((item: MenuItemType) => menuItem(item, open, propContainer, closeMenu));

export default menuItemsWrapper;
