import { makeAutoObservable, observable, action } from 'mobx';

import { EditModeType, FormActionsType, SimpleObject } from 'forms/interfaces';
import PropContainer from 'dataObj/PropContainer';
import DataStock from 'dataObj/DataStock';

export default class ScriptStore {
    // form manipulation
    @observable form: any = null;
    @observable formDescr: any = null;
    @observable cb: any = null;

    @observable parentPropContainer?: PropContainer;
    @observable parentDataStock?: DataStock;

    @observable formName = '';
    @observable values: any[] = [];

    @observable editMode: EditModeType = null;
    @observable formActions: FormActionsType = {};
    @observable modalMessage: SimpleObject = {};

    constructor() {
        makeAutoObservable(this);
    }

    @action setForm = (
        form: any,
        parentDataStock?: DataStock,
        parentPropContainers?: PropContainer
    ) => {
        this.form = form;
        this.parentDataStock = parentDataStock;
        this.parentPropContainer = parentPropContainers;
    };

    @action setFormDescr = (formDescr: any) => {
        this.formDescr = formDescr;
    };

    @action setCallback = (cb: any) => {
        this.cb = cb;
    };

    @action setFormName = (formName: any) => {
        this.formName = formName;
    };

    @action setValues = (values: any) => {
        this.values = values;
    };

    @action setEditMode = (editMode: EditModeType) => {
        this.editMode = editMode;
    };

    @action setFormActions = (formActions: FormActionsType) => {
        this.formActions = formActions;
    };

    @action setModalMessage = (modalMessage: SimpleObject) => {
        this.modalMessage = modalMessage;
    };
}
