import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';

import { styled } from '@mui/material/styles';
import MuiGrid from '@mui/material/Grid';
import { SplitPane } from '@calypso/react-multi-split-pane';

import { ResourceClass } from 'store/resourceStore';
import ConfigurationStore from 'store/configurationStore';

import Loading from 'components/utils/Loading/Loading';

import ResPreviewControl from './ResPreviewControl';
import ResTree from './ResTree';
import ResProps from './ResProps';

const Grid = styled(MuiGrid)(({ theme }) => ({
    height: `calc(100% - ${theme.spacing(2)})`,
    width: '100%',
    padding: theme.spacing(2),
    ...theme.typography.body2,
    '& [role="separator"]': {
        margin: theme.spacing(0, 2)
    }
}));

interface ResEditorType {
    ResourceStore: ResourceClass;
}

const ResEditor: FunctionComponent<ResEditorType> = observer(({ ResourceStore }) => {
    const { curResLoading } = ResourceStore;
    const { resource } = ConfigurationStore.content;

    return (
        <>
            {curResLoading ? (
                <Loading message={resource.resProps.refresh} />
            ) : (
                <Grid container style={{ position: 'relative' }} className="clearfix">
                    <SplitPane
                        split="vertical"
                        defaultSizes={[ResourceStore.treeWidth, ResourceStore.propsWidth]}
                        minSize={[270, 470]}
                        onChange={ResourceStore.onSizeChange}
                    >
                        <Grid item>
                            <ResTree ResourceStore={ResourceStore} />
                        </Grid>
                        <Grid item>
                            <ResProps ResourceStore={ResourceStore} />
                        </Grid>
                    </SplitPane>
                </Grid>
            )}
            <ResPreviewControl ResourceStore={ResourceStore} />
        </>
    );
});

export default ResEditor;
