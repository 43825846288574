import React, { FunctionComponent, useMemo, useState } from 'react';
import { observer } from 'mobx-react';

import { v4 as uuidv4 } from 'uuid';

import { IconButton, Tooltip } from '@mui/material';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';

import ConfigurationStore from 'store/configurationStore';
import NotificationStore from 'store/notificationStore';
import { ResourceClass } from 'store/resourceStore';

import { DatasetFieldType, DBControlType } from 'forms/interfaces';
import { checkFieldInControl } from 'forms/form-utils';

import ImportFields from './ImportFields';

interface PropsType {
    ctrl: any;
    ResourceStore: ResourceClass;
}

const ResCtrlImportFields: FunctionComponent<PropsType> = observer(({ ctrl, ResourceStore }) => {
    const { curRes, updateCurRes, getDatasetList } = ResourceStore;
    const { content } = ConfigurationStore;

    const [open, setOpen] = useState(false);

    const datasetList = useMemo(() => getDatasetList(), [ctrl]);

    const getEditType = (dataType: string) => {
        switch (dataType) {
            case 'KRN_MEMO':
                return 'dbMemoEdit';

            case 'KRN_NUMERIC':
                return 'dbNumEdit';

            case 'KRN_RTF':
                return 'dbRtfViewer';

            default:
                return 'dbEdit';
        }
    };

    const uniqueNameGen = (name: string, names: string[]): string => {
        const regex = new RegExp(name);
        const test = names.filter(n => regex.test(n));

        if (test.length) return uniqueNameGen(`${name}_${test.length}`, names);

        return name;
    };

    const setNewEdits = (
        datasetName: string,
        ctrlElList: Array<DBControlType>,
        newElList: Array<DatasetFieldType>
    ) =>
        (newElList || []).map(newEl => {
            const props = {
                name: uniqueNameGen(
                    newEl.name,
                    ctrlElList.map(ctrlEl => ctrlEl.name)
                ),
                datasetName,
                fieldName: newEl.name,
                label: newEl.caption,
                styles: ''
            };

            return {
                ...newEl,
                guid: uuidv4(),
                ...props,
                type: getEditType(newEl.dataType)
            };
        });

    const importDBEdit = (selectedDataset: string[], selectedFields: string[]) => {
        let count = 0;

        datasetList
            .filter(ds => selectedDataset.indexOf(ds.name) !== -1)
            .map(dataset => {
                const { name, fields } = dataset;
                const newControls = setNewEdits(
                    name,
                    ctrl.controls,
                    fields.filter(fld => selectedFields.includes(fld.guid))
                );

                ctrl.controls = [...ctrl.controls, ...newControls];

                count += newControls.length;

                updateCurRes(curRes);
                return;
            });

        NotificationStore.enqueueSnackbar({
            message: content.resource.snackbars.import.fields(count),
            options: { variant: 'info' }
        });
    };

    const checkFields = (dsName: string, field: DatasetFieldType) =>
        checkFieldInControl(ctrl, dsName, field);

    const handleConfirm = (selectedDataset: string[], selectedFields: string[]) => {
        importDBEdit(selectedDataset, selectedFields);
    };

    return (
        <>
            <Tooltip title={content.resource.loadDSEdit}>
                <IconButton size="small" onClick={() => setOpen(true)}>
                    <SystemUpdateAltIcon />
                </IconButton>
            </Tooltip>
            <ImportFields
                open={open}
                setOpen={setOpen}
                ResourceStore={ResourceStore}
                checkFields={checkFields}
                onConfirm={handleConfirm}
            />
        </>
    );
});

export default ResCtrlImportFields;
