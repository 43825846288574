const langEng = {
    loading: 'Loading',
    refreshList: 'Refresh list',
    importEditor: 'Import editor',
    loadReqFields: 'Load request fields and parameters',
    loadDSColumns: 'Load columns from dataset',
    loadDSEdit: 'Load fields from dataset',
    clearNestedControls: 'Clear nested controls',
    resSelect: 'Resource selection',
    needSelect: 'Select a resource',
    guidEditor: 'Editing the GUID',
    alert: {
        idRequest: 'Request id not specified',
        missingDS: 'Source dataset is missing',
        specDS: 'Source dataset is not specified',
        statReNotExist: (name: string): string =>
            `Import error! Possibly stat-request ${name} does not exist!` as any,
        nameNotSpec: 'Stat-request name not specified!',
        cantImport: 'Resource cannot be imported',
        needSave: 'Changes must be saved or cancelled!',
        guidChange:
            'Attention! Changing the guid of an element can lead to irreparable consequences!',
        errorTitle: 'Error!',
        guidErrorMsg: 'Invalid guid format!'
    },
    dialog: {
        title: 'Field insertion wizard',
        chooseDS: 'Choose DataSet',
        chooseFields: 'Choose fields'
    },
    clearList: {
        title: 'Attention!',
        content: 'Do you really want to delete all nested controls?'
    },
    resList: {
        groupStore: {
            all: 'All objects',
            wuiForm: 'Forms',
            subForm: 'Child forms',
            wuiQuery: 'SQL-requests',
            wuiExtQuery: 'Ext-requests',
            wuiStatQuery: 'Stat-requests',
            cdo: 'CDO',
            table: 'Tables',
            settings: 'Settings',
            wuiIconSet: 'Iconset',
            wuiApplication: 'Application settings',
            wuiGroup: 'Resource group',
            wuiPackage: 'Script package'
        },
        show: 'Show',
        type: 'Type',
        searchLabel: 'Name / GUID',
        searchTag: '#Tag'
    },
    resProps: {
        property: 'Property',
        value: 'Value',
        saving: 'Saving',
        refresh: 'Refresh'
    },
    buttons: {
        ok: 'Ok',
        cancel: 'Cancel',
        add: 'Add',
        next: 'Next',
        back: 'Back',
        refresh: 'Refresh',
        close: 'Close',
        create: 'Create',
        save: 'Save',
        undo: 'Cancel',
        copy: 'Copy',
        cut: 'Cut',
        paste: 'Paste',
        delete: 'Delete',
        clear: 'Clear',
        pasteFromDataset: 'Insert fields from dataset',
        export: 'Export',
        import: 'Import',
        load: 'Load',
        open: 'Open',
        changeLocale: 'Language',
        editor: 'Editor',
        guid: 'Change GUID',
        generate: 'Generate a new guid',
        resetCache: 'Reset cache',
        getDescriptor: 'Descriptor Export',
        homePage: 'Go to homepage',
        search: 'Search',
        upward: 'Upward',
        downward: 'Downward',
        updateResource: 'Update resource',
        selectAll: 'Select all',
        clearAll: 'Clear all'
    },
    scriptEditor: {
        title: 'Code editor',
        change: 'Change',
        apply: 'Apply',
        cancel: 'Cancel',
        options: 'Calculation options',
        asDefault: 'default value',
        asValue: 'calculated value'
    },
    iconEditor: {
        title: 'Icon editor',
        preview: 'Preview',
        fontSize: 'Font size'
    },
    gridProps: {
        caption: 'Grid properties editor',
        selectFields: 'Field selection',
        tabs: {
            cols: 'Columns',
            fastFilter: 'Fast filters',
            sort: 'Sorting',
            group: 'Grouping',
            aggregation: 'Aggregation',
            toolbar: 'Toolbar',
            settings: 'Settings'
        },
        columns: {
            caption: { headerName: 'Name', description: 'Field name' },
            resource: { headerName: 'Resource', description: 'Resource' },
            direction: { headerName: 'Sorting', description: 'Field sorting settings' },
            visible: { headerName: 'Visibility', description: 'Field visibility' },
            align: { headerName: 'Alignment', description: 'Horizontal alignment' },
            func: {
                headerName: 'Aggregation function',
                description: 'Aggregation function settings'
            },
            buttons: {
                caption: { headerName: 'Caption', description: 'Field caption' },
                type: {
                    headerName: 'Button type',
                    description: 'Selecting the basic action for the button'
                },
                variant: { headerName: 'Button variant', description: 'Render variant' },
                color: { headerName: 'IconColor', description: 'Icon button color' },
                iconSet: {
                    headerName: 'Icon set',
                    description: 'A set of icons containing an icon for a button'
                },
                icon: {
                    headerName: 'Icon',
                    description: 'Icon name'
                },
                script: {
                    headerName: 'Script',
                    description: 'The script executed when the button is clicked'
                }
            }
        },
        alignText: {
            left: 'left',
            right: 'right',
            center: 'centered',
            justify: 'width'
        },
        sortModeText: {
            asc: 'ascending',
            desc: 'descending',
            deny: 'deny change',
            none: ''
        },
        aggMode: {
            sum: 'Sum',
            avg: 'Average',
            min: 'Minimum',
            max: 'Maximum',
            count: 'Count'
        },
        configuration: {
            hideRefresh: 'Disable "Refresh" button',
            hideToolbar: 'Hide toolbar'
        }
    },
    cdoProps: {
        caption: 'CDO properties editor',
        selectDataset: 'Dataset',
        selectFields: 'Field selection',
        tabs: {
            fields: 'Fields',
            groups: 'Groups of field'
        },
        columns: {
            caption: { headerName: 'Field', description: 'Field name' },
            dataset: { headerName: 'Dataset', description: 'Dataset name' },
            group: { headerName: 'Group', description: 'Group name' },
            groupCaption: { headerName: 'Heading', description: 'Group display heading' },
            groupFields: { headerName: 'Group fields', description: 'List of the group fields' },
            width: { headerName: 'Width', description: 'Width of control' }
        }
    },
    snackbars: {
        operation: {
            success: 'Operation completed successfully'
        },
        save: {
            success: 'Resource successfully saved',
            error: 'Error saving resource'
        },
        import: {
            success: 'Resource successfully imported',
            error: 'Error importing resource',
            fields: (count: number): string =>
                count ? `${count} fields added` : `Fields not found!`,
            params: (count: number): string =>
                count > 0 ? `${count} params added` : `${count * -1} params removed`,
            columns: (count: number): string =>
                count ? `${count} columns added` : `Columns not found`,
            filters: (count: number) =>
                count > 0 ? ` ${count} filters added` : `${count * -1} filters removed`
        },
        details: 'Details'
    },
    translation: {
        fieldName: 'Field caption',
        baseValue: 'Base translation',
        exists: 'A field with this name already exists'
    }
};

export default langEng;
