import React, { FunctionComponent, useState } from 'react';
import { observer } from 'mobx-react';

import { Box, ListItemIcon, ListItemText } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import NestedMenu from '../NestedMenu';
import MenuItem from '../MenuItem';
import SVGIcon from '../../SVGIcon';

import { ControlPropsType, MenuItemType } from '../../../interfaces';

interface DropDownMenuProps extends ControlPropsType {
    descr: MenuItemType;
}

const NavBarMenuItem: FunctionComponent<DropDownMenuProps> = observer(
    ({ descr, propContainer }) => {
        const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

        const handleClick = (event: React.MouseEvent<HTMLElement>) => {
            !!descr?.items?.length && setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        return (
            <>
                <Box style={{ alignSelf: 'center' }} onClick={handleClick}>
                    <MenuItem descr={descr} propContainer={propContainer}>
                        {descr.icon ? (
                            <ListItemIcon style={{ color: 'inherit' }}>
                                <SVGIcon
                                    descr={descr}
                                    propContainer={propContainer}
                                    fontSize="small"
                                />
                            </ListItemIcon>
                        ) : null}
                        <ListItemText>{descr.caption}</ListItemText>
                        {!!descr?.items?.length && <KeyboardArrowDownIcon />}
                    </MenuItem>
                </Box>
                <NestedMenu
                    key={`nav-bar-menu-item-nested-${descr.guid}`}
                    anchorEl={anchorEl}
                    items={descr?.items}
                    propContainer={propContainer}
                    handleClose={handleClose}
                    minWidth={anchorEl?.offsetWidth ?? 'inherit'}
                />
            </>
        );
    }
);

export default NavBarMenuItem;
