import React, { CSSProperties, MouseEvent, useState } from 'react';
import { observer } from 'mobx-react';

import {
    Chip,
    Select,
    MenuItem,
    Stack,
    Checkbox,
    ListItemText,
    ListSubheader,
    SelectChangeEvent,
    Button
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import { Cancel } from '@mui/icons-material';
import FormControl from '@mui/material/FormControl';
import _without from 'lodash/without';

import ConfigurationStore from 'store/configurationStore';

interface TagsProps {
    tags: Array<string>;
    tagList: Array<string> | null | undefined;
    label: string;
    onChange: (avalues: string[]) => void;
    onGetTaglist: () => void;
    style: CSSProperties;
}

function Tags({ tags, tagList, label, onChange, onGetTaglist, style }: TagsProps) {
    const { content } = ConfigurationStore;

    const [open, setOpen] = useState(false);
    const [selectedTags, setSelectedTags] = React.useState<string[]>(tags);

    const handleChange = (event: SelectChangeEvent<string[]>) => {
        setSelectedTags(event.target.value as string[]);
        if (onChange) onChange(event.target.value as string[]);
    };

    const handleDelete = (e: React.MouseEvent, value: string) => {
        e.preventDefault();
        const values = _without(selectedTags, value);
        setSelectedTags(values);
        if (onChange) {
            onChange(values);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <FormControl style={style} size="small">
            <InputLabel id="mutiple-chip-checkbox-label">{label}</InputLabel>
            <Select
                open={open}
                labelId="mutiple-chip-checkbox-label"
                id="mutiple-chip-checkbox"
                label={label}
                multiple
                value={selectedTags}
                onChange={handleChange}
                onOpen={() => {
                    handleOpen();
                    if (!tagList && onGetTaglist) {
                        onGetTaglist();
                    }
                }}
                onClose={handleClose}
                renderValue={selected =>
                    selected.map(value => (
                        <Chip
                            key={value}
                            label={value}
                            color="default"
                            size="small"
                            clickable
                            deleteIcon={
                                <Cancel
                                    onMouseDown={(event: MouseEvent) => event.stopPropagation()}
                                />
                            }
                            onDelete={e => handleDelete(e, value)}
                            sx={{ m: 0.5 }}
                        />
                    ))
                }
                sx={{
                    '& ul': { pt: 0 }
                }}
            >
                <ListSubheader sx={{ pt: 2 }}>
                    <Stack direction="row" justifyContent="flex-end" alignItems="center">
                        <Button onClick={handleClose}>{content.application.buttons.close}</Button>
                    </Stack>
                </ListSubheader>
                {(tagList || []).map((tag: string) => (
                    <MenuItem key={tag} value={tag}>
                        <Checkbox checked={selectedTags.includes(tag)} />
                        <ListItemText primary={tag} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default observer(Tags);
