import React, { FunctionComponent, ReactNode } from 'react';

interface TabPanelProps {
    children?: ReactNode;
    index: any;
    value: any;
}

const TabPanel: FunctionComponent<TabPanelProps> = ({ children, value, index, ...other }) => (
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`wrapped-tabpanel-${index as number}`}
        aria-labelledby={`wrapped-tab-${index as number}`}
        style={{
            height: '100%',
            boxSizing: 'border-box',
            paddingTop: '8px'
        }}
        {...other}
    >
        {children}
    </div>
);

export default TabPanel;
