import { makeObservable, observable, action } from 'mobx';
import Dataset from './customDataset';
import DataChunk from './DataChunk';
import AccumGroup from './AccumGroup';
import { GridSettingsAgg } from 'components/resources/ResEditor/gridSettings';

export default class DataAccum {
    ds: Dataset;
    owner: DataChunk;
    root?: AccumGroup;
    groupFields?: string[];
    aggrFields?: GridSettingsAgg[];

    constructor(ds: Dataset, owner: DataChunk) {
        this.ds = ds;
        this.owner = owner;
        this.root = undefined;
        makeObservable(this, { root: observable, init: action });
    }

    init() {
        this.groupFields = this.ds.groupFields;
        this.aggrFields = this.ds.aggrFields;
        this.root = new AccumGroup({ accum: this, parent: undefined, level: 0, grVal: null });
        this.owner.data.forEach((rec, i) => {
            this.root && this.root.add(rec, i);
        });
    }

    clear() {
        this.groupFields = undefined;
        this.aggrFields = undefined;
        this.root = undefined;
    }
}
