import PropContainer from 'dataObj/PropContainer';

import useDatasetLoading from 'hooks/datasetLoading';

import { ContentType } from 'localization/interfaces';

export default function useTaskTree(
    descr: any,
    propContainer: PropContainer,
    content: ContentType
) {
    const dataset = propContainer.dataStock.getDatasetObj(descr.datasetName);
    const mainDataset = dataset.masterDS;

    const parentTask = mainDataset?.data[0];

    const { loading, loadingType, loadingMessage } = useDatasetLoading(dataset, content);
    const { loading: mainDSLoading } = useDatasetLoading(mainDataset, content);

    return { dataset, parentTask, loading: loading || mainDSLoading, loadingType, loadingMessage };
}
