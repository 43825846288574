import React, { ReactElement } from 'react';

import { styled } from '@mui/material/styles';

import Tab from '@mui/material/Tab';

interface StyledTabProps {
    label: ReactElement;
}

const StyledTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        opacity: 0.6,
        color: '#1a1b1d',
        textTransform: 'none',
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        '&:hover': {
            opacity: 1
        }
    })
);

export default StyledTab;
