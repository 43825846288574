import React, { FunctionComponent, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { Button, Stack } from '@mui/material';

import TranslationEditorDialog from './EditorDialog/TranslationEditorDialog';

import { Locale } from './interfaces';
import { FormType } from '../../../../forms/interfaces';

import ConfigurationStore from '../../../../store/configurationStore';
import resValidator from '../../clientResValidator';

const LOCALISATIONS = ['rus', 'eng', 'fra'];

interface PropTypes {
    name: string;
    formDescr: FormType;
    formRes: any;
    translation: string;
    onConfirm: any;
}

const ResTranslationEditor: FunctionComponent<PropTypes> = observer(
    ({ name, formDescr, formRes, translation, onConfirm }) => {
        const { resource }: any = ConfigurationStore.content;

        const [open, setOpen] = useState(false);
        const [localisations, setLocalisations] = useState(['']);

        // Вычисляем доступные локали в соостветствии с описателем типа контрола
        useEffect(() => {
            setLocalisations(
                resValidator.getTypeDescriptor(formRes.type)?.fields?.lang?.list ?? LOCALISATIONS
            );
        }, []);

        const handleOpen = () => {
            setOpen(true);
        };

        const handleClose = () => {
            setOpen(false);
        };

        const handleConfirm = (updateTranslation: { [key: string]: Locale }) => {
            onConfirm({ target: { name, value: JSON.stringify(updateTranslation) } });
            setOpen(false);
        };

        return (
            <Stack spacing={1} direction="row">
                <Button onClick={handleOpen} color={translation ? 'info' : 'primary'}>
                    {translation ? resource.scriptEditor.change : resource.buttons.add}
                </Button>
                <TranslationEditorDialog
                    open={open}
                    handleConfirm={handleConfirm}
                    handleClose={handleClose}
                    formRes={formRes}
                    localisations={localisations}
                />
            </Stack>
        );
    }
);

export default ResTranslationEditor;
