import dagre from 'dagre';

const nodeWidth = 300;
const nodeHeight = 200;

const getLayoutedElements = (nodes: any[], edges: any[], direction: 'TB' | 'LR' = 'TB') => {
    const dagreGraph = new dagre.graphlib.Graph();

    dagreGraph.setDefaultEdgeLabel(() => ({}));

    const isHorizontal = direction === 'LR';
    dagreGraph.setGraph({ rankdir: direction, align: 'DL' });

    nodes.forEach(node => {
        dagreGraph.setNode(node.id, {
            width: node.style?.width || nodeWidth,
            height: node.style?.height || nodeHeight
        });
    });

    edges.forEach(edge => {
        dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    nodes.forEach(node => {
        const nodeWithPosition = dagreGraph.node(node.id);
        node.targetPosition = isHorizontal ? 'left' : 'top';
        node.sourcePosition = isHorizontal ? 'right' : 'bottom';

        node.position = {
            x: nodeWithPosition.x - (node.style?.width || nodeWidth) / 2,
            y: nodeWithPosition.y - (node.style?.height || nodeHeight) / 2
        };

        return node;
    });

    return [nodes, edges];
};

export default getLayoutedElements;
