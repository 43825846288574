import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import NotificationStore from '../../store/notificationStore';

const AlertDialog: FunctionComponent = observer(() => {
    const { alertOpen, alertTitle, alertMessage, closeAlert } = NotificationStore;

    return (
        <Dialog open={alertOpen}>
            {alertTitle && <DialogTitle>{alertTitle}</DialogTitle>}
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Typography sx={{ whiteSpace: 'pre-wrap' }}>{alertMessage}</Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeAlert} color="primary" autoFocus>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default AlertDialog;
