export const fieldsColumns = (content: any) => [
    {
        field: 'name',
        headerName: content.columns.caption.headerName,
        description: content.columns.caption.description,
        flex: 1,
        disableColumnMenu: true,
        editable: false,
        sortable: false
    },
    {
        field: 'dataset',
        headerName: content.columns.dataset.headerName,
        description: content.columns.dataset.description,
        flex: 1,
        disableColumnMenu: true,
        editable: false,
        sortable: false
    },
    {
        field: 'width',
        headerName: content.columns.width.headerName,
        description: content.columns.width.description,
        width: 120,
        disableColumnMenu: true,
        editable: true,
        sortable: false
    }
];

export const groupColumns = (content: any, datasets: string[], fieldsButton: any) => [
    {
        field: 'name',
        headerName: content.columns.group.headerName,
        description: content.columns.group.description,
        flex: 1,
        disableColumnMenu: true,
        editable: true,
        sortable: false
    },
    {
        field: 'dataset',
        type: 'singleSelect',
        headerName: content.columns.dataset.headerName,
        description: content.columns.dataset.description,
        flex: 1,
        disableColumnMenu: true,
        editable: true,
        sortable: false,
        valueOptions: datasets || []
    },
    {
        field: 'caption',
        headerName: content.columns.groupCaption.headerName,
        description: content.columns.groupCaption.description,
        flex: 1,
        disableColumnMenu: true,
        editable: true,
        sortable: false
    },
    {
        field: 'width',
        headerName: content.columns.width.headerName,
        description: content.columns.width.description,
        width: 120,
        disableColumnMenu: true,
        editable: true,
        sortable: false
    },
    {
        field: 'fields',
        headerName: content.columns.groupFields.headerName,
        description: content.columns.groupFields.description,
        width: 120,
        disableColumnMenu: true,
        editable: false,
        sortable: false,
        renderCell: fieldsButton
    }
];

export const fieldListColumns = (content: any) => [
    {
        field: 'caption',
        headerName: content.columns.caption.headerName,
        description: content.columns.caption.description,
        flex: 1,
        disableColumnMenu: true,
        editable: false,
        sortable: false
    },
    {
        field: 'width',
        headerName: content.columns.width.headerName,
        description: content.columns.width.description,
        width: 120,
        disableColumnMenu: true,
        editable: true,
        sortable: false
    }
];
