import React, { FunctionComponent, memo } from 'react';

import { Handle, Position } from 'reactflow';
import { Grid, Typography } from '@mui/material';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import State from './State';

type DataType = {
    number: string;
    deadline: string;
    title: string;
    user?: string;
    status?: string;
};

interface NodeFlowTypes {
    data: DataType;
    isConnectable: boolean;
    sourcePosition?: Position;
    targetPosition?: Position;
}

const NodeFlow: FunctionComponent<NodeFlowTypes> = ({
    data,
    isConnectable,
    sourcePosition,
    targetPosition
}) => {
    const { number, title, deadline, status, user } = data;

    const handleStyle = {
        zIndex: -1,
        border: 0,
        background: 'grey'
    };

    return (
        <>
            <Handle
                type="target"
                position={targetPosition || Position.Top}
                isConnectable={isConnectable}
                style={{ visibility: 'hidden' }}
            />
            <Card key={number} sx={{ width: 300, backgroundColor: '#eeffee' }}>
                <CardHeader
                    sx={{ pb: 0 }}
                    action={
                        <IconButton aria-label="settings">
                            <MoreVertIcon />
                        </IconButton>
                    }
                    title={
                        <Grid container sx={{ pr: 2 }}>
                            <Grid item xs={6} sx={{ alignSelf: 'center' }}>
                                <Typography
                                    variant="subtitle1"
                                    color="text.secondary"
                                    fontSize="1.4rem"
                                >
                                    {number}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sx={{ textAlign: 'right', alignSelf: 'center' }}>
                                <Typography variant="subtitle2" fontSize="1.4rem">
                                    {deadline}
                                </Typography>
                            </Grid>
                        </Grid>
                    }
                />

                <CardContent sx={{ height: 60, pt: 0, pb: 0, overflow: 'hidden' }}>
                    <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        sx={{ fontSize: '1rem', fontWeight: 600 }}
                    >
                        {title}
                    </Typography>
                </CardContent>

                <CardActions sx={{ p: 4 }}>
                    <Typography
                        gutterBottom
                        variant="subtitle1"
                        component="div"
                        sx={{ fontSize: '1rem', fontWeight: 600 }}
                    >
                        {user}
                    </Typography>

                    <State variant={status} />
                </CardActions>
            </Card>
            <Handle
                type="source"
                position={sourcePosition || Position.Bottom}
                id="a"
                style={handleStyle}
                isConnectable={isConnectable}
            />
        </>
    );
};

export default memo(NodeFlow);
