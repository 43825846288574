import React, { ReactNode } from 'react';

import withStyles from '@mui/styles/withStyles';
import Tabs from '@mui/material/Tabs';

interface StyledTabsProps {
    value: number;
    onChange: (event: React.ChangeEvent<{ [key: string]: any }>, newValue: number) => void;
    children?: ReactNode;
}

const StyledTabs = withStyles({
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        '& > span': {
            width: '100%',
            backgroundColor: '#F57069'
        }
    }
})((props: StyledTabsProps) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span /> }}
        variant="scrollable"
        scrollButtons="auto"
    />
));

export default StyledTabs;
