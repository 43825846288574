import React, { FunctionComponent, useRef, useEffect, useState, useMemo } from 'react';

import { Grid, IconButton, TableCell, Tooltip } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import { isEllipsisActive } from 'forms/form-utils';
import useNodeResize from 'hooks/nodeResize';
import { getLocalizedString } from 'utils';

import Splitter from './Splitter';

import { HeaderColumnProps } from '../interface';

const MIN_COLUMN_WIDTH = 75;

const HeaderCell: FunctionComponent<HeaderColumnProps> = ({
    col,
    index,
    activeIndex,
    headerHeight,
    styles,
    dataset,
    onMouseDown,
    onClick
}) => {
    const [isEllipsis, setIsEllipsis] = useState(false);

    const renderRef = useRef(false);
    const labelRef = useRef(null) as any;
    const [labelWidth] = useNodeResize(labelRef);

    const caption = useMemo(() => getLocalizedString(col.caption), [col.caption]);

    const getDirectionIcon = (direction: string) => {
        if (direction === 'asc') {
            return <ArrowDownwardIcon fontSize="small" />;
        }
        if (direction === 'desc') {
            return <ArrowUpwardIcon fontSize="small" />;
        }
        return <ArrowDownwardIcon color="disabled" fontSize="small" />;
    };

    const direction = dataset?.getOrderByDirection(col.fieldName);

    useEffect(() => {
        if (renderRef.current) {
            setIsEllipsis(isEllipsisActive(labelRef.current));
        }
    }, [renderRef, labelRef, labelWidth]);

    useEffect(() => {
        if (renderRef.current) return;

        if (labelRef?.current) {
            renderRef.current = true;
            if (labelRef.current.scrollWidth || labelRef.current.scrollWidth === 0) {
                const width =
                    labelRef.current.scrollWidth > MIN_COLUMN_WIDTH
                        ? (labelRef.current.scrollWidth as number) + (direction ? 50 : 10)
                        : MIN_COLUMN_WIDTH;

                col.ref.current.style.width = `${width}px`;
            }
        }
    }, [renderRef, labelRef, direction]);

    return (
        <TableCell ref={col.ref} key={index} sx={styles.headerCell} width={col.width}>
            <Grid
                container
                flexWrap="nowrap"
                sx={{
                    cursor: 'pointer',
                    '& .sortIcon': {
                        visibility:
                            direction && ![4, 5].includes(dataset.state) ? 'visible' : 'hidden',
                        width: direction ? 'auto' : 0,
                        button: {
                            width: direction ? 'initial' : 0
                        }
                    },
                    '&:hover': {
                        '& .sortIcon': {
                            visibility: 'visible',
                            width: 'auto',
                            button: {
                                width: 'initial'
                            }
                        }
                    }
                }}
                onClick={() => onClick(col)}
            >
                <Grid
                    item
                    alignSelf="center"
                    sx={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        display: 'block'
                    }}
                >
                    <Tooltip
                        title={caption}
                        placement="bottom-start"
                        disableHoverListener={!isEllipsis}
                    >
                        <span
                            ref={labelRef}
                            style={{
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                display: 'block'
                            }}
                        >
                            {caption}
                        </span>
                    </Tooltip>
                </Grid>

                <Grid item marginLeft="auto" className="sortIcon" sx={{ width: 0 }}>
                    <IconButton size="small">{getDirectionIcon(direction)}</IconButton>
                </Grid>
            </Grid>
            <Splitter
                headerHeight={headerHeight}
                index={index}
                activeIndex={activeIndex}
                mouseDown={onMouseDown}
            />
        </TableCell>
    );
};

export default HeaderCell;
