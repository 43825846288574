import isString from 'lodash/isString';
import toNumber from 'lodash/toNumber';
import set from 'lodash/set';

import { v4 as uuidv4 } from 'uuid';

import { jsonFetch } from 'utils';

import clientResValidator from 'components/resources/clientResValidator';

import {
    ColumnType,
    ControlType,
    DatasetFieldType,
    DatasetType,
    DBControlType,
    FormType,
    QueryFieldType,
    StartQueryType
} from './interfaces';

import styles from './form.module.scss';
import CustomDataset from '../dataObj/customDataset';

export const isGUID = (guid: any) => {
    let s: any = String(guid);
    s = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
    return s !== null;
};

export const getClasses = (descr: ControlType) => {
    let classes: Array<string> = [];

    if (descr?.classes) classes = descr?.classes.split(' ');

    return classes.map((cl: string) => styles[cl]);
};

export const getResourceDescriptor = async (
    guid: string | undefined,
    tableId: string | undefined,
    descriptorSetter?: any,
    DescriptorStore?: any,
    options?: { logout: any }
) => {
    const descrID = `${guid ?? ''}${tableId ? `/${tableId}` : ''}`;
    const cachedDescr = DescriptorStore?.getDescriptor(descrID);

    if (cachedDescr) {
        descriptorSetter && descriptorSetter(cachedDescr);

        return cachedDescr;
    }

    const loadedDescr = await jsonFetch(`views/${descrID}`, 'GET', undefined, {}, 'json', options);
    DescriptorStore?.setDescriptor(descrID, loadedDescr);
    descriptorSetter && descriptorSetter(loadedDescr);

    return loadedDescr;
};

export const styleStringToStyleObject = (customStyle: { [key: string]: any }) => {
    if (customStyle?.length > 0) {
        const arrStyle = customStyle
            .split(';')
            .filter((e: string) => e.trim().length !== 0)
            .map((line: string) => {
                const result = Array.from(line.split(':')[0].matchAll(/-/g));
                result.forEach((arr: any) => {
                    line = Array.from(line)
                        .map((a, i) => (i === (arr.index as number) + 1 ? a.toUpperCase() : a))
                        .join('');
                });
                line = [
                    line.split(':')[0].replace(/-/g, ''),
                    line.slice(1).split(':').slice(1).join(':')
                ].join(': ');
                return line;
            });
        const rObj: { [key: string]: any } = {};
        arrStyle.map((line: string) => {
            const a = line.split(':');
            const name = a[0].trim();
            rObj[name] = a.slice(1).join(':').trim().toString();
            return line;
        });
        return rObj;
    }

    return {};
};

export const hiddenStyle = (hidden = false) => {
    if (hidden)
        return {
            position: 'absolute' as any,
            padding: 0,
            margin: 0,
            height: 0,
            width: 0,
            overflow: 'hidden'
        };

    return {};
};

export const getGridContainer = (descr: any) => {
    const getDirection = (layout?: string) => {
        if (layout === 'vertical') return { direction: 'column', flexWrap: 'nowrap' };

        return { direction: 'row' };
    };

    if (descr.controls && descr.layout) {
        return { ...{ container: true }, ...getDirection(descr.layout) };
    }

    return {};
};

export const getGridBreakpoint = (
    breakpoint: string | number,
    layout?: string,
    sx?: { [key: string]: any }
) => {
    const gridSets: { [key: string]: any } = {
        xs: false,
        sm: false,
        md: false,
        lg: false,
        xl: false
    };

    const display: { [key: string]: 'block' | 'none' } = {
        xs: 'block',
        sm: 'block',
        md: 'block',
        lg: 'block',
        xl: 'block'
    };

    if (isString(breakpoint)) {
        const bpComp = breakpoint?.toLowerCase().replace(/\s/g, '');

        if (bpComp === 'auto' || bpComp === 'content')
            return {
                flexGrow: bpComp === 'auto' ? 1 : 0,
                flexShrink: 1,
                flexBasis: layout === 'horizontal' ? 0 : bpComp,
                sx: {
                    ...sx,
                    ...{
                        height: layout === 'horizontal' ? '100%' : 'auto',
                        overflow: bpComp === 'content' ? 'inherit' : 'auto'
                    }
                }
            };

        const params = breakpoint?.toLowerCase().replace(/\s/g, '')?.split(',');

        params.map((s: string) => {
            const key = s.split(':')[0];
            const val = s.split(':')[1];

            if (gridSets.hasOwnProperty(key)) {
                if (/\b([1-9]|1[0-2])\b/.test(val)) {
                    gridSets[key] = toNumber(val);
                } else {
                    switch (val) {
                        case 'fill':
                            gridSets[key] = true;
                            break;

                        case 'auto':
                            gridSets[key] = 'auto';
                            break;

                        case 'content':
                            gridSets[key] = 'content';
                            break;

                        case 'none':
                            display[key] = 'none';
                            break;

                        default:
                            gridSets[key] = false;
                            break;
                    }
                }
            } else if (s) {
                gridSets.xs = s === '0' ? 12 : toNumber(s); // временное решение для обраной совместимости
            }

            return;
        });
    }

    return { ...gridSets, ...{ display } };
};

export const getGridOrder = (descr: any) => {
    const order: { [key: string]: any } = {};

    if (isString(descr.gridOrder)) {
        const bp = ['xs', 'sm', 'md', 'lg', 'xl'];
        const params = descr.gridOrder?.toLowerCase().replace(/\s/g, '')?.split(',');

        if (params.length)
            params.map((s: string) => {
                const key = s.split(':')[0];
                const val = s.split(':')[1];

                if (val && bp.includes(key)) order[key] = val;
                return;
            });
    }

    return { order };
};

export const getControlClass = (classes: string, name: string) => {
    const regExp = new RegExp(name.toLowerCase());
    return (classes || '').split(' ').find(s => s.trim().toLowerCase().search(regExp) !== -1);
};

// Проверка наличия полей на форме по наименованию и родительскому датасету
export const checkFieldInControl = (ctrl: any, dsName: string, field: DatasetFieldType) =>
    !ctrl.controls
        // Учитываем только поля текущего датасета
        .filter((ctrlEl: DBControlType) => ctrlEl.datasetName === dsName)
        // Формируем массив наименований полей
        .map((ctrlEl: DBControlType) => ctrlEl.name)
        // Проверяем наличие текущего поля в массиве полей формы
        .filter((ctrlElName: string) => {
            const regex = new RegExp(field.name);
            return regex.test(ctrlElName);
        }).length;

/**
 * Получение дескрипторов датасетов по иерархии
 * @param {*} datasets // коллекция дескрипторов датасетов
 * @param {*} dsArray // одномерный массив собирающий дескрипторы датасетов по иерархии
 */
export const collectDatasetDescriptors = (datasets: Array<unknown>, dsArray: Array<unknown>) => {
    if (datasets) {
        datasets.forEach((dsDescr: any) => {
            dsArray.push(dsDescr);
            collectDatasetDescriptors(dsDescr.datasets, dsArray);
        });
    }
};

/**
 * Генерация клиентского датасета из описателя запроса
 * @param resource
 */
export const generateTempDataset = (resource: any) => {
    const {
        guid,
        resourceLinkName,
        fields,
        params = [],
        filters,
        multiSelect,
        splitField,
        splitValue
    } = resource;

    if (splitField && splitValue)
        params.push({
            type: 'param',
            paramName: splitField,
            paramType: 'const',
            value: splitValue
        });

    const dsDescr = {
        name: resourceLinkName,
        type: 'dataset',
        request: guid,
        fields,
        params,
        filters,
        keyField: fields[0].name,
        flMainSelect: true,
        multiSelect,
        pageSize: 25
    };

    return clientResValidator.validate(dsDescr);
};

const makeGridField = (field: QueryFieldType | DatasetFieldType): ColumnType => ({
    type: 'column',
    guid: uuidv4(),
    name: `column-${uuidv4()}`,
    fieldName: field.name,
    caption: field.caption
});

export const generateGridColumnsFromDS = (dataset: CustomDataset) =>
    dataset.descr.fields?.filter(field => !field.hidden).map(makeGridField);

export const generateColumnsFromRequest = async (requestGuid: string) => {
    try {
        const requestDescr: StartQueryType = await jsonFetch(`resources/${requestGuid}`, 'GET');

        return requestDescr.fields.map(makeGridField);
    } catch (err: any) {
        console.error(err.message);
    }
};

/**
 * Геренация контрола Дата Грида из клиентского датасета
 * @param guid
 * @param dataset
 */
export const generateGrid = async (guid: string, dataset: DatasetType) => {
    const options = await jsonFetch('settings/', 'GET', { resGuid: guid, type: 'auto' });
    const gridDescr = {
        type: 'grid',
        isDynamic: true,
        name: `${dataset.name}-grid`,
        datasetName: dataset.name,
        columns: dataset.fields.map((field: any) => ({
            type: 'column',
            fieldName: field.name,
            caption: field.caption
        })),
        options
    };

    return clientResValidator.validate(gridDescr);
};

/**
 * Генерация формы
 * @param {DatasetType} tempDS - датасет
 * @param {*} parentForm - родительская форма
 * @param {String} ownerGuid - guid формы верхнего уровня
 * @param {Array} controls - массив описателей контролов
 * @param {String} [queryName] - имя запроса (опционально)
 * @param {String} [formName] - имя формы (опционально)
 */
export const generateForm = async (
    tempDS: DatasetType,
    parentForm: FormType,
    controls: any[] = [],
    queryName?: string,
    formName?: string,
    ownerGuid?: string
) => {
    const genDescr = {
        type: 'wuiForm',
        name: `${formName ?? 'generatedForm'}-${queryName ?? 'query'}`,
        isEditor: false,
        datasets: [tempDS],
        controls,
        styles: `height: 100%`,
        ownerGuid
    };

    // Даём описателю формы признак сгенерированности
    return {
        ...clientResValidator.validate(genDescr),
        ...{ isGenerated: true, parentForm }
    };
};

/**
 * Генерация скриптовой кнопки
 * @param {string} caption - текст кнопки
 * @param {string} onClickScript - наименование скрипта
 * @param {object} iconDescr - описание иконки для кнопки
 */
export const generateScriptButton = (
    caption: string,
    onClickScript: string,
    iconDescr?: { iconSet: string; icon: string }
) => {
    const btnDescr = {
        type: 'scriptButton',
        caption,
        name: `generatedScriptButton-${caption}`,
        iconSet: iconDescr?.iconSet ?? '',
        icon: iconDescr?.icon ?? '',
        onClickScript
    };

    // Даём описателю кнопки признак сгенерированности
    return {
        ...clientResValidator.validate(btnDescr),
        ...{ isGenerated: true }
    };
};

export const generateEditControl = (
    label: string,
    datasetName: string,
    fieldName: string,
    options: { [key: string]: string | number },
    ctrlType?: 'dbEdit' | 'dbMultipleEdit'
) => {
    const editCtrlDescr = {
        type: ctrlType || 'dbEdit',
        label: label || fieldName,
        name: `generatedDBEdit-${fieldName}`,
        gridWidth: options?.width,
        datasetName,
        fieldName
    };

    // Даём описателю кнопки признак сгенерированности
    return {
        ...clientResValidator.validate(editCtrlDescr),
        ...{ isGenerated: true }
    };
};

export const generateLookupControl = (
    label: string,
    datasetName: string,
    fieldName: string,
    options: { [key: string]: string | number },
    resourceName?: string,
    splitField?: string,
    splitValue?: string,
    codeFields?: string,
    labelFields?: string
) => {
    const editCtrlDescr = {
        type: 'dbLookup',
        label: label || fieldName,
        name: `generatedDBLookup-${datasetName}-${fieldName}`,
        datasetName,
        fieldName,
        splitField,
        splitValue,
        gridWidth: options?.width,
        lookupResourceName: resourceName,
        codeFields: codeFields || 'CODE',
        labelFields: labelFields || 'LABEL'
    };

    // Даём описателю кнопки признак сгенерированности
    return {
        ...clientResValidator.validate(editCtrlDescr),
        ...{ isGenerated: true }
    };
};

export const applyFormExtParams = (descr: FormType, extParamVals?: { [key: string]: string }) => {
    const { extParams } = descr;

    if (!extParams?.length || !extParamVals) return descr;

    extParams
        .filter(extParam => extParam.kind === 'property')
        .forEach(extParam => {
            const param = extParamVals[extParam.name];

            if (param && extParam.placeholder) {
                set(descr, extParam.placeholder, param);
                descr.isModified = true;
            }
        });

    return descr;
};

export const isEllipsisActive = (el: HTMLElement) => el.offsetWidth < el.scrollWidth;

export const collectDataset = (control: any, list: DatasetType[]) => {
    if (control?.datasets) {
        collectDatasetDescriptors(control.datasets, list);
    }
    if (control?.controls) {
        for (const contr of control.controls) {
            collectDataset(contr, list);
        }
    }
    if (control?.dataObjects) {
        for (const contr of control.dataObjects) {
            collectDataset(contr, list);
        }
    }
};

export const isRegExpString = (str: string): boolean => {
    const pattern = str.match(/\/(.*)\/[gimsuy]*$/)?.[1];
    const flags = str.match(/\/([gimsuy]*)$/)?.[1];

    if ((pattern && flags) || (pattern && !flags)) return true;

    return false;
};

export const convertStrToRegExpObj = (str: string): RegExp | null => {
    const pattern = str.match(/\/(.*)\/[gimsuy]*$/)?.[1];
    const flags = str.match(/\/([gimsuy]*)$/)?.[1];

    if ((pattern && flags) || (pattern && !flags)) return new RegExp(pattern, flags);

    return null;
};
