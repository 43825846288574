import React, {
    FunctionComponent,
    ChangeEvent,
    useCallback,
    useEffect,
    useState,
    MouseEvent
} from 'react';
import { observer } from 'mobx-react';

import {
    Button,
    IconButton,
    Grid,
    Stack,
    TextField,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText
} from '@mui/material';
import {
    Add,
    Refresh,
    Download,
    GetApp,
    CleaningServices,
    MoreVert,
    Home
} from '@mui/icons-material';

import ComboBox from 'components/Inputs/ComboBox';
import Tags from 'components/Inputs/Tags/Tags';

import { ResourceClass } from 'store/resourceStore';
import ConfigurationStore from 'store/configurationStore';

import { saveFile, downloadBlobFile } from 'utils';

import useDebounce from 'hooks/debounce';
import useBackdrop from 'hooks/backdrop';
import resValidator from '../../clientResValidator';
import exportStructDescriptor from '../../exportStructDescriptor';

interface ResListType {
    types: Array<string>;
    createResource: () => unknown;
    showResource: (guid: string) => unknown;
    ResourceStore: ResourceClass;
}

const SearchPanel: FunctionComponent<ResListType> = observer(
    ({ types, createResource, showResource, ResourceStore }) => {
        const {
            curGrp,
            setCurGrp,
            filterName,
            setFilterName,
            tags,
            setTags,
            getResourceList,
            getTaglist,
            resetCache,
            importResource
        } = ResourceStore;
        const { resource } = ConfigurationStore.content;

        const [loading, setLoading] = useState(false);
        const [tagList, setTagList] = useState<string[] | undefined | null>();
        const [search, setSearch] = useState<string>(filterName ?? '');
        const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

        const open = Boolean(anchorEl);

        useBackdrop(loading);

        const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
            setAnchorEl(event.currentTarget);
        };

        const handleCloseMenu = () => {
            setAnchorEl(null);
        };

        const generateHtmlContent = (data: any) => {
            const components = Object.entries(data).map(([component, props]) => ({
                component,
                ...(props as any)
            }));
            const html = exportStructDescriptor(components);
            return html;
        };

        const handleExportStructDescriptor = () => {
            const structDescriptorData = resValidator.getStructDescriptorData();
            const content = generateHtmlContent(structDescriptorData);
            const blob = new Blob([content as unknown as BlobPart], { type: 'text/html' });
            downloadBlobFile(blob, 'descriptor.html');
        };

        const activeSearch = useDebounce(search) as string;

        const names = resource.resList.groupStore as Record<string, string>;
        const groupStore = [
            { key: 'all', val: names.all },
            { key: 'wuiForm', val: names.wuiForm },
            { key: 'wuiGroup', val: names.wuiGroup },
            { key: 'subForm', val: names.subForm },
            { key: 'wuiQuery', val: names.wuiQuery },
            { key: 'wuiExtQuery', val: names.wuiExtQuery },
            { key: 'wuiStatQuery', val: names.wuiStatQuery },
            { key: 'cdo', val: names.cdo },
            { key: 'wuiPackage', val: names.wuiPackage },
            { key: 'table', val: names.table },
            { key: 'wuiSettings', val: names.settings },
            { key: 'wuiIconSet', val: names.wuiIconSet },
            { key: 'wuiApplication', val: names.wuiApplication }
        ];

        const refreshResourceList = useCallback(() => {
            const filterString = [...tags, filterName.trim()];
            if (curGrp !== 'subForm')
                getResourceList([curGrp], filterString.join(',')).catch((err: Error) =>
                    ConfigurationStore.log(err.message, 'error')
                );
        }, [curGrp, tags, filterName, getResourceList]);

        const addResourceHandle = () => {
            createResource();
        };

        const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
            setSearch(event.target.value);
        };

        const handleImport = (event: any) => {
            if (event.target.files[0]) {
                setLoading(true);

                importResource(event.target.files[0], showResource)
                    .catch((err: any) => console.error(err.message))
                    .finally(() => setLoading(false));
            }
        };

        useEffect(() => {
            if (tagList === null) {
                getTaglist()
                    .then(list => setTagList(list))
                    .catch(() => {});
            }
        }, [tagList, getTaglist]);

        useEffect(() => {
            refreshResourceList();
        }, [curGrp, filterName, refreshResourceList, tags]);

        useEffect(() => {
            setFilterName(activeSearch);
        }, [activeSearch]);

        return (
            <div id="resource-search-panel" style={{ paddingTop: 20 }}>
                <ComboBox
                    label={resource.resList.type}
                    value={curGrp}
                    store={groupStore}
                    filter={types}
                    onChange={e => setCurGrp(e.target.value)}
                />
                {curGrp !== 'subForm' ? (
                    <>
                        <TextField
                            sx={{ width: '100%', boxSizing: 'border-box', mt: 4 }}
                            size="small"
                            type="text"
                            label={resource.resList.searchLabel}
                            variant="outlined"
                            onChange={handleSearch}
                            value={search}
                        />
                        <Tags
                            style={{ width: '100%', boxSizing: 'border-box', marginTop: 16 }}
                            label={resource.resList.searchTag}
                            tags={tags}
                            tagList={tagList}
                            onChange={(values: string[]) => setTags(values)}
                            onGetTaglist={() => setTagList(null)}
                        />
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            style={{ marginTop: 16 }}
                            columns={14}
                        >
                            <Grid item xs={4}>
                                <Button
                                    variant="contained"
                                    onClick={addResourceHandle}
                                    endIcon={<Add />}
                                    fullWidth
                                >
                                    {resource.buttons.add}
                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    variant="outlined"
                                    aria-label="edit"
                                    onClick={refreshResourceList}
                                    startIcon={<Refresh />}
                                    fullWidth
                                >
                                    {resource.buttons.refresh}
                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    variant="outlined"
                                    aria-label="getRequests"
                                    component="label"
                                    startIcon={<GetApp />}
                                    fullWidth
                                >
                                    {resource.buttons.import}
                                    <input hidden accept="*" type="file" onChange={handleImport} />
                                </Button>
                            </Grid>
                            <Grid item>
                                <IconButton aria-label="more" onClick={handleOpenMenu}>
                                    <MoreVert />
                                </IconButton>
                                <Menu open={open} anchorEl={anchorEl} onClose={handleCloseMenu}>
                                    <MenuItem onClick={() => resetCache()}>
                                        <ListItemIcon>
                                            <CleaningServices fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>{resource.buttons.resetCache}</ListItemText>
                                    </MenuItem>
                                    <MenuItem onClick={() => handleExportStructDescriptor()}>
                                        <ListItemIcon>
                                            <GetApp fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>
                                            {resource.buttons.getDescriptor}
                                        </ListItemText>
                                    </MenuItem>
                                    <MenuItem
                                        component="a"
                                        href="/"
                                        target="_blank"
                                        onClick={handleCloseMenu}
                                    >
                                        <ListItemIcon>
                                            <Home fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>{resource.buttons.homePage}</ListItemText>
                                    </MenuItem>
                                </Menu>
                            </Grid>
                        </Grid>
                        {ConfigurationStore.isDebug ? (
                            <Stack spacing={1} direction="row" style={{ marginTop: 16 }}>
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    aria-label="getRequests"
                                    onClick={() =>
                                        saveFile(ConfigurationStore.getRequestList(), 'ammo.txt')
                                    }
                                    startIcon={<Download />}
                                >
                                    HTTP Запросы
                                </Button>
                            </Stack>
                        ) : null}
                    </>
                ) : null}
            </div>
        );
    }
);

export default SearchPanel;
