import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';

import { Button, Grid } from '@mui/material';

import ModalForm from 'hoc/ModalForm';
import CustomForm from 'forms/CustomForm';

import CustomDataset from 'dataObj/customDataset';
import { UpdateCDOResult, UpdateResult } from 'dataObj/dataInterfaces';

import useMaxWidth from 'hooks/maxWidth';

import Loading from 'components/utils/Loading/Loading';

import ConfigurationStore from 'store/configurationStore';
import EventsStore from 'store/eventsStore';
import { onNewRecord } from 'store/eventsStore/events';
import ActionStore from 'store/actionStore';

import { getCreatedCDORecordId, getCreatedRecordId } from 'utils';
import { getControlClass } from 'forms/form-utils';

interface EditorPropsType {
    dataset: CustomDataset;
}

interface ResultType {
    warn?: never[];
    err?: never[];
    noReload?: boolean;
    isValid?: boolean;
    result?: any[];
}

const Editor: FunctionComponent<EditorPropsType> = observer(({ dataset }) => {
    const [editorName, setEditorName] = React.useState('Редактор записи');

    const { content } = ConfigurationStore;
    const datasetActions = ActionStore.getDatasetActions(dataset.guid);

    const { editor, setEditor, editAction, setEditAction, setEditorExtParamVals } = datasetActions;

    const formConfig = dataset?.dataStock?.ownerPropContainer?.find(editor?.guid, 'wuiForm')?.descr;
    const formActions = ActionStore.getFormActions(editor?.guid);

    useEffect(() => {
        if (formActions?.getName) {
            setEditorName(formActions.getName());
        }
    }, [formActions]);

    const handleClose = async (state: boolean, resultArr?: any[]) => {
        if (state && formActions?.save) {
            formActions
                .save()
                .then((result?: ResultType) => {
                    if (result?.err?.length === 0) {
                        setEditor(false);

                        if (result.noReload) {
                            dataset.accum.init();
                        } else {
                            dataset.loadData({ forceRefresh: true });
                        }

                        if (editAction === 'create')
                            EventsStore.getEvents(dataset.descr.guid)
                                ?.triggerEvent(onNewRecord)
                                .catch(err => console.error(err.message));
                    }
                })
                .catch(err => console.error(err.message));
        } else {
            if (formActions?.cancel) formActions?.cancel();
            setEditor(false);
        }
    };

    const customButtons = () => (
        <>
            {['view'].includes(formActions?.editMode as string) && (
                <Button
                    key="edit"
                    onClick={() => formActions.setEditMode('edit')}
                    variant="outlined"
                >
                    {content.controls.buttons.change}
                </Button>
            )}
            {['edit', 'create', 'cdoCreate'].includes(formActions?.editMode as string) && (
                <Button
                    key="save"
                    onClick={async () => {
                        const result = await formActions.save();

                        if (result?.err.length === 0) {
                            if (result.noReload) {
                                dataset.accum.init();
                            } else {
                                dataset.loadData();
                            }

                            if (['create', 'cdoCreate'].includes(formActions?.editMode as string)) {
                                if (dataset.editType === 'detail') {
                                    setEditor(false);
                                } else {
                                    const newRecId = !formActions?.getDataStock().CDOs?.length
                                        ? getCreatedRecordId(
                                              result.resAccum as UpdateResult[],
                                              dataset.keyField
                                          )
                                        : getCreatedCDORecordId(
                                              result.resAccum as UpdateCDOResult[],
                                              dataset.name,
                                              dataset.keyField
                                          );

                                    if (newRecId) {
                                        setEditorExtParamVals(
                                            {
                                                [dataset.keyField]: newRecId
                                            },
                                            true
                                        );

                                        setEditAction('edit');

                                        formActions.setEditMode('edit');
                                    } else {
                                        console.error('Не найден идентификатор новой записи');
                                    }
                                }
                            } else {
                                formActions.clearDiff();
                                setEditorExtParamVals(
                                    {
                                        [dataset.keyField]: dataset.activeRec[dataset.keyField]
                                    },
                                    true
                                );
                            }

                            formActions.edit().catch(err => console.error(err.message));
                        }
                    }}
                    variant="outlined"
                    disabled={!formActions.validated}
                >
                    {content.controls.buttons.save}
                </Button>
            )}
        </>
    );

    const maxWidth = useMaxWidth(formActions);

    const minHeight = useMemo(() => {
        if (formActions) {
            const height = getControlClass(formActions.getFormDescr().classes, 'minHeight')?.split(
                '-'
            )?.[1] as string;

            return height ? `calc(${height}vh - 148px)` : undefined;
        }

        return;
    }, [formActions]);

    return editor?.guid ? (
        <ModalForm
            formGuid={editor.guid}
            maxWidth={maxWidth as false | 'xl' | 'md' | 'sm' | 'xs' | 'lg'}
            handleClose={handleClose}
            editorName={editorName}
            sx={{
                '& .MuiPaper-root': {
                    overflow: 'hidden'
                },
                '& .MuiDialogContent-root ': {
                    position: 'relative',
                    minHeight: minHeight ?? (!formActions?.isReady ? 300 : null)
                }
            }}
            customActions={customButtons}
            disabled={!formActions?.validated}
        >
            <Grid
                item
                sx={
                    minHeight
                        ? {
                              position: 'absolute',
                              height: '100%',
                              width: 'calc(100% - 48px)'
                          }
                        : {}
                }
            >
                {formConfig || editor?.guid ? (
                    <CustomForm
                        key={formConfig?.guid || editor?.guid}
                        formGuid={formConfig ? undefined : editor?.guid}
                        formConfig={formConfig}
                        extParamVals={editor?.extParamVals}
                        initEditMode={editAction}
                        parentDataStock={dataset?.dataStock}
                        cdoData={editor?.datasets}
                        parentPropContainer={editor?.propContainer}
                    />
                ) : (
                    <Loading type="empty" message={content.controls.data.noEditor} />
                )}
            </Grid>
        </ModalForm>
    ) : null;
});

export default Editor;
