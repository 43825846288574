import React from 'react';
import IconButton from '@mui/material/IconButton';
import RectangleOutlinedIcon from '@mui/icons-material/RectangleOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
// import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
// import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
// import RestoreOutlinedIcon from '@mui/icons-material/RestoreOutlined';
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import RedoOutlinedIcon from '@mui/icons-material/RedoOutlined';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
// import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

import DRAW_MODE from './constants';

interface PropsType {
    mode: string | undefined;
    selected: string | undefined;
    disabledUndo: boolean;
    disabledRedo: boolean;
    disabledSave: boolean;
    handleSetMode: (mode: string) => void;
    handleDeleteShape: () => void;
    handleUndoShape: () => void;
    handleRedoShape: () => void;
    handleDrawImage: () => void;
    handleSaveAsImage?: () => void;
    handleSave?: () => void;
}

const CanvasDrawToolbar = (props: PropsType) => {
    const {
        mode,
        selected,
        disabledUndo,
        disabledRedo,
        disabledSave,
        handleSetMode,
        handleDeleteShape,
        handleUndoShape,
        handleRedoShape,
        handleDrawImage,
        handleSaveAsImage,
        handleSave
    } = props;

    const onChangeDrawMode = (drawMode: string) => () => {
        handleSetMode(drawMode);
    };

    return (
        <>
            <IconButton onClick={handleDrawImage}>
                <AddPhotoAlternateOutlinedIcon
                    color={mode === DRAW_MODE.IMAGE ? 'primary' : 'inherit'}
                />
            </IconButton>
            <IconButton
                onClick={onChangeDrawMode(DRAW_MODE.RECTANGLE)}
                disabled={mode === DRAW_MODE.IMAGE}
            >
                <RectangleOutlinedIcon
                    color={mode === DRAW_MODE.RECTANGLE ? 'primary' : 'inherit'}
                />
            </IconButton>
            <IconButton
                onClick={onChangeDrawMode(DRAW_MODE.CIRCLE)}
                disabled={mode === DRAW_MODE.IMAGE}
            >
                <CircleOutlinedIcon color={mode === DRAW_MODE.CIRCLE ? 'primary' : 'inherit'} />
            </IconButton>
            {/* <IconButton onClick={onChangeDrawMode(DRAW_MODE.ARROW)}>
                <ArrowForwardOutlinedIcon
                    color={mode === DRAW_MODE.ARROW ? 'primary' : 'inherit'}
                />
            </IconButton> */}
            <IconButton
                onClick={onChangeDrawMode(DRAW_MODE.PENCIL)}
                disabled={mode === DRAW_MODE.IMAGE}
            >
                <BorderColorOutlinedIcon
                    color={mode === DRAW_MODE.PENCIL ? 'primary' : 'inherit'}
                />
            </IconButton>
            <IconButton onClick={handleUndoShape} disabled={disabledUndo}>
                <UndoOutlinedIcon />
            </IconButton>
            <IconButton onClick={handleRedoShape} disabled={disabledRedo}>
                <RedoOutlinedIcon />
            </IconButton>
            <IconButton disabled={!selected} onClick={handleDeleteShape}>
                <DeleteOutlineOutlinedIcon />
            </IconButton>
            <IconButton onClick={handleSaveAsImage} disabled={disabledSave}>
                <SaveAltOutlinedIcon />
            </IconButton>
            <IconButton onClick={handleSave} disabled={disabledSave}>
                <SaveOutlinedIcon />
            </IconButton>
        </>
    );
};

export default CanvasDrawToolbar;
