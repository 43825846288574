import { useEffect, useState } from 'react';
import { BackdropInstance } from '../store/notificationStore';

export default function useBackdrop(showBackdrop: boolean, timeout?: number) {
    const [backdrop, setBackdrop] = useState<BackdropInstance | null>(null);

    useEffect(() => {
        if (showBackdrop) {
            if (backdrop) {
                backdrop.updateBackdrop(timeout);
            } else {
                setBackdrop(new BackdropInstance(timeout));
            }
        } else {
            backdrop?.clearBackdrop();
        }

        return () => backdrop?.clearBackdrop();
    }, [showBackdrop]);
}
