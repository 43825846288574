import { FunctionComponent } from 'react';

import { Chip } from '@mui/material';

interface StateType {
    variant?: string;
}

const State: FunctionComponent<StateType> = ({ variant }) => {
    const getLabel = () => {
        switch (variant) {
            case 'R':
                return 'Можно приступать';

            case 'S':
                return 'Начата';

            case 'I':
                return 'Прервана';

            case 'F':
                return 'Завершена';

            case 'C':
                return 'Отменена';

            case 'W':
                return 'В ожидании';

            default:
                return 'В ожидании';
        }
    };

    const getColor = () => {
        switch (variant) {
            case 'R':
                return 'default';

            case 'S':
                return 'default';

            case 'I':
                return 'default';

            case 'F':
                return 'success';

            case 'C':
                return 'error';

            case 'W':
                return 'default';

            case 'done':
                return 'success';

            default:
                return 'default';
        }
    };

    return <Chip label={getLabel()} color={getColor()} sx={{ ml: 'auto !important' }} />;
};

export default State;
