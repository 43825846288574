import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';

import { Grid, Paper } from '@mui/material';
import GroupBoxTitle from 'forms/controls/Panel/GroupBoxTitle';

import { generateEditControl, getGridBreakpoint } from 'forms/form-utils';

import CustomDataset from 'dataObj/customDataset';
import { DatasetFieldType } from 'forms/interfaces';

import EditableNode from './EditableNode';

import { lookupGenerator } from '../../../utils';

import { GeneratedDescriptorType, TreeItemEditableContainer } from '../../../interfaces';

const EditableNodeContainer: FunctionComponent<TreeItemEditableContainer> = observer(props => {
    const { dataset, index, parentId, dataRow, disabled } = props;

    const dsOptions = useMemo(
        () => (dataset.descr.options ? JSON.parse(dataset.descr.options) : {}),
        [dataset]
    );

    const dsFields = useMemo(() => [...dataset.descr.fields], [dataset]);

    const groups = useMemo(() => {
        if (dataset.options?.groups) {
            return (dataset.options?.groupOrder || Object.keys(dataset.options?.groups)).map(
                (name: string) => {
                    const { caption, width, fields = [] } = dataset.options.groups[name];

                    const fldIdx = dsFields
                        .map((field: DatasetFieldType, idx) =>
                            fields.includes(field.name) ? idx : undefined
                        )
                        .filter(idx => idx !== undefined) as number[];

                    const grpFields = fields.map((fldName: string) =>
                        dsFields.find(field => field.name === fldName)
                    );

                    for (let i = fldIdx.length - 1; i >= 0; i--) {
                        dsFields.splice(fldIdx[i], 1);
                    }

                    return {
                        name,
                        caption,
                        width,
                        fields: grpFields
                    };
                }
            );
        }

        return [];
    }, [dataset]);

    const propContainer = useMemo(() => dataset.getPropContainer(), [dataset]);

    const generateLookup = useCallback(lookupGenerator, [dataset?.guid]);

    const getFieldCtrl = useCallback(
        (ds: CustomDataset, fieldDescr: DatasetFieldType) => {
            if (fieldDescr.lookupSource)
                return generateLookup(ds, fieldDescr) as GeneratedDescriptorType;

            return generateEditControl(
                fieldDescr.caption,
                ds.name,
                fieldDescr.name,
                ds.options?.fields[fieldDescr.name]
            ) as GeneratedDescriptorType;
        },
        [dataset]
    );

    const renderFields = useCallback(
        (fieldList: DatasetFieldType[]) =>
            fieldList
                .filter((field: DatasetFieldType) => !field.hidden)
                .map((field: DatasetFieldType) => {
                    // TODO Предусмотреть другие типы контролов?
                    const ctrlDescr = getFieldCtrl(dataset, field);

                    if (field.multiple) {
                        ctrlDescr.ctrlType = ctrlDescr.type;
                        ctrlDescr.type = 'dbMultipleEdit';
                    }

                    return (
                        <EditableNode
                            key={`${parentId}-${field.guid}-${index}`}
                            nodeId={`${parentId}-${field.guid}-${index}`}
                            parentId={parentId}
                            labelText={field.caption || field.name}
                            fieldName={field.name}
                            descr={ctrlDescr}
                            propContainer={propContainer}
                            disabled={field.archive || dsOptions?.archive || disabled || false}
                            dataRow={dataRow}
                            index={index}
                            multiple={field.multiple}
                        />
                    );
                }),
        [dataset, dsOptions?.archive, disabled]
    );

    if (dataset?.descr?.fields?.length) {
        return (
            <>
                {groups.map(
                    (group: {
                        name: string;
                        caption: string;
                        width: string;
                        fields: DatasetFieldType[];
                    }) => {
                        const gridBreakpoint = getGridBreakpoint(group.width);

                        return (
                            <Grid
                                container
                                sx={{ width: '100%', position: 'relative' }}
                                {...gridBreakpoint}
                            >
                                {group.caption ? (
                                    <>
                                        <GroupBoxTitle guid={group.name} caption={group.caption} />
                                        <Paper
                                            variant="outlined"
                                            elevation={0}
                                            sx={{ pt: 2, m: 2 }}
                                        >
                                            {renderFields(group.fields)}
                                        </Paper>
                                    </>
                                ) : (
                                    renderFields(group.fields)
                                )}
                            </Grid>
                        );
                    }
                )}
                {renderFields(dsFields)}
            </>
        );
    }

    return null;
});

export default EditableNodeContainer;
