import React, { forwardRef } from 'react';
import { Box } from '@mui/material';

import glossStyles from './gloss.module.scss';

import { ActionType } from './interfaces';

interface PropsType {
    onClick: (type: ActionType) => unknown;
}

const Carriage = forwardRef<HTMLDivElement | null, PropsType>(({ onClick }, ref) => (
    <div className={glossStyles.glossFloatButtons} ref={ref}>
        <Box className={glossStyles.imgForward} onClick={() => onClick('forward')} />
        <Box className={glossStyles.imgBackward} onClick={() => onClick('backward')} />
        <Box className={glossStyles.imgPoint} onClick={() => onClick('point')} />
        <Box className={glossStyles.imgComma} onClick={() => onClick('comma')} />
        <Box className={glossStyles.imgEnter} onClick={() => onClick('enter')} />
    </div>
));

export default Carriage;
