import { makeObservable, observable, action } from 'mobx';
import DataAccum from './DataAccum';
import { IQueryRow } from './dataInterfaces';
import { AggFuncType } from 'components/resources/ResEditor/gridSettings';

type TAccumGroupProps = {
    accum: DataAccum;
    parent?: AccumGroup;
    level: number;
    grVal: any;
};

type TAggrObj = Record<AggFuncType | 'value', number>;

export default class AccumGroup {
    accum: DataAccum;
    parent?: AccumGroup;
    level: number;
    grFld?: string;
    grVal: any;
    cldGrFld?: string;
    cldGroups: Map<any, AccumGroup>;
    aggr: { [key: string]: TAggrObj };
    recs: number[];

    constructor(props: TAccumGroupProps) {
        const { accum, parent, level, grVal } = props;

        this.accum = accum;
        this.parent = parent;
        this.level = level;
        // поле, по которому собрана группа
        this.grFld = accum.groupFields && accum.groupFields[level - 1];
        // значение поля, по которому собрана группа
        this.grVal = grVal;
        // поле, по которому группа собирает дочерние группы
        this.cldGrFld = accum.groupFields && accum.groupFields[level];

        // объект агрегатор (подсчет сумм и других агрегатных функций)
        this.aggr = {};

        // дочерние группы
        this.cldGroups = new Map();
        // номера входящих записей, заполняется для групп на вершинах дерев группировки.
        this.recs = [];

        makeObservable(this, { recs: observable, add: action });
    }

    add(rec: IQueryRow, num: number) {
        if (!this?.accum?.aggrFields) return;

        this.accum.aggrFields.forEach(aggrFld => {
            const val = Number(rec[aggrFld.fieldName]);

            let aggrObj: TAggrObj = this.aggr[aggrFld.fieldName];
            if (!aggrObj) aggrObj = { sum: 0, min: val, max: val, count: 0, avg: 0, value: 0 };

            aggrObj.sum += Number.isNaN(val) ? 0 : val;
            if (aggrObj.min > val) aggrObj.min = val;
            if (aggrObj.max < val) aggrObj.max = val;
            aggrObj.count++;
            aggrObj.avg = aggrObj.sum / aggrObj.count;

            aggrObj.value = aggrObj[aggrFld.func];

            this.aggr[aggrFld.fieldName] = aggrObj;
        });

        if (this.cldGrFld) {
            const grVal = rec[this.cldGrFld];
            let cldElem = this.cldGroups.get(grVal);
            if (!cldElem) {
                cldElem = new AccumGroup({
                    accum: this.accum,
                    parent: this,
                    level: this.level + 1,
                    grVal
                });
                this.cldGroups.set(grVal, cldElem);
            }
            cldElem.add(rec, num);
        } else {
            // если полей группировки больше нет -
            // добавляем запись в последнюю группу дерева группировки
            this.recs.push(num);
        }
    }
}
