interface StyleProps {
    visible: boolean;
    hasData: boolean;
}

export const dataGridStyles = ({ visible, hasData }: StyleProps) => ({
    wrapper: {
        height: '100%',
        overflow: 'hidden',
        visibility: visible ? 'inherit' : ('hidden' as any)
    },
    table: {
        borderCollapse: hasData ? 'collapse' : 'separate',
        tableLayout: 'fixed'
    },
    headerCell: { backgroundColor: '#fff', padding: '12px 16px' },
    tableBody: { border: '1px solid #d9deec' },
    tableCell: { borderWidth: '0' }
});

interface ToolbarStyleProps {
    containerWidth: number;
    breakpointWidth: number;
}

export const toolbarStyles = ({ containerWidth, breakpointWidth }: ToolbarStyleProps) => ({
    toolbar: {
        zIndex: 1,
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
        textTransform: 'none',
        '& .title': {
            flex: '1 1 100%'
        }
    },
    buttons: { display: containerWidth >= breakpointWidth ? 'flex' : 'none' },
    iconButtons: { display: containerWidth < breakpointWidth ? 'flex' : 'none' }
});
