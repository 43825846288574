import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';

import { Box } from '@mui/material';

import { ControlPropsType, DBDocumentViewerType } from '../interfaces';

import DBHtmlViewer from './DBHtmlViewer';
import DBRtfViewer from './DBRtfViewer';
import useCtrlData from '../../hooks/ctrlData';
import useNodeResize from '../../hooks/nodeResize';

interface PropsType extends ControlPropsType {
    descr: DBDocumentViewerType;
    dataRow: any;
}

const DBDocumentViewer: FunctionComponent<PropsType> = observer(
    ({ descr, propContainer, dataRow }) => {
        const documentWrapperRef = useRef(null) as any;

        const { datasetName, fieldName } = descr;

        const { dataset, dataVal } = useCtrlData(propContainer, datasetName, fieldName, dataRow);

        const [minWidth] = useNodeResize(documentWrapperRef);

        const checkRTF = (str: string) => /^{\\rtf/i.test(str);

        const [content, setContent] = useState('<b>...</b>');

        const styles = {
            height: '100%',
            width: '100%',
            position: 'relative'
        };

        useEffect(() => {
            if (dataset) {
                setContent(dataVal);
            }
        }, [dataset, dataVal]);

        const renderDocument = () => {
            if (checkRTF(content))
                return (
                    <DBRtfViewer
                        descr={descr}
                        propContainer={propContainer}
                        content={content}
                        minWidth={minWidth}
                    />
                );
            return (
                <DBHtmlViewer
                    descr={descr}
                    propContainer={propContainer}
                    content={content}
                    minWidth={minWidth}
                />
            );
        };

        return (
            <Box sx={styles} ref={documentWrapperRef}>
                {renderDocument()}
            </Box>
        );
    }
);

export default DBDocumentViewer;
