import { SimpleObject } from 'forms/interfaces';

const alignText = (content: any): SimpleObject => ({
    left: content.left,
    right: content.right,
    center: content.center,
    justify: content.justify
});
const sortModeText = (content: any): SimpleObject => ({
    asc: content.asc,
    desc: content.desc,
    deny: content.deny,
    none: content.none
});
const aggMode = (content: any): SimpleObject => ({
    sum: content.sum,
    avg: content.avg,
    min: content.min,
    max: content.max,
    count: content.count
});

export const toolbarButtons = (content: any): SimpleObject => [
    { name: 'add', caption: content.controls.buttons.add },
    { name: 'edit', caption: content.controls.buttons.edit },
    { name: 'delete', caption: content.controls.buttons.delete }
];

export const sortGridColumns = (content: any) => [
    {
        field: 'caption',
        headerName: content.columns.caption.headerName,
        description: content.columns.caption.description,
        flex: 1,
        disableColumnMenu: true,
        editable: true,
        sortable: false
    },
    {
        field: 'direction',
        type: 'singleSelect',
        headerName: content.columns.direction.headerName,
        disableColumnMenu: true,
        sortable: false,
        editable: true,
        description: content.columns.direction.description,
        valueOptions: ['asc', 'desc'],
        valueFormatter: (params: any) => {
            if (params.value === null || typeof params.value !== 'string') {
                return '';
            }
            return sortModeText(content.sortModeText)[params.value];
        },
        width: 150
    }
];

export const fieldListColumns = (content: any) => [
    {
        field: 'caption',
        headerName: content.columns.caption.headerName,
        description: content.columns.caption.description,
        flex: 1,
        disableColumnMenu: true,
        editable: false,
        sortable: false
    }
];

export const tableColumns = (content: any) => [
    {
        field: 'visible',
        type: 'boolean',
        headerName: content.columns.visible.headerName,
        description: content.columns.visible.description,
        width: 120,
        disableColumnMenu: true,
        editable: true,
        sortable: false
    },
    {
        field: 'caption',
        headerName: content.columns.caption.headerName,
        description: content.columns.caption.description,
        flex: 1,
        disableColumnMenu: true,
        editable: true,
        sortable: false
    },
    {
        field: 'align',
        type: 'singleSelect',
        headerName: content.columns.align.headerName,
        description: content.columns.align.description,
        width: 120,
        disableColumnMenu: true,
        editable: true,
        sortable: false,

        valueOptions: ['left', 'right', 'center', 'justify'],
        valueFormatter: (params: any) => {
            if (params.value === null || typeof params.value !== 'string') {
                return '';
            }
            return alignText(content.alignText)[params.value];
        }
    } /* ,
    {
        field: 'width',
        type: 'number',
        disableColumnMenu: true,
        sortable: false,
        editable: true,
        headerName: 'Ширина',
        description: 'Ширина указанного поля',
        width: 120
    } */
];

export const AggGridColumns = (content: any) => [
    {
        field: 'caption',
        headerName: content.columns.caption.headerName,
        description: content.columns.caption.description,
        flex: 1,
        disableColumnMenu: true,
        editable: true,
        sortable: false
    },
    {
        field: 'func',
        type: 'singleSelect',
        headerName: content.columns.func.headerName,
        disableColumnMenu: true,
        sortable: false,
        editable: true,
        description: content.columns.func.headerName,
        valueOptions: ['sum', 'avg', 'min', 'max', 'count'],
        valueFormatter: (params: any) => {
            if (params.value === null || typeof params.value !== 'string') {
                return '';
            }
            return aggMode(content.aggMode)[params.value];
        },
        width: 150
    }
];

export const toolbarColumns = (content: any) => {
    const { caption, visible } = content.columns;

    return [
        {
            field: 'caption',
            headerName: caption.headerName,
            description: caption.description,
            flex: 1,
            disableColumnMenu: true,
            editable: true,
            sortable: false
        },
        {
            field: 'visible',
            type: 'boolean',
            headerName: visible.headerName,
            description: visible.description,
            width: 120,
            disableColumnMenu: true,
            editable: true,
            sortable: false
        }
    ];
};
