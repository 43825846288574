import React, { FunctionComponent, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { observer } from 'mobx-react';

import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { Toolbar, Box, List, Typography, IconButton, Divider } from '@mui/material';
import { styled, Theme, CSSObject } from '@mui/material/styles';

import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { ControlPropsType, MenuType } from 'forms/interfaces';

import MainMenuStore from 'store/mainMenuStore';

import SideBarListItem from './ListItem';
import SVGIcon from '../../SVGIcon';

const DRAWER_WIDTH = 300;

const openedMixin = (theme: Theme): CSSObject => ({
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(11)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(13)} + 1px)`
    }
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 4, 0, 4),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
}));

const MenuButtonWrapper = styled('div')(({ theme }) => ({
    position: 'fixed',
    left: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 4, 0, 4),
    zIndex: theme.zIndex.drawer,
    ...theme.mixins.toolbar
}));

const Drawer = styled(MuiDrawer)(({ theme, open, variant }) => ({
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open &&
        variant === 'permanent' && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme)
        })
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: prop => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    ...(open && {
        marginLeft: DRAWER_WIDTH,
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    })
}));

interface PropsType extends ControlPropsType {
    descr: MenuType;
    fixed?: boolean;
}

const SideBar: FunctionComponent<PropsType> = observer(
    ({ descr, propContainer, fixed = false }) => {
        const { open, setOpen, initMenuSelections, setMenuVariant, setMenuPosition } =
            MainMenuStore;
        const history = useHistory();
        const location = useLocation();

        useEffect(() => {
            // Инициализируем только при получении пути
            if (history.action === 'POP') {
                initMenuSelections();
                setMenuVariant(descr.variant);
                setMenuPosition();
                setOpen(true);
            }
        }, [location, history, descr.variant]);

        const { items } = descr;

        const handleOpen = () => {
            setOpen(true);
        };

        const handleClose = () => {
            setOpen(false);
        };

        return (
            <>
                {fixed && (
                    <AppBar position="fixed" open={open}>
                        <Toolbar>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleOpen}
                                edge="start"
                                sx={{
                                    marginRight: 5,
                                    marginLeft: -1.5,
                                    ...(open && { display: 'none' })
                                }}
                            >
                                <MenuIcon />
                            </IconButton>
                            {!open && (
                                <Typography
                                    variant="h5"
                                    noWrap
                                    component="div"
                                    sx={{ mr: 'auto', display: 'inline-flex' }}
                                >
                                    {descr.icon ? (
                                        <SVGIcon
                                            descr={descr}
                                            propContainer={propContainer}
                                            sx={{ m: 'auto', mr: 2 }}
                                            fontSize="inherit"
                                        />
                                    ) : null}
                                    {descr?.caption}
                                </Typography>
                            )}
                        </Toolbar>
                    </AppBar>
                )}
                <Drawer
                    id={`drawer-${descr.guid}`}
                    variant={fixed ? 'permanent' : 'temporary'}
                    open={open}
                >
                    <DrawerHeader>
                        <Typography
                            color="primary"
                            variant="h5"
                            noWrap
                            component="div"
                            sx={{ mr: 'auto', display: 'inline-flex' }}
                        >
                            {descr.icon ? (
                                <SVGIcon
                                    descr={descr}
                                    propContainer={propContainer}
                                    sx={{ m: 'auto', mr: 2 }}
                                    fontSize="inherit"
                                />
                            ) : null}
                            {descr?.caption}
                        </Typography>
                        <IconButton id={`close-menu-btn-${descr.guid}`} onClick={handleClose}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </DrawerHeader>
                    <Divider />
                    <Box sx={{ width: DRAWER_WIDTH, overflowY: 'auto' }} role="presentation">
                        <List>
                            {items.map(item => (
                                <SideBarListItem
                                    key={item.guid}
                                    descr={item}
                                    propContainer={propContainer}
                                />
                            ))}
                        </List>
                    </Box>
                </Drawer>
                {!fixed && (
                    <MenuButtonWrapper id={`open-menu-btn-${descr.guid}`}>
                        <IconButton onClick={handleOpen}>
                            <MenuIcon />
                        </IconButton>
                    </MenuButtonWrapper>
                )}
            </>
        );
    }
);

export default SideBar;
