import React, { FunctionComponent, useState } from 'react';
import { observer } from 'mobx-react';

import { Grid, IconButton } from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import {
    DataGrid,
    GridCellEditCommitParams,
    GridRowId,
    GridSelectionModel
} from '@mui/x-data-grid';

import { GridToolbarType } from '../index';
import { SimpleObject } from '../../../../../forms/interfaces';

const arraymove = (arr: unknown[], fromIndex: number, toIndex: number) => {
    const element = arr[fromIndex];

    arr.splice(fromIndex, 1);

    let newIndex = toIndex;
    if (toIndex < 0) {
        newIndex = arr.length;
    } else if (toIndex > arr.length) {
        newIndex = 0;
    }

    arr.splice(newIndex, 0, element);
    return arr;
};

const GridToolbar: FunctionComponent<GridToolbarType> = observer(
    ({ columns, rows, setRows, defaultItem }) => {
        const [selectedRows, setSelectedRows] = useState<GridRowId[]>([]);
        defaultItem = defaultItem || {};

        const getIndex = (arr: SimpleObject[], id: unknown) => rows.findIndex(row => row.id === id);

        const handleAddClick = () => {
            setRows(
                [...rows, ...[defaultItem]].map((row, index) => ({ ...row, ...{ id: `${index}` } }))
            );
        };
        const handleRemoveClick = () => {
            const arr = [...rows];
            selectedRows.forEach(row => arr.splice(getIndex(arr, row), 1));
            setRows(arr);
        };
        const handleMoveClick = (up: boolean) => {
            const arr = [...rows];
            selectedRows.forEach(row => {
                const idx = getIndex(arr, row);
                const toIdx = (up ? -1 : 1) + idx;
                arraymove(arr, idx, toIdx);
            });
            setRows(arr);
        };

        const SelectionModelChange = (model: GridSelectionModel) => setSelectedRows(model);

        const onCellEditCommit = (params: GridCellEditCommitParams) => {
            // params
            const row = Object.values(rows).find(item => item.id === params.id);
            if (row) {
                row[params.field] = params.value;
            }
            setRows(rows);
        };

        const actionButtons = () => {
            const actionButtonCfg: { [key: string]: any } = {
                up: {
                    color: 'default',
                    icon: ArrowUpward,
                    onlySelected: true,
                    onClick: () => handleMoveClick(true)
                },
                down: {
                    color: 'default',
                    icon: ArrowDownward,
                    onlySelected: true,
                    onClick: () => handleMoveClick(false)
                }
            };

            return (
                <Grid item>
                    {Object.keys(actionButtonCfg).map((name: string) => {
                        const item = actionButtonCfg[name];
                        return (
                            <IconButton
                                color={item.color}
                                onClick={item.onClick}
                                disabled={item.onlySelected && selectedRows.length === 0}
                            >
                                {React.createElement(item.icon)}
                            </IconButton>
                        );
                    })}
                </Grid>
            );
        };

        return (
            <Grid container direction="column" sx={{ height: '100%' }}>
                {/* actionButtons() */}
                <Grid item flex="1 1">
                    <DataGrid
                        columns={columns}
                        rows={rows}
                        hideFooter
                        onSelectionModelChange={SelectionModelChange}
                        onCellEditCommit={onCellEditCommit}
                        // style={{ height: '100%' }}
                        hideFooterPagination
                    />
                </Grid>
            </Grid>
        );
    }
);

export default GridToolbar;
