import merge from 'lodash/merge';

import {
    Localisation,
    Locale,
    TranslationScheme,
    TranslationType
} from '../components/resources/ResEditor/ResTranslationEditor/interfaces';

import resValidator from '../components/resources/clientResValidator';

const LOCALISATIONS = ['rus', 'eng', 'fra'];

export default class Translation {
    private lang;
    private translation;
    private localisations;
    private customFldCheck = /00000000-0000-0000-0000-000000000000_/;

    private parseTranslation(translation: string): TranslationType {
        try {
            return JSON.parse(translation);
        } catch (err: any) {
            console.error(err.message);
            return {};
        }
    }

    constructor(lang: Localisation, translation: string, localisations = LOCALISATIONS) {
        this.lang = lang;
        this.translation = this.parseTranslation(translation);
        this.localisations = localisations;
    }

    private translate(descr: any) {
        const { guid, type } = descr;

        // Получаем поля описателя по типу контрола
        const descrFields = resValidator.getTypeDescriptor(type)?.fields as any;
        const translateFields =
            Object.keys(descrFields)?.filter(fld => descrFields[fld].translate) ?? [];

        const colNames = [];

        // Вычисляем поля со вложенными элементами из описателя
        for (const fld in descrFields) {
            if (descrFields[fld].type === 'array') {
                colNames.push(fld);
            }
        }

        translateFields.map((field: string) => {
            const translation = this.translation[`${guid as string}_${field}`];

            if (translation) {
                descr[field] = translation[this.lang] ? translation[this.lang] : descr[field];
            }
            return;
        });

        colNames.map(col => {
            descr[col]?.map((control: any) => this.translate(control));
            return;
        });

        return descr;
    }

    public run(ctrlDescr: any) {
        return this.translate({ ...ctrlDescr });
    }

    private generateTemplateLocalisation() {
        const templateLocalisation: TranslationScheme = {};

        Object.keys(this.translation)
            .filter(key => this.customFldCheck.test(key))
            .map(key => {
                // Формируем новый набор локалей
                const localisation: Locale = {
                    init: this.translation[key].init || this.translation[key][this.lang]
                };

                this.localisations.map(loc => {
                    if (this.lang !== loc)
                        localisation[loc as Localisation] =
                            this.translation[key][loc as Localisation] ?? '';
                    return;
                });

                templateLocalisation[key] = {
                    path: `${key.replace(this.customFldCheck, '')}`,
                    custom: true,
                    localisation
                };
                return;
            });

        return templateLocalisation;
    }

    private generateLocalisationArray(resource: any) {
        const { guid, type } = resource;
        // Получаем описатель по типу контрола
        const descrFields = resValidator.getTypeDescriptor(type)?.fields as any;
        const colNames = [];

        // Вычисляем поля со вложенными элементами из описателя
        for (const fld in descrFields) {
            if (descrFields[fld].type === 'array') {
                colNames.push(fld);
            }
        }

        let translationScheme: { [key: string]: { path: string; localisation: Locale } } = {};

        if (descrFields) {
            Object.keys(descrFields)
                // Выбираем только поля, подлежащие переводу согласно описателю
                .filter(key => descrFields[key].translate)
                .map(fld => {
                    const key = `${guid as string}_${fld}`;

                    // Формируем новый набор локалей
                    const localisation: Locale = { init: resource[fld] };

                    this.localisations.map(loc => {
                        if (this.lang !== loc) localisation[loc as Localisation] = '';
                        return;
                    });

                    // Если перевод для поля уже существует, то используем его
                    if (this.translation[key]) {
                        translationScheme[key] = {
                            path: `${resource.type as string} "${resource.name as string}": ${fld}`,
                            localisation: {
                                ...localisation,
                                ...this.translation[key]
                            }
                        };
                        return;
                    }

                    translationScheme[key] = {
                        path: `${resource.type as string} "${resource.name as string}": ${fld}`,
                        localisation
                    };
                    return;
                });
        }

        // Рекурсивно идём во все вложенные контролы
        colNames.map(col => {
            resource[col]?.map((control: any) => {
                translationScheme = {
                    ...translationScheme,
                    ...this.generateLocalisationArray(control)
                };
                return;
            });
            return;
        });

        return translationScheme;
    }

    public getScheme(resource: any) {
        return {
            ...this.generateLocalisationArray(resource),
            ...this.generateTemplateLocalisation()
        };
    }

    public updateTranslation(newTranslation: { [key: string]: Locale }, deletion: string[]) {
        // Объединяем исходную схему перевода с обновлённой
        const updateTranslation = merge({}, this.translation, newTranslation);

        Object.keys(updateTranslation)
            // Убираем начальный перевод языка формы
            .map(key => {
                if (!this.customFldCheck.test(key)) {
                    delete updateTranslation[key].init;
                } else {
                    // Кроме кастомных строк
                    updateTranslation[key][this.lang] = updateTranslation[key].init;
                    // delete updateTranslation[key].init;
                }
                return key;
            })
            // Убираем все пустые переводы
            .map(key => {
                const trns = updateTranslation[key]; // => { rus: '', eng: '', fra: '' }

                // Кроме кастомных строк
                if (!Object.keys(trns).filter(local => !!trns[local as Localisation]).length) {
                    delete updateTranslation[key];
                }
                return;
            });

        // Очищаем удалённые кастомные поля
        deletion.map(key => {
            if (this.customFldCheck.test(key)) delete updateTranslation[key];
            return;
        });

        return updateTranslation;
    }
}
